import { StyledLink } from './styles/AdditionalInfoCard.style'

interface AdditionalInfoProps {
  message: string
  url: string
}

export const AdditionalInfoCard = ({ message, url }: AdditionalInfoProps) => {
  return (
    <>
      <p>{message}</p>
      <StyledLink href={url}>Saiba Mais</StyledLink>
    </>
  )
}
