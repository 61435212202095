const Money = ({
  amount = 0,
  currency = true,
  isBold = false,
}: {
  amount?: number
  currency?: boolean
  isBold?: boolean
}) => {
  const formated = (amount || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  return (
    <span style={{ fontWeight: isBold ? '600' : '400' }}>
      {currency ? formated : formated.slice(3)}
    </span>
  )
}

export default Money
