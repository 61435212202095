import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { apiGet } from '../../services/api'
import { Invoices } from '../templates/Invoices'
import { _Pagination } from '../molecules/Pagination'
import { InvoiceSummary } from '../../store/data/types'
import { StyledSpinner } from './LoadablePage'
import { INVOICE_PAGE_SIZE } from '../../common/constants'
import { clubbiStyled as styled } from 'clubbi-ui'
import { RecommendedCorridorMyDeliveryPage } from '../molecules/RecommendedCorridorMyDelivery/RecommendedCorridorMyDeliveryPage'
import { useAppSelector } from '../../common/hooks'

interface Props {
  invoices: InvoiceSummary[]
  total: number
}

const StyledInvoicesSummary = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brandGrey.light,
}))

const StyledHeading = styled('h1')(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.brandPurple.main,
  paddingBottom: '24px',
  paddingTop: '24px',
  fontWeight: '1000',
  fontSize: '40px',
  lineHeight: '50%',
}))

const StyledDescription = styled('p')(({ theme }) => ({
  textAlign: 'center',
  color: 'black',
  padding: '0px 50px',
}))

const StyledCarousel = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '20px',
}))

export const invoicesFetch = async (params: {
  customerId: string
  limit: number
  offset: number
}) => {
  const response = await apiGet(
    `/invoices/${params.customerId}?limit=${params.limit}&offset=${params.offset}`
  )
  const invoices = response.invoices
  const total = response.total
  return { invoices, total }
}

export const InvoicesSummary = () => {
  const [data, setData] = useState<Props>()
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)

  const merchantCode = useAppSelector((state) => state.session.merchantCode)

  const getInvoices = async () => {
    setIsLoading(true)
    if (merchantCode) {
      const data = await invoicesFetch({
        customerId: merchantCode,
        limit: INVOICE_PAGE_SIZE,
        offset: INVOICE_PAGE_SIZE * (page - 1),
      })

      setIsLoading(false)
      setData(data)
    }
  }

  useEffect(() => {
    getInvoices()
  }, [merchantCode, page])

  if (!merchantCode) {
    /* TODO: Make this in the router (already tried and leaked memory, this is a quick fix) */
    return <Redirect to={'/'} />
  }

  return (
    <StyledInvoicesSummary>
      {!data || isLoading ? (
        <StyledSpinner animation={'border'} role={'spinbutton'}>
          <span className="sr-only">Carregando...</span>
        </StyledSpinner>
      ) : (
        <>
          <StyledCarousel>
            <RecommendedCorridorMyDeliveryPage />
          </StyledCarousel>
          <StyledHeading>Minhas Entregas</StyledHeading>
          <StyledDescription>
            Pedidos que estão em separação, em rota ou finalizados aparecem aqui, pedidos em aberto
            estão na aba &quot;Pedidos em Aberto&quot;.
          </StyledDescription>

          <Invoices invoices={data!.invoices} />
          <_Pagination total={data!.total} page={page} setPage={setPage} />
        </>
      )}
    </StyledInvoicesSummary>
  )
}
