import { themeClubbi, useMediaQuery } from 'clubbi-ui'
import { useRef, useState } from 'react'
import Countdown from 'react-countdown'
import { useHistory } from 'react-router-dom'

import { useAppSelector } from '../../../common/hooks'
import { handleScrollTo, showOrHideArrowsByScroll } from '../../../common/ScrollUtils'
import {
  END_BLACK_FRIDAY,
  END_ESQUENTA_BF,
  isWithinDateRange,
  pad,
  START_BLACK_FRIDAY,
  START_ESQUENTA_BF,
} from '../../../common/utils'
import { Product } from '../../../store/data/types'
import { TagIcon } from '../categoriesHorizontalMenu/icons/TagIcon'
import { OfertaoCard } from './OfertaoCard'
import gtm from '../../../common/gtmTracker'

import {
  StyledArrowButton,
  StyledContainerOfertaoBox,
  StyledContentOfertaoBox,
  StyledCountdown,
  StyledText,
  StyledTextContent,
  StyledTitleDayOffers,
  StyledButton,
  StyledCardContainers,
  StyledContainer,
  StyledButtonTitle,
  StyledBFIcons,
} from './style/OfertaoBox.style'

export interface OffersProps {
  products?: Product[]
}

export const OfertaoBox = ({ products }: OffersProps) => {
  const [leftButton, setLeftButton] = useState(false)
  const [rightButton, setRightButton] = useState(true)

  const isDesktop = useMediaQuery(themeClubbi.breakpoints.up('md'))
  const { merchantCode, clubberEmail } = useAppSelector((state) => state.session)

  const corridor = useRef(null)

  const renderer = ({
    hours,
    minutes,
    seconds,
  }: {
    hours: number
    minutes: number
    seconds: number
    completed: boolean
  }): JSX.Element => {
    const timer = `Termina em: ${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`
    return <StyledCountdown>{timer}</StyledCountdown>
  }

  const history = useHistory()

  const handleScrollBar = (_e: any) => {
    const element: any = corridor.current
    showOrHideArrowsByScroll(element, setRightButton, setLeftButton)
  }

  const handleScrollToRight = () => {
    const element: any = corridor.current
    handleScrollTo(element, 'right')
  }

  const handleScrollToLeft = () => {
    const element: any = corridor.current
    handleScrollTo(element, 'left')
  }

  const handleClickSeeMoreOffersButton = (): void => {
    gtm.triggerBannerOfertaoClick(merchantCode!, clubberEmail)
    history.push('/products/ofertoes')
  }

  const date = products && products?.[0]?.datetimeToExpire

  const seeMoreOffersButton = `VER ${products && products?.length} OFERTAS`

  const offersQuantity = products?.length

  const isEsquenta = isWithinDateRange(START_ESQUENTA_BF, END_ESQUENTA_BF)
  const isBlackFriday = isWithinDateRange(START_BLACK_FRIDAY, END_BLACK_FRIDAY)

  return (
    <StyledContainerOfertaoBox>
      <StyledContentOfertaoBox>
        <StyledTextContent>
          <StyledContainer>
            <StyledTitleDayOffers>
              {isEsquenta && (
                <StyledBFIcons>
                  <img src={'/img/icon-fire.png'} alt="Icone de fogo" width={25} height={30} />
                  <>Esquentaaa!</>
                </StyledBFIcons>
              )}
              {isBlackFriday && (
                <StyledBFIcons>
                  <img src={'/img/icon-coin.png'} alt="Icone de moeda" width={30} height={30} />
                  <>Black Friday!</>
                </StyledBFIcons>
              )}
              {!isEsquenta && !isBlackFriday && <>Ofertão do dia!</>}
            </StyledTitleDayOffers>
            {isDesktop && (
              <StyledText>
                {!isEsquenta ? (
                  <>
                    Todos os dias <br />
                    novas ofertas <br />
                    pra você.
                  </>
                ) : (
                  'Aproveite o Ofertão Esquenta Black Friday.'
                )}
              </StyledText>
            )}
            <Countdown date={date} renderer={renderer} />
          </StyledContainer>
          {products && (
            <StyledButton onClick={handleClickSeeMoreOffersButton}>
              {isDesktop && <TagIcon color={themeClubbi.palette.brandWhite.main} />}
              <StyledButtonTitle>{seeMoreOffersButton}</StyledButtonTitle>
            </StyledButton>
          )}
        </StyledTextContent>
        {offersQuantity !== undefined && offersQuantity > 5 && leftButton && (
          <StyledArrowButton
            direction="left"
            onClick={handleScrollToRight}
            color={themeClubbi.palette.brandWhite.main}
          />
        )}
        <StyledCardContainers onScroll={handleScrollBar} ref={corridor}>
          {products?.map((product, index) => (
            <OfertaoCard product={product} key={index} />
          ))}
        </StyledCardContainers>
        {offersQuantity !== undefined && offersQuantity > 5 && rightButton && (
          <StyledArrowButton
            direction="right"
            onClick={handleScrollToLeft}
            color={themeClubbi.palette.brandWhite.main}
          />
        )}
      </StyledContentOfertaoBox>
    </StyledContainerOfertaoBox>
  )
}
