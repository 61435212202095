import { clubbiStyled } from 'clubbi-ui'

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  width: '145px',
  height: '230px',
  borderRadius: '6px',
  border: `6px solid ${theme.palette.brandAlert[50]}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.brandWhite.main,
  padding: '4px',
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    width: '200px',
    height: '285px',
  },
}))

export const StyledContent = clubbiStyled('div')(({ theme }) => ({
  width: '130px',
  height: '210px',
  borderRadius: '2px',
  border: `2px solid ${theme.palette.brandAlert[50]}`,
  padding: '10px',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    width: '220px',
    height: '267px',
  },
}))

export const StyledContainerDescription = clubbiStyled('div')(({ theme }) => ({
  height: '200px',
}))

export const StyledDescription = clubbiStyled('h3')(({ theme }) => ({
  fontWeight: '700',
  fontSize: theme.typography.fontSize3XS,
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))

export const StyledSubTitle = clubbiStyled('h5')(({ theme }) => ({
  fontWeight: '400',
  textAlign: 'center',
  fontSize: theme.typography.fontSize3XS,
  [theme.breakpoints.up('md')]: {
    marginBottom: '10px',
    fontSize: theme.typography.fontSize2XS,
  },
}))

export const StyledContainerPrice = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '4px',
  [theme.breakpoints.up('md')]: {
    gap: '-2px',
  },
}))

export const StyledContentPrice = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
}))

export const StyledPrice = clubbiStyled('h1')(({ theme }) => ({
  fontWeight: '700',
  fontSize: theme.typography.fontSizeL,
  color: theme.palette.brandAlert[50],
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.fontSize4XL,
  },
}))

export const StyledCurrencySign = clubbiStyled('div')(({ theme }) => ({
  fontWeight: '400',
  fontSize: theme.typography.fontSize2XS,
  color: theme.palette.brandAlert[50],
  marginBottom: '2px',
  [theme.breakpoints.up('md')]: {
    marginBottom: '0px',
  },
}))

export const StyledContainerImage = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  marginTop: '5px',
}))

export const StyledImage = clubbiStyled('img')(({ theme }) => ({
  display: 'flex',
  width: '58px',
  height: '58px',
  mixBlendMode: 'multiply',
  justifyContent: 'end',
  alignItems: 'end',
  [theme.breakpoints.up('md')]: {
    width: '90px',
    height: '90px',
  },
}))

export const StyledTopDiv = clubbiStyled('div')(({ theme }) => ({
  width: '33px',
  height: '3px',
  borderRadius: '4px',
  background: theme.palette.brandYellow[50],
  marginBottom: '-5px',
}))

export const StyledBottomDiv = clubbiStyled('div')(({ theme }) => ({
  width: '33px',
  height: '3px',
  borderRadius: '4px',
  background: theme.palette.brandYellow[50],
  marginTop: '-13px',
}))
