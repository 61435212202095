import { Checkbox, FormControlLabel, clubbiStyled } from 'clubbi-ui'

export const StyledContainer = clubbiStyled('div')<{ isContactPage: boolean }>(
  ({ isContactPage }) => ({
    display: isContactPage ? 'none' : 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: '4px !important',
  })
)

export const StyledCheckbox = clubbiStyled(Checkbox)(({ theme }) => ({
  color: theme.palette.brandGrey[80],
  paddingTop: 0,
  '&.Mui-checked': {
    color: theme.palette.brandPurple[60],
  },
}))

export const StyledHR = clubbiStyled('hr')(({ theme }) => ({
  color: theme.palette.brandGrey.light,
  width: '100%',
  marginTop: '16px',
  marginBottom: '12px',
  [theme.breakpoints.up('md')]: {
    marginTop: '20px',
    marginBottom: '16px',
  },
}))

export const StyledLabelContainer = clubbiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledLabel = clubbiStyled('span')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '12px',
  marginTop: '2px',
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
  },
}))

export const StyledSubLabel = clubbiStyled('span')(({ theme }) => ({
  fontSize: '10px',
  lineHeight: '146.2%',
  color: theme.palette.brandGrey[60],
  textAlign: 'left',
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
  },
}))

export const StyledFormControlLabel = clubbiStyled(FormControlLabel)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginTop: '8px',
}))

export const StyledSpacing = clubbiStyled('div')(({ theme }) => ({
  height: '8px',
  [theme.breakpoints.up('md')]: {
    height: '14px',
  },
}))
