import {
  Product,
  Section,
  Quantity,
  Description,
  Ean,
  WrapElement,
} from '../../molecules/invoice/SharedStyle'
import { GoPackage } from 'react-icons/go'
import { FaFontAwesomeFlag } from 'react-icons/fa'
import { ProductBucket } from '../../molecules/invoice/delivery/types'
import { OrderItem } from '../../molecules/invoice/purchase/types'

type ProductInfo = OrderItem | ProductBucket
export type ProductInfoList = OrderItem[] | ProductBucket[]

const LIST_ITEM_COLORS = ['#04153b', '#d1ab63', '#ff4a55']

function delivery(product: ProductInfo) {
  const {
    finalQuantityUnitary,
    isMultiPackage,
    productPackageQtd,
    finalPriceUnitary,
    targetQuantityUnitary,
    description,
    ean,
    unitEan,
  } = product || {}

  const isBox = isMultiPackage && !(finalQuantityUnitary % productPackageQtd) ? true : false

  const quantity = isBox
    ? Math.floor(finalQuantityUnitary / productPackageQtd)
    : finalQuantityUnitary

  const targetQuantity: number = isBox
    ? Math.floor(targetQuantityUnitary / productPackageQtd)
    : targetQuantityUnitary

  const descriptionProduct =
    isMultiPackage && finalQuantityUnitary % productPackageQtd
      ? description.split(')')[1]
      : description

  const quantityStatus = quantity == targetQuantity ? 0 : quantity == 0 ? 2 : 0

  const finalTotalPrice: number = isBox
    ? finalPriceUnitary * quantity * productPackageQtd
    : finalPriceUnitary * quantity
  const eanProduct = isMultiPackage ? ean : unitEan

  return {
    quantity: quantity,
    unitPrice: finalPriceUnitary,
    description: descriptionProduct,
    isBox: isBox,
    quantityStatus: quantityStatus,
    color: LIST_ITEM_COLORS[quantityStatus],
    finalTotalPrice: finalTotalPrice,
    ean: eanProduct,
  }
}

function purchase(product: ProductInfo) {
  const { unitPrice, quantity, productDescription, isMultiPackage, productId, unitProductId } =
    product || {}
  const isBox = isMultiPackage ? true : false
  const ean = parseInt(isBox ? productId : unitProductId)
  const finalTotalPrice = unitPrice * quantity
  const quantityStatus = 0

  return {
    quantity: quantity,
    unitPrice: unitPrice,
    description: productDescription,
    isBox: isBox,
    finalTotalPrice: finalTotalPrice,
    color: LIST_ITEM_COLORS[0],
    ean: ean,
    quantityStatus: quantityStatus,
  }
}
function SectionDetailRow({
  product,
  isDelivery,
  evenRow,
}: {
  product: ProductInfo
  isDelivery: boolean
  evenRow: boolean
}) {
  const finalProduct = isDelivery ? delivery(product) : purchase(product)
  const { ean, quantityStatus, color, description, quantity, isBox, unitPrice, finalTotalPrice } =
    finalProduct || {}
  const bg = evenRow ? '#ffffff' : '#fcfaee'
  return (
    <WrapElement theme={{ color: color, bg: bg }}>
      <Product>
        <Ean>{ean}</Ean>
        <Description>
          {!!quantityStatus && isDelivery && (
            <FaFontAwesomeFlag size={15} style={{ color: color }} />
          )}
          <span> {description} </span>
        </Description>

        <Quantity>
          {quantity} {isBox ? <GoPackage /> : ''}
        </Quantity>

        <Quantity>
          {Number(unitPrice).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Quantity>

        <Quantity>
          {finalTotalPrice.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Quantity>
      </Product>
    </WrapElement>
  )
}

function SectionDetailItem({
  sectionName,
  productInfoList,
  isDelivery,
}: {
  sectionName: string
  productInfoList: ProductInfo[]
  isDelivery: boolean
}) {
  return (
    <>
      <Product className="border-outside">
        <Product className="wrapper">
          <Section>{sectionName}</Section>
        </Product>
      </Product>
      {productInfoList.map((productInfo, index) => (
        <SectionDetailRow
          product={productInfo}
          isDelivery={isDelivery}
          evenRow={index % 2 == 0}
          key={index}
        />
      ))}
    </>
  )
}

const SectionsDetails = ({
  productBuckets,
  isDelivery,
}: {
  productBuckets: ProductInfoList
  isDelivery: boolean
}) => {
  const sections = [
    ...new Set(productBuckets.map((product: ProductInfo) => product?.section)),
  ].sort()

  return (
    <>
      {sections
        .map(
          (section: string) =>
            [
              section,
              (productBuckets as ProductInfo[]).filter(
                (product: ProductInfo) => product.section == section
              ),
            ] as [string, ProductInfo[]]
        )
        .map(([sectionName, productsInfo]) => (
          <SectionDetailItem
            sectionName={sectionName}
            productInfoList={productsInfo}
            isDelivery={isDelivery}
            key={sectionName}
          />
        ))}
    </>
  )
}

export default SectionsDetails
