import { themeClubbi } from 'clubbi-ui'

interface Props {
  deviceSize?: boolean
  isSelected?: boolean
}

export const MissionDiscountIcon = ({ deviceSize }: Props) => {
  const size = deviceSize ? 28 : 25
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.3333 20C33.3333 18.1667 34.8333 16.6667 36.6666 16.6667V10C36.6666 8.16666 35.1666 6.66666 33.3333 6.66666H6.66659C4.83325 6.66666 3.34992 8.16666 3.34992 10V16.6667C5.18325 16.6667 6.66659 18.1667 6.66659 20C6.66659 21.8333 5.18325 23.3333 3.33325 23.3333V253.33325 31.8333 4.83325 33.3333 6.66659 33.3333H33.3333C35.1666 33.3333 36.6666 31.8333 36.6666 30V23.3333C34.8333 23.3333 33.3333 21.8333 33.3333 20ZM25.9666 28L19.9999 24.1667L14.0333 28L15.8333 21.1333L10.3499 16.65L17.4166 16.2333L19.9999 9.66666L22.5666 16.25L29.6333 16.6667L24.1499 21.15L25.9666 28Z"
        fill="#7C00F4"
      />
    </svg>
  )
}

export const MissionFreeShipping = ({ deviceSize }: Props) => {
  const size = deviceSize ? 28 : 25
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.797 14C31.797 12.075 33.3868 10.5 35.33 10.5V3.5C35.33 1.575 33.7402 0 31.797 0H3.533C1.58985 0 0.017665 1.575 0.017665 3.5V10.5C1.96082 10.5 3.533 12.075 3.533 14C3.533 15.925 1.96082 17.5 0 17.5V24.5C0 26.425 1.58985 28 3.533 28H31.797C33.7402 28 35.33 26.425 35.33 24.5V17.5C33.3868 17.5 31.797 15.925 31.797 14Z"
        fill="#7C00F4"
      />
      <path
        d="M27.3881 14.6249V13.2014C27.3881 12.7432 27.234 12.3044 26.9451 11.9534L23.1505 7.35141C22.7845 6.90291 22.2451 6.64941 21.6673 6.64941H10.0522C8.99277 6.64941 8.12598 7.52691 8.12598 8.59941V16.3994C8.12598 17.4719 8.99277 18.3494 10.0522 18.3494H10.2256C10.6301 19.4804 11.6895 20.2994 12.9415 20.2994C14.1936 20.2994 15.253 19.4804 15.6575 18.3494H23.7187C24.1136 19.4804 25.173 20.2994 26.4347 20.2994C28.0334 20.2994 29.324 18.9929 29.324 17.3744C29.3143 16.1069 28.5053 15.0344 27.3881 14.6249ZM24.8841 12.4994H22.5726V9.69141L24.8841 12.4994ZM10.0522 8.59941H13.9046V12.4994H10.0522V8.59941ZM12.9415 18.3494C12.4118 18.3494 11.9784 17.9107 11.9784 17.3744C11.9784 16.8382 12.4118 16.3994 12.9415 16.3994C13.4712 16.3994 13.9046 16.8382 13.9046 17.3744C13.9046 17.9107 13.4712 18.3494 12.9415 18.3494ZM26.425 18.3494C25.8953 18.3494 25.4619 17.9107 25.4619 17.3744C25.4619 16.8382 25.8953 16.3994 26.425 16.3994C26.9547 16.3994 27.3881 16.8382 27.3881 17.3744C27.3881 17.9107 26.9547 18.3494 26.425 18.3494Z"
        fill="white"
      />
    </svg>
  )
}

export const MissionsIcon = ({ isSelected }: Props) => {
  const color = isSelected ? themeClubbi.palette.brandPurple[60] : themeClubbi.palette.brandGrey[70]
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isSelected ? (
        <path
          d="M20 12C20 10.9 20.9 10 22 10V6C22 4.9 21.1 4 20 4H4C2.9 4 2.01 4.9 2.01 6V10C3.11 10 4 10.9 4 12C4 13.1 3.11 14 2 14V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V14C20.9 14 20 13.1 20 12ZM15.58 16.8L12 14.5L8.42 16.8L9.5 12.68L6.21 9.99L10.45 9.74L12 5.8L13.54 9.75L17.78 10L14.49 12.69L15.58 16.8Z"
          fill={color}
        />
      ) : (
        <path
          d="M22 10V6C22 4.9 21.1 4 20 4H4C2.9 4 2.01 4.9 2.01 6V10C3.11 10 4 10.9 4 12C4 13.1 3.11 14 2 14V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V14C20.9 14 20 13.1 20 12C20 10.9 20.9 10 22 10ZM20 8.54C18.81 9.23 18 10.53 18 12C18 13.47 18.81 14.77 20 15.46V18H4V15.46C5.19 14.77 6 13.47 6 12C6 10.52 5.2 9.23 4.01 8.54L4 6H20V8.54ZM9.07 16L12 14.12L14.93 16L14.04 12.64L16.73 10.44L13.26 10.23L12 7L10.73 10.22L7.26 10.43L9.95 12.63L9.07 16Z"
          fill={color}
        />
      )}
    </svg>
  )
}
