import { Categories } from '../store/data/types'
import { urlImages } from './constants'

const colorsArray = ['#C5C3FF', '#FFB7C8', '#FFE9EE', '#C58AFF', '#C8FFE8', '#FFE38A', '#FFD0B6']

const colors = [...colorsArray, ...colorsArray, ...colorsArray]

const getOrder = (a: Categories, b: Categories): number => {
  return a.order - b.order
}

export const filterExploreCategories = (categories: Categories[]) => {
  return categories
    .filter((category) => category.level === 0)
    .sort(getOrder)
    .map((category: { name: string; slug: string }, index) => ({
      colorBg: colors[index],
      label: category.name,
      image: `${urlImages}/${category.slug}`,
      path: `/products/${category.slug}`,
    }))
}

export const filterCategoriesTag = (categories: Categories[]) => {
  return categories
    .filter((category) => category.level === 0)
    .sort(getOrder)
    .map((category: { name: string; slug: string }, index) => ({
      label: category.name,
      path: `/products/${category.slug}`,
    }))
}

export const filterSubCategories = (categories: Categories[], section: { section: string }) => {
  return categories
    .filter((category) => category.level === 1 && category.parent?.slug === section.section)
    .map((category: { name: string; slug: string }) => ({
      label: category.name,
      path: `/products/${section.section}/${category.slug}`,
    }))
}
