import { StyledComplement, StyledInput, StyledInputBase } from './styles/OperatorInput.style'

export const OperatorInput = ({
  operator,
  setOperator,
}: {
  operator: string
  setOperator: (value: string | ((prevVar: string) => string)) => void
}): JSX.Element => {
  const handleChangeOnInput = (e: React.ChangeEvent) => {
    const value = (e.target as HTMLInputElement).value
    setOperator(value)
  }

  return (
    <StyledInputBase>
      <StyledInput
        type="text"
        placeholder="Confirme seu nome"
        onChange={handleChangeOnInput}
        value={operator}
        onFocus={(event: React.FocusEvent<HTMLInputElement>) => event.target.select()}
      />
      <StyledComplement>Apenas para efeito de controle e histórico</StyledComplement>
    </StyledInputBase>
  )
}
