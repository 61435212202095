import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Corridor } from '../data/types'

type State = {
  shoppingList: Corridor[] | []
  modalIsOpen: boolean
}

const searchListSlice = createSlice({
  name: 'searchList',
  initialState: {
    shoppingList: [],
    modalIsOpen: false,
  } as State,
  reducers: {
    setCorridors: (state, action: PayloadAction<Corridor[]>): State => {
      const shoppingList = action.payload
      return { ...state, shoppingList }
    },
    setIsOpen: (state, action: PayloadAction<boolean>): State => {
      const modalIsOpen = action.payload
      return { ...state, modalIsOpen }
    },
  },
})

export const searchListReducer = searchListSlice.reducer
export const searchListActions = searchListSlice.actions
