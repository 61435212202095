import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Product } from '../data/types'

type State = {
  openPopup: boolean
  comboProduct: Product | undefined
}

const initialState = { openPopup: false, comboProduct: undefined }

export const getMoreForLessSlice = createSlice({
  name: 'getMoreForLess',
  initialState: initialState as State,
  reducers: {
    setOpenPopup: (_state, action: PayloadAction<Product>): State => {
      return { openPopup: true, comboProduct: action.payload }
    },
    setClosePopup: (_state, action: PayloadAction<undefined>): State => {
      return { openPopup: false, comboProduct: undefined }
    },
    cleanUp: () => {
      return initialState
    },
  },
})

export const getMoreForLessReducer = getMoreForLessSlice.reducer
export const getMoreForLessActions = getMoreForLessSlice.actions
