import { clubbiStyled } from 'clubbi-ui'
import { BiArrowBack } from 'react-icons/bi'
import { Link } from 'react-router-dom'

export const StyledLinkRouter = clubbiStyled(Link)({
  border: 'none',
  textDecoration: 'underline',
  margin: '10px 5px',
})

export const StyledArrowBack = clubbiStyled(BiArrowBack)(({ theme }) => ({
  color: theme.palette.brandPurple[60],
}))

export const StyledContainerBack = clubbiStyled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
})
