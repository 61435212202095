import { clubbiStyled } from 'clubbi-ui'

export const StyledSection = clubbiStyled('p')(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  lineHeight: '23.83px',
  letterSpacing: '0em',
  textAlign: 'left',
  color: theme.palette.brandBlack.main,
  border: `1px solid ${theme.palette.brandGrey[20]}`,
  background: '#ffffff',
  width: '100%',
  padding: '10px',
  margin: '0',
  [theme.breakpoints.up('md')]: {
    padding: '10px 20px',
    fontSize: '14px',
  },
}))
