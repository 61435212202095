import { ProfileForm } from '../organisms/profile/ProfileForm'
import { SideMenu } from '../organisms/profile/SideMenu'
import { StyledContainer } from './styles/Profile.style'

export const Contacts = () => {
  return (
    <StyledContainer>
      <SideMenu />
      <ProfileForm />
    </StyledContainer>
  )
}
