import { ArrowForward, CloseIcon } from 'clubbi-ui'
import { formatHours, formatListWithCommas, getDaysToDelivery } from './helpers'
import { useAppSelector } from '../../../common/hooks'
import { MerchantDeliveryRestriction } from '../../../store/data/types'
import { ConfirmationModalProps } from './interfaces'
import {
  StyledCancelButton,
  StyledClose,
  StyledDivButton,
  StyledHeader,
  StyledInfos,
  StyledMain,
  StyledModal,
  StyledSaveButton,
  StyledText,
} from './styles/ConfirmationModal.style'

export const ConfirmationModal = ({ handleSave, open, setOpen }: ConfirmationModalProps) => {
  const { merchantDeliveryRestriction, hasBreak } = useAppSelector((state) => state.profile)

  const {
    deliveryDaysRestriction,
    deliveryTimeStart,
    deliveryTimeFinish,
    deliveryBreakTimeStart,
    deliveryBreakTimeFinish,
    saturdayClosure,
  } = merchantDeliveryRestriction as MerchantDeliveryRestriction

  const handleClose = () => {
    setOpen(false)
  }

  const handleSaveButton = () => {
    handleSave()
    handleClose()
  }

  const days = getDaysToDelivery(deliveryDaysRestriction!)

  const formattedDays = formatListWithCommas(days)

  const hasSaturdayClosure = !deliveryDaysRestriction?.includes(7)

  const showHasBreak = hasBreak && deliveryBreakTimeStart && deliveryBreakTimeFinish

  return (
    <StyledMain open={open} onClose={handleClose}>
      <StyledModal>
        <StyledClose onClick={handleClose}>
          <CloseIcon />
        </StyledClose>
        <StyledHeader>
          <p>Confirme sua disponibilidade para recebimento:</p>
        </StyledHeader>
        <StyledText>Podemos receber mercadorias:</StyledText>
        <StyledInfos>
          <>
            <ArrowForward dimensions="20px" />
            <b>{formattedDays}</b>
          </>
        </StyledInfos>
        <StyledText>Durante a semana no seguinte horário:</StyledText>
        <StyledInfos>
          <>
            <ArrowForward dimensions="20px" />
            <>
              Das: <b>{formatHours(deliveryTimeStart!)}</b> às{' '}
              <b>{formatHours(deliveryTimeFinish!)}</b>
            </>
          </>
        </StyledInfos>
        {hasSaturdayClosure && (
          <>
            <StyledText>Aos Sábados:</StyledText>
            <StyledInfos>
              <>
                <ArrowForward dimensions="20px" />
                Fecha às: <b>{formatHours(saturdayClosure!)}</b>
              </>
            </StyledInfos>
          </>
        )}
        {showHasBreak && (
          <>
            <StyledText>Não recebemos:</StyledText>
            <StyledInfos>
              <ArrowForward dimensions="20px" />
              Entre <b>{formatHours(deliveryBreakTimeStart)}</b> e{' '}
              <b>{formatHours(deliveryBreakTimeFinish)}</b>{' '}
            </StyledInfos>
          </>
        )}
        <StyledDivButton>
          <StyledSaveButton
            label="Confirmar"
            onClick={handleSaveButton}
            typeButton="filled"
            size="small"
          />
          <StyledCancelButton
            label="Cancelar"
            onClick={handleClose}
            typeButton="text"
            size="small"
          />
        </StyledDivButton>
      </StyledModal>
    </StyledMain>
  )
}
