import { clubbiStyled } from 'clubbi-ui'

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '18px',
  width: '100%',
  backgroundColor: theme.palette.brandWhite.main,
  [theme.breakpoints.up('md')]: {
    gap: '20px',
  },
}))
