import {
  DELIVERY_BREAK_TOGGLE_LABEL,
  DELIVERY_BREAK_TOGGLE_SUB_LABEL,
  MIN_DELIVERY_WINDOW_HOURS,
  timeRestrictions,
} from './constants'
import { TimeRestriction } from './TimeRestriction'
import { useAppDispatch, useAppSelector } from '../../../common/hooks'
import {
  StyledCheckbox,
  StyledContainer,
  StyledFormControlLabel,
  StyledHR,
  StyledLabel,
  StyledLabelContainer,
  StyledSpacing,
  StyledSubLabel,
} from './styles/WorkingHoursInfo.style'
import { profileActions } from '../../../store/slices/profile'
import { DeliveryDays } from './DeliveryDays'
import { WorkingHoursInfoProps } from './interfaces'
import { SubSectionTitle } from './SubSectionTitle'
import { isTotalDeliveryWindowValid } from './helpers'

const CheckboxLabel = () => {
  return (
    <StyledLabelContainer>
      <StyledLabel>{DELIVERY_BREAK_TOGGLE_LABEL}</StyledLabel>
      <StyledSubLabel>{DELIVERY_BREAK_TOGGLE_SUB_LABEL}</StyledSubLabel>
    </StyledLabelContainer>
  )
}

export const WorkingHoursInfo = ({ isContactPage }: WorkingHoursInfoProps) => {
  const { hasBreak, merchantDeliveryRestriction } = useAppSelector((state) => state.profile)
  const dispatch = useAppDispatch()

  const handleCheckbox = () => {
    dispatch(profileActions.updateHasBreak(!hasBreak))
  }

  return (
    <StyledContainer isContactPage={isContactPage}>
      <StyledHR />
      <DeliveryDays />
      <StyledHR />
      <SubSectionTitle
        title="Horário"
        firstSubtitle="Para garantir a entrega no prazo, é necessário um intervalo mínimo de "
        highlightedText={`${MIN_DELIVERY_WINDOW_HOURS} horas.`}
        isFulfilled={isTotalDeliveryWindowValid(hasBreak, merchantDeliveryRestriction)}
      />
      <StyledSpacing />
      {timeRestrictions.map((restriction) => {
        if (restriction.startKey === 'deliveryBreakTimeStart') {
          return (
            <>
              <StyledFormControlLabel
                control={
                  <StyledCheckbox
                    onChange={handleCheckbox}
                    checked={hasBreak}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={<CheckboxLabel />}
              />
              {hasBreak && <TimeRestriction key={restriction.startKey} {...restriction} />}
              <StyledHR />
            </>
          )
        }
        return (
          <>
            <TimeRestriction key={restriction.startKey} {...restriction} />
            <StyledHR />
          </>
        )
      })}
    </StyledContainer>
  )
}
