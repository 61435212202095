import { Notifications } from '../components/molecules/Notification/PopoverNotifications'
import { PatchMerchantBody } from '../components/organisms/profile/interfaces'
import { MerchantDeliveryRestriction, MerchantProfileData } from '../store/data/types'
import { apiGet, apiPatch, apiPut } from './api'
import { convertMerchant } from '../components/organisms/profile/helpers'
import { dispatch } from '../store'
import { notificationsActions } from '../store/slices/notifications'

export type NPS = {
  id: null | number
  show: number
}

export type MerchantData = {
  streetName: string
  zipCode: string
  neighborhood: string
  cnpj: string
  streetNumber: number
  razaoSocial: string
  websiteRegionId?: number
}

export const putNpsValue = async (npsId: number | null, data: { value: number }) => {
  if (npsId != null) {
    return apiPut(`/nps/value/${npsId}`, data, false)
  } else return
}

export const dismissNps = async (npsId: number | null, data: Record<string, unknown>) => {
  if (npsId != null) {
    return apiPut(`/nps/dismiss/${npsId}`, data, false)
  } else return
}

export const putNpsFeedback = async (
  npsId: number | null,
  data: { nps_experience_feedback: { [p: string]: string }; nps_comment: string }
) => {
  if (npsId != null) {
    return apiPut(`/nps/feedback/${npsId}`, data, false)
  } else return
}

export const checkNpsMain = async (merchantCode: string): Promise<NPS> =>
  await apiGet(`/nps/main/${merchantCode}`, false)

export const checkNpsCheckout = async (merchantCode: string, npsId: null | number): Promise<NPS> =>
  await apiGet(`/nps/checkout/${merchantCode}/${npsId}`, false)

export const getMerchantData = async (merchantCode: string): Promise<MerchantData[]> =>
  await apiGet(`/merchants?merchant_code=${merchantCode}`, false)

export const getNotifications = async (merchantCode: string | undefined): Promise<void> => {
  const response = await apiGet(`/notifications?merchant_code=${merchantCode}`)
  dispatch(notificationsActions.setNotifications(response))
}

export const patchNotifications = async (
  merchantCode: string | undefined
): Promise<Notifications[] | any> =>
  await apiPatch(`/notifications/visualize?merchant_code=${merchantCode}`)

export const getMerchantProfileData = async (
  merchantCode: string
): Promise<MerchantProfileData> => {
  const response = await apiGet(`/merchant/${merchantCode}`)

  return { ...response, merchant: { ...convertMerchant(response.merchant, true) } }
}

export const patchDeliveryRestrictions = async (
  merchantCode: string | undefined,
  data: MerchantDeliveryRestriction
) => await apiPatch(`/merchant/delivery-restriction/${merchantCode}`, data)

export const patchMerchant = async (merchantCode: string | undefined, data: PatchMerchantBody) =>
  await apiPatch(`/merchant/${merchantCode}`, data)
