import { Border, ContainerBottom } from '../SharedStyle'
import { CustomerDetails } from './types'
interface Props {
  customer: CustomerDetails
}

const DeliveryPriceDetails = ({ customer }: Props) => {
  const { deliveryFee, planDiscount, finalTotalPrice, totalPriceToBeChargedAfterDiscount } =
    customer
  const invoiceDiscount = planDiscount == null ? 0 : planDiscount
  return (
    <>
      <ContainerBottom>
        <p>Valor dos itens na fatura (R$)</p>
        <p>
          {finalTotalPrice.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </p>
      </ContainerBottom>
      <Border />
      <ContainerBottom>
        <p>Taxa de entrega (R$)</p>
        <p>
          {deliveryFee.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </p>
      </ContainerBottom>
      {!invoiceDiscount ? (
        <></>
      ) : (
        <ContainerBottom className={'text-success'}>
          <p>Desconto (R$)</p>
          <p>
            -{' '}
            {invoiceDiscount.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </p>
        </ContainerBottom>
      )}
      <ContainerBottom>
        <p>Total da fatura (R$)</p>
        <p>
          {totalPriceToBeChargedAfterDiscount.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </p>
      </ContainerBottom>
    </>
  )
}

export default DeliveryPriceDetails
