import { clubbiStyled } from 'clubbi-ui'

export const StyledCheckoutContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '14px 2px',
  width: '100%',
  backgroundColor: theme.palette.brandWhite.main,
  [theme.breakpoints.up('md')]: {
    padding: '18px 2px 0',
  },
}))

export const StyledFooter = clubbiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px',
  width: '100%',
}))

export const StyledTitle = clubbiStyled('div')(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 600,
  padding: '0 16px',
  marginBottom: '8px',
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
  },
}))
