import { Dispatch, SetStateAction } from 'react'
import { CartChange, CartItem, Product, SupplierPrice } from '../../../store/data/types'

export type PriceChange = {
  cartItem: CartItem
  product: Product
}

export interface Warnings {
  outOfStock: Product[]
  priceChanges: PriceChange[]
  limitChanges: PriceChange[]
  quantityChanges?: QuantityChange[]
}

export enum PRODUCT_CHANGES_ACTIONS {
  REMOVE,
  PRICE_CHANGE,
  LIMIT_CHANGE,
}

export type OrderItem = {
  ean: string
  quantity: number
  isPackageMode: boolean
  packageNumberOfItems?: number | undefined
  changes: CartChange[]
  unitPrice?: number | undefined
  packagePrice?: number | undefined
  inStockQuantity?: number | undefined
  originalPrice?: number | undefined
  datetimeToExpire?: string | number | Date | undefined
  offerType?: number | undefined
  offerId?: number
  storeId?: string
  supplierPrice: SupplierPrice
  discount?: number
}

export type QuantityChange = {
  typeChange: string
  orderItem: OrderItem
  orderItemUpdated: OrderItem
}

export type ConversionEventItem = {
  sku: string
  seller_id: string
  product_id: string
  quantity: number
  price: number
  promotional_price: number
}
export interface ConversionEventData {
  channel: string
  publisher_id: string | undefined
  user_id: string | undefined
  session_id: string
  order_id: string
  items: ConversionEventItem[]
  created_at: string
}

export type CheckoutFormType = {
  merchantCode: string
  merchantCodeInvalid: boolean
  invoiceType: number
  paymentType: number
}

export type IconProps = {
  size: string
  color: string
}

export type PaymentLabels = {
  id: number
  label: string
  subLabel?: string
}

export interface ClubbiToggleButtonGroupProps {
  hasIcon?: boolean
  paymentLabels: PaymentLabels[]
  id: string
  value: any
  onChange: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void
}

export enum SelectionType {
  PAYMENT = 'paymentType',
  INVOICE = 'invoiceType',
}

export interface PaymentSelectorProps {
  type: SelectionType
  setFormValue: Dispatch<SetStateAction<CheckoutFormType>>
  formValue: CheckoutFormType
}

export interface CheckoutFormProps {
  isLoading: boolean
  setFormValue: Dispatch<SetStateAction<CheckoutFormType>>
  formValue: CheckoutFormType
  submitOrder: () => void
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  setShowModalInstantPayment: Dispatch<SetStateAction<boolean>>
  loginUpdated: boolean
}

export interface FinishPurchaseButtonProps {
  loginUpdated: boolean
  formValue: CheckoutFormType
  isLoading: boolean
  submitOrder: () => void
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  setShowModalInstantPayment: Dispatch<SetStateAction<boolean>>
}
