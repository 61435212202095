import { createElement, Fragment, useEffect, useRef } from 'react'
import { createRoot, Root } from 'react-dom/client'
import { autocomplete } from '@algolia/autocomplete-js'

import { StyledWrapper } from './style/StyledAutocomplete.style'
// import { debounce } from '@algolia/autocomplete-shared'

export const Autocomplete = (props: any) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const panelRootRef = useRef<Root | null>(null)
  const rootRef = useRef<HTMLElement | null>(null)

  // const debouncedSetInstantSearchUiState = debounce((setInstanceSearchUiState), 500)

  useEffect(() => {
    if (!containerRef.current) {
      return undefined
    }
    const search = autocomplete({
      // onStateChange({ prevState, state }) {
      //   if (prevState.query !== state.query) {
      //     debouncedSetInstantSearchUiState({
      //       query: state.query,
      //     })
      //   }
      // },
      container: containerRef.current,
      // eslint-disable-next-line
      renderer: { createElement, Fragment, render: () => {} },

      render({ children }, root) {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root

          panelRootRef.current?.unmount()
          panelRootRef.current = createRoot(root)
        }

        panelRootRef.current?.render(children)
      },
      ...props,
    })

    return () => {
      search.destroy()
    }
  }, [props])

  return <StyledWrapper ref={containerRef} />
}
