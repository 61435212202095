import { clubbiStyled } from 'clubbi-ui'
import { getSeasonStyle } from '../../../../common/seasonality'

const season = getSeasonStyle()

export const StyledContainer = clubbiStyled('div')<{ color: string; isSelected: boolean }>(
  ({ theme, color, isSelected }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: color,
    cursor: 'pointer',
    fontSize: theme.typography.fontSize3XS,
    fontWeight: 600,
    lineHeight: theme.typography.fontSize2XS,
    [theme.breakpoints.up('md')]: {
      gap: '5px',
      fontSize: theme.typography.fontSizeXXS,
      color: theme.palette.brandWhite.main,
      padding: '10px 15px',
      flexDirection: 'row',
      whiteSpace: 'nowrap',
      borderRadius: '28px',
      backgroundColor: isSelected ? season.hoverColor : 'transparent',
      '&:hover': {
        backgroundColor: season.hoverColor,
      },
    },
  })
)
