import { useEffect } from 'react'
import { useLocation } from 'react-router'

import { SelectMissionTooltip } from '../atoms/SelectMissionTooltip'
import { useAppSelector } from '../../common/hooks'
import { getMissions } from '../../services/productApi'
import { Mission } from '../../store/data/types'
import { MissionCard } from './MissionCard'

import { StyleContainerMissions, StyledRow, StyledH3 } from './styles/MissionsRow.style'

export const MissionsRow = () => {
  const { merchantCode, regionId } = useAppSelector((state) => state.session)
  const missions = useAppSelector((state) => state.missions)

  const location = useLocation()

  const isCartPage = location.pathname === '/cart'

  useEffect(() => {
    if (merchantCode) {
      getMissions(merchantCode)
    }
  }, [merchantCode, regionId])

  return (
    <>
      {!!missions?.length && (
        <StyleContainerMissions isCartPage={isCartPage}>
          <StyledH3 isCartPage={isCartPage}>Missões do dia</StyledH3>
          {!!missions[0] && <SelectMissionTooltip />}
          <StyledRow>
            {missions?.map((mission: Mission, index) => {
              return <MissionCard key={index} mission={mission} />
            })}
          </StyledRow>
        </StyleContainerMissions>
      )}
    </>
  )
}
