import { clubbiStyled } from 'clubbi-ui'
import { Link } from 'react-router-dom'

export const StyledContainerLogged = clubbiStyled('div')(({ theme }) => ({
  width: '200px',
  height: 'auto',
  backgroundColor: '#fff',
  boxShadow: '-4px 4px 20px rgba(0, 0, 0, 0.25)',
  borderRadius: '4px 0px 4px 4px',
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  gap: '10px',
  fontSize: theme.typography.fontSize2XS,

  [theme.breakpoints.up('sm')]: {
    width: '265px',
    fontSize: theme.typography.fontSizeXXS,
  },
}))

export const StyledRow = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  marginLeft: '14px',

  [theme.breakpoints.up('sm')]: {
    marginLeft: '20px',
  },
}))

export const StyledDiv = clubbiStyled('div')(({ theme }) => ({
  width: '25px',
  height: '25px',
  borderRadius: '50%',
  backgroundColor: theme.palette.brandGrey[20],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '-16px',

  [theme.breakpoints.up('sm')]: {
    width: '35px',
    height: '35px',
  },
}))

export const StyledHr = clubbiStyled('hr')(({ theme }) => ({
  width: '100%',
  height: '0%',
  color: theme.palette.brandGrey[20],
  margin: '8px 0px 10px 0px',
}))

export const StyledHeader = clubbiStyled('div')(({ theme }) => ({
  marginLeft: '10px',

  [theme.breakpoints.up('sm')]: {
    marginLeft: '14px',
  },
}))

export const StyleName = clubbiStyled('p')(() => ({
  fontWeight: 700,
  marginBottom: '0',
}))

export const StyleCode = clubbiStyled('p')(({ theme }) => ({
  color: theme.palette.brandGrey[70],
  marginBottom: 0,
}))

export const StyledOptionLink = clubbiStyled('a')(({ theme }) => ({
  marginLeft: '16px',
  border: 'none',
  backgroundColor: 'transparent',
  color: '#000',

  [theme.breakpoints.up('sm')]: {
    marginLeft: '20px',
  },
}))

export const StyledLink = clubbiStyled(Link)(({ theme }) => ({
  marginLeft: '16px',
  border: 'none',
  backgroundColor: 'transparent',
  color: '#000',

  [theme.breakpoints.up('sm')]: {
    marginLeft: '20px',
  },
}))

export const StyledExit = clubbiStyled('button')(({ theme }) => ({
  color: theme.palette.brandAlert[50],
  textAlign: 'center',
  border: 'none',
  backgroundColor: 'transparent',
  marginTop: '-10px',
}))
