import { AlgoliaEvent } from '../store/data/types'
import { apiPost, loadBody } from './api'

export const tracking = async (algoliaEvent: any) => {
  const response = await apiPost('/algolia', { algoliaEvent }, false)
  switch (response.status) {
    case 200: {
      const body = await loadBody(response)
      return [true, body as AlgoliaEvent]
    }
    case 401:
      return [false, undefined]
    default:
      return [undefined, undefined]
  }
}
