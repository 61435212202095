import { ButtonClubbi, clubbiStyled, Modal } from 'clubbi-ui'

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const flexStart = {
  alignSelf: 'flex-start',
}

export const StyledModal = clubbiStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brandWhite.main,
  width: '360px',
  flexDirection: 'column',
  ...flexCenter,
  borderRadius: '7px',
  padding: '20px 24px',
  border: 'none',
  [theme.breakpoints.up('sm')]: {
    width: '480px',
  },
}))

export const StyledMain = clubbiStyled(Modal)({
  ...flexCenter,
  boxShadow: 'none',
})

export const StyledClose = clubbiStyled('button')(({ theme }) => ({
  width: '30px',
  height: '5px',
  border: 'none',
  transform: 'scale(0.8)',
  position: 'relative',
  top: '-12px',
  left: '16px',
  display: 'flex',
  alignSelf: 'flex-end',

  background: 'transparent',
}))

export const StyledHeader = clubbiStyled('div')(({ theme }) => ({
  fontWeight: 700,
  fontSize: theme.typography.fontSizeXS,
  ...flexStart,
  lineHeight: '27px',

  color: theme.palette.brandPurple[60],
}))

export const StyledText = clubbiStyled('div')(({ theme }) => ({
  ...flexStart,
  fontWeight: 400,
  fontSize: theme.typography.fontSizeXS,
}))

export const StyledInfos = clubbiStyled('p')(({ theme }) => ({
  ...flexStart,
  fontWeight: 400,
  fontSize: theme.typography.fontSizeS,
  lineHeight: '22px',
}))

export const StyledDivButton = clubbiStyled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
})

export const StyledSaveButton = clubbiStyled(ButtonClubbi)(({ theme }) => ({
  fontSize: theme.typography.fontSizeXS,
  fontWeight: 700,
  textTransform: 'capitalize',
}))

export const StyledCancelButton = clubbiStyled(ButtonClubbi)(({ theme }) => ({
  fontSize: theme.typography.fontSizeXS,
  fontWeight: 700,
  textDecoration: 'none',
  textTransform: 'capitalize',
  marginLeft: '10px',

  color: theme.palette.brandGrey[100],
}))
