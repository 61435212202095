import * as Sentry from '@sentry/react'
import { App } from './App'
import ReactDOM from 'react-dom/client'
import { StrictMode } from 'react'
import './index.css'

Sentry.init({
  dsn: 'https://17aa8d134ce629570dcaea6f3314d425@us.sentry.io/4506700992413696',
  integrations: [],
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <StrictMode>
    <App />
  </StrictMode>
)
