import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Message } from '../../components/atoms/SnackBar'

type State = Message[]

const messagesSlice = createSlice({
  name: 'messages',
  initialState: [] as unknown as State,
  reducers: {
    messages: (state, action: PayloadAction<Message[]>): State => {
      return action.payload
    },
  },
})

export const messagesReducer = messagesSlice.reducer
export const messagesActions = messagesSlice.actions
