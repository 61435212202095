import { clubbiStyled, Modal, CloseIcon, Spinner } from 'clubbi-ui'

export const StyledModal = clubbiStyled(Modal)(({ theme }) => ({
  border: 'none',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '40px',

  outlineColor: theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    marginTop: '200px',
  },
}))

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  width: '90vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  maxHeight: '90vh',
  overflowY: 'auto',
  position: 'absolute',
  top: '10px',
  padding: '22px 26px',
  borderRadius: '8px',

  background: theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    width: '342px',
  },
}))

export const StyledCloseButton = clubbiStyled('button')(({ theme }) => ({
  textDecoration: 'underline',
  border: 'none',
  position: 'absolute',
  right: 0,
  top: '4px',

  backgroundColor: theme.palette.brandWhite.main,
}))

export const StyledCloseIcon = clubbiStyled(CloseIcon)(() => ({
  width: '20px',
}))

export const StyledLoginText = clubbiStyled('p')(({ theme }) => ({
  fontSize: theme.typography.fontSizeM,
  lineHeight: '22px',
  fontWeight: '700',
  margin: 0,

  color: theme.palette.brandBlack.main,
}))

export const StyledDescription = clubbiStyled('p')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXXS,
  lineHeight: '16px',
  margin: 0,
  marginTop: '5px',

  color: theme.palette.brandGrey[100],
}))

export const StyledContainerInput = clubbiStyled('p')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  gap: '2px',
  margin: '18px 0',
  position: 'relative',

  color: theme.palette.brandAlert.main,
}))

export const StyledInput = clubbiStyled('input')<{ invalid: boolean }>(({ theme, invalid }) => ({
  fontSize: theme.typography.fontSize3XL,
  lineHeight: '38px',
  textTransform: 'uppercase',
  letterSpacing: '2px',
  width: '160px',
  height: '56px',
  outline: 'none',
  padding: '0 10px',
  borderRadius: '4px',
  border: `1px solid ${invalid ? theme.palette.brandAlert.main : theme.palette.brandPurple[60]}`,
  boxShadow: invalid ? `1px 1px 3px ${theme.palette.brandAlert[30]}` : 'none',

  color: theme.palette.brandGrey[100],
}))

export const StyledClubberMessage = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  fontWeight: '500',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '10px',
  cursor: 'pointer',
  transition: 'transform 1s',

  color: theme.palette.brandPurple[60],
}))

export const StyledContainerClubber = clubbiStyled('div')<{ isClubber: boolean }>(
  ({ theme, isClubber }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'start',
    height: '82px',
    padding: '14px 12px',
    borderRadius: '4px',
    transition: 'transform 1s',

    background: isClubber ? theme.palette.brandGrey[10] : theme.palette.brandWhite.main,
  })
)

export const StyledInputEmail = clubbiStyled('input')<{ invalid: boolean }>(
  ({ theme, invalid }) => ({
    fontSize: theme.typography.fontSizeXXS,
    lineHeight: '16px',
    width: '100%',
    height: '36px',
    outline: 'none',
    padding: '6px 10px',
    marginBottom: '5px',
    borderRadius: '4px',
    border: `1px solid ${invalid ? theme.palette.brandAlert[50] : theme.palette.brandGrey[60]}`,
    boxShadow: invalid ? `1px 1px 3px ${theme.palette.brandAlert[30]}` : ``,
  })
)

export const StyledErrorMessage = clubbiStyled('p')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,

  color: theme.palette.brandAlert.main,
}))

export const StyledMessageEmail = clubbiStyled('p')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,

  color: theme.palette.brandGrey.main,
}))

export const StyledButton = clubbiStyled('button')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXS,
  lineHeight: '18px',
  fontWeight: '700',
  height: '32px',
  borderRadius: '4px',
  border: 'none',
  margin: '24px 0 12px 0',
  cursor: 'pointer',

  color: theme.palette.brandWhite.main,
  background: theme.palette.brandPurple[60],

  '&: disabled': {
    background: theme.palette.brandGrey[30],
  },
}))

export const StyledSpinner = clubbiStyled(Spinner)(({ theme }) => ({
  width: '24px !important',
  height: '24px !important',

  color: theme.palette.brandWhite.main,
}))

export const StyledText = clubbiStyled('p')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  lineHeight: '30px',
  fontWeight: '500',
  height: '30px',
  margin: 0,

  color: theme.palette.brandGrey[100],
}))

export const StyledLink = clubbiStyled('a')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  fontWeight: '500',
  textDecoration: 'none',
  cursor: 'pointer',

  color: theme.palette.brandPurple.main,

  '&: hover': {
    color: theme.palette.brandPurple.main,
  },
}))
