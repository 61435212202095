import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  isOpen: boolean
}

export const missionProgressModal = createSlice({
  name: 'missionProgressModal',
  initialState: {
    isOpen: false,
  },
  reducers: {
    setIsOpen: (_state, action: PayloadAction<boolean>): State => {
      return { isOpen: action.payload }
    },
  },
})

export const missionProgressModalReducer = missionProgressModal.reducer
export const missionProgressModalActions = missionProgressModal.actions
