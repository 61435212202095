import { StyledSubTitle, StyledSubTitleSpan, StyledTitle } from './styles/SubSectionTitle.style'

import { SubSectionTitleProps } from './interfaces'
import { RoundedCheckIcon } from 'clubbi-ui'

export const SubSectionTitle = ({
  title,
  firstSubtitle,
  highlightedText,
  isFulfilled,
  secondSubtitle,
}: SubSectionTitleProps) => {
  return (
    <>
      {title && <StyledTitle>{title}</StyledTitle>}
      <StyledSubTitle>
        {firstSubtitle}
        <StyledSubTitleSpan isFulfilled={isFulfilled}>{highlightedText}</StyledSubTitleSpan>
        {secondSubtitle ?? secondSubtitle}
        {isFulfilled && <RoundedCheckIcon />}
      </StyledSubTitle>
    </>
  )
}
