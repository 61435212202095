import { Collapse, IconButton, clubbiStyled } from 'clubbi-ui'

export const StyledQuantityCell = clubbiStyled('div')(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 400,
  lineHeight: '18.72px',
  letterSpacing: '0em',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flex: 10,
  display: 'flex',
  flexDirection: 'row',
  gap: '0',
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '24px',
    flexDirection: 'column',
    flex: 3,
  },
  [theme.breakpoints.up('lg')]: {
    flex: 4,
  },
}))

export const StyledQuantityText = clubbiStyled('div')(() => ({
  display: 'flex',
}))

export const StyledQuantityChangeBox = clubbiStyled('div')(({ theme }) => ({
  background: theme.palette.brandWhite.main,
  width: '141px',
  height: '66px',
  padding: '10px',
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0px 1px 2px 0px #00000026',
}))

export const StyledUl = clubbiStyled('ul')(() => ({
  listStyleType: 'none',
  margin: 0,
  padding: 0,
}))

export const StyledLi = clubbiStyled('li')(({ theme }) => ({
  fontSize: '12px',
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    fontWeight: 400,
  },
}))

export const StyledQtyChangeRow = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

export const StyledCollapse = clubbiStyled(Collapse)<{ quantityBoxIsOpen: boolean }>(
  ({ quantityBoxIsOpen, theme }) => ({
    [theme.breakpoints.down('md')]: {
      display: quantityBoxIsOpen ? 'block' : 'none',
    },
  })
)

export const StyledIconButton = clubbiStyled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.brandPurple.main,
  fontSize: '14px',
  color: theme.palette.brandWhite.main,
  fontWeight: 400,
  width: '16px',
  height: '16px',
}))
