import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { WarningCutoffModal } from './WarningCutoffModal'
import { InstantPaymentModal } from './InstantPaymentModal/InstantPaymentModal'
import { CartWarningModalManager } from '../organisms/Cart/CartWarningModalManager'
import { cartActions } from '../../store/slices/cart'
import { loginFromCode, sessionActions } from '../../store/slices/session'
import { CartItem, Purchase } from '../../store/data/types'
import { Order, OrderResult, placeOrder } from '../../services/ordersApi'
import { useAppDispatch, useAppSelector } from '../../common/hooks'
import { sleep } from '../../common/request'
import { purchaseActions } from '../../store/slices/purchase'
import { BEACON_CONVERSION_URL, DELIVERY_TYPES } from '../../common/constants'
import {
  END_TWO_DAYS_TO_DELIVERY,
  getShippingInfos,
  isWithinDateRange,
  proveSourceWebhook,
  START_TWO_DAYS_TO_DELIVERY,
} from '../../common/utils'
import { formatRetailMediaData, getPriceByMode } from '../../common/productUtils'
import { CheckoutFormType, ConversionEventData } from '../organisms/Cart/interfaces'
import { CheckoutForm } from '../organisms/Cart/CheckoutForm'

const Checkout = (): React.ReactElement => {
  const session = useAppSelector((state) => state.session)
  const { regionId, status, cartSessionId, merchantCode, clubberEmail } = session
  const shippingType = useAppSelector((state) => state.shippingInfos.shippingType)

  const [error, setError] = useState<any>(null)

  const dispatch = useAppDispatch()
  if (error) {
    // TODO: In that case, every unexpected error will cause a cart cleanUp
    // It's good to Evaluate this action on REVIEW.
    dispatch(cartActions.cleanUp())
    throw error
  }
  const [isLoading, setLoading] = useState(false)
  const cartItems: CartItem[] = useAppSelector((state) => {
    return Object.values(state.cart).filter(
      ({ quantity, isPackageMode, product }) =>
        quantity > 0 && getPriceByMode(isPackageMode, product)
    )
  })
  const [formValue, setFormValue] = useState<CheckoutFormType>({
    merchantCode: session.merchantCode || '',
    merchantCodeInvalid: false,
    invoiceType: -1,
    paymentType: -1,
  })
  const [showModal, setShowModal] = useState(false)
  const [showModalInstantPayment, setShowModalInstantPayment] = useState(false)

  const [loginUpdated, setLoginUpdated] = useState(false)

  useEffect(() => {
    if (formValue.merchantCode.trim().length == 5) {
      dispatch(loginFromCode({ merchantCode: formValue.merchantCode }))
        .unwrap()
        .then((action) => {
          const [success, _] = action
          if (success) {
            setLoginUpdated(true)
          }
        })
    } else {
      setLoginUpdated(false)
    }
  }, [formValue.merchantCode])

  useEffect(() => {
    dispatch(cartActions.removeProductsWithoutQuantity())
  }, [])

  const { replace } = useHistory()

  const isBFWithTwoDaysToDelivery = isWithinDateRange(
    START_TWO_DAYS_TO_DELIVERY,
    END_TWO_DAYS_TO_DELIVERY
  )

  const submitOrder = () => {
    setLoading(true)
    const formMerchantCode = formValue.merchantCode.toUpperCase()

    if (formMerchantCode.length == 5 && formMerchantCode !== merchantCode) {
      dispatch(sessionActions.setMerchantCode(formMerchantCode))
    }

    const order: Order = {
      merchantCode: formMerchantCode,
      invoiceType: formValue.invoiceType,
      paymentType: formValue.paymentType,
      merchantStatus: status || null,
      regionId: regionId || null,
      items: cartItems,
      sessionId: cartSessionId,
      deliveryFee: isBFWithTwoDaysToDelivery
        ? DELIVERY_TYPES.blackFriday.value
        : getShippingInfos(shippingType).value,
      daysToDelivery: isBFWithTwoDaysToDelivery
        ? DELIVERY_TYPES.blackFriday.daysToDelivery
        : getShippingInfos(shippingType).daysToDelivery,
      clubberEmail: clubberEmail,
    }

    const { items: itemsData, ...orderData } = order
    console.info('placing order', JSON.stringify(itemsData), orderData, JSON.stringify(session))

    Promise.race([
      sleep(6000).then(() => 666),
      placeOrder(order)
        .then(([resultType, resultBody]) => {
          switch (resultType) {
            case OrderResult.SUCCESS:
              dispatch(cartActions.cleanUp())
              dispatch(sessionActions.clearCartSession())
              console.info('clearing cart info')
              replace('/checkout')
              if (resultBody) {
                const conversionEvent = (data: ConversionEventData) => {
                  const headers = {
                    type: 'application/json',
                  }
                  const blob = new Blob([JSON.stringify(data)], headers)
                  navigator.sendBeacon(BEACON_CONVERSION_URL, blob)
                }
                const { id, orderDatetime, paymentType, status } = resultBody.order as any
                const purchase = { id, orderDatetime, paymentType, itemsData, status } as Purchase
                dispatch(purchaseActions.setPurchase(purchase))
                conversionEvent(formatRetailMediaData(id, itemsData, cartSessionId, merchantCode))
              }
              proveSourceWebhook({ email: `${formMerchantCode.toLowerCase()}@clubbi.com.br` }).then(
                (res) => console.log('ProveSource notified: ', res)
              )
              break
            case OrderResult.INVALID_CODE:
              setFormValue((state) => ({ ...state, merchantCodeInvalid: true }))
              setLoading(false)
              dispatch(sessionActions.setIsActive({ isActive: true }))
              break
            case OrderResult.PRODUCT_CHANGES:
              setLoading(false)
              dispatch(sessionActions.showAlert())
              break
            default:
              throw OrderResult.UNEXPECTED
          }
        })
        .catch(setError),
    ]).then((s) => {
      if (s == 666) {
        console.error(
          'took too long',
          JSON.stringify(itemsData),
          orderData,
          JSON.stringify(session)
        )
      }
    })
  }

  return (
    <>
      <CartWarningModalManager />
      <WarningCutoffModal
        showModal={showModal}
        hide={() => setShowModal(false)}
        submitOrder={submitOrder}
      />
      <InstantPaymentModal
        showModal={showModalInstantPayment}
        hide={() => setShowModalInstantPayment(false)}
        submitOrder={submitOrder}
      />
      <CheckoutForm
        formValue={formValue}
        setFormValue={setFormValue}
        isLoading={isLoading}
        submitOrder={submitOrder}
        loginUpdated={loginUpdated}
        showModal={showModal}
        setShowModal={setShowModal}
        setShowModalInstantPayment={setShowModalInstantPayment}
      />
    </>
  )
}

export default Checkout
