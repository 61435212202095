import { clubbiStyled } from 'clubbi-ui'

export const StyledButton = clubbiStyled('button')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '43px',
  height: '43px',
  backgroundColor: theme.palette.brandYellow[60],
  border: 'none',
  borderRadius: '4px',
  marginTop: '-10px',

  [theme.breakpoints.up('sm')]: {
    marginLeft: '5px',
    width: '46px',
    height: '46px',
  },
}))

export const StyledDiv = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  border: 'none',
  borderRadius: '4px',

  [theme.breakpoints.up('sm')]: {
    marginLeft: '5px',
    width: '46px',
    height: '46px',
  },
}))
