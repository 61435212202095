import { Category, CategoryProducts, LinkData, Offer } from '../store/data/types'

export const getCategoryRoute = (category: Category, suffix = ''): string => {
  if (category.parent) {
    return getCategoryRoute(category.parent!, `/${category.slug}`)
  }
  return `/products/${category.slug}${suffix}`
}

export const categoryRoutes = (category?: Category, base: LinkData[] = []): LinkData[] => {
  if (!category) {
    return [{ text: 'Início', href: '/' }, ...base]
  }
  return categoryRoutes(category.parent, [
    {
      href: getCategoryRoute(category!),
      text: category?.name,
    },
    ...base,
  ])
}

export const categoryProductsToGroups = (productsCategory: CategoryProducts): Offer => {
  return {
    name: productsCategory.name,
    link: {
      text: productsCategory.name,
      href: getCategoryRoute(productsCategory),
    },
    products: productsCategory.products,
  }
}
