import React from 'react'
import { ProductLimitBadge } from '../../atoms/ProductLimitBadge'
import { CartChange, Product } from '../../../store/data/types'
import { getCartQuantityLimitReached } from '../../../store/data/cartFunctions'
import { useAppDispatch, useAppSelector } from '../../../common/hooks'
import { blockedBannerActions } from '../../../store/slices/blockedBanner'
import { isMerchantBlocked } from '../../../common/utils'
import { AddIcon, RemoveIcon } from 'clubbi-ui'
import {
  StyledIconButton,
  StyledInputWrapper,
  StyledContainer,
  StyledInputLabel,
  StyledInput,
} from './styles/CartItemInput.style'

export interface Props {
  value: number
  onChange: (change: CartChange) => void
  ctx?: string
  addAlgoliaEvent?: (_algoliaEvent: any) => void
  product: Product
  isOfertao: boolean
  isPackageMode: boolean
  variant?: string
}

const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => event.target.select()

export const CartItemInput = React.forwardRef(
  (
    {
      value = 0,
      onChange,
      ctx = '',
      addAlgoliaEvent,
      product,
      isOfertao,
      isPackageMode,
      variant,
      ...props
    }: Props,
    ref
  ) => {
    const dispatch = useAppDispatch()
    const merchantStatus = useAppSelector((state) => state.session.status)

    const isDefaultingCustomer = isMerchantBlocked(merchantStatus)

    const context = ctx!.concat('/')
    const limitReached = getCartQuantityLimitReached(isPackageMode, product, value)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === '') return
      const inputValue = parseInt(e.target.value)

      return onChange({
        quantity: inputValue,
        context: context,
      })
    }

    const handleBlockedChange = () => {
      dispatch(blockedBannerActions.setShow())
      addAlgoliaEvent &&
        addAlgoliaEvent({
          timestamp: Date.now(),
        })
    }

    const handleBlockedPlusClick = () => {
      dispatch(blockedBannerActions.setShow())
      addAlgoliaEvent &&
        addAlgoliaEvent({
          timestamp: Date.now(),
        })
    }

    const handlePlusBtnClick = () => {
      onChange({ quantity: value + 1, context: context })
      addAlgoliaEvent &&
        addAlgoliaEvent({
          timestamp: Date.now(),
        })
    }

    const getInputLabel = () => {
      const label = isPackageMode ? 'Caixa' : 'Unidade'
      return value === 1 ? label : `${label}s`
    }

    return (
      <>
        <StyledContainer>
          <StyledInputWrapper data-testid="cart-item-input">
            <StyledIconButton
              data-testid="cart-item-decrease-btn"
              onClick={() => {
                onChange({ quantity: value - 1, context: context })
              }}
            >
              <RemoveIcon fontSize="inherit" />
            </StyledIconButton>
            <StyledInput
              type="number"
              min="0"
              inputMode="numeric"
              pattern="[0-9]*"
              aria-label="quantidade no carrinho"
              value={value}
              onChange={isDefaultingCustomer ? handleBlockedChange : handleChange}
              onFocus={handleFocus}
            />
            <StyledIconButton
              onClick={isDefaultingCustomer ? handleBlockedPlusClick : handlePlusBtnClick}
              // @ts-ignore
              disabled={limitReached}
              data-testid="cart-item-increase-btn"
            >
              <AddIcon fontSize="inherit" />
            </StyledIconButton>
          </StyledInputWrapper>
          <StyledInputLabel>{getInputLabel()}</StyledInputLabel>
        </StyledContainer>
        {!!limitReached && (
          <ProductLimitBadge
            variant={variant}
            label="Você atingiu o limite de itens dessa promoção"
          />
        )}
      </>
    )
  }
)
