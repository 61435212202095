import { ProgressBar, themeClubbi } from 'clubbi-ui'
import { useEffect } from 'react'

import { ShippingTypesSelector } from './ShippingTypesSelector'
import { Money } from '../../atoms'
import { dispatch } from '../../../store'
import { totalPriceSelector } from '../../routes/Cart'
import { shippingInfosActions } from '../../../store/slices/ShippingInfos'
import { useAppSelector } from '../../../common/hooks'
import { DELIVERY_TYPE_ENUM, MIN_AMOUNT_TO_HAVE_DELIVERY_FREE } from '../../../common/constants'
import gtm from '../../../common/gtmTracker'

import {
  StyledInfos,
  StyledMax,
  StyledProgressBar,
  StyledSelectionContainer,
  StyledTitle,
} from './styles/ShippingTypes.style'
import { useInView } from 'react-intersection-observer'

const ProductTotalPriceText = (MAX: number, totalPrice: number) => {
  const result = MAX - totalPrice
  return <Money amount={result} isBold />
}

export const ShippingTypes = () => {
  const { ref, inView } = useInView({ threshold: 0.6 })
  const { merchantCode, clubberEmail } = useAppSelector((state) => state.session)
  const totalPrice = useAppSelector(totalPriceSelector)

  const currentProgress = totalPrice
  const MIN = 0
  const MAX = MIN_AMOUNT_TO_HAVE_DELIVERY_FREE

  const completedProgress = currentProgress! >= MAX

  const handleProgressBar = () => {
    if (currentProgress! < MIN) {
      return 0
    } else if (currentProgress! === MAX) {
      return 100
    } else {
      return ((currentProgress! - MIN) * 100) / (MAX - MIN)
    }
  }

  useEffect(() => {
    if (!completedProgress) {
      dispatch(
        shippingInfosActions.setShippingType({
          shippingType: DELIVERY_TYPE_ENUM.fast,
          completedProgress,
        })
      )
    }
    dispatch(
      shippingInfosActions.setShippingType({
        shippingType: DELIVERY_TYPE_ENUM.fast,
        completedProgress,
      })
    )
  }, [completedProgress])

  useEffect(() => {
    if (inView) {
      gtm.triggerShippingTypesImpression(merchantCode, clubberEmail)
    }
  }, [inView])

  return (
    <StyledSelectionContainer ref={ref}>
      <StyledTitle>Opções de Entrega</StyledTitle>
      <StyledProgressBar>
        <ProgressBar value={handleProgressBar()} color={themeClubbi.palette.brandPurple[60]} />
      </StyledProgressBar>
      <StyledInfos>
        {!completedProgress ? (
          <div>
            Faltam <b>{ProductTotalPriceText(MAX, totalPrice)}</b> para optar pela{' '}
            <b>Entrega Econômica</b>.
          </div>
        ) : (
          <div>Entrega Econômica disponível!</div>
        )}
        <StyledMax>R${MIN_AMOUNT_TO_HAVE_DELIVERY_FREE}</StyledMax>
      </StyledInfos>
      <ShippingTypesSelector />
    </StyledSelectionContainer>
  )
}
