import { ButtonClubbi, clubbiStyled } from 'clubbi-ui'

export const StyledButton = clubbiStyled(ButtonClubbi)(({ theme }) => ({
  textTransform: 'capitalize',
  width: '100%',
  fontSize: '14px',
  fontWeight: 600,
  height: '32px',
  padding: '4px 20px',
  '&:disabled': {
    backgroundColor: theme.palette.brandGrey[40],
    color: theme.palette.brandWhite.main,
  },
}))
