import { useEffect } from 'react'
import { CloseIcon, Slide } from 'clubbi-ui'

import { setCurrency } from '../../../common/cardUtils'
import { useAppSelector } from '../../../common/hooks'
import { getEconomy, quantityToCompleteComboCalc } from '../../../common/utils'
import { dispatch } from '../../../store'
import { getMoreForLessActions } from '../../../store/slices/getMoreForLess'
import { ComboBadge } from './ComboBadge'

import {
  StyledImg,
  StyledContainerPrice,
  StyledPopupHeader,
  StyledCloseButton,
  StyledPopup,
} from './styles/PopupGetMoreForLess.style'

export const PopupGetMoreForLess = () => {
  const { comboProduct, openPopup } = useAppSelector((state) => state.getMoreForLess)
  const cart = useAppSelector((state) => state.cart)

  const item = comboProduct && cart[comboProduct.id]

  const quantity = !!quantityToCompleteComboCalc(item)

  const closePopup = () => {
    dispatch(getMoreForLessActions.setClosePopup())
  }

  useEffect(() => {
    setTimeout(() => {
      closePopup()
    }, 8000)
  }, [])

  return (
    <>
      {item && quantity && (
        <Slide direction="up" in={openPopup} mountOnEnter unmountOnExit>
          <StyledPopup data-testid="popup-getmoreforless">
            <StyledImg src={comboProduct?.imageUrls.image120Px} alt="imagem do produto" />
            <StyledPopupHeader>
              <ComboBadge item={item} />
              <StyledContainerPrice data-testid="economy-text">
                <span>E economize</span>
                <strong>{`R$${setCurrency(getEconomy(item))}`}</strong>
                <span>neste produto!</span>
              </StyledContainerPrice>
            </StyledPopupHeader>
            <StyledCloseButton onClick={closePopup}>
              <CloseIcon />
            </StyledCloseButton>
          </StyledPopup>
        </Slide>
      )}
    </>
  )
}
