import { clubbiStyled } from 'clubbi-ui'

export const StyledContainer = clubbiStyled('div')<{ isContactPage: boolean }>(
  ({ isContactPage }) => ({
    display: isContactPage ? 'flex' : 'none',
    flexDirection: 'column',
    width: '100%',
  })
)

export const StyledPhoneContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '10px',
  [theme.breakpoints.up('md')]: {
    width: '72%',
  },
}))

export const StyledHR = clubbiStyled('hr')(({ theme }) => ({
  color: theme.palette.brandGrey.light,
  width: '100%',
  marginTop: '12px',
  marginBottom: '16px',
  [theme.breakpoints.up('md')]: {
    marginTop: '16px',
    marginBottom: '20px',
  },
}))

export const StyledOptionalLabel = clubbiStyled('span')(({ theme }) => ({
  fontSize: '10px',
  lineHeight: '170.2%',
  color: theme.palette.brandGrey[60],
  textAlign: 'left',
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
  },
}))
