import { CategoryProducts, Product, SpecialSupplier } from '../../store/data/types'
import React from 'react'
import { LoadablePage, LoadablePageProps } from './LoadablePage'
import { ProductsList } from '../organisms/product/ProductsList'
import { HomeProps } from '../templates/Home'

export interface WelcomeData {
  ofertoes: Product[]
  dailyOffers: Product[]
  weekOffers: Product[]
  bestPrices: Product[]
  bestSellers: Product[]
  previouslyOrdered: Product[]
  sections: CategoryProducts[]
  specialSuppliers: SpecialSupplier[]
  bfCorridor?: Product[]
}

export const partnerFetch = () => {
  return {
    groups: [],
    context: ['promotions'],
  }
}

export const Partner = (props: LoadablePageProps<HomeProps>) => {
  const fetch = partnerFetch()
  return (
    <LoadablePage {...props} dataFetch={fetch}>
      {ProductsList}
    </LoadablePage>
  )
}
