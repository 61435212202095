import { clubbiStyled as styled, CardMedia, IconButton } from 'clubbi-ui'

export const StyledMain = styled('div')(({ theme }) => ({
  width: '266px',
  display: 'flex',
  flexDirection: 'column',
  padding: '12px',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    width: '370px',
  },
}))

export const StyledCardMedia = styled(CardMedia)<{ component: string; alt: string }>(
  ({ theme }) => ({
    maxHeight: '120px',
    width: 'auto',
    alignSelf: 'center',
    margin: '7px',

    [theme.breakpoints.up('sm')]: {
      maxHeight: '180px',
      margin: '0px',
    },
  })
)

export const StyledTypography = styled('p')(({ theme }) => ({
  lineHeight: 1,
  fontSize: theme.typography.fontSizeXS,
  fontWeight: '700',
  marginBottom: '1px',

  overflow: 'hidden',
  '-webkit-overflow': 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': '3',
  lineClamp: '3',
  '-webkit-box-orient': 'vertical',

  color: theme.palette.brandGrey[100],

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeM,
  },
}))

export const StyledButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.brandGrey[60],
  position: 'absolute',
  left: '220px',
  top: '0px',

  [theme.breakpoints.up('sm')]: {
    left: '320px',
    top: '5px',
  },
}))

export const StyledDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'start',
  padding: '6px',
}))

export const StyledAditionalInfoCard = styled('div')(({ theme }) => ({
  borderRadius: '6px',
  padding: '6px',
  backgroundColor: theme.palette.brandGrey[10],
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'start',
  fontSize: theme.typography.fontSize2XS,
}))
