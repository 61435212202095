import { clubbiStyled } from 'clubbi-ui'

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '160px',
  height: '16px',
  marginBottom: '5px',

  [theme.breakpoints.up('sm')]: {
    width: '248px',
    height: '20px',
  },
}))

export const StyledContent = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  gap: '5px',
  width: '160px',
  height: '16px',

  [theme.breakpoints.up('sm')]: {
    width: '248px',
    height: '20px',
  },
}))

export const StyledEanContainer = clubbiStyled('div')<{ checked: boolean }>(
  ({ theme, checked }) => ({
    fontSize: theme.typography.fontSize4XS,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    gap: '4px',
    height: '16px',
    padding: '0 6px',
    borderRadius: '10px',
    cursor: 'pointer',

    color: theme.palette.brandGrey[100],
    background: checked ? theme.palette.brandYellow[60] : theme.palette.brandWhite.main,

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.fontSize2XS,
      height: '20px',
      padding: '2px 10px 0 10px',
    },
  })
)

export const StyledCheckbox = clubbiStyled('input')(({ theme }) => ({
  width: '9px',
  height: '9px',
  marginBottom: '1px',

  [theme.breakpoints.up('sm')]: {
    width: '12px',
    height: '12px',
    marginBottom: '2px',
  },
}))

export const StyledEan = clubbiStyled('div')({})

export const StyledCopyButton = clubbiStyled('div')(({ theme }) => ({
  width: '18px',
  height: '16px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  color: theme.palette.brandPurple[80],
  background: theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    width: '20px',
    height: '20px',
  },
}))
