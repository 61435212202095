import { clubbiStyled } from 'clubbi-ui'

export const StyledInvoiceSummaryCard = clubbiStyled('div')(({ theme }) => ({
  display: 'inline-block',
  position: 'relative',
  background: theme.palette.brandWhite.main,
  padding: '24px',
  margin: '16px 0px 0px 0px',
  width: '100%',
  boxShadow: '0px 4px 12px rgba(98, 98, 102, 0.2)',
  borderRadius: '13px',
  [theme.breakpoints.up('md')]: {
    width: '60%',
  },
}))

export const StyledLink = clubbiStyled('a')(({ theme }) => ({
  textDecoration: 'underline',
  fontSize: '10px',
  color: theme.palette.brandGrey.dark,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))

export const StyledWrapper = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}))
