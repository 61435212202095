import { clubbiStyled } from 'clubbi-ui'
import { getSeasonStyle } from '../../../../common/seasonality'

const season = getSeasonStyle()

export const StyledFlyerContainer = clubbiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '1080px',
  height: '1920px',
  background: season.backgroundColor,
}))

export const StyledContent = clubbiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
}))

export const StyledDateContainer = clubbiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'end',
  gap: '2px',
  position: 'absolute',
  top: '40px',
  right: '15px',
}))

export const StyledDay = clubbiStyled('span')(({ theme }) => ({
  fontSize: theme.typography.fontSize4XL,
  fontWeight: 700,
  lineHeight: '44px',
  textAlign: 'center',
  color: theme.palette.brandWhite.main,
  textShadow: season.shadow,
}))

export const StyledDate = clubbiStyled('span')(({ theme }) => ({
  fontSize: theme.typography.fontSize3XL,
  lineHeight: '40px',
  textShadow: season.shadow,
  color: theme.palette.brandWhite.main,
}))

export const StyledHeaderImage = clubbiStyled('img')(() => ({
  width: '700px',
}))

export const StyledProductsContainer = clubbiStyled('div')(({ theme }) => ({
  width: '1000px',
  height: '1500px',
  borderRadius: '25px',

  background: theme.palette.brandWhite.main,
}))

export const StyledProductList = clubbiStyled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  flexWrap: 'wrap',
  alignItems: 'center',
  width: '960px',
  height: '1460px',
  margin: '20px',
}))

export const StyedFooter = clubbiStyled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '950px',
  height: '120px',
  borderTopLeftRadius: '25px',
  borderTopRightRadius: '25px',

  background: season.footerColor,
}))

export const StyledFooterImage = clubbiStyled('img')(() => ({
  width: '700px',
  height: '120px',
}))
