import { Dispatch, SetStateAction } from 'react'
import { GOOD_GRADES, BAD_GRADES } from '../../../common/constants'
import { Select, SelectChangeEvent } from 'clubbi-ui'
import {
  StyledFormSelect,
  StyledMenuItem,
  StyledSelectLabel,
} from './styles/SelectEvaluation.style'

export const SelectEvaluationDropdown = ({
  dropdownTitle,
  setDropdownTitle,
  isSnackBar,
}: {
  dropdownTitle: string
  setDropdownTitle: Dispatch<SetStateAction<string>>
  isSnackBar?: boolean
}) => {
  const handleChange = (e: SelectChangeEvent) => {
    setDropdownTitle(e.target.value)
  }

  return (
    <StyledFormSelect>
      <StyledSelectLabel>{'Qual o principal motivo da avaliação?'}</StyledSelectLabel>
      <Select
        value={dropdownTitle}
        title={dropdownTitle}
        onChange={(event: SelectChangeEvent<any>) => handleChange(event)}
        className="select"
        variant="standard"
      >
        {[...GOOD_GRADES, ...BAD_GRADES]
          .reduce<string[]>(
            (acc, grade) => {
              if (acc.includes(grade)) return [...acc]
              else return [...acc, grade]
            },
            ['']
          )
          .sort()
          .map((el, ind, arr) => {
            return (
              <StyledMenuItem
                key={ind.toString()}
                isLast={ind === arr.length - 1 && isSnackBar}
                value={el}
              >
                {el === '' ? '-' : el}
              </StyledMenuItem>
            )
          })}
      </Select>
    </StyledFormSelect>
  )
}
