import { clubbiStyled } from 'clubbi-ui'
import { TypeInfos } from '../../../../../common/cardUtils'

export const StyledCardInfos = clubbiStyled('div')<{ type: TypeInfos }>(({ theme, type }) => ({
  width: '162px',
  height: '22px',
  borderBottomRightRadius: '4px',
  borderBottomLeftRadius: '4px',
  transitionDuration: '0.4s',
  opacity: type === 'hide' ? 0 : 1,

  color: type === 'totalPrice' ? theme.palette.brandWhite.main : theme.palette.brandAlert[50],
  background: type === 'totalPrice' ? theme.palette.brandPurple.main : theme.palette.brandAlert[10],

  [theme.breakpoints.up('sm')]: {
    width: '248px',
    height: '26px',
  },
}))

export const StyledWhiteBorder = clubbiStyled('div')(({ theme }) => ({
  width: '162px',
  height: '4px',
  borderBottomRightRadius: '4px',
  borderBottomLeftRadius: '4px',
  transitionDuration: '0.4s',

  background: theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    width: '248px',
  },
}))

export const StyledMessage = clubbiStyled('p')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  width: '220px',
  paddingLeft: '8px',
  transitionDuration: '0.1s',

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))
