import { CartItem, Mission } from '../store/data/types'
import { getPriceByMode } from './productUtils'

export const isCartSatisfyingMission = (
  mission: Mission,
  cartItems: { [key: string]: CartItem }
): boolean => {
  const activeMissionEans = mission.eans.filter((ean) => ean.active).map((v) => v.ean)
  const missionCartItems = Object.values(cartItems).filter(
    (cartItem) => activeMissionEans.includes(cartItem.product.ean) || mission.criteria == 'NONE'
  )
  const missionCartItemsValue = missionCartItems.reduce(
    (x: number, cartItem: CartItem) =>
      x + cartItem.quantity * getPriceByMode(cartItem.isPackageMode, cartItem.product)!,
    0
  )
  if (missionCartItems.length === 0) {
    return false
  }
  if (missionCartItems.length !== 0 && !mission.minOrderAmount) {
    return true
  }
  return !!(
    missionCartItems &&
    mission.minOrderAmount &&
    missionCartItemsValue >= mission.minOrderAmount
  )
}

export const getMissionProgress = (
  mission: Mission,
  cartItems: { [key: string]: CartItem }
): number | undefined => {
  if (mission) {
    const activeMissionEans = mission.eans.filter((ean) => ean.active).map((v) => v.ean)
    const missionCartItems = Object.values(cartItems).filter(
      (cartItem) => activeMissionEans.includes(cartItem.product.ean) || mission.criteria == 'NONE'
    )
    const missionCartItemsValue = missionCartItems.reduce(
      (x: number, cartItem: CartItem) =>
        x + cartItem.quantity * getPriceByMode(cartItem.isPackageMode, cartItem.product)!,
      0
    )
    return missionCartItemsValue
  }
}

export const getMissionsDiscount = (
  missions: Mission[] | undefined,
  cartItems: { [key: string]: CartItem },
  shippingFee: number
): number => {
  let discountAmount = 0

  const selectedMissions = missions?.filter(({ customerIds }) => customerIds[0].selected)
  const validMissions = selectedMissions ? [...selectedMissions] : []

  validMissions.forEach((mission) => {
    if (isCartSatisfyingMission(mission, cartItems)) {
      switch (mission.type) {
        case 'FREE_SHIPPING':
          discountAmount += shippingFee
          break
        case 'FIXED_VALUE':
          discountAmount += mission.discountAmount!
          break
        default:
          discountAmount += mission.discountAmount!
      }
    }
  })
  return discountAmount
}
