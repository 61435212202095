import { Button } from 'react-bootstrap'
import { H3, H6, LinkBackHome } from '../atoms'
import styled from 'styled-components'
import { SIGN_UP_LINK } from '../../common/constants'
import { useAppDispatch } from '../../common/hooks'
import { useCallback } from 'react'
import { sessionActions } from '../../store/slices/session'
import { LoginModal } from './LoginModal'

const StyledPromptLogin = styled('div')`
  background-color: white;
  position: fixed;
  left: calc(50% - 15em);
  top: calc(40% - 4em);
  width: 30em;
  height: 8em;

  @media (max-width: 620px) {
    left: calc(50% - 10em);
    width: 20em;
  }

  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    text-align: center;
    line-height: 1.2;
    background-color: white;
    padding-top: 24px;
  }

  div {
    display: flex;
  }
`

const StyledButton = styled(Button)`
  margin: auto 0.5em;
  padding: 15px 30px;
  background-color: #7c00f4;
  font-weight: bold;
`
const StyledLink = styled.button`
  background-color: white;
  font-weight: bold;
  border: none;
  text-decoration: underline;
  margin-bottom: 0.5em;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const PromptLogin = () => {
  const dispatch = useAppDispatch()
  const showLoginModal = useCallback(() => dispatch(sessionActions.showModal(true)), [dispatch])

  return (
    <StyledPromptLogin>
      <LoginModal />
      <section>
        <H3>Para visualizar as ofertas, faça o login</H3>
        <StyledButton onClick={() => showLoginModal()}>Fazer login</StyledButton>
        <StyledContainer>
          <div>
            <H6>Não tem um login?</H6>
            <StyledLink type="button" onClick={() => window.location.assign(SIGN_UP_LINK)}>
              Inscreva-se
            </StyledLink>
          </div>
          <span>ou</span>
          <LinkBackHome />
        </StyledContainer>
      </section>
    </StyledPromptLogin>
  )
}
