import { ButtonClubbi } from 'clubbi-ui'
import React from 'react'
import { useHistory } from 'react-router'

export const ReturnButton = () => {
  const { replace } = useHistory()
  return (
    <ButtonClubbi size="medium" typeButton="outline" label="Voltar" onClick={() => replace('/')} />
  )
}
