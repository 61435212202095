import { themeClubbi, useMediaQuery } from 'clubbi-ui'

import { Mission } from '../../store/data/types'
import { MissionDiscountIcon, MissionFreeShipping } from '../atoms/MissionsIcons'

import { StyledMissionHeadline, StyledTextPurple } from './styles/MissionPromo.style'

export const MissionPromo = ({ mission }: { mission: Mission }) => {
  const { type, discountAmount } = mission
  const isDesktop = useMediaQuery(themeClubbi.breakpoints.up('md'))
  const isCartPage = location.pathname === '/cart'

  const getPromoText = () => {
    if (type == 'FREE_SHIPPING') return 'Frete Grátis'
    else if (type == 'FIXED_VALUE') return `Ganhe R$${discountAmount}`
    else return `Ganhe ${discountAmount}%`
  }

  return (
    <StyledMissionHeadline>
      {type == 'FREE_SHIPPING' ? (
        <MissionFreeShipping deviceSize={isDesktop} />
      ) : (
        <MissionDiscountIcon deviceSize={isDesktop} />
      )}
      <StyledTextPurple isCartPage={isCartPage}>{getPromoText()}</StyledTextPurple>
    </StyledMissionHeadline>
  )
}
