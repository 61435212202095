import { useCallback, useEffect, useState } from 'react'
import { themeClubbi } from 'clubbi-ui'

import { useAppSelector } from '../../common/hooks'
import { Message } from '../atoms/SnackBar'
import { MerchantStatus } from '../../store/slices/session'

import {
  StyledCloseButton,
  StyledCloseIcon,
  StyledContainer,
  StyledContent,
  StyledHeader,
  StyledModal,
} from './styles/PopupWarning.style'

export const PopupWarning = () => {
  const [modalVisibility, setModalVisibility] = useState(false)
  const [message, setMessage] = useState<Message>()
  const [messages, { regionId, status }] = useAppSelector((state) => [
    state.messages,
    state.session,
  ])

  const isDefaultingCustomer = status === MerchantStatus.DEFAULTED
  const hide = useCallback(() => setModalVisibility(false), [])

  const getRangeIds = () => {
    return messages.map((message) => message.pricingGroupId)
  }

  useEffect(() => {
    if (message && getRangeIds().includes(message.pricingGroupId)) {
      setModalVisibility(true)
    }
  }, [message])

  useEffect(() => {
    const [msg] = messages.filter(
      (message) => message.pricingGroupId == regionId && message.type === 'modal'
    )
    if (msg) {
      setMessage(msg)
    }
  }, [regionId, messages])

  return (
    <>
      {!!(message && !isDefaultingCustomer) && (
        <StyledModal open={modalVisibility} onClose={hide}>
          <StyledContainer>
            <StyledHeader>
              {message.title}
              <StyledCloseButton onClick={hide}>
                <StyledCloseIcon color={themeClubbi.palette.brandGrey.main} />
              </StyledCloseButton>
            </StyledHeader>
            <StyledContent>
              <p>Cliente Clubbi,</p>
              <p>{message.text}</p>
              <p>Clubbi</p>
            </StyledContent>
          </StyledContainer>
        </StyledModal>
      )}
    </>
  )
}
