import { InvoiceSummary } from '../../../store/data/types'
import { checkDelayedPayment, statusTranslator } from '../../../common/utils'
import { whatsappBillingCXLinkGenerator } from '../../../common/constants'
import {
  StyledInvoiceSummaryCard,
  StyledLink,
  StyledWrapper,
} from './styles/InvoiceSummaryCard.style'
import { InvoiceCardTextContent } from './InvoiceCardTextContent'
import { InvoiceCardLinkBoard } from './InvoiceCardLinkBoard'
import { InvoiceCardHeader } from './InvoiceCardHeader'
import { RatingModule } from './RatingModule'
import { useAppSelector } from '../../../common/hooks'

export const InvoiceSummaryCard = ({ invoice }: { invoice: InvoiceSummary }) => {
  const merchantCode = useAppSelector((state) => state.session.merchantCode)
  const {
    shopperPlanId,
    status,
    paymentType,
    billingStatus,
    totalDeliveredPrice,
    billingValue,
    totalPrice,
    rating,
  } = invoice

  return (
    <StyledInvoiceSummaryCard>
      <StyledWrapper>
        <InvoiceCardHeader
          title={`Entrega #${shopperPlanId}`}
          deliveryStatus={statusTranslator(status)}
          isPaymentDelayed={checkDelayedPayment(billingStatus)}
        />
        {rating && <RatingModule rating={rating} />}
      </StyledWrapper>
      <InvoiceCardTextContent
        paymentType={paymentType}
        billingStatus={billingStatus}
        billingValue={billingValue}
        totalDeliveredPrice={totalDeliveredPrice}
        totalPrice={totalPrice}
      />
      <InvoiceCardLinkBoard invoice={invoice} merchantCode={merchantCode} />
      {checkDelayedPayment(billingStatus) && (
        <StyledLink
          href={whatsappBillingCXLinkGenerator(merchantCode, shopperPlanId)}
          target="_blank"
          rel="noreferrer"
        >
          Já efetuou o pagamento? Nos envie o comprovante por aqui
        </StyledLink>
      )}
    </StyledInvoiceSummaryCard>
  )
}
