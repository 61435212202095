import { CloseIcon, clubbiStyled, Modal } from 'clubbi-ui'

export const StyledModal = clubbiStyled(Modal)(({ theme }) => ({
  border: 'none',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  outlineColor: theme.palette.brandGrey[10],
}))

export const StyledLoading = clubbiStyled('div')(({ theme }) => ({
  position: 'absolute',
  width: '90vw',
  height: '80%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
  backgroundColor: theme.palette.brandGrey[10],
  outlineColor: theme.palette.brandGrey[10],
  [theme.breakpoints.up('sm')]: {
    width: '43vw',
    height: '64%',
  },
}))

export const StyledLoadingMessage = clubbiStyled('h2')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXXS,
  fontWeight: '700',
  lineHeight: '18px',
  color: theme.palette.brandPurple[60],
}))

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  position: 'absolute',
  width: '90vw',
  height: 'auto',
  padding: '0 12px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: '12px',

  backgroundColor: theme.palette.brandGrey[10],
  outlineColor: theme.palette.brandGrey[10],
  [theme.breakpoints.up('sm')]: {
    maxWidth: '72vh',
  },
}))

export const StyledCloseButton = clubbiStyled('button')(({ theme }) => ({
  position: 'absolute',
  right: '10px',
  top: '10px',
  textDecoration: 'underline',
  border: 'none',

  backgroundColor: theme.palette.brandGrey[10],
}))

export const StyledCloseIcon = clubbiStyled(CloseIcon)(() => ({
  width: '40px',
}))

export const StyledLine = clubbiStyled('hr')(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.brandGrey[10],
  marginTop: '47px',
}))

export const StyledTitle = clubbiStyled('h2')(({ theme }) => ({
  fontSize: theme.typography.fontSizeM,
  fontWeight: '700',
  lineHeight: '23px',

  color: theme.palette.brandPurple[60],
}))

export const StyledDescription = clubbiStyled('p')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXXS,
  lineHeight: '21px',
  maxWidth: '266px',
  maxheight: '63px',

  color: theme.palette.brandGrey[100],
}))

export const StyledTextAreaContainer = clubbiStyled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '251px',
}))

export const StyledSearchImage = clubbiStyled('img')(({ theme }) => ({
  position: 'absolute',
  top: '15px',
  left: '10px',
  width: '14px',
  height: '14px',
}))

export const StyledTextArea = clubbiStyled('textarea')<{ formatError: boolean }>(
  ({ theme, formatError }) => ({
    fontSize: theme.typography.fontSizeXXS,
    lineHeight: '22px',
    width: '100%',
    height: '251px',
    borderRadius: '4px',
    border: formatError
      ? `2px solid ${theme.palette.brandAlert[50]}`
      : `2px solid ${theme.palette.brandPurple[20]}`,
    padding: '10px',
    paddingLeft: '34px',

    color: theme.palette.brandGrey[100],

    '&:focus': {
      outlineColor: theme.palette.brandPurple[30],
    },
  })
)

export const StyledButton = clubbiStyled('button')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXXS,
  fontWeight: '700',
  width: '100%',
  height: '43px',
  borderRadius: '4px',
  border: 'none',
  marginTop: '20px',
  alignSelf: 'center',

  color: theme.palette.brandWhite.main,
  background: theme.palette.brandPurple[60],

  '&:disabled': {
    textDecoration: 'none',
    background: theme.palette.brandGrey[30],
  },
}))

export const StyledNextImage = clubbiStyled('img')(({ theme }) => ({
  marginLeft: '21px',
}))

export const StyledCloseMessage = clubbiStyled('button')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXXS,
  fontWeight: '700',
  textDecoration: 'underline',
  alignSelf: 'center',
  marginTop: '5px',
  marginBottom: '40px',
  border: 'none',

  background: 'none',
  color: theme.palette.brandAlert[60],
}))
