import { clubbiStyled } from 'clubbi-ui'
import { Link } from 'react-router-dom'

export const StyledLink = clubbiStyled(Link)({
  '&:hover': {
    textDecoration: 'none',
  },

  p: {
    fontWeight: 700,
  },
})

export const StyledHeader = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '15px 0px',

  span: {
    fontSize: theme.typography.fontSizeL,
    fontWeight: theme.typography.fontWeightBold,
  },

  a: {
    marginTop: '5px',
    '&:hover': {
      textDecoration: 'none',
    },

    span: {
      color: theme.palette.brandGrey[100],
      fontSize: theme.typography.fontSizeXS,
    },
  },

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXS,
    margin: '0 0 16px 0',

    span: {
      fontSize: theme.typography.fontSizeL,
      fontWeight: 600,

      color: theme.palette.brandGrey[100],
    },

    a: {
      fontSize: theme.typography.fontSizeXXS,
    },
  },

  [theme.breakpoints.down('sm')]: {
    paddingRight: '10px',
  },
}))

export const StyledContainer = clubbiStyled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
})

export const StyledMain = clubbiStyled('div')<{ isHomePage: boolean }>(({ theme, isHomePage }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: '16px',
  marginBottom: !isHomePage ? '50px' : '105px',

  [theme.breakpoints.up('sm')]: {
    width: '100%',
    marginLeft: '32px',
    marginBottom: !isHomePage ? '100px' : '105px',
    paddingRight: '58px',
  },
}))
