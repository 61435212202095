import { IconButton, clubbiStyled } from 'clubbi-ui'

export const StyledIconButton = clubbiStyled(IconButton)(({ theme }) => ({
  fontSize: '16px',
  color: theme.palette.brandGrey[60],
  width: '16px',
  height: '16px',
  [theme.breakpoints.up('md')]: {
    width: '20px',
    height: '20px',
    fontSize: '20px',
  },
}))
