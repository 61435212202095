import React from 'react'

import { UnavailableMode } from './UnavailableMode'
import { GetMoreForLessInformations } from '../../../molecules/getMoreForLessInformations/GetMoreForLessInformations'
import { setCurrency } from '../../../../common/cardUtils'
import { WITHOUT_PRICE } from '../../../../common/constants'
import { useAppSelector } from '../../../../common/hooks'
import { showPrices } from '../../../../common/utils'
import { getPackagePrice, getUnitPerPackagePrice } from '../../../../common/productUtils'
import { dispatch } from '../../../../store'
import { getMaxLimitForMode } from '../../../../store/data/cartFunctions'
import { Product } from '../../../../store/data/types'
import { cartActions } from '../../../../store/slices/cart'

import {
  StyledContainerPrices,
  StyledContainerPackage,
  StyledModePackage,
  StyledContainerPricesPackage,
  StyledContainerDiscount,
  StyledCurrency,
  StyledContainerUnit,
  StyledModeUnit,
  StyledContainerPricesUnit,
  StyledContainerUnitPrice,
  StyledPricePackage,
  StyledPriceUnit,
  StyledContainerValues,
} from './styles/CardPriceInfo.style'

interface CardPriceInfoProps {
  mode?: string
  warning: boolean
  setWarning: any
  product: Product
  setLimitReached: any
  quantity: number
}

const CardPriceInfo = ({
  mode,
  warning,
  setWarning,
  product,
  setLimitReached,
  quantity,
}: CardPriceInfoProps) => {
  const { discount, packageNumberOfItems, inOfertao, price, packagePrice } = product
  const cartItem = useAppSelector((state) => state.cart[product.id])
  const { merchantCode } = useAppSelector((state) => state.session)

  const isPackageSelected = mode === 'package'
  const isUnitSelected = mode === 'unit'
  const isPromotion = !!discount
  const flagSecondTitlePromotion = `${inOfertao && !discount ? 'OFERTÃO' : ''}`
  const [firstSupplier] = product.supplierPrices
  const unitPrice = firstSupplier.price
  const supplierPackagePrice = firstSupplier.packagePrice

  const { getMoreForLess } = firstSupplier

  const unitPerPackagePromoPriceWithoutDiscount = getUnitPerPackagePrice(
    packagePrice,
    packageNumberOfItems
  )

  const packagePriceSupplier = getPackagePrice(product)

  const getPackagePriceToShow = () => {
    let packagePriceToshow = supplierPackagePrice

    if (getMoreForLess) {
      packagePriceToshow = getMoreForLess.priceWithoutPromotion
    }

    return packagePriceToshow
  }

  const unitPerPackagePrice = getUnitPerPackagePrice(getPackagePriceToShow(), packageNumberOfItems)

  const handleModeChange = (isPackageMode: boolean) => {
    const quantity = cartItem?.quantity || 0
    const maxLimit = quantity > getMaxLimitForMode(!!cartItem?.isPackageMode, product)
    setLimitReached(maxLimit)
    dispatch(cartActions.setMode({ product, isPackageMode }))
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget.id === 'package-selector') {
      handleModeChange(true)
    } else {
      handleModeChange(false)
    }

    setWarning(false)
  }

  const unvailableUnit = !!flagSecondTitlePromotion || !unitPrice
  const unvailablePackage = !packagePriceSupplier

  const finalPackagePrice = showPrices(merchantCode)
    ? setCurrency(unitPerPackagePrice)
    : WITHOUT_PRICE
  const finalUnitPrice = showPrices(merchantCode) ? setCurrency(unitPrice) : WITHOUT_PRICE

  return (
    <StyledContainerPrices>
      {unvailablePackage ? (
        <UnavailableMode unvailablePackage={unvailablePackage} unvailableUnit={unvailableUnit} />
      ) : (
        <></>
      )}
      {!!packagePriceSupplier && (
        <StyledContainerPackage
          onClick={handleClick}
          id="package-selector"
          isPackageSelected={isPackageSelected}
          isUnitSelected={isUnitSelected}
          warning={warning}
        >
          <StyledModePackage isPackageSelected={isPackageSelected} warning={warning}>
            <span>Caixa</span> {packageNumberOfItems} un.
          </StyledModePackage>
          <StyledContainerPricesPackage isPackageSelected={isPackageSelected}>
            <StyledContainerValues isUnitSelected={isUnitSelected}>
              <StyledCurrency fontSizeMobile={'0.5rem'}>R$</StyledCurrency>
              <StyledPricePackage
                isUnitSelected={isUnitSelected}
                data-testid="unit-price-in-package"
              >
                {finalPackagePrice}
              </StyledPricePackage>
              <span>/un.</span>
              {isPromotion && (
                <StyledContainerDiscount>
                  <span>R${setCurrency(unitPerPackagePromoPriceWithoutDiscount)}</span>
                </StyledContainerDiscount>
              )}
            </StyledContainerValues>
          </StyledContainerPricesPackage>
        </StyledContainerPackage>
      )}
      {getMoreForLess ? (
        <GetMoreForLessInformations product={product} quantity={quantity} />
      ) : unvailableUnit ? (
        <UnavailableMode unvailableUnit={unvailableUnit} unvailablePackage={unvailablePackage} />
      ) : (
        <StyledContainerUnit
          onClick={handleClick}
          id="unit-selector"
          visible={unitPrice && !flagSecondTitlePromotion}
          isUnitSelected={isUnitSelected}
          warning={warning}
        >
          <StyledModeUnit
            isUnitSelected={isUnitSelected}
            warning={warning}
            visible={unitPrice && !flagSecondTitlePromotion}
          >
            Fracionado
          </StyledModeUnit>
          <StyledContainerPricesUnit>
            <StyledContainerUnitPrice isUnitSelected={isUnitSelected}>
              <StyledCurrency fontSizeMobile={'0.5rem'}>R$</StyledCurrency>
              <StyledPriceUnit isUnitSelected={isUnitSelected} data-testid="unit-price">
                {finalUnitPrice}
              </StyledPriceUnit>
              <span>/un.</span>
              {isPromotion && (
                <StyledContainerDiscount>
                  <span>R${setCurrency(price)}</span>
                </StyledContainerDiscount>
              )}
            </StyledContainerUnitPrice>
          </StyledContainerPricesUnit>
        </StyledContainerUnit>
      )}
    </StyledContainerPrices>
  )
}

export default CardPriceInfo
