import { Product } from '../../store/data/types'
import { ProductsGrid } from '../templates/ProductsGrid'
import { LoadablePage, LoadablePageProps } from './LoadablePage'
import { getSupplierProducts } from '../../services/productApi'
import React, { memo, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { StyledLinkToHome } from '../atoms/styles/LinkBackHomeDiv.style'
import { LinkBackHome } from '../atoms'

export const supplierProductsFetch = async (supplierId: string): Promise<Props> => {
  const { products, supplierName } = await getSupplierProducts(supplierId)
  return { products, supplierId, supplierName }
}

interface Props {
  supplierName: string
  products: Product[]
  supplierId: string
}

const _LinkHome = memo(() => (
  <StyledLinkToHome>
    <LinkBackHome />
  </StyledLinkToHome>
))

const _Grid = ({ products }: Props) => {
  const header = {
    node: <_LinkHome />,
    height: 63,
  }

  return <ProductsGrid context={['partner']} data={products} header={header} />
}

export const SpecialSupplier = (props: LoadablePageProps<Props>) => {
  const { supplierId } = useParams<{ supplierId: string }>()
  const fetch = useCallback(() => supplierProductsFetch(supplierId), [supplierId])
  return (
    <LoadablePage {...props} dataFetch={fetch}>
      {_Grid}
    </LoadablePage>
  )
}
