import { clubbiStyled } from 'clubbi-ui'

export const StyledPopup = clubbiStyled('div')(({ theme }) => ({
  position: 'fixed',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '15px',
  bottom: '62px',
  zIndex: 9998,
  padding: '15px',
  width: '355px',
  height: '66px',
  left: 'calc(50% - 178px)',
  borderRadius: '6px',
  boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',

  backgroundColor: theme.palette.brandWhite.main,

  [theme.breakpoints.up('md')]: {
    left: 'calc(100% - 465px)',
    width: '435px',
    height: '95px',
    bottom: '30px',
  },
}))

export const StyledImg = clubbiStyled('img')(({ theme }) => ({
  width: '50px',
  height: '50px',

  [theme.breakpoints.up('md')]: {
    width: '70px',
    height: '70px',
  },
}))

export const StyledPopupHeader = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: '100%',
  height: '50px',

  [theme.breakpoints.up('md')]: {
    height: '70px',
  },
}))

export const StyledContainerPrice = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '6px',
  fontSize: theme.typography.fontSize2XS,

  color: theme.palette.brandSuccess[70],

  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.fontSizeXS,
  },
}))

export const StyledCloseButton = clubbiStyled('button')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  border: 'none',
  borderRadius: '6px',
  backgroundColor: theme.palette.brandWhite.main,

  svg: {
    width: '11px',
    height: '11px',
  },
}))
