import { Route, Switch } from 'react-router-dom'
import { useCallback, useMemo } from 'react'
import { clubbiStyled } from 'clubbi-ui'

import { Welcome } from './Welcome'
import { Category } from './Category'
import { Section } from './Section'
import { Delivery } from './Delivery'
import { Purchase } from './Purchase'
import { Search } from './Search'
import { Cart } from './Cart'
import { InvoicesSummary } from './InvoicesSummary'
import { CheckoutSuccess } from './CheckoutSuccess'
import { NewClient } from './NewClient'
import { BestSellers } from './BestSellers'
import { Promotions } from './Promotions'
import { PromotionsDaily } from './PromotionsDaily'
import { SpecialSupplier } from './SpecialSupplier'
import { PreviouslyOrdered } from './PreviouslyOrdered'
import { Group } from './Group'
import { BestPrices } from './BestPrices'
import { WeekPromotions } from './WeekPromotions'
import { Partner } from './Partner'
import { Ofertao } from './Ofertao'
import { MoreItemsBetterPrices } from './MoreItemsBettePrices'
import { CartSuggestions } from './CartSuggestions'
import { CustomPromotions } from './CustomPromotions'
import { PromotionalSection } from './PromotionalSection'
import { CustomCorridor } from './CustomCorridor'
import { Missions } from './Missions'
import { ExploreCategories } from '../molecules/exploreCategories/ExploreCategories'
import { BannerProducts } from './BannerProducts'
import { ShoppingList } from './ShoppingList'
import { ProductsAva } from './ProductsAva'
import { OpenOrders } from './OpenOrders'
import { Profile } from './Profile'
import { Contacts } from './Contacts'
import { SalesFlyer } from './SalesFlyer'

export const StyledContainer = clubbiStyled('div')({
  width: '100vw',
})

interface Props {
  data?: any
}

export const ApplicationRouter = ({ data: _data, ...originalProps }: Props) => {
  const dataGenerator = useMemo(() => {
    return consumeData(_data)
  }, [])
  const getInitialData = useCallback(() => dataGenerator.next().value || null, [])
  const props = { ...originalProps, getInitialData }
  return (
    <Switch>
      <Route exact={true} path={'/cart'}>
        <Cart />
      </Route>
      <Route exact={true} path={'/profile'}>
        <Profile />
      </Route>
      <Route path={'/banner/:bannerId'}>
        <BannerProducts {...props} />
      </Route>
      <Route path={'/cart/suggestions'}>
        <CartSuggestions {...props} />
      </Route>
      <Route path={'/mission/:missionId'}>
        <Missions {...props} />
      </Route>
      <Route path={'/promotions/daily-promotions'}>
        <PromotionsDaily {...props} />
      </Route>
      <Route path={'/promotions/best-prices'}>
        <BestPrices {...props} />
      </Route>
      <Route path={'/promotions/week-promotions'}>
        <WeekPromotions {...props} />
      </Route>
      <Route path={'/promotions'}>
        <Promotions />
      </Route>
      <Route path={'/products/ofertoes/more-items-better-prices'}>
        <MoreItemsBetterPrices {...props} />
      </Route>
      <Route path={'/products/ofertoes'}>
        <Ofertao {...props} />
      </Route>
      <Route path={'/new-client'}>
        <NewClient />
      </Route>
      <Route path={'/products/best_sellers'}>
        <BestSellers {...props} />
      </Route>
      <Route path={'/products/previously_ordered'}>
        <PreviouslyOrdered {...props} />
      </Route>
      <Route path={'/products/group/:token'}>
        <Group {...props} />
      </Route>
      <Route path={'/products/:section/:category'}>
        <Category {...props} />
      </Route>
      <Route path={'/products/:section'}>
        <Section />
      </Route>
      <Route path={'/search'}>
        <Search {...props} />
      </Route>
      <Route path={'/recommended/search'}>
        <Search {...props} />
      </Route>
      <Route path={'/checkout'}>
        <CheckoutSuccess />
      </Route>
      <Route path={'/parceiro/:supplierId'}>
        <SpecialSupplier {...props} />
      </Route>
      <Route path={'/parceiro'}>
        <Partner {...props} />
      </Route>
      <Route path={'/purchase'}>
        <Purchase {...props} />
      </Route>
      <Route path={'/delivery'}>
        <Delivery {...props} />
      </Route>
      <Route path={'/invoices'}>
        <InvoicesSummary />
      </Route>
      <Route path={'/custom-section/:id'}>
        <CustomCorridor {...props} />
      </Route>
      <Route path={'/custom-promotions'}>
        <CustomPromotions {...props} />
      </Route>
      <Route path={'/suas-ofertas-especiais'}>
        <PromotionalSection />
      </Route>
      <Route path={'/explorar-as-categorias'}>
        <ExploreCategories />
      </Route>
      <Route path={'/shopping-list'}>
        <ShoppingList />
      </Route>
      <Route path={'/mais-economia'}>
        <ProductsAva />
      </Route>
      <Route path={'/pedidos-em-aberto'}>
        <OpenOrders />
      </Route>
      <Route path={'/profile/contacts'}>
        <Contacts />
      </Route>
      <Route path={'/pedidos-em-aberto'}>
        <OpenOrders />
      </Route>
      <Route path={'/sales-flyer'}>
        <SalesFlyer />
      </Route>
      <Route path={'/'}>
        <Welcome />
      </Route>
    </Switch>
  )
}

function* consumeData<T>(data: T) {
  yield data
  while (true) {
    yield undefined
  }
}
