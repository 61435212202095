import { useAppSelector } from '../../../../common/hooks'
import { getPriceByMode } from '../../../../common/productUtils'
import { CartItem, Product } from '../../../../store/data/types'
import { Money } from '../../../atoms'
import {
  StyledListGroupItem,
  StyledMGreenStyledEm,
  StyledMRedStyledEm,
} from './styles/CartItemsChanges.style'

export const ProductPriceChangeItem = ({
  cartItem,
  updatedProduct,
}: {
  cartItem: CartItem
  updatedProduct: Product
}) => {
  const cartItemUpdated = useAppSelector((state) => state.cart[updatedProduct.id])
  let newPrice = 0

  const descriptionPrepend = cartItem.isPackageMode ? (
    <span className={'text-muted'}>{`(Caixa com ${cartItem.product.packageNumberOfItems}) `}</span>
  ) : null
  const oldPrice = getPriceByMode(cartItem.isPackageMode, cartItem.product)

  if (cartItemUpdated) {
    const { isPackageMode } = cartItemUpdated
    newPrice = getPriceByMode(isPackageMode, updatedProduct)

    if (isPackageMode !== cartItem.isPackageMode) {
      newPrice = newPrice * updatedProduct.packageNumberOfItems!
    }
  }

  return (
    <StyledListGroupItem>
      <p>
        {descriptionPrepend}
        {cartItem.product.description + ' '}
        <StyledMRedStyledEm>
          <Money amount={oldPrice} />{' '}
        </StyledMRedStyledEm>
        {' => '}
        <StyledMGreenStyledEm>
          <Money amount={newPrice} />
        </StyledMGreenStyledEm>
      </p>
    </StyledListGroupItem>
  )
}
