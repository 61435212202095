import { clubbiStyled, themeClubbi } from 'clubbi-ui'

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '24px',
  paddingBottom: '30px',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  backgroundColor: theme.palette.brandWhite.main,
  borderRadius: '8px',
  width: '300px',
  minWidth: '300px',
  height: 'fit-content',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

export const StyledHR = clubbiStyled('hr')(({ theme }) => ({
  color: theme.palette.brandGrey.light,
  width: '100%',
  margin: 0,
  marginTop: '18px',
}))

export const getColor = (isContactPage: boolean, label: string) => {
  const { brandPurple, brandBlack } = themeClubbi.palette

  if (isContactPage && label === 'Dados de Contatos') {
    return brandPurple[60]
  }

  if (!isContactPage && label === 'Dados da Empresa') {
    return brandPurple[60]
  }

  return brandBlack.main
}

export const StyledOptionLink = clubbiStyled('a')<{ isContactPage: boolean; label: string }>(
  ({ theme, isContactPage, label }) => ({
    border: 'none',
    backgroundColor: 'transparent',
    color: getColor(isContactPage, label),
  })
)

export const StyledBody = clubbiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: '46px',
  marginTop: '28px',
  padding: '0 24px',
}))

export const StyledSection = clubbiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: '16px',
}))

export const StyledSectionBody = clubbiStyled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: '8px',
}))

export const StyledSectionTitle = clubbiStyled('span')(({ theme }) => ({
  color: theme.palette.brandBlack.main,
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '109.9%',
}))

export const StyledMerchantName = clubbiStyled('span')(() => ({
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '109.9%',
  padding: '0 24px',
}))

export const StyledMerchantCode = clubbiStyled('span')(() => ({
  fontSize: '16px',
  lineHeight: '170.2%',
  padding: '0 24px',
}))
