import { clubbiStyled } from 'clubbi-ui'

export const StyledRecommended = clubbiStyled('div')({
  marginLeft: '6px',
})

export const StyledMain = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 'calc(100% + 100px)',
}))

export const StyledContent = clubbiStyled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  padding: '16px',

  [theme.breakpoints.up('sm')]: {
    padding: '16px 32px',
  },
}))

export const StyledButtonContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'start',
  marginLeft: '18px',

  [theme.breakpoints.up('sm')]: {
    marginLeft: '31px',
  },
}))
