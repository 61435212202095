import { useEffect, useState } from 'react'
import { StyledLinkToHome } from '../atoms/styles/LinkBackHomeDiv.style'
import { LinkBackHome } from '../atoms'
import { Spinner } from '../atoms/Spinner'
import {
  StyleContent,
  StyledPageMessage,
  StyledHeading,
  StyledMain,
} from './styles/OpenOrders.style'
import { OpenOrderDetails, OrderItem } from '../molecules/openOrders/OpenOrderDetails'
import gtm from '../../common/gtmTracker'
import { useAppSelector } from '../../common/hooks'
import { getOpenOrders } from '../../services/productApi'

export interface OpenOrderProps {
  orderId: number
  creationDatetime: Date | string
  items: OrderItem[]
  supplier: string | null
}

export const OpenOrders = () => {
  const [loading, setloading] = useState(false)

  const [orders, setOrders] = useState<OpenOrderProps[]>([])
  const { merchantCode, clubberEmail } = useAppSelector((state) => state.session)

  const fetchOrders = async () => {
    const response = await getOpenOrders(merchantCode!)
    setOrders(response)
  }

  useEffect(() => {
    setloading(true)
    fetchOrders()
    gtm.triggerOpenOrders(merchantCode, clubberEmail)
    setloading(false)
  }, [])

  useEffect(() => {
    setloading(true)
    fetchOrders()
    gtm.triggerOpenOrders(merchantCode, clubberEmail)
    setloading(false)
  }, [merchantCode])

  return (
    <div>
      {!loading ? (
        <>
          <StyledLinkToHome>
            <LinkBackHome />
          </StyledLinkToHome>
          <StyledMain noOrders={orders.length === 0}>
            <StyledHeading>Pedidos em Aberto</StyledHeading>
            <StyledPageMessage>
              Pedidos em aberto aparecem nessa página. Pedidos que estão em separação, em rota ou
              finalizados estão na aba &quot;Minhas entregas&quot;.
            </StyledPageMessage>
            <StyledPageMessage>
              Você pode diminuir a quantidade dos itens em seu pedido ou cancelar. Para aumentar a
              quantidade de um item na sua entrega, é preciso realizar um novo pedido.
            </StyledPageMessage>
            {orders.length > 0 ? (
              <StyleContent>
                {orders.map((order: OpenOrderProps) => (
                  <OpenOrderDetails
                    key={order.orderId}
                    orderId={order.orderId}
                    creationDatetime={new Date(order.creationDatetime)}
                    productBuckets={order.items}
                    fetchOrders={fetchOrders}
                    orderSupplier={order.supplier}
                  />
                ))}
              </StyleContent>
            ) : (
              <h5>Não há pedidos em aberto no momento.</h5>
            )}
          </StyledMain>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  )
}
