import { clubbiStyled, FormControl, MenuItem } from 'clubbi-ui'

export const StyledFormSelect = clubbiStyled(FormControl)(({ theme }) => ({
  '.select': {
    fontWeight: 400,
    color: theme.palette.brandGrey[100],
    fontSize: theme.typography.fontSize2XS,
    height: '34px',

    '.MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.brandGrey[100]}`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.fontSizeXXS,
    },
  },
}))

export const StyledMenuItem = clubbiStyled(MenuItem)<{ isLast?: boolean }>(({ isLast, theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  marginBottom: isLast ? '36px' : 0,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.fontSizeXXS,
    marginBottom: 0,
  },
}))

export const StyledSelectLabel = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))
