import { DecreaseCardIcon, IncreaseCardIcon, Fade } from 'clubbi-ui'

import { useAppSelector } from '../../../../common/hooks'
import { getPackagePrice } from '../../../../common/productUtils'
import { isMerchantBlocked } from '../../../../common/utils'
import { dispatch } from '../../../../store'
import { Product } from '../../../../store/data/types'
import { blockedBannerActions } from '../../../../store/slices/blockedBanner'
import { getMoreForLessActions } from '../../../../store/slices/getMoreForLess'
import { sessionActions } from '../../../../store/slices/session'

import {
  StyledInput,
  StyledInputBase,
  StyledQuantityButton,
  StyledQuantityDiv,
  StyledInputValue,
  StyledSuffixSpan,
} from './styles/QuantityInput.style'

interface QuantityInputProps {
  quantity: number
  limitReached: boolean
  alertInput: boolean
  setQuantity: any
  setWarning: any
  dispatchQuantity: any
  cartChange: any
  maxLimit: number
  product: Product
  mode?: string
}

export const QuantityInput = ({
  quantity,
  limitReached,
  alertInput,
  mode,
  setQuantity,
  setWarning,
  dispatchQuantity,
  cartChange,
  maxLimit,
  product,
}: QuantityInputProps) => {
  const isDisabledInput = !mode
  const { comboProduct } = useAppSelector((state) => state.getMoreForLess)
  const [{ merchantCode, status, cartSessionId, clubberEmail }, cartProducts] = useAppSelector(
    (state) => [state.session, state.cart]
  )

  const blocked = !!isMerchantBlocked(status)

  const isGetMoreForLess = !!product.supplierPrices[0].getMoreForLess
  const isOtherProduct = comboProduct?.id !== product.id

  const handleIncrease = (quantity: number) => {
    dispatchQuantity(cartChange('addToCart', quantity))

    if (isGetMoreForLess && isOtherProduct) {
      dispatch(getMoreForLessActions.setOpenPopup(product))
    }
  }

  const handleDecrease = (quantity: number) =>
    dispatchQuantity(cartChange('removeFromCart', quantity))

  const handleChange = (inputQuantity: number) => {
    dispatchQuantity(cartChange('inputChange', inputQuantity))
  }

  const handleClickOnDecrease = () => {
    const value = quantity > 1 ? quantity - 1 : 0
    handleDecrease(value)
    return setQuantity(value)
  }

  const handleChangeOnInput = (e: React.ChangeEvent) => {
    const value = Number((e.target as HTMLInputElement).value)

    if (mode) {
      setQuantity(value > maxLimit ? maxLimit : value)
      handleChange(value)
    }
  }

  const showLoginModal = () => {
    dispatch(sessionActions.showModal(true))
  }

  const showBlockedBanner = () => {
    dispatch(blockedBannerActions.setShow())
  }

  const sendClickEvent = () => {
    const { id, sponsoredInfo } = product
    const data = {
      userId: merchantCode!,
      cartSessionId,
      clubberEmail,
    }
    const productNotInCart = !cartProducts[id]?.quantity

    if (sponsoredInfo && productNotInCart) {
      navigator.sendBeacon(sponsoredInfo?.clickUrl, JSON.stringify(data))
    }
  }

  const handleClickOnIncrease = () => {
    sendClickEvent()

    if (!merchantCode) {
      return showLoginModal()
    } else if (merchantCode && blocked) {
      return showBlockedBanner()
    }

    if (!mode) {
      setWarning(true)
    } else {
      setWarning(false)
      if (quantity + 1 === maxLimit) {
        handleIncrease(quantity + 2)
      } else {
        setWarning(false)

        if (quantity + 1 === maxLimit) {
          handleIncrease(quantity + 2)
        } else {
          handleIncrease(quantity + 1)
          setQuantity(quantity + 1)
        }
      }
    }
  }

  const handleSelectedMode = () => {
    return (
      quantity !== 0 &&
      (mode === 'unit' ? (
        <StyledSuffixSpan>un.</StyledSuffixSpan>
      ) : mode === 'package' ? (
        <StyledSuffixSpan>cx.</StyledSuffixSpan>
      ) : (
        <StyledSuffixSpan />
      ))
    )
  }

  const [firstSupplier] = product.supplierPrices

  const packagePriceSupplier = getPackagePrice(product)
  const unitSupplierPrice = firstSupplier.price
  const isDisabledQuantityButton =
    (mode === 'package' && !packagePriceSupplier) || (mode === 'unit' && !unitSupplierPrice)

  const isAddQuantity = quantity !== 0 ? quantity : ''

  return (
    <StyledQuantityDiv alertInput={alertInput && limitReached} quantity={quantity}>
      <Fade in={quantity !== 0} {...(quantity !== 0 ? { timeout: 100 } : {})}>
        <StyledQuantityButton
          data-testid="decrease-button"
          disabled={isDisabledQuantityButton}
          onClick={handleClickOnDecrease}
        >
          <DecreaseCardIcon fontSize="large" htmlColor="#7C00F4" />
        </StyledQuantityButton>
      </Fade>

      <StyledInputBase>
        <StyledInputValue>
          <StyledInput
            type="number"
            data-testid="number-input"
            pattern="[0-9]*"
            onChange={handleChangeOnInput}
            value={isDisabledInput ? '' : isAddQuantity}
            onFocus={(event: React.FocusEvent<HTMLInputElement>) => event.target.select()}
            disabled={isDisabledInput}
          />
          {handleSelectedMode()}
        </StyledInputValue>
      </StyledInputBase>

      <StyledQuantityButton
        data-cy="increase-button"
        data-testid="increase-button"
        disabled={isDisabledQuantityButton || limitReached}
        onClick={handleClickOnIncrease}
      >
        <IncreaseCardIcon fontSize="large" htmlColor={limitReached ? '#E3E3E3' : '#7C00F4'} />
      </StyledQuantityButton>
    </StyledQuantityDiv>
  )
}
