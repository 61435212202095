import {
  StyledContainer,
  StyledData,
  StyledStatusWrapper,
  StyledTitle,
  getColorAndIcon,
} from './styles/InvoiceCardHeader.style'

export const InvoiceCardHeader = ({
  title,
  deliveryStatus,
  isPaymentDelayed = false,
}: {
  title: string
  deliveryStatus: string
  isPaymentDelayed?: boolean
}) => {
  const { color, icon: IconComponent } = getColorAndIcon(isPaymentDelayed, deliveryStatus)
  const data = (
    <StyledStatusWrapper>
      <IconComponent />
      {deliveryStatus}
    </StyledStatusWrapper>
  )
  return (
    <StyledContainer>
      <StyledTitle>{title}</StyledTitle>
      <StyledData customColor={color}>{isPaymentDelayed ? 'Pagamento em atraso' : data}</StyledData>
    </StyledContainer>
  )
}
