import { ButtonClubbi, Popover, clubbiStyled } from 'clubbi-ui'

export const StyledRatingPopover = clubbiStyled(Popover)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  borderRadius: '4px',
  width: '102vw',
  backgroundColor: 'transparent',
  marginRight: '4px',
  [theme.breakpoints.up('sm')]: {
    width: '360px',
  },
}))

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  border: 'none',
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'top',
  gap: '4px',
  padding: '24px',
  paddingRight: '48px',
  borderRadius: '4px',
  boxShadow: '0px 1px 24px 0px rgba(0, 0, 0, 0.15)',
  background: theme.palette.brandWhite.main,
  [theme.breakpoints.up('sm')]: {
    width: '330px',
    padding: '24px',
  },
}))

export const StyledModalHeader = clubbiStyled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '4px',
}))

export const StyledEvaluationLabel = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))

export const StyledButton = clubbiStyled(ButtonClubbi)(({ theme }) => ({
  textTransform: 'lowercase',
  fontSize: theme.typography.fontSize2XS,
  lineHeight: '22.43px',
  alignSelf: 'end',
  fontWeight: 700,
  '&:disabled': {
    backgroundColor: theme.palette.brandPurple[20],
    color: theme.palette.brandWhite.main,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))

export const StyledCloseButton = clubbiStyled(ButtonClubbi)(({ theme }) => ({
  size: 'small',
  color: theme.palette.brandGrey.dark,
  textDecoration: 'none',
  textTransform: 'lowercase',
  fontSize: theme.typography.fontSize2XS,
  fontWeight: 500,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))

export const StyledButtonContainer = clubbiStyled('div')(() => ({
  display: 'flex',
  justifyContent: 'right',
  width: '100%',
  paddingTop: '12px',
}))

export const StyledComplementContainer = clubbiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledInputComment = clubbiStyled('textarea')(({ theme }) => ({
  borderRadius: '4px',
  marginTop: '10px',
  padding: '10px',
  resize: 'none',

  [theme.breakpoints.up('sm')]: {
    height: '72px',
  },
}))
