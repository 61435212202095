import { clubbiStyled } from 'clubbi-ui'

export const StyledPromotionSection = clubbiStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brandGrey.light,
  width: '100%',
  height: 'calc(100% + 400px)',
  [theme.breakpoints.up('md')]: {
    marginTop: '-25px',
  },
}))

export const StyledContainerList = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '16px',
  paddingLeft: '16px',
  marginBottom: '16px',
}))
