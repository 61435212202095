interface IconProps {
  size?: string
  className?: string
  ariaLabel?: string
  altText?: string
  fill?: string
}

const BagCheck = ({ size = '1em', className = '', ariaLabel }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      className={className + ' bi bi-bag-check'}
      aria-label={ariaLabel}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M8 1a2.5 2.5 0 0 0-2.5 2.5V4h5v-.5A2.5 2.5 0 0 0 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V5H2z"
      />
      <path
        fillRule="evenodd"
        d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"
      />
    </svg>
  )
}

const Cart = ({ size = '1em', className = '', ariaLabel }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      className={className + ' bi bi-cart-x'}
      aria-label={ariaLabel}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
      />
      <path
        fillRule="evenodd"
        d="M6.646 5.646a.5.5 0 0 1 .708 0L8.5 6.793l1.146-1.147a.5.5 0 0 1 .708.708L9.207 7.5l1.147 1.146a.5.5 0 0 1-.708.708L8.5 8.207 7.354 9.354a.5.5 0 1 1-.708-.708L7.793 7.5 6.646 6.354a.5.5 0 0 1 0-.708z"
      />
    </svg>
  )
}

const ChevronLeft = ({ size = '1em', className = '', ariaLabel }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      className={className + ' bi bi-chevron-left'}
      aria-label={ariaLabel}
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
      />
    </svg>
  )
}

const ChevronRight = ({ size = '1em', className = '', ariaLabel }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      className={className + ' bi bi-chevron-left'}
      aria-label={ariaLabel}
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
      />
    </svg>
  )
}

const EnvelopOpen = ({ size = '1em', className = '', ariaLabel }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      className={className + ' bi bi-envelope-open'}
      aria-label={ariaLabel}
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M8.47 1.318a1 1 0 0 0-.94 0l-6 3.2A1 1 0 0 0 1 5.4v.818l5.724 3.465L8 8.917l1.276.766L15 6.218V5.4a1 1 0 0 0-.53-.882l-6-3.2zM15 7.388l-4.754 2.877L15 13.117v-5.73zm-.035 6.874L8 10.083l-6.965 4.18A1 1 0 0 0 2 15h12a1 1 0 0 0 .965-.738zM1 13.117l4.754-2.852L1 7.387v5.73zM7.059.435a2 2 0 0 1 1.882 0l6 3.2A2 2 0 0 1 16 5.4V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5.4a2 2 0 0 1 1.059-1.765l6-3.2z"
      />
    </svg>
  )
}

const Minus = ({ size = '1em', className = '', ariaLabel }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      className={className + ' bi bi-dash'}
      aria-label={ariaLabel}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
    </svg>
  )
}

const Plus = ({ size = '1em', className = '', ariaLabel }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      className={className + ' bi bi-plus'}
      aria-label={ariaLabel}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
      />
    </svg>
  )
}

const PlusSquare = ({ size = '1em', className = '', ariaLabel }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
      className={className + ' bi bi-plus-square'}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
      />
      <path
        fillRule="evenodd"
        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
      />
    </svg>
  )
}

const Search = ({ size = '1em', className = '', ariaLabel, fill = 'currentColor' }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      className={className + ' bi bi-search'}
      aria-label={ariaLabel}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
      />
      <path
        fillRule="evenodd"
        d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
      />
    </svg>
  )
}

const ShoppingCart = ({
  size = '1em',
  className = '',
  ariaLabel,
  fill = 'currentColor',
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      className={className + ' bi bi-cart2'}
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"
      />
    </svg>
  )
}

const TelephoneForward = ({ size = '1em', className = '', ariaLabel }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      className={className + ' bi bi-telephone-forward'}
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"
      />
    </svg>
  )
}

const People = ({ size = '1em', className = '', ariaLabel }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      className={className + ' bi bi-people'}
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"
      />
    </svg>
  )
}

const PersonLinesFill = ({ size = '1em', className = '', ariaLabel }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      className={className + ' bi bi-person-lines-fill'}
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"
      />
    </svg>
  )
}

const Xlg = ({ size = '1em', className = '', ariaLabel }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      className={className + ' bi bi-person-lines-fill'}
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
    </svg>
  )
}

const PriceTag = ({
  size = '1em',
  className = '',
  ariaLabel,
  fill = 'currentColor',
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      className={className + ' bi bi-person-lines-fill'}
      viewBox="0 0 10 10"
      aria-label={ariaLabel}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.705 4.79L5.205 0.29C5.01751 0.10404 4.76407 -0.000210412 4.5 3.18856e-07H1C0.734784 3.18856e-07 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 3.13965e-07 0.734784 3.13965e-07 1V4.5C-0.000104246 4.63196 0.0259084 4.76263 0.0765387 4.88448C0.127169 5.00634 0.201416 5.11697 0.295 5.21L4.795 9.71C4.98249 9.89596 5.23593 10.0002 5.5 10C5.76479 9.99888 6.01832 9.89279 6.205 9.705L9.705 6.205C9.89279 6.01832 9.99888 5.76479 10 5.5C10.0001 5.36804 9.97409 5.23737 9.92346 5.11552C9.87283 4.99366 9.79858 4.88303 9.705 4.79ZM5.5 9L1 4.5V1H4.5L9 5.5L5.5 9ZM2.25 1.5C2.39834 1.5 2.54334 1.54399 2.66668 1.6264C2.79001 1.70881 2.88614 1.82594 2.94291 1.96299C2.99968 2.10003 3.01453 2.25083 2.98559 2.39632C2.95665 2.5418 2.88522 2.67544 2.78033 2.78033C2.67544 2.88522 2.5418 2.95665 2.39632 2.98559C2.25083 3.01453 2.10003 2.99968 1.96299 2.94291C1.82594 2.88614 1.70881 2.79001 1.6264 2.66668C1.54399 2.54334 1.5 2.39834 1.5 2.25C1.5 2.05109 1.57902 1.86032 1.71967 1.71967C1.86032 1.57902 2.05109 1.5 2.25 1.5Z"
        fill={fill}
      />
    </svg>
  )
}

const PromoTag = ({
  size = '1em',
  className = '',
  ariaLabel,
  fill = 'currentColor',
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_652)"></g>
      <path
        d="M2.8 4C2.48174 4 2.17652 3.87357 1.95147 3.64853C1.72643 3.42348 1.6 3.11826 1.6 2.8C1.6 2.48174 1.72643 2.17652 1.95147 1.95147C2.17652 1.72643 2.48174 1.6 2.8 1.6C3.11826 1.6 3.42348 1.72643 3.64853 1.95147C3.87357 2.17652 4 2.48174 4 2.8C4 3.11826 3.87357 3.42348 3.64853 3.64853C3.42348 3.87357 3.11826 4 2.8 4ZM15.528 7.664L8.328 0.464C8.04 0.176 7.64 0 7.2 0H1.6C0.712 0 0 0.712 0 1.6V7.2C0 7.64 0.176 8.04 0.472 8.328L7.664 15.528C7.96 15.816 8.36 16 8.8 16C9.24 16 9.64 15.816 9.928 15.528L15.528 9.928C15.824 9.64 16 9.24 16 8.8C16 8.352 15.816 7.952 15.528 7.664Z"
        fill={fill}
      />
      <defs>
        <clipPath id="clip0_1_652">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

const DeleteIcon = ({
  size = '1em',
  className = '',
  ariaLabel,
  fill = 'currentColor',
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Path_23"
        data-name="Path 23"
        d="M-250.5-236H-255v-1.5a1.5,1.5,0,0,0-1.5-1.5h-3a1.5,1.5,0,0,0-1.5,1.5v1.5h-4.5a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h1.5v10.5a1.5,1.5,0,0,0,1.5,1.5h9a1.5,1.5,0,0,0,1.5-1.5V-235h1.5a.5.5,0,0,0,.5-.5A.5.5,0,0,0-250.5-236Zm-9.5-1.5a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5v1.5h-4Zm7,13a.5.5,0,0,1-.5.5h-9a.5.5,0,0,1-.5-.5V-235h10Zm-7-9v8a.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5v-8a.5.5,0,0,1,.5-.5A.5.5,0,0,1-260-233.5Zm4.5-.5a.5.5,0,0,1,.5.5v8a.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5v-8A.5.5,0,0,1-255.5-234Zm-2,.5v8a.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5v-8a.5.5,0,0,1,.5-.5A.5.5,0,0,1-257.5-233.5Z"
        fill={fill}
        transform="translate(266 239)"
      />
    </svg>
  )
}

const ExclamationTriangle = ({ size = '1em', className = '', ariaLabel }: IconProps) => {
  return (
    <svg width="14" height="14" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.5 4.125V6.41667" stroke="#F21F52" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.50007 9.81292H2.72257C1.13215 9.81292 0.467571 8.67625 1.23757 7.2875L2.66757 4.71167L4.01507 2.29167C4.8309 0.82042 6.16924 0.82042 6.98507 2.29167L8.33257 4.71625L9.76257 7.29209C10.5326 8.68084 9.86341 9.8175 8.27757 9.8175H5.50007V9.81292Z"
        stroke="#F21F52"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.49707 7.79167H5.50119"
        stroke="#F21F52"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const WarningIcon = ({ size = '1em', className = '', ariaLabel }: IconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 6V9.33333"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99979 14.2733H3.95979C1.64646 14.2733 0.679793 12.62 1.79979 10.6L3.87979 6.85333L5.83979 3.33333C7.02646 1.19333 8.97313 1.19333 10.1598 3.33333L12.1198 6.86L14.1998 10.6067C15.3198 12.6267 14.3465 14.28 12.0398 14.28H7.99979V14.2733Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99609 11.3333H8.00208"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const Icon = {
  BagCheck,
  Cart,
  ChevronLeft,
  ChevronRight,
  EnvelopOpen,
  Minus,
  Plus,
  PlusSquare,
  Search,
  ShoppingCart,
  TelephoneForward,
  People,
  PersonLinesFill,
  Xlg,
  PriceTag,
  PromoTag,
  DeleteIcon,
  ExclamationTriangle,
  WarningIcon,
}

export default Icon
