import React from 'react'
import { BlockLeft, BlockRight, Information, InformationHeaderAddress } from './SharedStyle'
import { Customer as customerDelivery } from './delivery/types'
import { Customer as customerPurchase } from './purchase/types'
import { PRICING_GROUP_IDS_SALVADOR } from '../../../common/constants'

interface Props {
  customer: customerDelivery | customerPurchase
  regionId: number | null
  orderIds: number[]
  creationDatetime: Date
  payment: string
  planId: string
}

const HeaderInformation = ({
  customer,
  regionId,
  orderIds,
  creationDatetime,
  planId,
  payment,
}: Props) => {
  return (
    <>
      <Information>
        <InformationHeaderAddress>
          <BlockLeft>
            <h4>Para: {customer.razaoSocial}</h4>
            <p>{customer.cnpj}</p>
            <p>
              {customer.streetName}, {customer.streetNumber},{' '}
            </p>
            <p>
              {/*TODO: Consertar essa gambiarra*/}
              {customer.neighborhood},{' '}
              {regionId && PRICING_GROUP_IDS_SALVADOR.includes(regionId)
                ? 'Salvador - BA'
                : 'Rio de Janeiro - RJ'}{' '}
              - {customer.zipCode}
            </p>
          </BlockLeft>
          <BlockRight>
            <h4>
              Pedido: {orderIds.map((item: number, index: number) => (index ? ', ' : '') + item)}
            </h4>
            <h4>Emissão: {creationDatetime.toLocaleString('pt-BR').split(' ')[0]}</h4>
            <h4>Plano de entrega: {planId}</h4>
            <h4>Pagamento: {payment}</h4>
          </BlockRight>
        </InformationHeaderAddress>
      </Information>
    </>
  )
}

export default HeaderInformation
