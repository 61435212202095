import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import ga4 from './common/ga4Tracker'
import gtm from './common/gtmTracker'
import { store } from './store'
import { saveState } from './store/localStorage'
import { ApplicationRouter } from './components/routes'

import '@fontsource/baloo-2'
import './styles/app.scss'
import { ClubbiThemeProvider, Layout as GridLayout, themeClubbi } from 'clubbi-ui'
import { Layout } from './components/organisms/layout/Layout'
import { Middleware } from './components/molecules/Middleware'

export const App = () => {
  store.subscribe(() => {
    saveState(store.getState())
  })

  useEffect(() => {
    gtm.init()
    ga4.init()
  }, [])

  return (
    <Provider store={store}>
      <ClubbiThemeProvider theme={themeClubbi}>
        <GridLayout>
          <BrowserRouter>
            <Middleware>
              <Layout>
                <ApplicationRouter />
              </Layout>
            </Middleware>
          </BrowserRouter>
        </GridLayout>
      </ClubbiThemeProvider>
    </Provider>
  )
}
