import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getMerchantProfileData } from '../../services/merchantApi'
import {
  MerchantProfileData,
  MerchantContact,
  MerchantDeliveryRestriction,
  UpdateMerchantPayload,
} from '../data/types'
import {
  DEFAULT_DELIVERY_TIME_FINISH,
  DEFAULT_DELIVERY_TIME_START,
} from '../../components/organisms/profile/constants'
import { removePhoneMask } from '../../components/organisms/profile/helpers'

export const merchantProfileDataThunk = createAsyncThunk(
  'profile/getMerchantProfileData',
  getMerchantProfileData
)

const profileSlice = createSlice({
  name: 'profile',
  initialState: {} as MerchantProfileData,
  reducers: {
    updateMerchant: (state, action: PayloadAction<UpdateMerchantPayload>): MerchantProfileData => {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          ...action.payload,
        },
      }
    },
    updateMerchantContacts: (
      state,
      action: PayloadAction<MerchantContact>
    ): MerchantProfileData => {
      const existingContactWithType = state.merchantContacts?.find(
        (contact) => contact.contactType === action.payload.contactType
      )
      if (existingContactWithType) {
        return {
          ...state,
          merchantContacts: state.merchantContacts?.map((contact) =>
            contact.contactType === action.payload.contactType
              ? { ...action.payload, phone: removePhoneMask(action.payload.phone) }
              : contact
          ),
        }
      } else {
        return {
          ...state,
          merchantContacts: [
            ...(state.merchantContacts || []),
            { ...action.payload, phone: removePhoneMask(action.payload.phone) },
          ],
        }
      }
    },
    updateMerchantDeliveryRestrictions: (
      state,
      action: PayloadAction<MerchantDeliveryRestriction>
    ): MerchantProfileData => {
      return {
        ...state,
        merchantDeliveryRestriction: {
          ...state.merchantDeliveryRestriction,
          ...action.payload,
        },
      }
    },
    updateHasBreak: (state, action: PayloadAction<boolean>): MerchantProfileData => {
      return {
        ...state,
        hasBreak: action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(merchantProfileDataThunk.fulfilled, (state, action) => {
      const profileData = action.payload
      if (profileData)
        return {
          ...state,
          ...profileData,
          merchantDeliveryRestriction: {
            ...profileData.merchantDeliveryRestriction,
            deliveryTimeStart:
              profileData.merchantDeliveryRestriction?.deliveryTimeStart ??
              DEFAULT_DELIVERY_TIME_START,
            deliveryTimeFinish:
              profileData.merchantDeliveryRestriction?.deliveryTimeFinish ??
              DEFAULT_DELIVERY_TIME_FINISH,
            saturdayClosure:
              profileData.merchantDeliveryRestriction?.saturdayClosure ??
              profileData.merchantDeliveryRestriction?.deliveryTimeFinish ??
              DEFAULT_DELIVERY_TIME_FINISH,
            deliveryDaysRestriction:
              profileData.merchantDeliveryRestriction?.deliveryDaysRestriction ?? [],
          },
          hasBreak:
            !!profileData.merchantDeliveryRestriction?.deliveryBreakTimeStart ||
            !!profileData.merchantDeliveryRestriction?.deliveryBreakTimeFinish,
        }
    })
  },
})

export const profileReducer = profileSlice.reducer
export const profileActions = profileSlice.actions
