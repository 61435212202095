import { clubbiStyled } from 'clubbi-ui'

export const StyledHomeContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  maxWidth: '100%',
  backgroundColor: theme.palette.brandGrey[10],
  gap: '5px',
  marginBottom: '50px',

  [theme.breakpoints.up('md')]: {
    maxWidth: '100%',
    gap: '15px',
    marginBottom: '100px',
  },
}))

export const StyledHomeSections = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '10px',
  height: 'auto',
  width: 'auto',

  background: theme.palette.brandGrey[10],
}))
