import { InvoiceSummary } from '../../store/data/types'
import { filterInvoicesByBillingStatus } from '../../common/utils'

import { GroupedInvoiceSummaryCards } from '../molecules/Invoices/GroupedInvoiceSummaryCards'

export interface InvoicesProps {
  invoices: InvoiceSummary[]
}

export const Invoices = (props: InvoicesProps) => {
  const { invoices, ...otherProps } = props
  const overdueInvoices = filterInvoicesByBillingStatus(invoices, true)
  const nonOverdueInvoices = filterInvoicesByBillingStatus(invoices, false)

  return (
    <>
      <GroupedInvoiceSummaryCards invoices={overdueInvoices} {...otherProps} />
      <GroupedInvoiceSummaryCards invoices={nonOverdueInvoices} {...otherProps} />
    </>
  )
}
