import { useAppSelector } from '../../../../common/hooks'
import { QuantityChange } from '../interfaces'
import {
  StyledContainerChangesInfo,
  StyledCurrentInfo,
  StyledListGroupItem,
  StyledUpdateInfo,
} from './styles/CartItemsChanges.style'

interface QuantityAndModeChangeItemProps {
  quantityChange: QuantityChange
}

export const QuantityAndModeChangeItem = ({ quantityChange }: QuantityAndModeChangeItemProps) => {
  const cartItemsState = useAppSelector((state) => state.cart)

  const orderItemMode = quantityChange.orderItem.isPackageMode
    ? `(Caixa com ${quantityChange.orderItem.packageNumberOfItems})`
    : 'Unidades'

  const orderItemUpdated = quantityChange.orderItemUpdated.isPackageMode
    ? `(Caixa com ${quantityChange.orderItem.packageNumberOfItems})`
    : 'Unidades'

  const getProductDescription = () => {
    const cartItems = Object.values(cartItemsState)
    const cartItemWillBeChanged = cartItems.find(
      ({ product }) => product.ean === quantityChange.orderItem.ean
    )

    return cartItemWillBeChanged?.product.description
  }

  return (
    <StyledListGroupItem>
      <p>{getProductDescription()}</p>
      <StyledContainerChangesInfo>
        <StyledCurrentInfo>
          <span>De</span>
          <strong>{`${quantityChange.orderItem.quantity} x `}</strong>
          <strong>{orderItemMode}</strong>
          <span>{'=>'}</span>
        </StyledCurrentInfo>
        <StyledUpdateInfo>
          <span>Para</span>
          <strong>{`${quantityChange.orderItemUpdated.quantity} x `}</strong>
          <strong>{orderItemUpdated}</strong>
        </StyledUpdateInfo>
      </StyledContainerChangesInfo>
    </StyledListGroupItem>
  )
}
