import { clubbiStyled, ScrollButton } from 'clubbi-ui'

export const StyledImg = clubbiStyled('img')(({ theme }) => ({
  width: '99%',
  height: 'auto',
  borderRadius: '8px',
  margin: '0 auto',

  [theme.breakpoints.up('md')]: {
    maxWidth: '1440px',
  },
}))

export const StyledMain = clubbiStyled('div')(({ theme }) => ({
  width: '100%',
  marginTop: '10px',
  position: 'relative',

  [theme.breakpoints.up('md')]: {
    marginTop: 0,
    marginBottom: '32px',
  },
}))

export const StyledArrowButton = clubbiStyled(ScrollButton)(({ theme, direction }) => ({
  display: 'none',

  [theme.breakpoints.up('md')]: {
    display: 'block',
    position: 'absolute',
    background: theme.palette.brandWhite.main,
    color: theme.palette.brandGrey[100],
    right: direction === 'right' && '81px',
    left: direction === 'left' && '75px',
    top: 'calc(50% - 22px)',
  },
}))
