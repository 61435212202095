import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Offer } from '../data/types'

type State = Offer[]

const welcomeOffersSlice = createSlice({
  name: 'welcomeOffers',
  initialState: [] as unknown as State,
  reducers: {
    setOffers: (_state, action: PayloadAction<Offer[]>): State => {
      return action.payload
    },
  },
})

export const welcomeOffersReducer = welcomeOffersSlice.reducer
export const welcomeOffersActions = welcomeOffersSlice.actions
