import { ButtonClubbi, clubbiStyled } from 'clubbi-ui'

export const StyledWrapElement = clubbiStyled('div')(({ theme }) => ({
  background: theme.palette.brandWhite.main,
  width: '100%',
  padding: '10px',
  border: `1px solid ${theme.palette.brandGrey[20]}`,
  display: 'flex',
  paddingRight: '0',
}))

export const StyledProduct = clubbiStyled('div')<{ isCancelled: boolean | null }>(
  ({ isCancelled, theme }) => ({
    width: '100%',
    display: 'flex',
    textAlign: 'start',
    alignItems: 'center',
    flexDirection: 'column',
    color: isCancelled ? theme.palette.brandAlert[50] : theme.palette.brandBlack.main,
    textDecoration: isCancelled ? 'line-through' : '',
    [theme.breakpoints.up('md')]: {
      padding: '20px',
      flexDirection: 'row',
    },
  })
)

export const StyledEan = clubbiStyled('div')<{ isCancelled: boolean | null }>(
  ({ isCancelled, theme }) => ({
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '17.02px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: isCancelled ? theme.palette.brandAlert[50] : theme.palette.brandGrey[60],
    [theme.breakpoints.up('md')]: {
      fontSize: '12px',
      lineHeight: '20px',
    },
  })
)

export const StyledDescription = clubbiStyled('div')(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 600,
  lineHeight: '12.67px',
  letterSpacing: '0em',
  textAlign: 'left',
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
  },
}))

export const StyledValueCell = clubbiStyled('div')(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 400,
  lineHeight: '18.72px',
  letterSpacing: '0em',
  textAlign: 'right',
  flex: 4,
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '24px',
    flex: 3,
    padding: '4px',
  },
  [theme.breakpoints.up('xl')]: {
    flex: 2,
    padding: '0',
  },
}))

export const StyledIconCell = clubbiStyled('div')(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  lineHeight: '18.72px',
  letterSpacing: '0em',
  textAlign: 'right',
  flex: 1,
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '24px',
    marginLeft: '4px',
  },
}))

export const StyledDescriptionCell = clubbiStyled('div')(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0em',
  alignItems: 'center',
  textAlign: 'left',
  flex: 8,
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '24px',
    flex: 4,
  },
  [theme.breakpoints.up('lg')]: {
    flex: 6,
  },
}))

export const StyledTopRow = clubbiStyled('div')(({ theme }) => ({
  alignItems: 'top',
  textAlign: 'left',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}))

export const StyledBottomRow = clubbiStyled('div')(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'flex',
  width: '100%',
  paddingRight: '10px',
  [theme.breakpoints.up('md')]: {
    paddingRight: '0',
  },
}))

export const StyledTextButton = clubbiStyled(ButtonClubbi)<{
  label: string
  customColor: string
}>(({ label, customColor, theme }) => ({
  label: label,
  typeButton: 'text',
  size: 'small',
  color: customColor,
  textDecoration: 'none',
  textTransform: 'capitalize',
  fontSize: '11px',
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    fontWeight: 400,
  },
}))
