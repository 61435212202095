import { clubbiStyled } from 'clubbi-ui'

export const StyledMain = clubbiStyled('div')<{
  unvailablePackage: boolean
  unvailableUnit: boolean
}>(({ theme, unvailablePackage, unvailableUnit }) => ({
  width: '146px',
  height: '40px',
  position: 'relative',
  border: `1px solid ${theme.palette.brandGrey[20]}`,
  borderRadius: '4px',
  marginBottom: unvailablePackage ? '8px' : 0,

  [theme.breakpoints.up('sm')]: {
    width: '218px',
    height: '48px',
  },
}))

export const StyledMode = clubbiStyled('div')(({ theme }) => ({
  color: theme.palette.brandGrey[60],
  fontSize: theme.typography.fontSize3XS,
  backgroundColor: theme.palette.brandGrey[20],
  borderRadius: '0 0 2px 2px',
  height: '13px',
  position: 'absolute',
  top: '-1px',
  left: '8px',
  padding: '1px 5px',
  lineHeight: 1,
  weight: 600,

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXXS,
    height: '16px',
  },
}))

export const StyledText = clubbiStyled('p')(({ theme }) => ({
  fontSize: '10px',
  padding: '16px 0 0 8px',
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXXS,
    padding: '20px 16px 16px 8px',
  },
}))
