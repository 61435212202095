import { PropsWithChildren, useEffect, useState } from 'react'
import { themeClubbi, useMediaQuery } from 'clubbi-ui'
import { useLocation } from 'react-router'
import { useDispatch } from 'react-redux'

import { Header } from './Header'
import { Footer } from './Footer'
import { CategoriesMenu } from '../../molecules/categoriesMenu/CategoriesMenu'
import { ModalManager } from '../../molecules/ModalManager'
import { FlyerGeneratorBar } from '../../molecules/eanSelect/FlyerGeneratorBar'
import { PopupWarning } from '../../molecules/PopupWarning'
import { RecommendedDrawer } from '../../molecules/recommendedDrawer/RecommendedDrawer'
import { Modal as ShoppingListModal } from '../../molecules/ShoppingList/Modal'
import { PopupWarningByMerchantCode } from '../../molecules/PopupWarningByMerchantCode'
import { NavigationTooltip } from '../../molecules/navigationTooltips/NavigationTooltip'
import { tagCategoriesMenuActions } from '../../../store/slices/tagCategoriesMenu'
import { welcomeOffersActions } from '../../../store/slices/welcome'
import { CategoriesTagsDesktop } from '../../molecules/categoriesHorizontalMenu/CategoriesTagsDesktop'
import { cartActions } from '../../../store/slices/cart'
import { salesFlyerActions } from '../../../store/slices/salesFlyer'
import { useAppSelector } from '../../../common/hooks'
import { PopupGetMoreForLess } from '../../molecules/PopupGetMoreForLess/PopupGetMoreForLess'
import {
  getBanners,
  getCategories,
  getMessages,
  getTaxBenefitEans,
  getWelcomeV2,
} from '../../../services/productApi'
import { getNotifications } from '../../../services/merchantApi'

import { StyledContainer, StyledContent, StyledInitialSpace } from './styles/Layout.style'
import { PRICING_GROUP_IDS_SALVADOR } from '../../../common/constants'

export interface CategoriesMenuProps {
  handleDrawerToggle?: () => void
  isOpen?: boolean
}

const cartBarBanList = [
  '/cart',
  '/checkout',
  '/new-client',
  '/delivery',
  '/purchase',
  '/select_region',
  '/invoices',
]

export const Layout = ({ children, ...props }: PropsWithChildren) => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { merchantCode, regionId, cartSessionId } = useAppSelector((state) => state.session)
  const notifications = useAppSelector((state) => state.notifications)

  const { openPopup } = useAppSelector((state) => state.getMoreForLess)
  const { modeGenerator } = useAppSelector((state) => state.salesFlyer)
  const [mobileIsOpen, setMobileIsOpen] = useState(false)
  const isMobile = useMediaQuery(themeClubbi.breakpoints.down('md'))
  const isDesktop = useMediaQuery(themeClubbi.breakpoints.up('md'))
  const isInCartPage = location.pathname === '/cart'

  const isSalesFlyerPage = pathname === '/sales-flyer'

  const showCartBar = !cartBarBanList.includes(pathname)
  const showNewNavigationMobile = isMobile

  const welcomeSectionsFetch = async () => {
    const responseWelcomeV2 = await getWelcomeV2(merchantCode)
    dispatch(welcomeOffersActions.setOffers(responseWelcomeV2.section))
  }

  useEffect(() => {
    if (merchantCode && !isSalesFlyerPage)
      dispatch(salesFlyerActions.setMerchantCodeInput(merchantCode))
  }, [merchantCode, isSalesFlyerPage])

  useEffect(() => {
    getMessages()
    welcomeSectionsFetch()
    getNotifications(merchantCode)
    getCategories(merchantCode ?? '', regionId ?? 1)

    if (merchantCode) {
      getBanners(merchantCode, 'home', cartSessionId)
    }

    if (regionId && PRICING_GROUP_IDS_SALVADOR.includes(regionId)) {
      getTaxBenefitEans(regionId)
    }

    dispatch(cartActions.removeProductsWithoutQuantity())
  }, [merchantCode, regionId])

  useEffect(() => {
    const tagPromotions = localStorage.getItem('tag-promotions')
    if (tagPromotions) {
      dispatch(tagCategoriesMenuActions.setStatus({ badgeMenuIcon: false, tagPromotions: false }))
    }
  }, [])

  const handleDrawerToggle = () => {
    setMobileIsOpen(!mobileIsOpen)
  }

  return (
    <StyledContainer>
      {!isSalesFlyerPage ? (
        <>
          <PopupWarning />
          {notifications && <PopupWarningByMerchantCode />}
          <Header />
          <StyledInitialSpace />
          {isDesktop && !isInCartPage && <CategoriesTagsDesktop />}
          <ModalManager />
          <CategoriesMenu handleDrawerToggle={handleDrawerToggle} isOpen={mobileIsOpen} />
          <ShoppingListModal />
          <StyledContent>{children}</StyledContent>
          {openPopup && <PopupGetMoreForLess />}
          {showNewNavigationMobile && (
            <Footer handleDrawerToggle={handleDrawerToggle} offersIsOpen={mobileIsOpen} />
          )}
          <RecommendedDrawer />
          {modeGenerator && <FlyerGeneratorBar />}
          {showCartBar && merchantCode && <NavigationTooltip />}
        </>
      ) : (
        <StyledContent>{children}</StyledContent>
      )}
    </StyledContainer>
  )
}
