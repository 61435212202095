import { shippingInfosActions } from '../../../store/slices/ShippingInfos'
import { Money } from '../../atoms'
import { DELIVERY_TYPES, DELIVERY_TYPE_ENUM } from '../../../common/constants'
import { useAppDispatch, useAppSelector } from '../../../common/hooks'
import gtm from '../../../common/gtmTracker'

import {
  StyledToggleButton,
  StyledToggleButtonGroup,
  StyledType,
} from './styles/ShippingTypes.style'

export const ShippingTypesSelector = () => {
  const [deliveryType, completedProgress, { merchantCode, clubberEmail }] = useAppSelector(
    (state) => [
      state.shippingInfos.shippingType,
      state.shippingInfos.completedProgress,
      state.session,
    ]
  )

  const dispatch = useAppDispatch()

  const handleChange = (_event: React.MouseEvent<HTMLElement>, newType: DELIVERY_TYPE_ENUM) => {
    if (completedProgress) {
      dispatch(shippingInfosActions.setShippingType({ shippingType: newType, completedProgress }))
      if (newType) {
        gtm.triggerClickShippingTypes(merchantCode, newType, clubberEmail)
      }
    } else {
      dispatch(
        shippingInfosActions.setShippingType({
          shippingType: DELIVERY_TYPE_ENUM.fast,
          completedProgress,
        })
      )
    }
  }

  return (
    <StyledToggleButtonGroup
      value={deliveryType}
      onChange={handleChange}
      exclusive
      data-testid="toggle-group"
    >
      <StyledToggleButton
        value={DELIVERY_TYPE_ENUM.fast}
        completedProgress={completedProgress}
        data-testid="fast-shipping"
      >
        <StyledType>
          <div>Entrega rápida</div>
          <div className="days-to-delivery">Receba no próximo dia útil</div>
        </StyledType>
        <Money
          amount={DELIVERY_TYPES.fast.value}
          isBold={deliveryType === DELIVERY_TYPE_ENUM.fast}
        />
      </StyledToggleButton>
      <StyledToggleButton
        value={DELIVERY_TYPE_ENUM.free}
        disabled={!completedProgress}
        completedProgress={completedProgress}
        data-testid="free-shipping"
      >
        <StyledType>
          <div>Entrega econômica</div>
          <div className="days-to-delivery">Receba em 2 dias úteis</div>
        </StyledType>
        {DELIVERY_TYPES.free.value === 0 && <span className="free-shipping">Grátis</span>}
      </StyledToggleButton>
    </StyledToggleButtonGroup>
  )
}
