import { Product } from '../../store/data/types'
import { LoadablePage, LoadablePageProps } from './LoadablePage'
import { getPreviouslyOrdered } from '../../services/productApi'
import React, { memo, useCallback, useEffect } from 'react'
import { useAppSelector } from '../../common/hooks'
import { Redirect, useHistory } from 'react-router-dom'
import { ProductsGrid } from '../templates/ProductsGrid'
import { StyledLinkToHome } from '../atoms/styles/LinkBackHomeDiv.style'
import { LinkBackHome } from '../atoms'

export const previouslyOrderedFetch = async (
  merchantCode: string,
  clubberEmail: string
): Promise<Props> => {
  const products = await getPreviouslyOrdered(merchantCode ?? '', clubberEmail ?? '')
  return { products }
}

interface Props {
  products: Product[]
}

const _LinkHome = memo(() => (
  <StyledLinkToHome>
    <LinkBackHome />
  </StyledLinkToHome>
))

const _Grid = ({ products }: Props) => {
  const header = {
    node: <_LinkHome />,
    height: 63,
  }

  return (
    <>
      {products.length > 0 ? (
        <ProductsGrid context={['previously-ordered']} data={products} header={header} />
      ) : (
        <Redirect to={'/'} />
      )}
    </>
  )
}

export const PreviouslyOrdered = (props: LoadablePageProps<Props>) => {
  const { merchantCode, clubberEmail } = useAppSelector((state) => state.session)
  const history = useHistory()
  useEffect(() => {
    if (!merchantCode) {
      history.replace('/')
    }
  }, [merchantCode])

  const fetch = useCallback(
    () => previouslyOrderedFetch(merchantCode!, clubberEmail!),
    [merchantCode, clubberEmail]
  )

  return (
    <LoadablePage {...props} dataFetch={fetch}>
      {_Grid}
    </LoadablePage>
  )
}
