import { clubbiStyled } from 'clubbi-ui'

export const StyledContainer = clubbiStyled('div')<{ rowAlign: boolean }>(
  ({ theme, rowAlign }) => ({
    display: 'flex',
    flexDirection: rowAlign ? 'row' : 'column',
    gap: '0px',
    justifyContent: rowAlign ? 'space-between' : 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: rowAlign ? '68%' : '100%',
    },
  })
)

export const StyledLabelContainer = clubbiStyled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
}))

export const StyledLabel = clubbiStyled('p')(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '170.2%',
  color: theme.palette.brandBlack.main,
  textAlign: 'left',
  margin: '4px 0',
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
  },
}))

export const StyledInput = clubbiStyled('input')<{
  width: string
  rowAlign: boolean
  requiredEmpty: boolean
}>(({ theme, width, rowAlign, requiredEmpty }) => ({
  fontSize: '12px',
  lineHeight: '170.2%',
  outline: 'none',
  padding: '6px 12px',
  borderRadius: '4px',
  border: `1px solid ${
    requiredEmpty ? theme.palette.brandAlert.main : theme.palette.brandGrey[60]
  }`,
  backgroundColor: theme.palette.brandWhite.main,
  color: theme.palette.brandBlack.main,
  width: rowAlign ? '40%' : '100%',
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
    padding: '8px 16px',
    width: width,
  },
  ':disabled': {
    color: theme.palette.brandBlack.main,
    backgroundColor: theme.palette.brandGrey[10],
    opacity: '0.5',
  },
  ':focus': {
    border: `1px solid ${
      requiredEmpty ? theme.palette.brandAlert.main : theme.palette.brandPurple[60]
    }`,
  },
}))
