import { CategoryBanner, themeClubbi } from 'clubbi-ui'

export const Banner = () => {
  return (
    <CategoryBanner
      description="Aproveite as melhores ofertas para o seu mercadinho"
      title="Sua lista de compras"
      image="img/cartShoppingList.png"
      background={themeClubbi.palette.brandPurple[10]}
    />
  )
}
