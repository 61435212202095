import { clubbiStyled } from 'clubbi-ui'

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  borderRadius: '4px',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  backgroundColor: theme.palette.brandWhite.main,
}))

export const StyledTitle = clubbiStyled('span')(({ theme }) => ({
  fontStyle: 'normal',
  fontSize: '18px',
  lineHeight: '109.9%',
  color: theme.palette.brandBlack.main,
  textAlign: 'left',
  [theme.breakpoints.up('md')]: {
    fontSize: '32px',
  },
}))

export const StyledLink = clubbiStyled('a')(({ theme }) => ({
  fontStyle: 'normal',
  fontSize: '10px',
  lineHeight: '170.2%',
  color: theme.palette.brandPurple[60],
  textAlign: 'right',
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
  },
}))
