import camelcaseKeys from 'camelcase-keys'
import { CartChange, CartItem } from '../store/data/types'
import { apiPatch, apiPost, loadBody } from './api'

export type OrderItem = {
  ean: string
  quantity: number
  unitPrice: number
  isPackageMode: boolean
  packageNumberOfItems: number | null
  packagePrice: number | null
  changes: CartChange[]
  offerType: number | undefined
  offerId: number | undefined
}

export type Order = {
  merchantCode: string
  invoiceType: number | null
  paymentType: number | null
  items: CartItem[]
  regionId: number | null
  merchantStatus: string | null
  sessionId?: string
  deliveryFee?: number
  daysToDelivery?: number
  clubberEmail?: string | null
}

export type PatchOrderItemBody = {
  quantity: number
}

export type CancelOrderBody = {
  operator: string
}

export enum OrderResult {
  SUCCESS,
  INVALID_CODE,
  PRODUCT_CHANGES,
  UNEXPECTED,
}

const STATUS_TO_ORDER_RESULT: { [key: number]: OrderResult } = {
  401: OrderResult.INVALID_CODE,
  422: OrderResult.PRODUCT_CHANGES,
}

export enum OrderChangeResult {
  PRODUCT_CHANGES,
  NO_CHANGES,
  UNEXPECTED,
}

const STATUS_TO_ORDER_CHANGE_RESULT: { [key: number]: OrderChangeResult } = {
  200: OrderChangeResult.PRODUCT_CHANGES,
  204: OrderChangeResult.NO_CHANGES,
}

export const placeOrder = async (
  order: Order
): Promise<[OrderResult, Record<string, unknown> | null]> => {
  const request = await apiPost('/orders/v2', order)
  const body = await loadBody(request)

  if (request.ok) {
    return [OrderResult.SUCCESS, body]
  }

  return [STATUS_TO_ORDER_RESULT[request.status] || OrderResult.UNEXPECTED, body]
}

export const checkOrder = async (
  order: Order
): Promise<[OrderChangeResult, Record<string, unknown> | null]> => {
  const response = await apiPost('/orders/check', order)
  let body

  if (!response.ok) {
    return [STATUS_TO_ORDER_CHANGE_RESULT[response.status] || OrderChangeResult.UNEXPECTED, null]
  }

  if (STATUS_TO_ORDER_CHANGE_RESULT[response.status] == OrderChangeResult.PRODUCT_CHANGES) {
    body = await loadBody(response, false)
  }

  const formattedBody = body ? camelcaseKeys(body, { deep: true }) : null
  return [STATUS_TO_ORDER_CHANGE_RESULT[response.status], formattedBody]
}

export const cancelOrder = async (orderId: number, data: CancelOrderBody): Promise<OrderResult> => {
  const response = await apiPatch(`/orders/${orderId}/cancel`, data)
  if (!response.ok) {
    return OrderResult.UNEXPECTED
  }
  return OrderResult.SUCCESS
}

export const cancelOrderItem = async (orderItemId: number): Promise<OrderResult> => {
  const response = await apiPatch(`/orders/order_item/${orderItemId}/cancel`)
  if (!response.ok) {
    return OrderResult.UNEXPECTED
  }
  return OrderResult.SUCCESS
}

export const patchOrderItem = async (
  orderItemId: number,
  data: PatchOrderItemBody
): Promise<OrderResult> => {
  const response = await apiPatch(`/orders/order_item/${orderItemId}`, data)
  if (!response.ok) {
    return OrderResult.UNEXPECTED
  }
  return OrderResult.SUCCESS
}
