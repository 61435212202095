import { clubbiStyled } from 'clubbi-ui'

export const StyledIcon = clubbiStyled('div')({
  width: '25px',
  height: '25px',
  position: 'relative',
})

export const StyledDiv = clubbiStyled('div')({
  maxWidth: '100px',
  display: 'flex',
})

export const StyledBadgeQty = clubbiStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brandYellow[50],
  minWidth: '18px',
  height: '12px',
  position: 'absolute',
  border: `1px solid ${theme.palette.brandPurple[60]}`,
  borderRadius: '37px',
  left: '12px',
  top: '-1px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    left: '12px',
    top: '-4px',
  },
}))

export const StyledQty = clubbiStyled('div')(({ theme }) => ({
  color: theme.palette.brandPurple[60],
  fontSize: '10px',
  fontWeight: 700,
  lineHeight: theme.typography.fontSize2XS,
  alignSelf: 'center',
  textAlign: 'center',
  padding: '1px 2px',
}))
