import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProductsSection } from '../data/types'

type State = ProductsSection

export const productsSectionSlice = createSlice({
  name: 'productsSection',
  initialState: {} as State,
  reducers: {
    setProductsSection: (state, action: PayloadAction<any>): State => {
      return {
        ...state,
        ...action.payload,
      }
    },
    cleanUp: () => {
      return {} as State
    },
  },
})

export const productsSectionReducer = productsSectionSlice.reducer
export const productsSectionActions = productsSectionSlice.actions
