import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type State = {
  onTestShippingTypes: boolean
}

export const splitTestShippingTypes = createSlice({
  name: 'splitTestShippingTypes',
  initialState: {
    onTestShippingTypes: false,
  },
  reducers: {
    setIsInExperiment: (_state, action: PayloadAction<boolean>): State => {
      return { onTestShippingTypes: action.payload }
    },
  },
})

export const splitTestShippingTypesReducer = splitTestShippingTypes.reducer
export const splitTestShippingTypesActions = splitTestShippingTypes.actions
