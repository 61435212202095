import { Snackbar, themeClubbi, useMediaQuery } from 'clubbi-ui'
import { AlertSnackbarProps } from './interfaces'

export const AlertSnackbar = ({
  openOkSnackBar,
  setOpenOkSnackBar,
  openErrorSnackBar,
  setOpenErrorSnackBar,
}: AlertSnackbarProps) => {
  const isMobile = useMediaQuery(themeClubbi.breakpoints.down('md'))
  return (
    <Snackbar
      anchorOrigin={{ vertical: isMobile ? 'top' : 'bottom', horizontal: 'center' }}
      open={openOkSnackBar || openErrorSnackBar}
      autoHideDuration={5000}
      onClose={() => {
        setOpenOkSnackBar(false)
        setOpenErrorSnackBar(false)
      }}
      label={openErrorSnackBar ? 'Erro ao salvar dados' : 'Dados salvos com sucesso'}
      severity={openErrorSnackBar ? 'error' : 'success'}
    />
  )
}
