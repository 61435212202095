import { searchListProps } from '../../routes/ShoppingList'
import {
  StyledNotFoundContainer,
  StyledNotFoundList,
  StyledNotFoundText,
  StyledNotFoundTitle,
} from './styles/NotfoundComponent.style'

interface NotFoundComponentProps {
  notFoundSearch: searchListProps[]
}

export const NotFoundComponent = (props: NotFoundComponentProps) => {
  return (
    <StyledNotFoundContainer>
      <StyledNotFoundTitle>Produtos não encontrados 👀</StyledNotFoundTitle>
      <StyledNotFoundText>Poxa, não encontramos os produtos abaixo:</StyledNotFoundText>
      {props.notFoundSearch.map((search: any, index: any) => (
        <StyledNotFoundList key={index}>{search.term}</StyledNotFoundList>
      ))}
      <StyledNotFoundText>Refaça a sua pesquisa utilizando outros termos.</StyledNotFoundText>
    </StyledNotFoundContainer>
  )
}
