import { clubbiStyled } from 'clubbi-ui'

export const StyledMRedStyledEm = clubbiStyled('em')(({ theme }) => ({
  color: theme.palette.brandAlert[50],
}))

export const StyledMGreenStyledEm = clubbiStyled('em')(({ theme }) => ({
  color: theme.palette.brandSuccess[80],
}))

export const StyledModal = clubbiStyled('section')({
  display: 'flex',
  flexDirection: 'column',
  margin: '10px',
})

export const StyledListGroup = clubbiStyled('div')(({ theme }) => ({
  marginTop: '0px',
  background: theme.palette.brandWhite.main,
}))

export const StyledListGroupItem = clubbiStyled('div')(({ theme }) => ({
  width: '100%',
  Minheight: '70px',
  left: '20px',
  background: theme.palette.brandGrey[10],
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: '9px',
  marginBottom: '9px',
  textAlign: 'center',
  padding: '4px',

  p: {
    fontWeight: '700',
    fontSize: theme.typography.fontSize2XS,
    color: theme.palette.brandAlert[30],
    margin: '0',
  },
}))

export const StyledContainerChangesInfo = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  gap: '5px',
  fontSize: theme.typography.fontSize2XS,
  margin: '0',
}))

export const StyledCurrentInfo = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  gap: '5px',
  color: theme.palette.brandAlert[60],
}))

export const StyledUpdateInfo = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  gap: '5px',
  color: theme.palette.brandSuccess[60],
}))
