import ReactGA from 'react-ga4'

const init = () => {
  try {
    const GA4_ID = process.env.REACT_APP_GA4_ID
    ReactGA.initialize(GA4_ID!)
  } catch (error) {
    console.error('Error initializing Google Analytics 4. Check environment variables. \n ', error)
  }
}

export default {
  init,
}
