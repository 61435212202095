import { IconButton, MenuItem, clubbiStyled } from 'clubbi-ui'

export const StyledMobileInvoiceCardLinkBoard = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))

export const StyledIconButton = clubbiStyled(IconButton)(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  padding: 0,
  color: theme.palette.brandPurple[60],
}))

export const StyledMenuItem = clubbiStyled(MenuItem)<{ disabled: boolean }>(
  ({ disabled, theme }) => ({
    color: disabled ? theme.palette.brandGrey[40] : theme.palette.brandGrey[100],
    fontWeight: 500,
    fontSize: theme.typography.fontSize2XS,
  })
)
