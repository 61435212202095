import { ScrollButton, clubbiStyled } from 'clubbi-ui'
import { Link } from 'react-router-dom'

export const StyledLink = clubbiStyled(Link)(({ theme }) => ({
  '&:hover': {
    textDecoration: 'none',
  },
  p: {
    fontSize: theme.typography.fontSizeXS,

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.fontSizeXS,
    },
  },
}))

export const StyledHeader = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBotton: '8px',

  span: {
    fontSize: theme.typography.fontSizeL,
    fontWeight: theme.typography.fontWeightBold,
  },
}))

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  whiteSpace: 'nowrap',
  overflow: 'auto',
  height: '50px',
  position: 'relative',

  [theme.breakpoints.up('sm')]: {
    height: '60px',
  },

  '&::-webkit-scrollbar': {
    height: '5px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: 'transparent',
    borderRadius: '8px',
  },

  '&::-webkit-scrollbar-track': {
    height: '1px',
    borderRadius: '8px',
    background: 'transparent',
  },

  '&:hover::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.brandPurple[60],
  },

  button: {
    margin: '2px',
  },
}))

export const StyledMain = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '0px 16px 0px 16px',
  justifyContent: 'center',
  width: '96%',

  [theme.breakpoints.up('sm')]: {
    margin: '10px 32px',
  },
}))

export const StyledArrowButton = clubbiStyled(ScrollButton)(({ theme, direction }) => ({
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
    position: 'absolute',
    right: direction === 'right' && '45px',
    background: theme.palette.brandGrey[100],
  },
}))

export const StyledDiv = clubbiStyled('div')({
  display: 'flex',
})
