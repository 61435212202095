import { billingStatusTranslator, paymentTypeTranslator } from '../../../common/utils'
import {
  StyledContainer,
  StyledLabel,
  StyledRowWrapper,
  StyledValue,
} from './styles/InvoiceCardTextContent.style'

export const InvoiceCardTextContent = ({
  paymentType,
  totalPrice,
  billingStatus,
  billingValue,
  totalDeliveredPrice,
}: {
  paymentType: number
  totalPrice: number
  billingStatus?: string
  billingValue?: number
  totalDeliveredPrice?: number
}) => {
  const generatePrice = (price: number) => price.toFixed(2).replace('.', ',')

  return (
    <StyledContainer>
      <StyledRowWrapper>
        <StyledLabel>Forma de pagamento: </StyledLabel>
        <StyledValue>{paymentTypeTranslator(paymentType)}</StyledValue>
      </StyledRowWrapper>
      <StyledRowWrapper>
        <StyledLabel>Valor do pagamento: </StyledLabel>
        {totalDeliveredPrice ? (
          <StyledValue>{`R$ ${generatePrice(
            billingValue ? billingValue : totalDeliveredPrice + 15
          )}`}</StyledValue>
        ) : (
          <StyledValue>{`R$ ${generatePrice(
            billingValue ? billingValue : totalPrice + 15
          )}`}</StyledValue>
        )}
      </StyledRowWrapper>
      {billingStatus && (
        <StyledRowWrapper>
          <StyledLabel>Status do pagamento: </StyledLabel>
          <StyledValue>{billingStatusTranslator(billingStatus)}</StyledValue>
        </StyledRowWrapper>
      )}
    </StyledContainer>
  )
}
