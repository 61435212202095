import { CloseIcon, clubbiStyled, Modal, ButtonClubbi } from 'clubbi-ui'

export const StyledModal = clubbiStyled(Modal)(({ theme }) => ({
  border: 'none',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  outlineColor: theme.palette.brandWhite.main,
}))

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  width: '90vw',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '90vh',
  overflowY: 'auto',
  position: 'absolute',
  top: '10px',

  background: theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    width: '800px',
  },
}))

export const StyledHeader = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  padding: '10px',
  fontSize: theme.typography.fontSizeM,
  borderBottom: `1px solid ${theme.palette.brandGrey[20]}`,

  background: theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    alignItems: 'center',
    fontSize: theme.typography.fontSizeL,
  },
}))

export const StyledCloseButton = clubbiStyled('button')(({ theme }) => ({
  textDecoration: 'underline',
  border: 'none',

  backgroundColor: theme.palette.brandWhite.main,
}))

export const StyledCloseIcon = clubbiStyled(CloseIcon)(() => ({
  width: '30px',
}))

export const StyledContent = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '10px',
  padding: '10px',

  background: theme.palette.brandWhite.main,
}))

export const StyledList = clubbiStyled('ol')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  alignSelf: 'start',
  padding: '10px 20px',
}))

export const StyledLink = clubbiStyled('a')(({ theme }) => ({
  color: theme.palette.brandPurple[30],
}))

export const StyledButton = clubbiStyled(ButtonClubbi)(({ theme }) => ({
  textTransform: 'lowercase',
  fontSize: theme.typography.fontSize2XS,
  margin: '20px',
  alignSelf: 'end',

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))
