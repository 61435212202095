import { Product } from '../../store/data/types'
import { ProductsGrid } from '../templates/ProductsGrid'
import { LoadablePage, LoadablePageProps } from './LoadablePage'
import { getCustomProducts } from '../../services/productApi'
import { memo } from 'react'
import { StyledLinkToHome } from '../atoms/styles/LinkBackHomeDiv.style'
import { LinkBackHome } from '../atoms'
import { useAppSelector } from '../../common/hooks'

export const suggestionsFetch = async (session: any): Promise<Props> => {
  const { cartSessionId } = session
  const products = await getCustomProducts(10, cartSessionId)
  return { products }
}

interface Props {
  products: Product[]
}

const _LinkHome = memo(() => (
  <StyledLinkToHome>
    <LinkBackHome />
  </StyledLinkToHome>
))

const _Grid = ({ products }: Props) => {
  const header = {
    node: <_LinkHome />,
    height: 63,
  }

  return <ProductsGrid context={['suggestions']} data={products} header={header} />
}

export const CartSuggestions = (props: LoadablePageProps<Props>) => {
  const session = useAppSelector((state) => state.session)

  return (
    <LoadablePage {...props} dataFetch={() => suggestionsFetch(session)}>
      {_Grid}
    </LoadablePage>
  )
}
