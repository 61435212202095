import { Product, CartItem, Mission } from '../store/data/types'
import TagManager from 'react-gtm-module'
import { DELIVERY_TYPE_ENUM } from './constants'

export interface customWindow extends Window {
  dataLayer: Record<string, any>[]
}

declare const window: customWindow

const init = () => {
  try {
    const GTM_ID = process.env.REACT_APP_GTM_ID!
    const GTM_AUTH = process.env.REACT_APP_GTM_AUTH!
    const GTM_PREVIEW = process.env.REACT_APP_GTM_PREVIEW!

    const tagManagerArgs = {
      gtmId: GTM_ID,
      auth: GTM_AUTH,
      preview: GTM_PREVIEW,
    }

    TagManager.initialize(tagManagerArgs)
  } catch (error) {
    console.error('Error initializing Google Tag Manager. Check environment variables. \n', error)
  }
}

const shortenProduct = (product: Product) => {
  const { description, category, price, discount, inOfertao } = product
  return {
    description,
    category,
    price,
    discount,
    inOfertao,
  }
}

const triggerLogin = (id: string, datetime: string, regionId: number, clubberEmail?: string) => {
  window.dataLayer &&
    window.dataLayer?.push({
      event: 'login',
      userId: id,
      clubberEmail,
      datetime,
      regionId,
    })
}

const triggerAddToCart = (
  product: Product,
  id: string,
  quantity: number,
  section?: string,
  clubberEmail?: string
) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'addToCart',
      userId: id,
      clubberEmail,
      ean: product.ean,
      quantity,
      details: shortenProduct(product),
      section,
      path: window.location.pathname,
    })
}

const triggerRemoveFromCart = (
  product: Product,
  id: string,
  quantity: number,
  section?: string,
  clubberEmail?: string
) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'removeFromCart',
      userId: id,
      clubberEmail,
      ean: product.ean,
      quantity,
      details: shortenProduct(product),
      section,
    })
}

const triggerProductImpression = (
  product: Product,
  id: string,
  label: string | undefined,
  clubberEmail?: string
) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'productImpression',
      userId: id,
      clubberEmail,
      ean: product.ean,
      details: shortenProduct(product),
      corridor: label,
    })
}

const triggerBannerImpression = (
  id: string | undefined,
  bannerId: string,
  clubberEmail?: string
) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'BannerImpression',
      userId: id,
      clubberEmail,
      bannerId: bannerId,
    })
}

const triggerSearch = (
  product: Product,
  id: string,
  label: string | undefined,
  term: string | undefined | null,
  clubberEmail?: string
) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'searchProducts',
      userId: id,
      clubberEmail,
      ean: product.ean,
      details: shortenProduct(product),
      corridor: label,
      term: term,
    })
}

const triggerCheckout = (
  cartItems: CartItem[],
  paymentType: number,
  id: string,
  clubberEmail?: string
) => {
  const products = cartItems.map(({ product, quantity, corridor, url }) => {
    return {
      ean: product.ean,
      details: shortenProduct(product),
      quantity,
      corridor,
      url,
    }
  })

  window.dataLayer &&
    window.dataLayer.push({
      event: 'checkout',
      userId: id,
      clubberEmail,
      paymentType,
      products: products,
    })
}

const triggerMissionClick = (mission: Mission, id: string, clubberEmail: string | undefined) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'missionClick',
      userId: id,
      clubberEmail,
      missionId: mission.id,
    })
}

const triggerMissionClickOnCheckout = (
  mission: Mission,
  id: string,
  clubberEmail: string | undefined
) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'missionClickOnCheckout',
      userId: id,
      clubberEmail,
      missionId: mission.id,
    })
}
const triggerAddMissionItemsToCart = (
  id: string,
  product: Product,
  quantity: number,
  clubberEmail?: string
) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'AddMissionItemsToCart',
      userId: id,
      clubberEmail,
      ean: product.ean,
      quantity: quantity,
      details: shortenProduct(product),
    })
}

const triggerMissionProgressClick = (mission: Mission, id: string, clubberEmail?: string) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'missionProgressClick',
      userId: id,
      clubberEmail,
      missionId: mission.id,
    })
}

const triggerMissionStart = (mission: Mission, id: string | undefined, clubberEmail?: string) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'missionStart',
      userId: id,
      clubberEmail,
      missionId: mission.id,
    })
}

const triggerMissionCompleted = (
  mission: Mission,
  id: string | undefined,
  clubberEmail?: string
) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'missionEnd',
      userId: id,
      clubberEmail,
      missionId: mission.id,
    })
}

const triggerNotificationCtaClick = (
  id: string,
  regionId: number,
  title: string,
  clubberEmail?: string
) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'notificationCtaClick',
      userId: id,
      clubberEmail,
      regionId,
      title,
    })
}

const triggerNotificationClick = (id: string, regionId: number, clubberEmail?: string) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'notificationCtaClick',
      userId: id,
      clubberEmail,
      regionId,
    })
}

const triggerCategoriesClick = (
  id: string | undefined,
  page: string,
  type: string,
  category: string,
  clubberEmail?: string
) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'categoriesClick',
      userId: id,
      clubberEmail,
      page,
      type,
      category,
    })
}

const triggerDrawer = (id: string, regionId: number, clubberEmail?: string) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'drawerShow',
      userId: id,
      clubberEmail,
      regionId,
    })
}

const triggerDrawerClose = (id: string, regionId: number, clubberEmail?: string) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'drawerClose',
      userId: id,
      clubberEmail,
      regionId,
    })
}

const triggerBannerClick = (bannerId: string, id: string | undefined, clubberEmail?: string) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'bannerClick',
      userId: id,
      clubberEmail,
      bannerId: bannerId,
    })
}

const triggerAddBannerItemsToCart = (
  id: string,
  product: Product,
  quantity: number,
  bannerId: string,
  clubberEmail?: string
) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'AddBannerItemsToCart',
      userId: id,
      clubberEmail,
      ean: product.ean,
      quantity: quantity,
      bannerId: bannerId,
      details: shortenProduct(product),
    })
}

const triggerScrollMyDeliverysAvaCorridor = (id: string | undefined, clubberEmail?: string) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'scrollMyDeliveryAvaCorridor',
      userId: id,
      clubberEmail,
      scroll: '100%',
    })
}

const triggerScrollOfertaoAvaCorridor = (id: string | undefined, clubberEmail?: string) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'scrollOfertaoCorridor',
      userId: id,
      clubberEmail,
      scroll: '100%',
    })
}

const triggerScrollProductsOnPage = (
  id: string | undefined,
  index: number | undefined,
  totalProducts: number | undefined,
  clubberEmail?: string
) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'scrollProductsOnPage',
      userId: id,
      clubberEmail,
      productPosition: index,
      totalProductsOnPage: totalProducts,
    })
}

const triggerShoppingListClick = (id: string | undefined, clubberEmail?: string) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'shoppingListClick',
      userId: id,
      clubberEmail,
    })
}

const triggerShoppingListTerms = (id: string | undefined, terms: string, clubberEmail?: string) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'shoppingListTerms',
      userId: id,
      clubberEmail,
      terms,
    })
}

const triggerShoppingListInputError = (
  id: string | undefined,
  input: string,
  clubberEmail?: string
) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'shoppingListInputError',
      userId: id,
      clubberEmail,
      input,
    })
}

const triggerSplitAvaPreviouslyOrdered = (
  id?: string | undefined,
  status?: boolean,
  clubberEmail?: string
) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'splitAvaPreviouslyOrdered',
      userId: id,
      clubberEmail,
      experiment: 'ava-previously-ordered',
      onExperiment: status,
    })
}

const triggerOpenOrders = (id: string | undefined, clubberEmail?: string) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'openOrders',
      userId: id,
      clubberEmail,
    })
}

const triggerOpenOrdersSupportContact = (id: string | undefined, clubberEmail?: string) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'openOrdersSupportContact',
      userId: id,
      clubberEmail,
    })
}

const triggerBannerOfertaoClick = (id: string, clubberEmail?: string) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'bannerOfertaoClick',
      userId: id,
      clubberEmail,
    })
}

const triggerSplitLayoutMobile = (
  id?: string | undefined,
  status?: boolean,
  clubberEmail?: string
) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'splitNewLayoutMobile',
      userId: id,
      clubberEmail,
      experiment: 'new-layout-mobile',
      onExperiment: status,
    })
}

const triggerCloseOnboardingNavigation = (id: string, step: number, clubberEmail?: string) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'closeOnboardingNavigation',
      userId: id,
      clubberEmail,
      button: 'close',
      tooltipNumber: step,
    })
}

const triggerCompletedOnboardingNavigation = (id: string, clubberEmail?: string) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'completedOnboardingNavigation',
      userId: id,
      clubberEmail,
      button: 'understood',
    })
}

const triggerFooterClick = (id: string | undefined, icon: string, clubberEmail?: string) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'footerClick',
      icon: icon,
      userId: id,
      clubberEmail,
    })
}

const triggerSelectMission = (
  id: string | undefined,
  missionId: number,
  selected: boolean,
  clubberEmail?: string
) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'selectMission',
      userId: id,
      clubberEmail,
      missionId,
      selected,
    })
}

const triggerProfile = (id: string | undefined, clubberEmail?: string) => {
  if (window?.dataLayer)
    window.dataLayer.push({
      event: 'profile',
      userId: id,
      clubberEmail,
    })
}

const triggerSplitTestShippingTypes = (
  id: string | undefined,
  status: boolean,
  clubberEmail?: string
) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'splitTestShippingTypes',
      userId: id,
      clubberEmail,
      experiment: 'TestShippingTypes',
      onExperiment: status,
    })
}

const triggerClickShippingTypes = (
  id: string | undefined,
  type: DELIVERY_TYPE_ENUM,
  clubberEmail?: string
) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'ClickShippingTypes',
      userId: id,
      clubberEmail,
      shippingtype: type,
    })
}

const triggerShippingTypesImpression = (id: string | undefined, clubberEmail?: string) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'ShippingTypesImpression',
      userId: id,
      clubberEmail,
    })
}

const triggerGenerateSalesFlyer = (merchantCodeInput: string | undefined) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'clickGenerateSalesFlyer',
      userInput: merchantCodeInput,
    })
}

const triggerSaveSalesFlyer = () => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'saveSalesFlyer',
    })
}

const triggerPagesView = (
  id: string | undefined,
  pathname: string,
  regionId: number | undefined,
  clubberEmail?: string
) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'pagesView',
      userId: id,
      clubberEmail,
      page: pathname,
      region: regionId,
    })
}

export default {
  init,
  triggerAddToCart,
  triggerRemoveFromCart,
  triggerProductImpression,
  triggerCheckout,
  triggerLogin,
  triggerMissionClick,
  triggerAddMissionItemsToCart,
  triggerMissionClickOnCheckout,
  triggerNotificationClick,
  triggerNotificationCtaClick,
  triggerCategoriesClick,
  triggerDrawer,
  triggerDrawerClose,
  triggerBannerClick,
  triggerAddBannerItemsToCart,
  triggerMissionProgressClick,
  triggerMissionStart,
  triggerMissionCompleted,
  triggerScrollProductsOnPage,
  triggerScrollOfertaoAvaCorridor,
  triggerScrollMyDeliverysAvaCorridor,
  triggerSearch,
  triggerShoppingListClick,
  triggerShoppingListTerms,
  triggerShoppingListInputError,
  triggerSplitAvaPreviouslyOrdered,
  triggerOpenOrders,
  triggerOpenOrdersSupportContact,
  triggerBannerOfertaoClick,
  triggerBannerImpression,
  triggerSplitLayoutMobile,
  triggerCloseOnboardingNavigation,
  triggerCompletedOnboardingNavigation,
  triggerFooterClick,
  triggerSelectMission,
  triggerProfile,
  triggerSplitTestShippingTypes,
  triggerClickShippingTypes,
  triggerShippingTypesImpression,
  triggerGenerateSalesFlyer,
  triggerSaveSalesFlyer,
  triggerPagesView,
}
