import React, { useState, FormEvent, MouseEvent } from 'react'

import { NPS, putNpsFeedback } from '../../../services/merchantApi'
import { modalController } from '../ModalManager'
import { BAD_GRADES } from '../../../common/constants'

import {
  StyledCloseButton,
  StyledCloseIcon,
  StyledContainer,
  StyledContainerOptions,
  StyledContent,
  StyledHeader,
  StyledModal,
  StyledOptionButton,
  StyledInputComment,
  StyledButton,
} from './styles/NpsBadGrade.style'
import { themeClubbi } from 'clubbi-ui'

export const NpsBadGrade = ({
  showNps,
  setShowNps,
  latestNps,
}: {
  showNps: modalController
  setShowNps: (value: modalController | ((prevVar: modalController) => modalController)) => void
  latestNps: NPS
}): JSX.Element => {
  const [badGradeValue, setBadGradeValue] = useState<Set<string>>(new Set())
  const [comment, setComment] = useState('')

  const areOptionsSelected = [...badGradeValue].length < 1

  const handleClose = (): void => {
    setShowNps((state) => ({ ...state, showBadGrade: false }))
  }

  const checkBadGrades = (event: MouseEvent<HTMLButtonElement>): void => {
    const value = event.currentTarget.value
    let badGrades = new Set(badGradeValue)
    if (badGrades.has(value)) {
      badGrades.delete(value)
    } else {
      if (badGrades.size < 1) {
        badGrades = new Set([...badGrades, ...[value]])
      } else {
        badGrades.delete(badGrades.values().next().value)
        badGrades = new Set([...badGrades, ...[value]])
      }
    }
    setBadGradeValue(badGrades)
  }

  const handleChange = (event: FormEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value
    setComment(value)
  }

  const submitNpsFeedback = async (): Promise<void> => {
    setShowNps((state) => ({ ...state, showBadGrade: false, showNpsThanks: true }))
    const feedback: { [feedback: string]: string } = {}
    ;[...badGradeValue].forEach((element, index) => {
      feedback[`feedback_${index + 1}`] = element
    })
    await putNpsFeedback(latestNps.id, {
      nps_experience_feedback: feedback,
      nps_comment: comment,
    })
  }

  return (
    <StyledModal open={showNps.showBadGrade} onClose={handleClose}>
      <StyledContainer>
        <StyledHeader>
          Nos ajude a melhorar a sua experiência
          <StyledCloseButton onClick={handleClose}>
            <StyledCloseIcon color={themeClubbi.palette.brandGrey.main} />
          </StyledCloseButton>
        </StyledHeader>
        <StyledContent>
          Quais os três principais pontos que a Clubbi precisa melhorar?
          <StyledContainerOptions>
            {BAD_GRADES.map((grade, index) => (
              <StyledOptionButton
                key={index}
                name={grade}
                value={grade}
                onClick={checkBadGrades}
                bgColor={badGradeValue.has(grade)}
              >
                {grade}
              </StyledOptionButton>
            ))}
          </StyledContainerOptions>
        </StyledContent>
        <StyledContent>
          Escreva comentários, elogios, sugestões ou reclamações
          <StyledInputComment onInput={handleChange} />
        </StyledContent>
        <StyledButton
          size="small"
          typeButton="filled"
          label="Enviar avaliação"
          onClick={submitNpsFeedback}
          disabled={areOptionsSelected}
        />
      </StyledContainer>
    </StyledModal>
  )
}
