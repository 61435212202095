import { StyleContainerTag } from './styles/GetMoreForLessTag.style'

export interface Props {
  quantityToGet: number
  quantityToPurchase: number
}

export const GetMoreForLessTag = ({ quantityToGet, quantityToPurchase }: Props) => {
  return (
    <StyleContainerTag>{`Leve ${quantityToGet}cx. Pague ${quantityToPurchase}`}</StyleContainerTag>
  )
}
