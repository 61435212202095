import { clubbiStyled } from 'clubbi-ui'

export const StyledTitle = clubbiStyled('p')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontStyle: 'normal',
  fontSize: '12px',
  lineHeight: '170.2%',
  color: theme.palette.brandBlack.main,
  textAlign: 'left',
  margin: 0,
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
  },
}))

export const StyledSubTitle = clubbiStyled('p')(({ theme }) => ({
  fontSize: '10px',
  lineHeight: '146.2%',
  color: theme.palette.brandGrey[60],
  textAlign: 'left',
  margin: 0,
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
  },
  '>svg': {
    height: '12px',
    width: '16px',
    marginLeft: '2px',
    marginBottom: '2px',
    [theme.breakpoints.up('md')]: {
      height: '16px',
      width: '16px',
      marginLeft: '4px',
    },
  },
  '>svg path': {
    stroke: theme.palette.brandSuccess[70],
    strokeWidth: '1px',
  },
}))

export const StyledSubTitleSpan = clubbiStyled('span')<{ isFulfilled: boolean }>(
  ({ isFulfilled, theme }) => ({
    color: isFulfilled ? theme.palette.brandGrey[70] : theme.palette.brandAlert[50],
    fontWeight: 700,
  })
)
