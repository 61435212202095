import { Offer } from '../../store/data/types'
import { PromotionsData, getPromotions } from '../../services/productApi'
import { useEffect, useState } from 'react'
import { ProductsList } from '../organisms/product/ProductsList'
import { Spinner } from '../atoms/Spinner'
import { LinkBackHome } from '../atoms'
import { StyledMain } from './styles/Promotions.style'
import { StyledLinkToHome } from '../atoms/styles/LinkBackHomeDiv.style'

export const Promotions = () => {
  const [promotions, setPromotions] = useState<any>([])

  const promotionsData = (response: PromotionsData) => {
    const { weekOffers, dailyOffers, bestPrices } = response
    let data: Offer[] = []

    if (dailyOffers.length > 0) {
      data = [
        ...data,
        {
          name: 'Promoções do Dia',
          link: {
            text: 'Daily Offers',
            href: '/promotions/daily-promotions',
          },
          products: [...dailyOffers],
          context: 'daily-promotions',
        },
      ]
    }
    if (weekOffers.length > 0) {
      data = [
        ...data,
        {
          name: 'Promoções da Semana',
          link: {
            text: 'Week Offers',
            href: '/promotions/week-promotions',
          },
          products: [...weekOffers],
          context: 'week-promotions',
        },
      ]
    }

    if (bestPrices.length > 0) {
      data = [
        ...data,
        {
          name: 'Melhores Preços',
          link: {
            text: 'Week Offers',
            href: '/promotions/best-prices',
          },
          products: [...bestPrices],
          context: 'best-prices',
        },
      ]
    }
    return data
  }

  const promotionsFetch = async () => {
    const groups = await getPromotions()
    setPromotions(promotionsData(groups))
  }

  useEffect(() => {
    promotionsFetch()
  }, [])

  return (
    <StyledMain>
      <StyledLinkToHome>
        <LinkBackHome />
      </StyledLinkToHome>
      <div>
        {promotions.length > 0 ? (
          <ProductsList groups={promotions} context={['promotions']} />
        ) : (
          <Spinner />
        )}
      </div>
    </StyledMain>
  )
}
