import React, { useEffect } from 'react'
import { useAppSelector } from '../../common/hooks'
import { OrderStatus } from '../../store/data/types'
import { ContactMessage } from '../molecules/checkoutSuccess/ContactMessage'
import { OrderInstructionsMessage } from '../molecules/checkoutSuccess/OrderInstructionsMessage'
import { OrderStepper } from '../molecules/checkoutSuccess/OrderStepper'
import { ReturnButton } from '../molecules/checkoutSuccess/ReturnButton'
import { TitleCheckout } from '../molecules/checkoutSuccess/TitleCheckout'
import { StyledContainer, StyledHr } from './styles/CheckoutSuccess.style'
import { OpenOrdersMessage } from '../molecules/checkoutSuccess/OpenOrdersMessage'

export const CheckoutSuccess = () => {
  const purchaseStatus = useAppSelector((state) => state.purchase.status)

  const isOrderUnderReview = purchaseStatus === OrderStatus.CREDITREVIEW

  useEffect(() => {
    localStorage.setItem('lastModalOpened', '')
  }, [])

  return (
    <StyledContainer>
      <TitleCheckout isOrderUnderReview={isOrderUnderReview} />
      <OrderInstructionsMessage isOrderUnderReview={isOrderUnderReview} />
      {!isOrderUnderReview && <OpenOrdersMessage />}
      <OrderStepper isOrderUnderReview={isOrderUnderReview} />
      <StyledHr />
      <ContactMessage />
      <ReturnButton />
    </StyledContainer>
  )
}
