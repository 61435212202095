import { clubbiStyled } from 'clubbi-ui'

export const StyledMain = clubbiStyled('div')(({ theme }) => ({
  maxWidth: '100%',
  marginBottom: '50px',
  minHeight: '100vh',
  [theme.breakpoints.up('md')]: {
    maxWidth: '100%',
    gap: '15px',
    marginBottom: '100px',
    marginTop: '-25px',
  },
}))

export const StyleContent = clubbiStyled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  padding: '2px 16px 24px 16px',

  [theme.breakpoints.up('sm')]: {
    padding: '16px 32px',
  },
}))
