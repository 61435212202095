import { clubbiStyled, Modal, CloseIcon, ButtonClubbi } from 'clubbi-ui'

export const StyledModal = clubbiStyled(Modal)(({ theme }) => ({
  border: 'none',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  outlineColor: theme.palette.brandWhite.main,
}))

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  width: '90vw',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '90vh',
  overflowY: 'auto',
  position: 'absolute',
  top: '10px',

  background: theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    width: '800px',
  },
}))

export const StyledHeader = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  padding: '10px',
  fontSize: theme.typography.fontSizeM,
  borderBottom: `1px solid ${theme.palette.brandGrey[20]}`,

  background: theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    alignItems: 'center',
    fontSize: theme.typography.fontSizeL,
  },
}))

export const StyledCloseButton = clubbiStyled('button')(({ theme }) => ({
  textDecoration: 'underline',
  border: 'none',

  backgroundColor: theme.palette.brandWhite.main,
}))

export const StyledCloseIcon = clubbiStyled(CloseIcon)(() => ({
  width: '30px',
}))

export const StyledContent = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '10px',
  padding: '10px',

  background: theme.palette.brandWhite.main,
}))

export const StyledContainerButton = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  paddingRight: '10px',
}))

export const StyledErrorMessage = clubbiStyled('p')(({ theme }) => ({
  fontWeight: '700',

  color: theme.palette.brandAlert.main,
}))

export const StyledContainerOptions = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  marginTop: '10px',
  padding: '10px 30px',

  background: theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    alignItems: 'center',
    padding: '10px',
    fontSize: theme.typography.fontSizeL,
  },
}))

export const StyledOptionButton = clubbiStyled('button')<{ bgColor: boolean }>(
  ({ theme, bgColor }) => ({
    textTransform: 'lowercase',
    fontSize: theme.typography.fontSize2XS,
    whiteSpace: 'nowrap',
    padding: '4px 20px',
    border: 'none',
    borderRadius: '5px',

    background: bgColor ? theme.palette.brandPurple[10] : theme.palette.brandGrey[20],

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.fontSizeXXS,
    },
  })
)

export const StyledButton = clubbiStyled(ButtonClubbi)(({ theme }) => ({
  textTransform: 'lowercase',
  fontSize: theme.typography.fontSize2XS,
  margin: '20px 5px',
  alignSelf: 'end',

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))
