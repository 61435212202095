import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BlockedBanner } from '../data/types'

type State = BlockedBanner

const blockedBannerSlice = createSlice({
  name: 'popup',
  initialState: { showBanner: false } as State,
  reducers: {
    setHide: (state, action: PayloadAction<undefined>): State => {
      return { ...state, showBanner: false }
    },
    setShow: (state, action: PayloadAction<undefined>): State => {
      return { ...state, showBanner: true }
    },
  },
})

export const blockedBannerReducer = blockedBannerSlice.reducer
export const blockedBannerActions = blockedBannerSlice.actions
