export const hasHorizontalScroll = (element: any): boolean => {
  return element.scrollWidth > element.clientWidth
}

export const showOrHideArrowsByScroll = (element: any, setRightButton: any, setLeftButton: any) => {
  setRightButton(true)
  setLeftButton(true)

  if (element.scrollLeft <= 20) {
    setLeftButton(false)
  }
  if (element.scrollLeft + element.clientWidth + 20 >= element.scrollWidth) {
    setRightButton(false)
  }
}

export const handleScrollTo = (element: any, direction: 'right' | 'left') => {
  if (direction === 'right') {
    element.scrollTo({
      left: element.scrollLeft - 400,
      behavior: 'smooth',
    })
  } else {
    element.scrollTo({
      left: element.scrollLeft + 400,
      behavior: 'smooth',
    })
  }
}
