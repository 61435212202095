import ReactPanZoom from 'react-image-pan-zoom-rotate'

import {
  StyledCloseModal,
  StyledImageModal,
  StyledModalContainer,
} from './styles/FullScreenImage.style'

interface FullScreenImageProps {
  flyerImageUrl: string
  fullScreenImage: boolean
  setFullScreenImage: React.Dispatch<React.SetStateAction<boolean>>
}

export const FullScreenImage = ({
  fullScreenImage,
  setFullScreenImage,
  flyerImageUrl,
}: FullScreenImageProps) => {
  return (
    <StyledImageModal open={fullScreenImage} onClose={() => setFullScreenImage(false)}>
      <StyledModalContainer>
        <StyledCloseModal onClick={() => setFullScreenImage(false)}>X fechar</StyledCloseModal>
        <ReactPanZoom image={flyerImageUrl} alt="sales-flyer" />
      </StyledModalContainer>
    </StyledImageModal>
  )
}
