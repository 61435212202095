import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  isOpen: boolean
}

export const recommendedSearchSlice = createSlice({
  name: 'recommendedSearch',
  initialState: {
    isOpen: false,
  },
  reducers: {
    setIsOpen: (_state, action: PayloadAction<boolean>): State => {
      return { isOpen: action.payload }
    },
  },
})

export const recommendedSearchReducer = recommendedSearchSlice.reducer
export const recommendedSearchActions = recommendedSearchSlice.actions
