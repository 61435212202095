import { useState, useEffect } from 'react'
import { ButtonClubbi } from 'clubbi-ui'

import { Warnings } from './interfaces'
import { CartItemsChanges, WarningNames } from './CartItemsChanges/CartItemsChanges'
import {
  StyledDivButton,
  StyledHeader,
  StyledList,
  StyledListItem,
  StyledMain,
  StyledModal,
} from './styles/CartWarningModal.style'

export const CartWarningModal = ({
  warnings,
  onDispose = () => null,
}: {
  warnings: Warnings
  onDispose: () => void
}) => {
  const [show, setShow] = useState(true)
  const activeWarnings: [WarningNames, []][] = Object.entries(warnings).filter(
    ([_, value]) => value.length
  ) as [WarningNames, []][]
  const hasWarnings = !!activeWarnings.length
  const handleClose = () => {
    setShow(false)
    onDispose()
  }
  useEffect(() => {
    if (hasWarnings && !show) {
      setShow(true)
    }
  }, [hasWarnings])

  return (
    <StyledMain open={hasWarnings && show} onClose={handleClose}>
      <StyledModal>
        <StyledHeader>
          <p>Houve uma alteração na sua cesta</p>
        </StyledHeader>
        <StyledList>
          {activeWarnings.map(([key]) => (
            <StyledListItem key={key}>
              <CartItemsChanges warnings={warnings} warningName={key} />
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDivButton>
          <ButtonClubbi label="Continuar" onClick={handleClose} typeButton="filled" size="small" />
        </StyledDivButton>
      </StyledModal>
    </StyledMain>
  )
}
