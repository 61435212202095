import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { DELIVERY_TYPE_ENUM } from '../../common/constants'

export const ShippingInfos = createSlice({
  name: 'shippingInfos',
  initialState: {
    shippingType: DELIVERY_TYPE_ENUM.fast,
    completedProgress: false,
  },
  reducers: {
    setShippingType: (
      _state,
      action: PayloadAction<{ shippingType: DELIVERY_TYPE_ENUM; completedProgress: boolean }>
    ) => {
      return {
        shippingType: action.payload.shippingType,
        completedProgress: action.payload.completedProgress,
      }
    },
  },
})

export const shippingInfosReducer = ShippingInfos.reducer
export const shippingInfosActions = ShippingInfos.actions
