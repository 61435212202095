import { clubbiStyled as styled } from 'clubbi-ui'

export const StyledQuantityDiv = styled('div')<{
  quantity: number
  alertInput: boolean
}>(({ theme, quantity, alertInput }) => ({
  height: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: quantity === 0 ? 'end' : 'space-between',
  borderColor: `${
    alertInput ? `${theme.palette.brandAlert[50]}` : `${theme.palette.brandGrey[20]}`
  }`,
  borderRadius: '4px',

  [theme.breakpoints.up('sm')]: {
    width: '222px',
    height: '35px',
  },
}))

export const StyledQuantityButton = styled('button')(({ theme }) => ({
  padding: '0px',
  border: 'none',
  backgroundColor: theme.palette.brandWhite.main,

  ['button:disabled']: {
    pointerEvents: 'none',
  },
}))

export const StyledInputBase = styled('div')`
  text-align: center;
  border: none;
  height: 24px;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  @media (min-width: 600px) {
    padding: 1px 10px;
  }
`

export const StyledInput = styled('input')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  textAlign: 'end',
  border: 'none',
  width: '50%',
  backgroundColor: theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeS,
    height: '20px',
  },
}))

export const StyledInputValue = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '2px',
  marginRight: '12px',
  [theme.breakpoints.up('sm')]: {
    marginTop: '0px',
    marginBottom: '4px',
  },
}))

export const StyledSuffixSpan = styled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  fontWeight: 400,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeS,
    height: '20px',
    marginBottom: '10px',
  },
}))
