import React from 'react'

import {
  StyledBoldP,
  StyledOpenOrdersLink,
  StyledP,
  StyledContainer,
} from './styles/OpenOrdersMessage.style'

export const OpenOrdersMessage = () => {
  return (
    <StyledContainer>
      <StyledBoldP>Precisa fazer alterações no seu pedido?</StyledBoldP>
      <StyledP>
        {'Acesse a área de '}
        <StyledOpenOrdersLink to={'/pedidos-em-aberto'}>Pedidos em Aberto</StyledOpenOrdersLink>
      </StyledP>
    </StyledContainer>
  )
}
