import { clubbiStyled } from 'clubbi-ui'

export const StyledSelectedButton = clubbiStyled('button')<{ selected: boolean }>(
  ({ theme, selected }) => ({
    fontSize: theme.typography.fontSize3XS,
    fontWeight: '600',
    borderRadius: '60px',
    border: 'none',
    width: '100px',
    height: '20px',
    padding: '10px',
    background: selected ? theme.palette.brandSuccess[70] : theme.palette.brandPurple[60],
    color: theme.palette.brandWhite.main,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.fontSizeXXS,
      width: '110px',
    },
  })
)
