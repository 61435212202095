import { clubbiStyled, Drawer } from 'clubbi-ui'

export const StyledHeader = clubbiStyled('div')(({ theme }) => ({
  width: '240px',
  height: '124px',
  paddingTop: '88px',
  paddingLeft: '20px',
  fontWeight: 'bold',
  fontSize: theme.typography.fontSizeS,

  background: theme.palette.brandPurple[60],
  color: theme.palette.brandWhite.main,
}))

export const StyledMenu = clubbiStyled(Drawer)(({ theme }) => ({
  display: 'block',

  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width: '100%',
  },

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))
