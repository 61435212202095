import { clubbiStyled } from 'clubbi-ui'

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(6),
  justifyContent: 'flex-start',
  paddingTop: '0',
  gap: theme.spacing(5),
  fontFamily: `'${theme.typography.fontFamily}'`,
  fontStyle: 'normal',
  fontSize: theme.typography.fontSizeS,
  lineHeight: '124.4%',
  marginTop: theme.spacing(10),
  width: '100%',
}))

export const StyledHr = clubbiStyled('hr')(({ theme }) => ({
  color: 'black',
  marginTop: theme.spacing(8),
  marginBottom: '0',
  width: '100%',
}))
