import { useEffect, useState } from 'react'
import { useAppSelector } from '../../common/hooks'
import { getPromotionsSuggestions } from '../../services/productApi'
import { Product } from '../../store/data/types'
import { H3, LinkBackHome } from '../atoms'
import { PromptLogin } from '../molecules/PromptLogin'
import { StyledSpinner } from './LoadablePage'
import { ProductCard } from '../organisms/product/ProductCard'
import { clubbiStyled as styled } from 'clubbi-ui'

export const StyledPromotionSection = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brandGrey.light,
}))

export const StyledTitle = styled(H3)(({ theme }) => ({
  margin: '10px',
  fontWeight: '500',
  textAlign: 'left',
}))

export const StyledContainerList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '16px',
  paddingLeft: '20px',
  marginBottom: '100px',
}))

export const StyledContainerTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 0,
  paddingRight: '4rem',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    paddingRight: 0,
  },
}))

export const PromotionalSection = () => {
  const [products, setProducts] = useState<Product[] | []>([])
  const isLogged = useAppSelector((state) => !!state.session.merchantCode)
  const [merchantCode, regionId] = useAppSelector((state) => [
    state.session.merchantCode,
    state.session.regionId,
  ])

  const fetchPromotions = async () => {
    await getPromotionsSuggestions(merchantCode!, regionId!).then((response) => {
      setProducts(response)
    })
  }

  useEffect(() => {
    fetchPromotions()
  }, [isLogged])

  if (!isLogged) {
    return (
      <StyledPromotionSection>
        <PromptLogin />
      </StyledPromotionSection>
    )
  }

  return (
    <StyledPromotionSection>
      {products.length ? (
        <StyledContainerList>
          <StyledContainerTitle>
            <StyledTitle>Suas ofertas especiais</StyledTitle>
            <LinkBackHome />
          </StyledContainerTitle>
          {products.map((product) => (
            <ProductCard product={product} key={product.id} />
          ))}
        </StyledContainerList>
      ) : (
        <StyledSpinner animation={'border'} role={'spinbutton'}>
          <span className="sr-only">Carregando...</span>
        </StyledSpinner>
      )}
    </StyledPromotionSection>
  )
}
