import { useDispatch } from 'react-redux'

import { quantityToCompleteComboCalc } from '../../../common/utils'
import { CartItem } from '../../../store/data/types'
import { cartActions } from '../../../store/slices/cart'
import { useAppSelector } from '../../../common/hooks'

import { StyledBadgeContainer, StyledBadgeText } from './styles/ComboBadge.style'

export const ComboBadge = ({ item }: { item: CartItem }) => {
  const dispatch = useDispatch()
  const { merchantCode, clubberEmail } = useAppSelector((state) => state.session)

  const quantity = quantityToCompleteComboCalc(item)

  const cartChange = {
    context: 'addToCart',
    quantity: item.quantity + quantity,
    previousQuantity: item.quantity,
  }

  const setCompleteCombo = () => {
    if (merchantCode) {
      dispatch(
        cartActions.setQuantity({
          product: item.product,
          cartChange,
          merchantCode,
          ctx: 'popupGetMoreForLess',
          section: 'popupGetMoreForLess',
          clubberEmail,
        })
      )
    }
  }

  return (
    <StyledBadgeContainer data-testid="badge-button" onClick={setCompleteCombo}>
      <StyledBadgeText data-testid="badge-text">
        <span>Adicione mais </span>
        <strong>{quantity}</strong>
        <span>caixas para levar o combo .</span>
      </StyledBadgeText>
    </StyledBadgeContainer>
  )
}
