import { useState } from 'react'
import { themeClubbi } from 'clubbi-ui'
import { useHistory } from 'react-router'
import gtm from '../../../common/gtmTracker'

import { getShoppingList } from '../../../services/productApi'
import { useAppDispatch, useAppSelector } from '../../../common/hooks'
import { Spinner } from '../../atoms/Spinner'
import { searchListActions } from '../../../store/slices/searchList'

import {
  StyledModal,
  StyledLoading,
  StyledLoadingMessage,
  StyledContainer,
  StyledCloseButton,
  StyledCloseIcon,
  StyledLine,
  StyledTitle,
  StyledDescription,
  StyledTextAreaContainer,
  StyledSearchImage,
  StyledTextArea,
  StyledButton,
  StyledNextImage,
  StyledCloseMessage,
} from './styles/shoppingListModal.style'

export const Modal = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [inputValue, setInputValue] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const { merchantCode, regionId, clubberEmail } = useAppSelector((state) => state.session)
  const modalIsOpen = useAppSelector((state) => state.searchList.modalIsOpen)
  const { shoppingList } = useAppSelector((state) => state.searchList)

  const fetchShoppingList = async (userInput: string) => {
    const treatedShoppingListResponse = await getShoppingList(merchantCode!, regionId!, userInput)
    dispatch(searchListActions.setCorridors(treatedShoppingListResponse))
    gtm.triggerShoppingListTerms(merchantCode, userInput, clubberEmail)
  }

  const handleChange = (event: { target: { value: string } }) => {
    const { value } = event.target
    setInputValue(value)
  }

  const getQuotaAndRedirectToShoppingList = async () => {
    setLoading(true)
    await fetchShoppingList(inputValue)

    if (shoppingList.length) {
      setLoading(true)
      setInputValue('')
    }
    setLoading(false)
    dispatch(searchListActions.setIsOpen(false))
    history.push('/shopping-list')
  }

  const closeModal = () => {
    setInputValue('')
    dispatch(searchListActions.setIsOpen(false))
  }

  return (
    <StyledModal open={modalIsOpen} onClose={closeModal}>
      {loading ? (
        <StyledLoading>
          <StyledLoadingMessage>aguarde, gerando sua lista...</StyledLoadingMessage>
          <Spinner />
        </StyledLoading>
      ) : (
        <>
          <StyledContainer>
            <StyledCloseButton onClick={closeModal}>
              <StyledCloseIcon color={themeClubbi.palette.brandGrey.main} />
            </StyledCloseButton>
            <StyledLine />
            <StyledTitle>
              Faça uma cotação <br /> em segundos
            </StyledTitle>
            <StyledDescription>
              Digite ou cole a sua lista de compras no campo abaixo e nós faremos uma busca rápida
              para você.
            </StyledDescription>
            <StyledTextAreaContainer>
              <StyledSearchImage src={'/img/search-icon.svg'} alt="Ícone de busca" />
              <StyledTextArea
                rows={200}
                cols={42}
                value={inputValue}
                onChange={handleChange}
                placeholder={`Digite ou cole a sua lista de compras aqui`}
                formatError={false}
              />
            </StyledTextAreaContainer>
            <StyledButton
              onClick={getQuotaAndRedirectToShoppingList}
              disabled={inputValue.trim() === ''}
            >
              fazer cotação
              <StyledNextImage src={'/img/vector-button.png'} alt=">" />
            </StyledButton>
            <StyledCloseMessage onClick={closeModal}>não tenho interesse</StyledCloseMessage>
          </StyledContainer>
        </>
      )}
    </StyledModal>
  )
}
