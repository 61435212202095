import { dateFromTimestamp, groupInvoicesByDate } from '../../../common/utils'
import React from 'react'
import { StyledGroupedInvoiceCards, StyledDate } from './styles/GroupedInvoiceSummaryCards.style'
import { InvoiceSummaryCard } from './InvoiceSummaryCard'
import { InvoicesProps } from '../../templates/Invoices'

export const GroupedInvoiceSummaryCards = ({ invoices }: InvoicesProps) => {
  const groupedInvoices = groupInvoicesByDate(invoices)

  return (
    <StyledGroupedInvoiceCards>
      {Object.keys(groupedInvoices).map((dateKey) => {
        return (
          <div key={dateKey}>
            <StyledDate>{dateFromTimestamp(dateKey)}</StyledDate>
            {groupedInvoices[dateKey].map((invoice, index) => {
              return <InvoiceSummaryCard key={index} invoice={invoice} />
            })}
          </div>
        )
      })}
    </StyledGroupedInvoiceCards>
  )
}
