import { ScrollButton, clubbiStyled } from 'clubbi-ui'

export const StyledDrawerRecommended = clubbiStyled('div')(({ theme }) => ({
  width: '23rem',
  padding: '8px',
  [theme.breakpoints.up('sm')]: {
    width: '100rem',
  },

  [theme.breakpoints.between(400, 600)]: {
    width: '25rem',
  },

  [theme.breakpoints.between(600, 1000)]: {
    width: '34rem',
  },
  [theme.breakpoints.between(1000, 1300)]: {
    width: '40rem',
  },
  [theme.breakpoints.between(1300, 1900)]: {
    width: '70rem',
  },
}))

export const StyledContainer = clubbiStyled('div')(
  () =>
    ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      width: '100%',
      height: '450px',
      paddingLeft: '20px',
    } as any)
)

export const StyledMainContainer = clubbiStyled('div')(
  () =>
    ({
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'start',
      flexDirection: 'column',
      flexWrap: 'wrap',
    } as any)
)

export const StyleMainContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}))

export const StyledDrawer = clubbiStyled('div')(({ theme }) => ({
  backgroundColor: '#f7f7f7',
  padding: '8px 0 0 20px ',
  color: theme.palette.brandGrey[90],

  strong: {
    fontSize: theme.typography.fontSizeS,
  },

  [theme.breakpoints.up('sm')]: {
    padding: '10px 0 0 26px',
    strong: {
      fontSize: theme.typography.fontSizeM,
    },
  },
}))

export const StyledRecent = clubbiStyled('button')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXS,
  fontWeight: '400',
  lineHeight: '22px',
  border: 'none',
  background: 'transparent',
  width: '100%',
  textAlign: 'left',
  padding: '10px 0 10px 0',

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeS,
  },
}))

export const StyledCorridor = clubbiStyled('div')(({ theme }) => ({
  marginTop: '15px',
  width: '100%',
}))

export const StyledContainerList = clubbiStyled('div')(({ theme }) => ({
  maxWidth: '100%',
  height: '320px',
  display: 'flex',
  flexWrap: 'nowrap',
  gap: '4px',
  overflow: 'auto',

  '&::-webkit-scrollbar': {
    width: '5px',
    height: '5px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.brandGrey[40],
    borderRadius: '8px',
  },

  '&::-webkit-scrollbar-track': {
    height: '1px',
    borderRadius: '8px',
    background: 'transparent',
  },

  '&:hover::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.brandPurple[60],
  },

  [theme.breakpoints.up('sm')]: {
    width: '100%',
    height: '460px',
    gap: '8px',
  },
}))

export const StyledSpinner = clubbiStyled('div')(({ theme }) => ({
  width: '900px',
  height: '320px',
  display: 'flex',
  overflow: 'auto',

  [theme.breakpoints.up('sm')]: {
    width: '100%',
    height: '460px',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export const StyledTitle = clubbiStyled('div')(({ theme }) => ({
  fontSize: '20px',
  fontFamily: 'Baloo 2',
  fontStyle: 'normal',
  fontWeight: '600',
}))

export const StyledArrowButton = clubbiStyled(ScrollButton)(({ theme, direction }) => ({
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    background: theme.palette.brandGrey[100],
    display: 'block',
    position: 'absolute',
    right: direction === 'right' && '44px',
  },
}))

export const StyledDiv = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}))
