import { ScrollButton, clubbiStyled } from 'clubbi-ui'

export const StyledContainerList = clubbiStyled('div')(({ theme }) => ({
  width: '100%',
  height: '340px',
  display: 'flex',
  flexWrap: 'nowrap',
  gap: '4px',
  overflow: 'auto',

  [theme.breakpoints.up('sm')]: {
    height: '460px',
    gap: '10px',
  },
}))

export const StyledArrowButton = clubbiStyled(ScrollButton)(({ theme, direction }) => ({
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    background: theme.palette.brandGrey[100],
    display: 'block',
    position: 'absolute',
    right: direction === 'right' && '20px',
    left: direction === 'left' && '20px',
  },
}))
