import { clubbiStyled } from 'clubbi-ui'

export const StyledFooter = clubbiStyled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  height: '48px',
  zIndex: 9998,
  backgroundColor: theme.palette.brandWhite.main,
  width: '100%',
  display: 'flex',
  userSelect: 'none',
}))

export const StyledIconContainer = clubbiStyled('div')<{
  isSelected: boolean
  hasMission: boolean
}>(({ theme, isSelected, hasMission }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: hasMission ? '25%' : '33%',
  borderTop: isSelected
    ? `1px solid ${theme.palette.brandPurple[60]}`
    : `1px solid ${theme.palette.brandGrey[20]}`,
}))
