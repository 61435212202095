import { apiPut } from './api'
import { getMerchantCode } from './productApi'
import { PutResult } from './ratingApi'

export const putMissionsSelected = async (
  missionId: number,
  selected: boolean
): Promise<PutResult> => {
  const response = await apiPut(`/mission/selected/${missionId}`, {
    selected,
    merchant_code: getMerchantCode(),
  })

  if (response.ok) {
    return PutResult.SUCCESS
  }
  return PutResult.UNEXPECTED
}
