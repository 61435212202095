import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CategoryBanner, themeClubbi } from 'clubbi-ui'

import { useAppSelector } from '../../common/hooks'
import { LinkBackHome } from '../atoms'
import { Spinner } from '../atoms/Spinner'
import { ProductCard } from '../organisms/product/ProductCard'
import { Product } from '../../store/data/types'
import { CUSTOM_PRODUCTS_QUANTITY_PER_PAGE } from '../../common/constants'
import { getCustomProducts } from '../../services/productApi'
import { StyledLinkToHome } from '../atoms/styles/LinkBackHomeDiv.style'
import { StyleContent, StyledContainerList, StyledMain } from './styles/ProductsAva.style'

export const ProductsAva = () => {
  const [products, setProducts] = useState<Product[] | []>([])
  const [merchantCode] = useAppSelector(({ session }) => [session.merchantCode])

  const productsStore = useAppSelector((state) => state.productsListAva)

  const history = useHistory()

  const getProducts = async () => {
    const customProducts = await getCustomProducts(CUSTOM_PRODUCTS_QUANTITY_PER_PAGE)
    setProducts(customProducts)
  }

  useEffect(() => {
    if (!merchantCode) {
      history.replace('/')
    }

    if (productsStore.length === 0) {
      getProducts()
    } else {
      setProducts(productsStore)
    }
  }, [merchantCode])

  return (
    <StyledMain>
      {products ? (
        <>
          <StyledLinkToHome>
            <LinkBackHome />
          </StyledLinkToHome>
          <StyleContent>
            <CategoryBanner
              description="Aproveite as melhores ofertas para o seu mercadinho"
              title="Mais Economia"
              image="img/mais-economia.png"
              background={themeClubbi.palette.brandPurple[10]}
            />
          </StyleContent>
          <StyledContainerList>
            {products.map((product) => (
              <ProductCard product={product} key={product.id} label={'mais-economia'} />
            ))}
          </StyledContainerList>
        </>
      ) : (
        <Spinner />
      )}
    </StyledMain>
  )
}
