import { Product } from '../../store/data/types'
import { ProductsGrid } from '../templates/ProductsGrid'
import { LoadablePage, LoadablePageProps } from './LoadablePage'
import { getBestSellers } from '../../services/productApi'
import React, { memo } from 'react'
import { LinkBackHome } from '../atoms'
import { StyledLinkToHome } from '../atoms/styles/LinkBackHomeDiv.style'

export const bestSellersFetch = async (): Promise<Props> => {
  const products = await getBestSellers()
  return { products }
}

interface Props {
  products: Product[]
}

const _LinkHome = memo(() => (
  <StyledLinkToHome>
    <LinkBackHome />
  </StyledLinkToHome>
))

const _Grid = ({ products }: Props) => {
  const header = {
    node: <_LinkHome />,
    height: 63,
  }

  return <ProductsGrid context={['ofertoes']} data={products} header={header} />
}

export const BestSellers = (props: LoadablePageProps<Props>) => {
  return (
    <LoadablePage {...props} dataFetch={bestSellersFetch}>
      {_Grid}
    </LoadablePage>
  )
}
