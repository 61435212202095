import { Spinner, clubbiStyled } from 'clubbi-ui'

export const StyledMainContainer = clubbiStyled('div')(({ theme }) => ({
  minHeight: '100vh',
  minWidth: '100vw',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '14px',
  paddingBottom: '50px',

  background: theme.palette.brandYellow[50],
}))

export const StyledPreview = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  width: '90%',
  minHeight: '36px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: '5px',
  padding: '0 12px 20px 12px',

  background: theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXS,
    width: '40%',
    padding: '0 20px 30px 20px',
  },
}))

export const StyledDescription = clubbiStyled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '10px 0',

  color: theme.palette.brandGrey[100],
}))

export const StyledTitle = clubbiStyled('div')(({ theme }) => ({
  fontWeight: 700,
  marginRight: '8px',

  color: theme.palette.brandGrey[100],
}))

export const StyledSaveButton = clubbiStyled('button')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '5px',
  border: 'none',
  width: '26px',
  fontWeight: 700,

  background: theme.palette.brandWhite.main,
  color: theme.palette.brandPurple[60],
}))

export const StyledNotFound = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXS,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',

  background: theme.palette.brandWhite.main,
  color: theme.palette.brandGrey[80],
}))

export const StyledSpinner = clubbiStyled(Spinner)(({ theme }) => ({
  marginTop: '50px',
  marginBottom: '1000px',
}))

export const StyledImageSalesFlyer = clubbiStyled('img')(({ theme }) => ({
  width: '100%',
}))

export const StyledContainerFlyer = clubbiStyled('div')(({ theme }) => ({
  width: '1080px',
  height: '1920px',
}))
