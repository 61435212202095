import { clubbiStyled, List, ListItem, Modal } from 'clubbi-ui'

export const StyledModal = clubbiStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brandWhite.main,
  width: '360px',
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '7px',
  padding: '6px',
  border: 'none',
  [theme.breakpoints.up('sm')]: {
    width: '480px',
  },
}))

export const StyledMain = clubbiStyled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: 'none',
})

export const StyledHeader = clubbiStyled('div')(({ theme }) => ({
  border: 'none',
  paddingBottom: '0px',
  marginTop: '8px',
  fontSize: theme.typography.fontSizeXS,
  display: 'flex',
  gap: '26px',
  justifyContent: 'space-between',
  p: {
    fontWeight: '700',
    fontSize: theme.typography.fontSizeXS,
    margin: '0',
  },
  [theme.breakpoints.up('sm')]: {
    gap: '100px',
    marginTop: '15px',
  },
}))

export const StyledList = clubbiStyled(List)({
  display: 'flex',
  flexDirection: 'column',
})

export const StyledListItem = clubbiStyled(ListItem)({
  padding: '0px',
})

export const StyledDivButton = clubbiStyled('div')({
  marginBottom: '15px',
  display: 'flex',
  justifyContent: 'end',
  width: '85%',
  fontWeight: '700',
})
