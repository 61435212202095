import { clubbiStyled } from 'clubbi-ui'

export const StyledPointer = clubbiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '15px',
  height: '40px',
  position: 'absolute',
  zIndex: 1000,
  transform: 'rotate(180deg)',
  top: '65px',
}))

export const StyledLine = clubbiStyled('div')(({ theme }) => ({
  width: '2px',
  height: '30px',

  background: theme.palette.brandPurple[90],
}))

export const StyledCircle = clubbiStyled('div')(({ theme }) => ({
  width: '10px',
  height: '10px',
  borderRadius: '50%',

  background: theme.palette.brandPurple[90],
}))
