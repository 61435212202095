import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Categories } from '../data/types'

type State = Categories[]

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: [] as unknown as State,
  reducers: {
    categories: (_state, action: PayloadAction<Categories[]>): State => {
      return action.payload
    },
  },
})

export const categoriesReducer = categoriesSlice.reducer
export const categoriesActions = categoriesSlice.actions
