import {
  StyledContainer,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from './styles/DeliveryDays.style'

import { themeClubbi, useMediaQuery } from 'clubbi-ui'
import { useAppDispatch, useAppSelector } from '../../../common/hooks'
import { profileActions } from '../../../store/slices/profile'
import { MAX_RESTRICTION_DAYS, MIN_DELIVERY_DAYS, deliveryDaysData } from './constants'
import { SubSectionTitle } from './SubSectionTitle'

export const DeliveryDays = () => {
  const { merchantDeliveryRestriction } = useAppSelector((state) => state.profile)
  const isWeekdayInitialLetter = useMediaQuery(themeClubbi.breakpoints.down('lg'))
  const dispatch = useAppDispatch()

  const handleChange = (event: React.MouseEvent<HTMLElement>, newRestrictionDays: number[]) => {
    dispatch(
      profileActions.updateMerchantDeliveryRestrictions({
        ...merchantDeliveryRestriction,
        deliveryDaysRestriction: newRestrictionDays,
      })
    )
  }
  return (
    <StyledContainer>
      <SubSectionTitle
        title="Dias"
        firstSubtitle="Selecione pelo menos "
        highlightedText={`${MIN_DELIVERY_DAYS} dias`}
        isFulfilled={
          (merchantDeliveryRestriction?.deliveryDaysRestriction?.length ?? 0) <=
          MAX_RESTRICTION_DAYS
        }
        secondSubtitle=" para receber
        mercadoria."
      />
      <StyledToggleButtonGroup
        value={merchantDeliveryRestriction?.deliveryDaysRestriction}
        onChange={handleChange}
        fullWidth
      >
        {deliveryDaysData.map(({ value, label, mobileLabel }) => (
          <StyledToggleButton key={`${value} - ${label}`} value={value}>
            {isWeekdayInitialLetter ? mobileLabel : label}
          </StyledToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </StyledContainer>
  )
}
