import { clubbiStyled } from 'clubbi-ui'

export const StyledHeader = clubbiStyled('div')(({ theme }) => ({
  width: '100%',
  height: '72px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 15px',

  background: theme.palette.brandPurple[60],

  [theme.breakpoints.up('sm')]: {
    padding: '0 32px',
  },
}))

export const StyledLogo = clubbiStyled('img')(({ theme }) => ({
  width: '60px',

  [theme.breakpoints.up('sm')]: {
    width: '80px',
  },
}))

export const StyledTitle = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXXS,
  fontWeight: 700,
  lineHeight: '22px',
  textAlign: 'center',

  color: theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeM,
  },
}))
