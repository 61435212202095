import { ReactNode } from 'react'

import { Product } from '../../store/data/types'
import { ProductCard } from '../organisms/product/ProductCard'
import { StyledSpinner } from '../routes/LoadablePage'
import { StyledPromotionSection, StyledContainerList } from './styles/ProductsList.style'

export interface Header {
  height?: number
  node: ReactNode
}

interface Props {
  data: Product[]
  header?: Header
  className?: string
  context?: string[]
}

export const ProductsGrid = ({ data, header, context = [], ...props }: Props) => {
  const products = data
  if (!products) {
    return null
  }

  return (
    <StyledPromotionSection data-testid="grid-container">
      {header!.node}
      {products.length ? (
        <StyledContainerList>
          {products.map((product) => (
            <ProductCard product={product} key={product.id} label={context[0]} />
          ))}
        </StyledContainerList>
      ) : (
        <StyledSpinner animation={'border'} role={'spinbutton'}>
          <span className="sr-only">Carregando...</span>
        </StyledSpinner>
      )}
    </StyledPromotionSection>
  )
}
