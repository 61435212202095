import { useEffect, useRef, useState } from 'react'
import { Spinner, themeClubbi } from 'clubbi-ui'

import { Product } from '../../../store/data/types'
import { ProductCard } from '../../organisms/product/ProductCard'
import { useAppSelector } from '../../../common/hooks'
import { handleScrollTo, showOrHideArrowsByScroll } from '../../../common/ScrollUtils'
import { CUSTOM_PRODUCTS_QUANTITY } from '../../../common/constants'
import { StyledArrowButton, StyledContainerList } from './DrawerCorridor.style'
import { getCustomProducts } from '../../../services/productApi'

export const DrawerCorridor = () => {
  const [products, setProducts] = useState<Product[] | []>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { merchantCode } = useAppSelector((state) => state.session)
  const { isOpen } = useAppSelector((state) => state.recommendedDrawer)

  const [leftButton, setLeftButton] = useState(false)
  const [rightButton, setRightButton] = useState(true)

  const getProducts = async () => {
    setLoading(true)
    const customProducts = await getCustomProducts(CUSTOM_PRODUCTS_QUANTITY)
    setProducts(customProducts)
    setLoading(false)
  }

  useEffect(() => {
    if (isOpen) {
      getProducts()
    }
  }, [merchantCode, isOpen])

  const recommendedDrawer = useRef(null)

  const handleScrollBar = (_e: any) => {
    const element: any = recommendedDrawer.current
    showOrHideArrowsByScroll(element, setRightButton, setLeftButton)
  }

  const handleScrollToRight = () => {
    const element: any = recommendedDrawer.current
    handleScrollTo(element, 'right')
  }

  const handleScrollToLeft = () => {
    const element: any = recommendedDrawer.current
    handleScrollTo(element, 'left')
  }

  return (
    <>
      {leftButton && (
        <StyledArrowButton
          direction="left"
          onClick={handleScrollToRight}
          color={themeClubbi.palette.brandWhite.main}
        />
      )}
      {products.length && !loading ? (
        <StyledContainerList
          id="recommended-drawer"
          ref={recommendedDrawer}
          onScroll={handleScrollBar}
        >
          {products.map((product) => (
            <ProductCard product={product} key={product.id} label={'recommended Drawer'} />
          ))}
        </StyledContainerList>
      ) : (
        <Spinner />
      )}

      {rightButton && (
        <StyledArrowButton
          direction="right"
          onClick={handleScrollToLeft}
          color={themeClubbi.palette.brandWhite.main}
        />
      )}
    </>
  )
}
