import React from 'react'
import { Badge } from 'react-bootstrap'
import styled from 'styled-components'

const ProductLimitBadgeStyled = styled(Badge)`
  position: absolute;
  background-color: #626266 !important;
  color: white;
  margin-top: 4px;
  font-size: 11px;

  right: 2px;
  bottom: 2px;

  ${({ variant }) => {
    const split = variant?.split(' ')
    let style = ''

    if (split?.includes('large')) {
      style += `
        right: 15px;
        bottom: -14px;
      `
    }

    if (split?.includes('small')) {
      style += `
        right: unset;
        left: 8px;
        bottom: 4px;
      `
    }

    return style
  }}
`
interface ProductLimitBadgeProps {
  label: string
  variant: string | undefined
}

export const ProductLimitBadge = ({ label, variant }: ProductLimitBadgeProps) => (
  <ProductLimitBadgeStyled variant={variant}>{label}</ProductLimitBadgeStyled>
)
