import { clubbiStyled } from 'clubbi-ui'

const positionTooltipPointer = [
  {
    top: '164px',
    left: '18px',
    transform: 'rotate(180deg)',
  },
  {
    top: '-30px',
    left: '30px',
  },
  {
    top: '-30px',
    right: '20px',
  },
  {
    top: '164px',
    right: '18px',
    transform: 'rotate(180deg)',
  },
]

const positionTooltipPointerDesktop = [
  {
    top: '-30px',
    left: '18px',
  },
  {
    top: '-30px',
    left: '30px',
  },
  {
    top: '-30px',
    left: '50%',
  },
  {
    top: '-40px',
    right: '50px',
  },
]
export const StyledPointer = clubbiStyled('div')<{ step: number; isDesktop: boolean }>(
  ({ step, isDesktop }) => ({
    ...(isDesktop
      ? { ...positionTooltipPointerDesktop[step] }
      : { ...positionTooltipPointer[step] }),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '15px',
    height: '40px',
    position: 'absolute',
    zIndex: 1000,
  })
)

export const StyledLine = clubbiStyled('div')(({ theme }) => ({
  width: '2px',
  height: '30px',
  background: theme.palette.brandPurple[90],
}))

export const StyledCircle = clubbiStyled('div')(({ theme }) => ({
  width: '10px',
  height: '10px',
  borderRadius: '50%',

  background: theme.palette.brandPurple[90],
}))
