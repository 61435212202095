// TODO: Don't repeat yourself
import React, { memo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from '../../common/hooks'
import { Product } from '../../store/data/types'
import { getCustomPromotions } from '../../services/productApi'
import { LoadablePage, LoadablePageProps } from './LoadablePage'
import { ProductsGrid } from '../templates/ProductsGrid'
import { StyledLinkToHome } from '../atoms/styles/LinkBackHomeDiv.style'
import { LinkBackHome } from '../atoms'

export interface CustomPromotionsProduct {
  products: Product[]
}

const _LinkHome = memo(() => (
  <StyledLinkToHome>
    <LinkBackHome />
  </StyledLinkToHome>
))

const _Grid = ({ products }: CustomPromotionsProduct) => {
  const header = {
    node: <_LinkHome data-testid="linktohome" />,
    height: 63,
  }

  return (
    <ProductsGrid
      context={['CustomPromotions']}
      data={products}
      header={header}
      data-testid="grid-container"
    />
  )
}

export const customPromotionsFetch = async (): Promise<CustomPromotionsProduct> => {
  const data = await getCustomPromotions()
  return {
    products: data,
  }
}

export const CustomPromotions = (props: LoadablePageProps<CustomPromotionsProduct>) => {
  const merchantCode = useAppSelector((state) => state.session.merchantCode)
  const history = useHistory()

  useEffect(() => {
    if (!merchantCode) {
      history.replace('/')
    }
  }, [merchantCode])

  const enrichedProps = {
    dataFetch: customPromotionsFetch,
    ...props,
  }

  return <LoadablePage {...enrichedProps}>{_Grid}</LoadablePage>
}
