import styled from 'styled-components'
import { Button, Tab } from 'react-bootstrap'

export const Border = styled.div`
  width: 98%;
  margin: 0 auto;
  margin-bottom: 20px;
  border: 1px solid #04153b;
`
export const BorderBold = styled.div`
  width: 98%;
  margin: 0 auto;
  border: 2px solid #04153b;
`
export const Thanks = styled.p`
  text-align: center;
  font-size: 14px;
  color: #04153b;
  font-weight: bold;
  margin-top: 15px;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  color: #04153b;
  padding: 20px;
  background-color: #fff;
  margin: 0 auto;
  border-bottom: 3px solid #04153b;

  img {
    width: 134px;
    height: 40px;
  }
`
export const Product = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 5px;
  border-radius: 0px;
  text-align: start;
  align-items: center;
  display: flex;

  &.border-outside {
    align-items: flex-end;
    background-color: #fff;
    @media (max-width: 802px) {
      width: auto;
    }
  }
  &.wrapper {
    padding: 10px;
    height: 30px;
    background-color: #f7e4b2;
  }
`
export const Section = styled.p`
  font-size: 14px;
  color: #04153b;
  font-weight: bold;
  margin-top: 15px;
`

export const InvoiceContainer = styled.div`
  margin-left: -12px;

  .row {
    margin-left: 8px !important;
  }

  .container {
    width: 1500px;
    padding-right: 25px;
    padding-left: 25px;
    @media (max-width: 802px) {
      width: auto;
    }
  }

  @media (min-width: 767px) {
    .container {
      max-width: 800px;
    }
  }

  #tabs-tabpane-v2 {
    width: 800px;
    @media (max-width: 802px) {
      width: auto;
    }
  }

  .col {
    padding-left: 0px;
  }
`

export const BlockLeft = styled.div`
  float: left;
  width: 70%;
`
export const BlockRight = styled.div`
  float: right;
  width: 20%;
`

export const Message = styled.div`
  margin-left: 30px;

  h3 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
    line-height: 1.2;
  }
`

export const InformationBottom = styled.div`
  width: 100%;
  height: 350px;
  background-color: #fff;
  margin: 0 auto;
  padding-top: 20px;
`

export const ContainerBottom = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  color: #04153b;
  font-weight: bold;
`
export const Quantity = styled.div`
  text-align: center;
  align-items: end;
  justify-content: end;
  margin-left: 10px;
  font-size: 12px;
  flex: 1;
`

export const QuantityPrime = styled.div`
  text-align: center;
  margin-left: 10px;
  font-weight: 600;
  font-size: 13px;
  color: #04153b;
  flex: 1;
`

export const Ean = styled.div`
  margin-left: 10px;
  font-size: 12px;
  flex: 3;

  span {
    margin-left: 10px;
    align-items: center;
  }
`

export const EanPrime = styled.div`
  margin-left: 10px;
  align-items: flex-end;
  font-weight: 600;
  font-size: 13px;
  flex: 8;
`

export const Description = styled.div`
  margin-left: 10px;
  font-size: 12px;
  flex: 8;

  span {
    margin-left: 10px;
    align-items: center;
  }
`

export const DescriptionSubstitudedItem = styled.div`
  margin-left: -30px;
  font-size: 11px;

  span {
    margin-left: -30px;
    align-items: center;
    color: #ff4a55;
  }
`

export const DescriptionPrime = styled.div`
  margin-left: 10px;
  align-items: flex-end;
  font-weight: 600;
  font-size: 13px;
  flex: 8;
`

export const Body = styled.div`
  padding: 0;
  text-align: center;
  margin: auto;
  max-width: 800px;
  width: auto;
`

export const Information = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: start;
  align-items: flex-end;
  padding: 20px;
  background-color: #fff;
  margin: 0 auto;
`

export const InformationHeaderAddress = styled.div`
  margin-bottom: 0px;
  text-align: start;
  flex: 4;

  h4 {
    font-size: 15px;
    color: #04153b;
    font-weight: bolder;
  }

  p {
    margin-left: 35px;
    font-size: 15px;
    color: #04153b;
  }
`

export const WrapElement = styled.div`
  color: ${(props) => props.theme.color};
  background: ${(props) => props.theme.bg};
  width: 100%;
  margin: 0 auto;
`

export const StyledTab = styled(Tab)`
  width: auto;
  margin: auto;
`

export const StyledButton = styled(Button)`
  width: auto;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Communication = styled.div`
  background-color: white;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
`

export const EndCommunication = styled.div`
  background-color: white;
  padding: 20px;
  text-align: center;
`
