export enum Environment {
  Production,
  Development,
  Staging,
}

export function getEnvironment() {
  const url = new URL(process.env.REACT_APP_PRODUCT_API_URL!)
  const loc = url.hostname
  if (loc.includes('localhost')) {
    return Environment.Development
  }
  if (loc.includes('staging')) {
    return Environment.Staging
  } else {
    return Environment.Production
  }
}
