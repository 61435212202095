import { ToggleButton, ToggleButtonGroup, clubbiStyled } from 'clubbi-ui'

export const StyledToggleButton = clubbiStyled(ToggleButton)<{ isFiscal: boolean }>(
  ({ theme, isFiscal }) => ({
    textTransform: 'none',
    width: isFiscal ? '50%' : 'fit-content',
    padding: '4px 10px',
    fontSize: '12px',
    fontWeight: 400,
    borderRadius: '4px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: isFiscal ? 'flex-start' : 'center',
    gap: '4px',
    border: `1px solid ${theme.palette.brandGrey[20]} !important`,
    marginLeft: '1px !important',
    color: theme.palette.brandGrey[100],
    backgroundColor: theme.palette.brandWhite.main,
    '.payment-icon': {
      color: theme.palette.brandGrey[60],
    },
    '&.Mui-selected': {
      border: `1px solid ${theme.palette.brandPurple[60]} !important`,
      backgroundColor: theme.palette.brandWhite.main,
      fontWeight: 700,
      color: theme.palette.brandBlack.main,
      '.payment-icon': {
        color: theme.palette.brandBlack.main,
      },
    },
    '&.MuiButtonBase-root:hover': {
      backgroundColor: theme.palette.brandGrey[10],
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
    },
  })
)

export const StyledToggleButtonGroup = clubbiStyled(ToggleButtonGroup)(({ theme }) => ({
  gap: '10px',
}))

export const StyledSubLabel = clubbiStyled('span')(({ theme }) => ({
  color: theme.palette.brandGrey[60],
  fontSize: '10px',
  fontWeight: 400,
  lineHeight: '109.9%',
  [theme.breakpoints.up('md')]: {
    fontSize: '12px',
  },
}))

export const StyledIconlessContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
}))
