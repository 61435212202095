import { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Grid } from 'clubbi-ui'

import { LoadablePage, LoadablePageProps, StyledSpinner } from './LoadablePage'
import { ProductCard } from '../organisms/product/ProductCard'
import { searchProducts } from '../../services/productApi'
import { Product } from '../../store/data/types'
import { algoliaEventActions } from '../../store/slices/algoliaEvents'
import { useAppDispatch, useAppSelector } from '../../common/hooks'

import {
  SearchDiv,
  StyledSection,
  StyledContent,
  StyledText,
  StyledContainerList,
} from './styles/Search.style'

export interface SearchProps {
  totalCount: number
  products: Product[]
  query: string
  queryId?: string
}

export const searchFetch = async (clubberEmail: string, query: string, sessionId: string) => {
  if (query.trim() == '') {
    return {
      query: '',
      totalCount: 0,
      products: [],
    }
  }
  const defaultPageSize = 50
  const { products, numberOfMatches, queryId } = await searchProducts(
    clubberEmail,
    query,
    sessionId,
    0,
    defaultPageSize
  )
  return {
    query,
    products,
    totalCount: numberOfMatches,
    queryId,
  }
}

const _Search = ({ products, query, queryId = '' }: SearchProps) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const queryValue = queryParams.get('query')

  useEffect(() => {
    dispatch(algoliaEventActions.setQueryId({ queryId }))
  }, [dispatch, queryId])

  return products.length == 0 ? (
    <Grid
      container
      spacing={0}
      p={4}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <SearchDiv>
        <h3>Pesquisando por: {query}</h3>
      </SearchDiv>
      <div>Nenhum Resultado</div>
    </Grid>
  ) : (
    <StyledSection>
      {products.length ? (
        <StyledContent>
          <StyledText>Exibindo {products.length} resultados</StyledText>
          <StyledContainerList>
            {products.map((product, index) => {
              return (
                <ProductCard
                  product={product}
                  key={product.id}
                  index={index}
                  totalProducts={products.length}
                  searchTerm={queryValue}
                  label={'search-page'}
                />
              )
            })}
          </StyledContainerList>
        </StyledContent>
      ) : (
        <StyledSpinner animation={'border'} role={'spinbutton'}>
          <span className="sr-only">Carregando...</span>
        </StyledSpinner>
      )}
    </StyledSection>
  )
}

export const Search = (props: LoadablePageProps<SearchProps>) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const query = searchParams.get('query')
  const { regionId, cartSessionId, clubberEmail } = useAppSelector((state) => state.session)

  const fetch = useCallback(
    () => searchFetch(clubberEmail || '', query || '', cartSessionId),
    [query, regionId]
  )
  return (
    <LoadablePage key={query + '-' + regionId?.toString()} {...props} dataFetch={fetch}>
      {_Search}
    </LoadablePage>
  )
}
