import { clubbiStyled } from 'clubbi-ui'

const positionTooltip = [
  {
    bottom: '70px',
    left: '22%',
  },
  {
    top: '72px',
    left: '10%',
  },
  {
    top: '72px',
    right: '5%',
  },
  {
    bottom: '70px',
    left: '18%',
  },
]

const positionTooltipDesktop = [
  {
    top: '150px',
    left: '10%',
  },
  {
    top: '80px',
    left: '20%',
  },
  {
    top: '80px',
    left: '700px',
  },
  {
    top: '98px',
    right: '5%',
  },
]

export const StyledTooltip = clubbiStyled('div')<{
  step: number
  isDesktop: boolean
}>(({ theme, step, isDesktop }) => {
  let positionStyle
  switch (true) {
    case isDesktop:
      positionStyle = positionTooltipDesktop[step]
      break
    default:
      positionStyle = positionTooltip[step]
  }
  return {
    ...positionStyle,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'end',
    gap: '1px',
    width: '251px',
    height: '164px',
    borderRadius: '4px',
    zIndex: 9999,
    position: 'fixed',
    color: theme.palette.brandWhite.main,
    background: theme.palette.brandPurple[90],
    [theme.breakpoints.up('sm')]: {
      position: step === 0 ? 'absolute' : 'fixed',
      zIndex: step === 0 ? 40 : 50,
    },
  }
})

export const StyledClose = clubbiStyled('button')(({ theme }) => ({
  width: '30px',
  height: '21px',
  border: 'none',
  borderRadius: '4px',
  transform: 'scale(0.8)',
  color: theme.palette.brandPurple[40],
  background: theme.palette.brandPurple[90],
}))

export const StyledTextContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  height: '95px',
}))

export const StyledTitle = clubbiStyled('p')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXXS,
  fontWeight: 700,
  lineHeight: '14px',
  width: '100%',
  padding: '0 27px 0 18px',

  color: theme.palette.brandWhite.main,
}))

export const StyledDescription = clubbiStyled('p')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXXS,
  lineHeight: '16px',
  width: '100%',
  padding: '0 18px',

  color: theme.palette.brandWhite.main,
}))

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  height: '31px',
  padding: '0 18px',

  color: theme.palette.brandWhite.main,
}))

export const StyledContainerSteps = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  gap: '2px',
  width: '38px',
  height: '31px',
  paddingTop: '18px',

  color: theme.palette.brandWhite.main,
}))

export const StyledSteps = clubbiStyled('span')<{ viewed: boolean }>(({ theme, viewed }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',

  background: viewed ? theme.palette.brandWhite.main : theme.palette.brandPurple.main,
}))

export const StyledButton = clubbiStyled('button')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXXS,
  lineHeight: '16px',
  fontWeight: 700,
  width: '99px',
  height: '31px',
  padding: '8px 14px',
  borderRadius: '4px',
  border: 'none',

  color: theme.palette.brandPurple[90],
  background: theme.palette.brandWhite.main,
}))
