import { clubbiStyled } from 'clubbi-ui'

export const StyledP = clubbiStyled('p')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  fontStyle: 'normal',
  fontSize: theme.typography.fontSizeS,
  lineHeight: theme.spacing(7.75),
  color: theme.palette.brandBlack.main,
  width: '100%',
  marginBottom: theme.spacing(10),
}))

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(10),
  marginBottom: theme.spacing(14),
  [theme.breakpoints.up('sm')]: {
    width: '84%',
  },
}))

export const StyledSupportText = clubbiStyled('p')(({ theme }) => ({
  fontWeight: '600',
  fontStyle: 'normal',
  fontSize: theme.typography.fontSizeS,
  lineHeight: theme.spacing(5),
  color: theme.palette.brandBlack.main,
  width: '100%',
}))

export const StyledHelpLink = clubbiStyled('a')(({ theme }) => ({
  fontWeight: '600',
  fontStyle: 'normal',
  fontSize: theme.typography.fontSizeS,
  color: theme.palette.brandPurple.contrastText,
  lineHeight: theme.spacing(5.25),
  backgroundColor: theme.palette.brandPurple.main,
  width: theme.spacing(55.25),
  height: theme.spacing(13.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(5),
  borderRadius: theme.spacing(1),
  '&:hover': {
    color: theme.palette.brandPurple.contrastText,
    textDecoration: 'none',
    backgroundColor: theme.palette.brandPurple[70],
  },
}))

export const StyledLinkLabel = clubbiStyled('p')(({ theme }) => ({
  marginTop: theme.spacing(4),
}))
