import { ButtonClubbi, Tooltip, clubbiStyled } from 'clubbi-ui'

export const StyledSaveButton = clubbiStyled(ButtonClubbi)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: 'normal',
  textTransform: 'capitalize',
  width: '96%',
  margin: '10px',
  '&:disabled': {
    backgroundColor: theme.palette.brandGrey[20],
    color: theme.palette.brandWhite.main,
  },
  [theme.breakpoints.up('md')]: {
    width: '120px',
    margin: '28px',
    marginTop: '0px',
  },
}))

export const StyledTooltip = clubbiStyled(Tooltip)(() => ({
  width: '100%',
}))
