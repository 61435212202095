import { themeClubbi } from 'clubbi-ui'

import { WHATSAPP_API_URL } from '../../../common/constants'
import { modalController } from '../ModalManager'
import {
  StyledButton,
  StyledCloseButton,
  StyledCloseIcon,
  StyledContainer,
  StyledContent,
  StyledHeader,
  StyledLink,
  StyledList,
  StyledModal,
} from './styles/NpsThanksMessage.style'

export const NpsThanksMessage = ({
  showNps,
  setShowNps,
}: {
  showNps: modalController
  setShowNps: (value: modalController | ((prevVar: modalController) => modalController)) => void
}): JSX.Element => {
  const handleClose = (): void => {
    setShowNps((state) => ({ ...state, showNpsThanks: false }))
  }

  return (
    <StyledModal open={showNps.showNpsThanks} onClose={handleClose}>
      <StyledContainer>
        <StyledHeader>
          Obrigado por nos responder!
          <StyledCloseButton onClick={handleClose}>
            <StyledCloseIcon color={themeClubbi.palette.brandGrey.main} />
          </StyledCloseButton>
        </StyledHeader>
        <StyledContent>
          Quer receber R$50 reais de desconto na próxima compra?
          <StyledList>
            <li>
              Indique a Clubbi{' '}
              <StyledLink href={WHATSAPP_API_URL} target="_blank">
                clicando aqui
              </StyledLink>
            </li>
            <li>A loja amiga faz o 1° pedido</li>
            <li>Você ganha R$50, ela ganha R$30</li>
          </StyledList>
          <strong>
            Válido somente para mercadinhos, padarias e hortifrutis que vendem mercearia. Outras
            lojas não são cadastradas
          </strong>
        </StyledContent>
        <StyledButton size="small" typeButton="filled" label="Fechar" onClick={handleClose} />
      </StyledContainer>
    </StyledModal>
  )
}
