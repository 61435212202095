import { clubbiStyled } from 'clubbi-ui'

export const StyledHelpLink = clubbiStyled('a')(({ theme }) => ({
  fontWeight: '800',
  fontStyle: 'normal',
  textDecoration: 'underline',
  fontSize: theme.typography.fontSizeXXS,
  color: theme.palette.brandPurple.dark,
  lineHeight: theme.spacing(5),
}))
