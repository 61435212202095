import { Popover, clubbiStyled } from 'clubbi-ui'

export const StyledAvailability = clubbiStyled('button')(({ theme }) => ({
  fontSize: theme.typography.fontSize4XS,
  width: '32px',
  height: '16px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  border: 'none',

  color: theme.palette.brandGrey[100],
  background: theme.palette.brandGrey[20],

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSize2XS,
    lineHeight: '14px',
    width: '42px',
    height: '20px',
  },
}))

export const StyledPopover = clubbiStyled(Popover)(({ theme }) => ({
  top: '5px',
}))

export const StyledPopoverContainer = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSize4XS,
  width: '160px',
  borderRadius: '5px',
  border: `1px solid ${theme.palette.brandGrey[100]}`,
  padding: '8px',

  color: theme.palette.brandGrey[100],
  background: theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSize2XS,
    width: '248px',
    padding: '10px',
  },
}))

export const StyledTitle = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSize3XS,
  fontWeight: 700,
  lineHeight: '12px',

  color: theme.palette.brandGrey[100],

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXXS,
    lineHeight: '16px',
  },
}))

export const StyledMode = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSize3XS,
  lineHeight: '12px',
  marginTop: '5px',

  color: theme.palette.brandGrey[100],

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXXS,
    lineHeight: '16px',
  },
}))
