import { apiGet, apiOrdersPut } from './api'
import { SearchResult } from './productApi'

export const shopperPlanApi = async (
  customerId: string,
  planId: string,
  data: Record<string, unknown>
) => {
  return apiOrdersPut(
    `/shopper-plan/invoice-signature/${planId}/customer/${customerId}`,
    data,
    true
  )
}

export const customerProductsResults = async (
  term: string | string[],
  customerId: string,
  planId: string
): Promise<SearchResult> => {
  return await apiGet(`/shopper-plan/${planId}/${customerId}/plan-customer-results`, false)
}

export const orderResults = async (term: string | string[], customerId: string, planId: string) => {
  return await apiGet(`/shopper-plan/${planId}/merchant/${customerId}/orders`, false)
}
