import { useRef } from 'react'
import Slider from 'react-slick'
import { themeClubbi, useMediaQuery } from 'clubbi-ui'

import { PromotionalBanner } from './PromotionalBanner'
import { Banner } from '../../../store/data/types'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { StyledMain, StyledArrowButton } from './BannerSlider.style'

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '',
  className: 'center',
  arrows: false,
}

export const BannerSlider = ({ banners }: { banners: Banner[] }) => {
  const sliderRef = useRef<Slider>(null)

  const isLagerScreen = useMediaQuery(themeClubbi.breakpoints.up(1520))
  const isDesktop = useMediaQuery(themeClubbi.breakpoints.up(1024))

  const centerPadding = isLagerScreen ? '15%' : '2%'
  settings.centerPadding = centerPadding

  const renderArrows = () => {
    return (
      <>
        {banners.length > 1 && (
          <div className="slider-arrow">
            <StyledArrowButton
              onClick={() => sliderRef.current!.slickPrev()}
              direction="left"
              color={themeClubbi.palette.brandGrey[100]}
            />
            <StyledArrowButton
              onClick={() => sliderRef.current!.slickNext()}
              direction="right"
              color={themeClubbi.palette.brandGrey[100]}
            />
          </div>
        )}
      </>
    )
  }

  const filteredBanners = banners.filter((banner) =>
    isDesktop ? banner.mediaUrl : banner.mediaUrlMobile
  )

  return (
    <StyledMain>
      {filteredBanners.length > 1 && renderArrows()}
      <Slider {...settings} ref={sliderRef}>
        {filteredBanners.map((banner, index) => (
          <PromotionalBanner key={index} banner={banner} />
        ))}
      </Slider>
    </StyledMain>
  )
}
