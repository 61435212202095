import { memo } from 'react'

import { Product } from '../../store/data/types'
import { ProductsGrid } from '../templates/ProductsGrid'
import { LoadablePage, LoadablePageProps } from './LoadablePage'
import { getOfertoes } from '../../services/productApi'
import { RecommendedCorridor } from '../molecules/recommendedCorridor/RecommendedCorridor'
import { LinkBackHome } from '../atoms'
import { StyledLinkToHome } from '../atoms/styles/LinkBackHomeDiv.style'
import { getPackagePrice } from '../../common/productUtils'

export const ofertoesFetch = async (): Promise<Props> => {
  const products = await getOfertoes()
  const productsValid = products.filter((product) => {
    return getPackagePrice(product) || product.discount
  })
  return { products: productsValid }
}

interface Props {
  products: Product[]
}

const _LinkHome = memo(() => (
  <StyledLinkToHome>
    <LinkBackHome />
  </StyledLinkToHome>
))

const _Grid = ({ products }: Props) => {
  const header = {
    node: <_LinkHome />,
    height: 63,
  }

  return (
    <div>
      <ProductsGrid context={['ofertoes']} data={products} header={header} />
      <RecommendedCorridor />
    </div>
  )
}

export const Ofertao = (props: LoadablePageProps<Props>) => {
  return (
    <LoadablePage {...props} dataFetch={ofertoesFetch}>
      {_Grid}
    </LoadablePage>
  )
}
