import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Product } from '../data/types'

type State = Product[]

export const ProductsListAvaSlice = createSlice({
  name: 'productsListAva',
  initialState: [] as unknown as State,
  reducers: {
    setProductsListAva: (_state, action: PayloadAction<any>): State => {
      return action.payload
    },
    cleanUp: () => {
      return {} as State
    },
  },
})

export const ProductsListAvaReducer = ProductsListAvaSlice.reducer
export const ProductsListAvaActions = ProductsListAvaSlice.actions
