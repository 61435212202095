import { memo, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Mission, Product } from '../../store/data/types'
import { ProductsGrid } from '../templates/ProductsGrid'
import { LoadablePage, LoadablePageProps } from './LoadablePage'
import { buildProductURL } from '../../services/productApi'
import { useAppSelector } from '../../common/hooks'
import { apiGet } from '../../services/api'
import { MissionModal } from '../molecules/MissionModal'
import { StyledLinkToHome } from '../atoms/styles/LinkBackHomeDiv.style'
import { LinkBackHome } from '../atoms'
import { MissionPromo } from '../molecules/MissionPromo'

import {
  StyledDescription,
  StyledMissionCard,
  StyledPromotion,
  StyledClickableText,
} from '../molecules/styles/MissionCard.style'
import { StyledMissionProductsButton } from '../molecules/styles/MissionsRow.style'

export const missionProductsFetch = async (
  missionId: number,
  customerId?: string
): Promise<Props> => {
  const products = await apiGet(buildProductURL(`/products/mission/${missionId}`))
  const mission: Mission = await apiGet(buildProductURL(`/mission/${missionId}`))
  return { mission, products }
}

interface Props {
  mission: Mission
  products: Product[]
}

const _Header = memo(({ mission }: { mission: Mission }) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <StyledLinkToHome>
        <LinkBackHome />
      </StyledLinkToHome>
      <StyledMissionCard key={mission.id}>
        <MissionModal showModal={showModal} setShowModal={setShowModal}></MissionModal>
        <StyledPromotion className="promotion">
          {<MissionPromo mission={mission} />}
        </StyledPromotion>
        <StyledDescription className="description">
          {mission.description}
          <StyledMissionProductsButton>
            <StyledClickableText onClick={() => setShowModal(!showModal)}>
              Entenda como funciona
            </StyledClickableText>
          </StyledMissionProductsButton>
        </StyledDescription>
      </StyledMissionCard>
    </>
  )
})

const _Grid = ({ products, mission }: Props) => {
  const header = {
    node: <_Header mission={mission} />,
    height: 100,
  }
  return <ProductsGrid context={['mission']} data={products} header={header} />
}

export const Missions = (props: LoadablePageProps<Props>) => {
  const { missionId } = useParams<{ missionId: string }>()
  const [merchantCode] = useAppSelector((state) => [state.session.merchantCode])
  const fetch = useCallback(
    () => missionProductsFetch(Number(missionId), merchantCode),
    [missionId]
  )
  return (
    <LoadablePage {...props} dataFetch={fetch}>
      {_Grid}
    </LoadablePage>
  )
}
