import { useEffect, useState } from 'react'
import { CloseIcon, Fade, themeClubbi, useMediaQuery } from 'clubbi-ui'

import { TooltipPointer } from './TooltipPointer'
import { infoNavigationSteps, infoNavigationStepsDesktop } from '../../../common/constants'
import gtm from '../../../common/gtmTracker'
import {
  StyledButton,
  StyledClose,
  StyledContainer,
  StyledContainerSteps,
  StyledDescription,
  StyledSteps,
  StyledTextContainer,
  StyledTitle,
  StyledTooltip,
} from './styles/NavigationTooltip.style'
import { useAppSelector } from '../../../common/hooks'

export const NavigationTooltip = () => {
  const { merchantCode, clubberEmail } = useAppSelector((state) => state.session)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [step, setStep] = useState<number>(0)
  const [fade, setFade] = useState(true)

  const isDesktop = useMediaQuery(themeClubbi.breakpoints.up('sm'))

  const { title, description } = isDesktop
    ? infoNavigationStepsDesktop[step]
    : infoNavigationSteps[step]

  useEffect(() => {
    const completed = !!localStorage.getItem('onboarding-completed')
    setIsOpen(!completed)
  }, [])

  useEffect(() => {
    if (step) setIsOpen(true)
  }, [fade])

  const handleClick = (nextStep: number) => {
    const onboardingCompleted = nextStep > 3
    setIsOpen(false)

    if (onboardingCompleted) {
      setIsOpen(false)
      localStorage.setItem('onboarding-completed', JSON.stringify(true))
      gtm.triggerCompletedOnboardingNavigation(merchantCode!, clubberEmail)
    } else {
      setStep(nextStep)
      setFade(!fade)
    }
  }
  const close = () => {
    setIsOpen(false)
    localStorage.setItem('onboarding-completed', JSON.stringify(true))
    gtm.triggerCloseOnboardingNavigation(merchantCode!, step, clubberEmail)
  }

  return (
    <>
      {isOpen && (
        <Fade in={isOpen} {...(isOpen ? { timeout: 500 } : {})}>
          <StyledTooltip step={step} isDesktop={isDesktop}>
            <StyledClose onClick={close}>
              <CloseIcon />
            </StyledClose>
            <StyledTextContainer>
              <StyledTitle>{title}</StyledTitle>
              <StyledDescription>{description}</StyledDescription>
            </StyledTextContainer>
            <StyledContainer>
              <StyledContainerSteps>
                {infoNavigationSteps.map((_info, index) => (
                  <StyledSteps viewed={index <= step} key={index}></StyledSteps>
                ))}
              </StyledContainerSteps>
              <StyledButton onClick={() => handleClick(step + 1)}>
                {step > 2 ? 'Entendido!' : `Próximo >>`}
              </StyledButton>
            </StyledContainer>
            <TooltipPointer step={step} />
          </StyledTooltip>
        </Fade>
      )}
    </>
  )
}
