import { useEffect } from 'react'
import { themeClubbi, useMediaQuery } from 'clubbi-ui'
import { useInView } from 'react-intersection-observer'

import { Banner } from '../../../store/data/types'
import { useAppSelector } from '../../../common/hooks'
import gtm from '../../../common/gtmTracker'

import { StyledImg } from './BannerSlider.style'
import { Link } from 'react-router-dom'

interface PromotionalBannerProps {
  banner: Banner
}

export const PromotionalBanner = ({ banner }: PromotionalBannerProps) => {
  const { mediaUrl, mediaUrlMobile, adId, destinationUrl, viewUrl, clickUrl, impressionUrl } =
    banner

  const { merchantCode, clubberEmail, cartSessionId } = useAppSelector((state) => state.session)
  const { ref, inView } = useInView({ threshold: 0.6 })

  const isDesktop = useMediaQuery(themeClubbi.breakpoints.up(1024))

  const data = {
    userId: merchantCode,
    sessionId: cartSessionId,
  }

  const headers = {
    type: 'application/json',
  }
  const blob = new Blob([JSON.stringify(data)], headers)
  useEffect(() => {
    if (inView && merchantCode) {
      gtm.triggerBannerImpression(merchantCode, adId, clubberEmail)
      navigator.sendBeacon(viewUrl, blob)
    }
  }, [inView])

  useEffect(() => {
    navigator.sendBeacon(impressionUrl, blob)
  }, [])

  const eventsOnClick = () => {
    if (merchantCode) {
      gtm.triggerBannerClick(adId, merchantCode, clubberEmail)
      navigator.sendBeacon(clickUrl, blob)
    }
  }
  return (
    <>
      {isDesktop && mediaUrl && (
        <div ref={ref}>
          <Link to={destinationUrl}>
            <StyledImg src={mediaUrl} alt={`Banner ${adId}`} onClick={eventsOnClick} />
          </Link>
        </div>
      )}
      {!isDesktop && mediaUrlMobile && (
        <div ref={ref}>
          <Link to={destinationUrl}>
            <StyledImg src={mediaUrlMobile} alt={`Banner ${adId}`} onClick={eventsOnClick} />
          </Link>
        </div>
      )}
    </>
  )
}
