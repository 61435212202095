import { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { Spinner, themeClubbi } from 'clubbi-ui'
import { useLocation } from 'react-router'

import gtm from '../../../common/gtmTracker'
import { ProductCard } from '../../organisms/product/ProductCard'
import { Product } from '../../../store/data/types'
import { useAppSelector } from '../../../common/hooks'
import { StyledScrollCapture } from '../../routes/styles/Search.style'
import { handleScrollTo, showOrHideArrowsByScroll } from '../../../common/ScrollUtils'
import { CUSTOM_PRODUCTS_QUANTITY } from '../../../common/constants'
import { getCustomProducts } from '../../../services/productApi'

import {
  StyledContainerList,
  StyledCorridor,
  StyledSpinner,
  StyledDiv,
  StyledArrowButton,
} from './styles/recommendedCorridor.style'

export const RecommendedProducts = () => {
  const { merchantCode, clubberEmail } = useAppSelector(({ session }) => session)
  const currentPathname = useLocation().pathname
  const [products, setProducts] = useState<Product[] | []>([])
  const [loading, setLoading] = useState<boolean>(false)

  const [leftButton, setLeftButton] = useState(false)
  const [rightButton, setRightButton] = useState(true)

  const { ref, inView } = useInView({ threshold: 0.1 })

  const recommendedCorridor = useRef(null)

  const getProducts = async () => {
    setLoading(true)
    const customProducts = await getCustomProducts(CUSTOM_PRODUCTS_QUANTITY)
    setProducts(customProducts)
    setLoading(false)
  }

  useEffect(() => {
    getProducts()
  }, [merchantCode])

  const handleScrollBar = (_e: any) => {
    const element: any = recommendedCorridor.current
    showOrHideArrowsByScroll(element, setRightButton, setLeftButton)
  }

  const handleScrollToRight = () => {
    const element: any = recommendedCorridor.current
    handleScrollTo(element, 'right')
  }

  const handleScrollToLeft = () => {
    const element: any = recommendedCorridor.current
    handleScrollTo(element, 'left')
  }

  useEffect(() => {
    if (inView) {
      gtm.triggerScrollOfertaoAvaCorridor(merchantCode, clubberEmail)
    }
  }, [inView])

  return (
    <StyledDiv>
      {leftButton && (
        <StyledArrowButton
          direction="left"
          onClick={handleScrollToRight}
          color={themeClubbi.palette.brandWhite.main}
        />
      )}
      <StyledCorridor>
        {products.length && !loading ? (
          <StyledContainerList
            id={`recommended ${currentPathname}`}
            ref={recommendedCorridor}
            onScroll={handleScrollBar}
          >
            {products.map((product: Product, index) => (
              <>
                <ProductCard
                  product={product}
                  key={product.id}
                  label={`recommended ${currentPathname}`}
                />
                {index === products.length - 1 && <StyledScrollCapture ref={ref} />}
              </>
            ))}
          </StyledContainerList>
        ) : (
          <StyledSpinner>
            <Spinner />
          </StyledSpinner>
        )}
      </StyledCorridor>
      {rightButton && (
        <StyledArrowButton
          direction="right"
          onClick={handleScrollToLeft}
          color={themeClubbi.palette.brandWhite.main}
        />
      )}
    </StyledDiv>
  )
}
