import { clubbiStyled } from 'clubbi-ui'
import { Link } from 'react-router-dom'

export const StyledP = clubbiStyled('p')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: '16px',
  lineHeight: '26px',
  color: theme.palette.brandBlack.main,
  width: '100%',
  textAlign: 'center',
  margin: 0,
}))

export const StyledBoldP = clubbiStyled('p')(({ theme }) => ({
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '26px',
  color: theme.palette.brandBlack.main,
  width: '100%',
  textAlign: 'center',
  margin: 0,
}))

export const StyledOpenOrdersLink = clubbiStyled(Link)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontStyle: 'normal',
  fontSize: '16px',
  lineHeight: '26px',
  color: theme.palette.brandPurple.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}))

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brandWhite.main,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px',
  marginBottom: '24px',
  [theme.breakpoints.up('sm')]: {
    width: 'fit-contents',
  },
}))
