import { setCurrency } from '../../../common/cardUtils'
import { getUnitPerPackagePrice } from '../../../common/productUtils'
import { Product } from '../../../store/data/types'

import {
  StyledBadgePrice,
  StyledCard,
  StyledContainer,
  StyledCurrency,
  StyledProductDescription,
  StyledProductImage,
  StyledProductPrice,
} from './styles/ProductCardFlyer.style'

interface ProductsCardFlyerProps {
  product: Product
}

export const ProductsCardFlyer = ({ product }: ProductsCardFlyerProps) => {
  const [firstSupplier] = product.supplierPrices
  const { packagePrice } = firstSupplier

  const getPrice = () => {
    const price = getUnitPerPackagePrice(packagePrice, product.packageNumberOfItems)
    if (!price) return setCurrency(firstSupplier.price)
    return setCurrency(price)
  }

  return (
    <StyledCard key={product.ean}>
      <StyledProductDescription>{product.description}</StyledProductDescription>
      <StyledContainer>
        <StyledProductImage src={product.imageUrls.image300Px} alt="imagem do produto" />
        <StyledBadgePrice src={'/img/badge.png'} alt="bagde price" />
        <StyledCurrency>R$</StyledCurrency>
        <StyledProductPrice>{getPrice()}</StyledProductPrice>
      </StyledContainer>
    </StyledCard>
  )
}
