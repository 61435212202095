import { clubbiStyled } from 'clubbi-ui'

export const StyleBar = clubbiStyled('div')<{ active: number }>(({ theme, active }) => ({
  position: 'fixed',
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '95%',
  height: '36px',
  padding: '8px 12px',
  bottom: '65px',
  boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: '8px',

  background: active ? theme.palette.brandYellow[50] : theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    width: '480px',
    height: '48px',
    padding: '12px',
    bottom: '30px',
  },
}))

export const StyledInfosContainer = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  lineHeight: '14px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  gap: '8px',

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXS,
  },
}))

export const StyledQuantity = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '60px',
  fontWeight: 700,
  lineHeight: '14px',
  width: '21px',
  height: '21px',

  background: theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    width: '28px',
    height: '28px',
  },
}))

export const StyledLink = clubbiStyled('button')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  fontWeight: '600',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: '21px',
  borderRadius: '4px',
  border: 'none',
  padding: '6px 8px',

  color: theme.palette.brandGrey[10],
  backgroundColor: theme.palette.brandPurple[60],

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXS,
    height: '28px',
    padding: '6px 10px',
  },

  [theme.breakpoints.down(315)]: {
    lineHeight: '12px',
    fontWeight: '100',
    height: '28px',
    padding: '2px 4px',
  },
}))
