import { clubbiStyled } from 'clubbi-ui'

export const StyledTitle = clubbiStyled('div')<{ noSelected: boolean | null }>(
  ({ noSelected, theme }) => ({
    fontWeight: 500,
    fontSize: '12px',
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
    },
    color: noSelected ? theme.palette.brandAlert[50] : theme.palette.brandBlack.main,
  })
)

export const StyledSelectionContainer = clubbiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  gap: '8px',
  padding: '0 16px',
  paddingTop: '24px',
}))

export const StyledPaymentTextContainer = clubbiStyled('div')(({ theme }) => ({
  color: theme.palette.brandYellow[100],
  backgroundColor: theme.palette.brandYellow[20],
  width: '100%',
  gap: '8px',
  marginTop: '4px',
  display: 'flex',
  padding: '8px',
  borderRadius: '4px',
  alignItems: 'center',
  fontSize: '10px',
  [theme.breakpoints.up('md')]: {
    fontSize: '12px',
  },
}))
