import { clubbiStyled } from 'clubbi-ui'

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  padding: '10px',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    padding: '110px',
    paddingTop: '26px',
    gap: '16px',
  },
}))
