import { v4 as uuidv4 } from 'uuid'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { identify } from '../../services/loginApi'

export enum MerchantStatus {
  REGULAR = 'REGULAR',
  DEACTIVATED = 'DEACTIVATED',
  DEFAULTED = 'DEFAULTED',
  NEGATIVATED = 'NEGATIVATED',
}

export interface Session {
  showModal: boolean
  alertControl?: number
  merchantCode?: string
  merchantName?: string
  paymentDeadline?: string
  lastLoginRequirement?: string
  regionId?: number
  loginDatetime?: string
  isActive?: boolean
  status?: MerchantStatus
  isInstantPayment?: boolean
  cartSessionId: string
  isDeveloperMode?: boolean
  isPurchasingAllowed?: boolean
  clubberEmail?: string
  clubberArea?: string
  geoRegionId?: number
  websiteRegionId?: number
}

const developmentUsers = ['BBEBZ', 'AXOUM', 'QTKAU']

export const loginFromCode = createAsyncThunk(
  'session/implicitLoginFromMerchantCode',
  async (args: { merchantCode: string; clubberEmail?: string }) => {
    return identify(args.merchantCode, args.clubberEmail)
  }
)
const sessionSlice = createSlice({
  name: 'session',
  initialState: { showModal: false, cartSessionId: uuidv4(), alertControl: 0 } as Session,
  reducers: {
    showModal: (state, action: PayloadAction<boolean>) => {
      const automatic = action.payload
      return {
        ...state,
        showModal: true,
        lastLoginRequirement: automatic ? new Date().toISOString() : state.lastLoginRequirement,
      }
    },
    hideModal: (state, action: PayloadAction<undefined>) => ({
      ...state,
      showModal: false,
      lastLoginRequirement: new Date().toISOString(),
    }),
    showAlert: (state, action: PayloadAction<undefined>) => ({
      ...state,
      alertControl: (state.alertControl ?? 0) + 1,
    }),
    setMerchantCode: (state, action: PayloadAction<string>): Session => {
      const merchantCode = action.payload.toUpperCase()
      if (merchantCode.length != 5) {
        return state
      }
      if (state.merchantCode && merchantCode != state.merchantCode) {
        return { ...state, merchantCode, merchantName: undefined, lastLoginRequirement: undefined }
      }
      return { ...state, merchantCode }
    },
    clearMerchantData: (state, action: PayloadAction<undefined>) => {
      return {
        showModal: false,
        isInstantPayment: false,
        cartSessionId: uuidv4(),
        clubberEmail: state.clubberEmail,
      }
    },
    clearCartSession: (state, action: PayloadAction<undefined>) => {
      return { ...state, cartSessionId: uuidv4() }
    },
    setIsActive: (state, action: PayloadAction<{ isActive: boolean }>) => {
      const { isActive } = action.payload
      return { ...state, isActive }
    },
    clearIsActive: (state, action: PayloadAction<undefined>) => {
      return { ...state, isActive: undefined }
    },
    setRegionId: (state, action: PayloadAction<{ regionId?: number | undefined }>) => {
      const { regionId } = action.payload
      return { ...state, regionId: regionId }
    },
    setClubber: (state, action: PayloadAction<{ clubber?: string | undefined }>) => {
      const { clubber } = action.payload
      return { ...state, clubberEmail: clubber }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginFromCode.fulfilled, (state, action) => {
      const [status, merchantData] = action.payload
      const isDeveloperMode = developmentUsers.includes(merchantData?.merchantCode || '')

      if (status === 'OK') {
        return {
          ...state,
          ...merchantData,
          isDeveloperMode: isDeveloperMode,
          regionId: isDeveloperMode ? state.regionId : merchantData?.regionId,
          loginDatetime: new Date().toISOString(),
          isInstantPayment: merchantData?.isInstantPayment,
        }
      } else if (status === 'UNAUTHORIZED') {
        return { isActive: false, showModal: true, cartSessionId: uuidv4() }
      } else {
        return { showModal: false, cartSessionId: uuidv4() }
      }
    })
  },
})

export const sessionReducer = sessionSlice.reducer
export const sessionActions = sessionSlice.actions
