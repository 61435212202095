import { Product } from '../../store/data/types'
import { ProductsGrid } from '../templates/ProductsGrid'
import { LoadablePageProps } from './LoadablePage'
import { memo } from 'react'
import { LinkBackHome } from '../atoms'
import { useAppSelector } from '../../common/hooks'

import { StyledLinkToHome } from '../atoms/styles/LinkBackHomeDiv.style'
import { useParams } from 'react-router-dom'
interface Props {
  products: Product[]
  id: string
}

const _LinkHome = memo(() => (
  <StyledLinkToHome>
    <LinkBackHome />
  </StyledLinkToHome>
))

const _Grid = ({ products, id }: Props) => {
  const header = {
    node: <_LinkHome />,
    height: 63,
  }
  return <ProductsGrid context={['banner', id]} data={products} header={header} />
}

export const BannerProducts = (props: LoadablePageProps<Props>) => {
  const { bannerId } = useParams<{ bannerId: string }>()
  const banners = useAppSelector((state) => state.banners)
  const banner = banners.find((banner) => banner.adId === bannerId)

  return <>{banner?.products && <_Grid products={banner.products} id={bannerId} />}</>
}
