import { StyledMain, StyledMode, StyledText } from './styles/UnavailableMode.style'

interface UnavailableModeProps {
  unvailablePackage: boolean
  unvailableUnit: boolean
}

export const UnavailableMode = ({ unvailablePackage, unvailableUnit }: UnavailableModeProps) => {
  const modeDescriptions = {
    unvailablePackage: {
      title: 'Caixa',
      mode: 'fracionado',
    },
    unvailableUnit: {
      title: 'Fracionado',
      mode: 'caixa',
    },
  }

  const { title, mode } = unvailablePackage
    ? modeDescriptions.unvailablePackage
    : modeDescriptions.unvailableUnit

  return (
    <StyledMain unvailablePackage={unvailablePackage} unvailableUnit={unvailableUnit}>
      <StyledMode>{title}</StyledMode>
      <StyledText>{`Disponível apenas ${mode}.`}</StyledText>
    </StyledMain>
  )
}
