import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Notifications } from '../../components/molecules/Notification/PopoverNotifications'

type State = Notifications[]

const notificationSlices = createSlice({
  name: 'notifications',
  initialState: [] as unknown as State,
  reducers: {
    setNotifications: (state, action: PayloadAction<Notifications[]>): State => {
      return action.payload
    },
    setVisualizeAll: (state, action: PayloadAction<Notifications[]>): State => {
      return action.payload
    },
  },
})

export const notificationsReducer = notificationSlices.reducer
export const notificationsActions = notificationSlices.actions
