import { themeClubbi, useMediaQuery } from 'clubbi-ui'
import { getTotalPrice } from '../../../common/cardUtils'
import { useAppSelector } from '../../../common/hooks'
import { getPriceByMode } from '../../../common/productUtils'
import { CartItem } from '../../../store/data/types'
import { CartIcon } from '../../atoms/CartIcon'
import { FooterIcon } from './FooterIcon'
import { StyledBadgeQty, StyledQty, StyledIcon, StyledDiv } from './styles/CartIconWithBadge.style'

interface Props {
  isSelectedCart: boolean
  handleClickCart: () => void
}

export const CartIconWithBadge = ({ isSelectedCart, handleClickCart }: Props) => {
  const cartItems = useAppSelector((state) =>
    Object.values(state.cart).filter((e) => e.quantity > 0)
  )

  const getCartItemPrice = (cartItem: CartItem) => {
    const { getMoreForLess } = cartItem.product.supplierPrices[0]
    if (getMoreForLess) {
      return +getTotalPrice(cartItem.product, 0, cartItem.quantity)
    }
    return cartItem.quantity * getPriceByMode(cartItem.isPackageMode, cartItem.product)!
  }

  const totalPrice = (cart: CartItem[]) => {
    return cart.reduce((x: number, cartItem: CartItem) => x + getCartItemPrice(cartItem), 0)
  }

  const isDesktop = useMediaQuery(themeClubbi.breakpoints.up('md'))

  const cartItemsCount = (cart: CartItem[]) => {
    return cart.reduce((x: number, cartItem: CartItem) => x + cartItem.quantity, 0)
  }

  const quantity = cartItemsCount(cartItems)

  const formatedPrice = totalPrice(cartItems).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const text = totalPrice(cartItems) > 0 ? `R$ ${formatedPrice}` : 'Carrinho'

  const colorMobile = isSelectedCart
    ? themeClubbi.palette.brandPurple[60]
    : themeClubbi.palette.brandGrey[70]

  return (
    <StyledDiv>
      <FooterIcon isSelected={isSelectedCart} onClick={handleClickCart} text={text}>
        <StyledIcon>
          {quantity > 0 && (
            <StyledBadgeQty>
              <StyledQty>{quantity}</StyledQty>
            </StyledBadgeQty>
          )}
          {isDesktop ? (
            <CartIcon isSelected={true} color={themeClubbi.palette.brandWhite.main} />
          ) : (
            <CartIcon isSelected={isSelectedCart} color={colorMobile} />
          )}
        </StyledIcon>
      </FooterIcon>
    </StyledDiv>
  )
}
