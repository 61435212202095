import { useCallback, useEffect, useRef, useState } from 'react'
import { CloudDownloadIcon, themeClubbi } from 'clubbi-ui'
import { toJpeg } from 'html-to-image'

import { DetailsFlyerInput } from '../molecules/flyer/DetailsFlyerInput'
import { Flyer } from '../molecules/flyer/Flyer'
import { FullScreenImage } from '../molecules/flyer/FullScreenImage'
import { SalesHeader } from '../molecules/flyer/SalesHeader'
import gtm from '../../common/gtmTracker'
import { useAppSelector } from '../../common/hooks'
import { currentDateString, sortProducts } from '../../common/utils'
import { Product } from '../../store/data/types'
import { getMerchantData } from '../../services/merchantApi'
import { getSalesFlyerProducts } from '../../services/productApi'

import {
  StyledContainerFlyer,
  StyledDescription,
  StyledImageSalesFlyer,
  StyledMainContainer,
  StyledNotFound,
  StyledPreview,
  StyledSaveButton,
  StyledSpinner,
  StyledTitle,
} from './styles/SalesFlyer.style'

export const SalesFlyer = () => {
  const { merchantCodeInput, eans } = useAppSelector((state) => state.salesFlyer)
  const [products, setProducts] = useState<Product[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [flyerImageUrl, setFlyerImageUrl] = useState<string>('')
  const [fullScreenImage, setFullScreenImage] = useState<boolean>(false)
  const [productsNotFound, setProductsNotFound] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  const disableGetButton = !merchantCodeInput || !eans.length
  const showFlyer = loading && !!products.length
  const showSaveButton = !!flyerImageUrl && !showFlyer

  useEffect(() => {
    if (showFlyer) {
      setTimeout(() => {
        generateImageUrl()
      }, 2000)
    }
  }, [showFlyer])

  const generateImageUrl = useCallback(async () => {
    if (ref?.current) {
      const dataUrl = await toJpeg(ref.current!)
      setFlyerImageUrl(dataUrl)
      setLoading(false)
    }
  }, [ref])

  const getFlyerProducts = async () => {
    setLoading(true)
    setFlyerImageUrl('')
    const [{ websiteRegionId }] = await getMerchantData(merchantCodeInput!)

    const dataProducts = await getSalesFlyerProducts(
      merchantCodeInput!,
      websiteRegionId!,
      eans.join(',')
    )

    if (!dataProducts.length) {
      setProductsNotFound(true)
      setLoading(false)
    }

    const sortedProducts = sortProducts(dataProducts, eans)

    setProducts(sortedProducts)
    gtm.triggerGenerateSalesFlyer(merchantCodeInput)
  }

  const saveImage = () => {
    const link = document.createElement('a')
    link.download = `sales-flyer${currentDateString}`
    link.href = flyerImageUrl
    link.click()
    gtm.triggerSaveSalesFlyer()
  }

  return (
    <StyledMainContainer>
      <SalesHeader />
      <DetailsFlyerInput getFlyerProducts={getFlyerProducts} disabled={disableGetButton} />
      <StyledPreview>
        <StyledDescription>
          <StyledTitle>2. Pré-visualizar</StyledTitle>
          {showSaveButton && (
            <StyledSaveButton onClick={saveImage} data-testid="save-img-button">
              <CloudDownloadIcon
                color={themeClubbi.palette.brandPurple[60]}
                size={themeClubbi.typography.fontSizeM}
              />
              Salvar
            </StyledSaveButton>
          )}
        </StyledDescription>
        {showSaveButton && (
          <StyledImageSalesFlyer
            src={flyerImageUrl}
            alt="sales-flyer"
            onClick={() => setFullScreenImage(true)}
          />
        )}
        {productsNotFound && <StyledNotFound>Produtos não encontrados</StyledNotFound>}
      </StyledPreview>
      {loading && <StyledSpinner />}
      {showFlyer && (
        <StyledContainerFlyer ref={ref}>
          <Flyer products={products} />
        </StyledContainerFlyer>
      )}
      {fullScreenImage && (
        <FullScreenImage
          flyerImageUrl={flyerImageUrl}
          fullScreenImage={fullScreenImage}
          setFullScreenImage={setFullScreenImage}
        />
      )}
    </StyledMainContainer>
  )
}
