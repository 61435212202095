import React, { Dispatch, SetStateAction } from 'react'
import { INVOICE_PAGE_SIZE } from '../../common/constants'
import { Pagination } from 'react-bootstrap'

interface Props {
  total: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
}

export const _Pagination = ({ total: total, page: page, setPage: setPage }: Props) => {
  const numberOfPages: number = Math.floor(total / INVOICE_PAGE_SIZE) + 1
  const neighbours = 4
  const buttons = []
  for (let i = page - neighbours; i <= page + neighbours; i++) {
    if (2 <= i && i < numberOfPages) {
      buttons.push(
        <div
          style={{
            display: 'inline-block',
          }}
        >
          <Pagination.Item
            active={page === i}
            onClick={() => {
              window.scrollTo(0, 0)
              setPage(i)
            }}
          >
            {i}
          </Pagination.Item>
        </div>
      )
    }
  }

  const ellipsisLeft = page - neighbours > 2
  const ellipsisRight = page + neighbours < numberOfPages

  const component = (
    <Pagination>
      <Pagination.First
        disabled={page === 1}
        onClick={() => {
          window.scrollTo(0, 0)
          setPage(1)
        }}
      />
      <Pagination.Prev
        disabled={page === 1}
        onClick={() => {
          window.scrollTo(0, 0)
          setPage(page - 1)
        }}
      />

      <Pagination.Item
        active={page == 1}
        onClick={() => {
          window.scrollTo(0, 0)
          setPage(1)
        }}
      >
        {1}
      </Pagination.Item>

      {ellipsisLeft && <Pagination.Ellipsis />}

      {buttons}

      {ellipsisRight && <Pagination.Ellipsis />}
      {numberOfPages != 1 && (
        <Pagination.Item
          active={page == numberOfPages}
          onClick={() => {
            window.scrollTo(0, 0)
            setPage(numberOfPages)
          }}
        >
          {numberOfPages}
        </Pagination.Item>
      )}
      <Pagination.Next
        disabled={page === numberOfPages}
        onClick={() => {
          window.scrollTo(0, 0)
          setPage(page + 1)
        }}
      />
      <Pagination.Last
        disabled={page === numberOfPages}
        onClick={() => {
          window.scrollTo(0, 0)
          setPage(numberOfPages)
        }}
      />
    </Pagination>
  )
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        margin: '0 auto',
        paddingTop: '3em',
        paddingBottom: '3em',
      }}
    >
      {component}
    </div>
  )
}
