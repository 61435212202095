import { ToggleButton, ToggleButtonGroup, clubbiStyled } from 'clubbi-ui'

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  backgroundColor: theme.palette.brandWhite.main,
  [theme.breakpoints.up('md')]: {
    margin: '8px 0',
  },
}))

export const StyledToggleButton = clubbiStyled(ToggleButton)(({ theme }) => ({
  textTransform: 'none',
  width: '22px',
  height: '22px',
  padding: '4px 10px',
  fontSize: '12px',
  fontWeight: 400,
  borderRadius: '44px !important',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '1px !important',
  border: `1px solid ${theme.palette.brandPurple[60]} !important`,
  color: theme.palette.brandWhite.main,
  backgroundColor: theme.palette.brandPurple[60],
  '&.Mui-selected': {
    border: `1px solid ${theme.palette.brandGrey[60]} !important`,
    backgroundColor: theme.palette.brandWhite.main,
    color: theme.palette.brandGrey[60],
    '&.MuiButtonBase-root:hover': {
      backgroundColor: theme.palette.brandGrey[10],
      color: theme.palette.brandGrey[60],
      border: `1px solid ${theme.palette.brandGrey[60]} !important`,
    },
  },
  '&.MuiButtonBase-root:hover': {
    border: `1px solid ${theme.palette.brandPurple[70]} !important`,
    color: theme.palette.brandWhite.main,
    backgroundColor: theme.palette.brandPurple[70],
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    width: 'fit-content',
    height: '29px',
  },
}))

export const StyledToggleButtonGroup = clubbiStyled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '8px',
  marginTop: '14px',
  [theme.breakpoints.up('md')]: {
    gap: '14px',
    marginTop: '10px',
  },
}))
