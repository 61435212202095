import { useLocation } from 'react-router'

import { StyledAlert, StyledLink } from './styles/SnackBarDefaulting.style'
import { useAppSelector } from '../../common/hooks'
import { isMerchantBlocked } from '../../common/utils'

export const SnackBarDefaulting = () => {
  const isHomePage = useLocation().pathname == '/'
  const { merchantCode, status } = useAppSelector((state) => state.session)

  const isDefaultingCustomer = isMerchantBlocked(status)

  return (
    <>
      {!!merchantCode && isHomePage && isDefaultingCustomer && (
        <StyledAlert severity="error" icon={false}>
          <span>
            <strong>Não identificamos o pagamento de uma de suas faturas já vencidas.</strong> Para
            continuar utilizando a Clubbi, você pode resolver essa pendência clicando{' '}
            <StyledLink to={'/invoices'}>aqui</StyledLink> e, caso tenha alguma dúvida ou problema,
            é só entrar em contato com o suporte no pedido em atraso.
          </span>
        </StyledAlert>
      )}
    </>
  )
}
