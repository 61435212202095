import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getMerchantCreditInfo } from '../../services/creditApi'
import { Credit } from '../data/types'

export const merchantCreditInfoThunk = createAsyncThunk(
  'credit/getMerchantCreditInfo',
  getMerchantCreditInfo
)

const creditSlice = createSlice({
  name: 'credit',
  initialState: { remainingCredit: 0, initialCredit: 1000 } as Credit,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(merchantCreditInfoThunk.fulfilled, (state, action) => {
      const creditData = action.payload
      if (creditData)
        return {
          ...state,
          ...creditData,
        }
    })
  },
})

export const creditReducer = creditSlice.reducer
export const creditActions = creditSlice.actions
