import { StyledContainer, StyledLink, StyledTitle } from './styles/ProfileHeader.style'

import { ProfileHeaderProps } from './interfaces'
import { ZENDESK_PROFILE_EDIT_FAQ_LINK } from '../../../common/constants'

export const ProfileHeader = ({ title }: ProfileHeaderProps) => {
  return (
    <StyledContainer>
      <StyledTitle>{title}</StyledTitle>
      <StyledLink href={ZENDESK_PROFILE_EDIT_FAQ_LINK} target={'_blank'} rel="noreferrer">
        Como alterar meus dados?
      </StyledLink>
    </StyledContainer>
  )
}
