import React, { useState } from 'react'
import { NpsModal } from './nps/NpsModal'

export type modalController = {
  showNps: boolean | null
  showGoodGrade: boolean
  showBadGrade: boolean
  showRatings: boolean
  showNpsThanks: boolean
}

export const ModalManager = () => {
  const [showModal, setModalNps] = useState<modalController>({
    showNps: null,
    showGoodGrade: false,
    showBadGrade: false,
    showRatings: true,
    showNpsThanks: false,
  })

  return (
    <>
      <NpsModal showNps={showModal} setShowNps={setModalNps} />
    </>
  )
}
