import { FallbackProps } from 'react-error-boundary'

import { Button } from 'react-bootstrap'
import styled from 'styled-components'

const StyledErrorBoundary = styled.div`
  position: fixed;
  top: calc(40% - 4em);
  width: 100%;
  height: 100%;
  vertical-align: center;
  text-align: center;
`
const StyledImage = styled.img`
  max-height: 40px;
  max-width: 134px;
  margin: 1em;
`

const StyledButton = styled(Button)`
  margin: auto 0.5em;
`

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <StyledErrorBoundary role="alert">
      <StyledImage src="/img/new_clubbi.png" alt="" />
      <p>
        Desculpe, estamos enfrentando algumas dificuldades técnicas! <br />
        Tente novamente em alguns minutos!
      </p>
      <pre>{error.message}</pre>
      <a href={'/'}>
        <StyledButton variant={'secondary'}>Ir Pra Home</StyledButton>
      </a>
      <StyledButton onClick={resetErrorBoundary}>Recarregar</StyledButton>
    </StyledErrorBoundary>
  )
}
