import { PaymentSelector } from './PaymentSelector'
import { CartPurchaseInfoCard } from './CartPurchaseInfoCard'
import { FinishPurchaseButton } from './FinishPurchaseButton'
import { ShippingTypes } from './ShippingTypes'
import { CheckoutFormProps, SelectionType } from './interfaces'
import { useAppSelector } from '../../../common/hooks'

import { StyledCheckoutContainer, StyledTitle, StyledFooter } from './styles/CheckoutForm.style'

export const CheckoutForm = ({
  isLoading,
  setFormValue,
  formValue,
  submitOrder,
  loginUpdated,
  showModal,
  setShowModal,
  setShowModalInstantPayment,
}: CheckoutFormProps) => {
  const onSplitShippingTypes = useAppSelector(
    (state) => state.splitTestShippingTypes.onTestShippingTypes
  )
  return (
    <StyledCheckoutContainer>
      <StyledTitle>Pagamento</StyledTitle>
      {Object.values(SelectionType).map((selectionType) => (
        <PaymentSelector
          key={`selector-${selectionType}`}
          type={selectionType}
          setFormValue={setFormValue}
          formValue={formValue}
        />
      ))}
      {onSplitShippingTypes && <ShippingTypes />}
      <StyledFooter>
        <CartPurchaseInfoCard />
        <FinishPurchaseButton
          formValue={formValue}
          isLoading={isLoading}
          submitOrder={submitOrder}
          loginUpdated={loginUpdated}
          showModal={showModal}
          setShowModal={setShowModal}
          setShowModalInstantPayment={setShowModalInstantPayment}
        />
      </StyledFooter>
    </StyledCheckoutContainer>
  )
}
