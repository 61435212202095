import { clubbiStyled } from 'clubbi-ui'
import { getSeasonStyle } from '../../../../common/seasonality'

const season = getSeasonStyle()

export const StyledHeader = clubbiStyled('div')(({ theme }) => ({
  width: '100%',
  height: '70px',
  left: '0px',
  top: '0px',
  position: 'fixed',
  zIndex: 50,
  padding: '5px 0 0 0',
  backgroundImage: `url(${season.imageHeaderMobile})`,
  backgroundColor: season.headerColor,
  color: theme.palette.brandWhite.main,
  backgroundSize: 'cover',

  [theme.breakpoints.up('md')]: {
    backgroundImage: `url(${season.imageHeaderDesktop})`,
    padding: '6px 26px 0 26px',
    height: '72px',
  },
}))

export const StyledDiv = clubbiStyled('div')<{
  isDeveloperMode: boolean
  isLogged: boolean
}>(({ theme, isDeveloperMode, isLogged }) => ({
  width: '100%',
  backgroundColor: theme.palette.brandPurple[60],
  height: '16px',

  div: {
    display: 'block',
    borderRadius: '15px 15px 0px 0px',
    backgroundColor: theme.palette.brandGrey[10],
    height: '16px',
  },

  [theme.breakpoints.down('sm')]: {
    div: {
      display: !isLogged || isDeveloperMode ? 'none' : 'block',
    },
  },
}))
