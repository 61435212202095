import {
  StyledContainer,
  StyledInput,
  StyledLabel,
  StyledLabelContainer,
} from './styles/ProfileTextInput.style'

import { ProfileTextInputProps } from './interfaces'
import { useCallback, useState } from 'react'
import { themeClubbi, useMediaQuery } from 'clubbi-ui'
import { useAppDispatch } from '../../../common/hooks'
import { profileActions } from '../../../store/slices/profile'
import { OptionalLabel } from './PhonesInfo'
import { ContactType } from '../../../store/data/types'
import { phoneMask } from './helpers'
import { MIN_PHONE_LENGTH_WITH_MASK } from './constants'

export const ProfileTextInput = ({
  label,
  readOnly,
  defaultContent,
  width,
  name,
  isContact,
  isOptional,
}: ProfileTextInputProps) => {
  const [value, setValue] = useState(isContact ? phoneMask(defaultContent) : defaultContent)
  const isMobile = useMediaQuery(themeClubbi.breakpoints.down('md'))
  const dispatch = useAppDispatch()

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!readOnly) {
        if (isContact) {
          setValue(phoneMask(event.target.value))
          dispatch(
            profileActions.updateMerchantContacts({
              phone: event.target.value,
              contactType: name as ContactType,
              mainContact: name === ContactType.MAIN_WHATSAPP,
            })
          )
        } else {
          setValue(event.target.value)
          dispatch(profileActions.updateMerchant({ [name]: event.target.value }))
        }
      }
    },
    [setValue]
  )

  const getPlaceholder = () => {
    if (isContact) return '(21) 99999-9999'
    if (name == 'financeEmail') return 'email@email.com.br'
    return ''
  }

  return (
    <StyledContainer rowAlign={isContact}>
      <StyledLabelContainer>
        <StyledLabel>{label}</StyledLabel>
        {isOptional && isMobile && <OptionalLabel />}
      </StyledLabelContainer>
      <StyledInput
        onChange={handleChange}
        disabled={readOnly}
        value={value}
        width={width}
        rowAlign={isContact}
        type={isContact ? 'tel' : 'text'}
        maxLength={isContact ? 15 : 500}
        data-testid={`${name}-input`}
        placeholder={getPlaceholder()}
        requiredEmpty={isContact && !isOptional && value.length < MIN_PHONE_LENGTH_WITH_MASK}
      />
    </StyledContainer>
  )
}
