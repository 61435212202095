import { CloseIcon, clubbiStyled, Modal, ButtonClubbi } from 'clubbi-ui'

export const StyledModal = clubbiStyled(Modal)<{ isItem: boolean }>(({ isItem, theme }) => ({
  border: 'none',
  width: '360px',
  height: isItem ? '206px' : '270px',
  display: 'flex',
  position: 'fixed',
  top: '50%',
  left: '50%',
  zIndex: 999,
  padding: '24px',
  [theme.breakpoints.up('sm')]: {
    width: '405px',
    height: isItem ? '200px' : '289px',
  },
}))

export const StyledContainer = clubbiStyled('div')<{ isItem: boolean }>(({ isItem, theme }) => ({
  border: 'none',
  borderRadius: '10px',
  width: '360px',
  height: isItem ? '206px' : '270px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'top',
  boxShadow: '0px 2px 8px 0px #0000001A',
  transform: 'translate(-56%, -50%)',
  background: theme.palette.brandWhite.main,
  [theme.breakpoints.up('sm')]: {
    width: '405px',
    height: isItem ? '200px' : '289px',
    transform: 'translate(-40%, -50%)',
  },
}))

export const StyledHeader = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  width: '100%',
}))

export const StyledHeaderText = clubbiStyled('div')<{ isItem: boolean }>(({ isItem, theme }) => ({
  padding: '20px',
  paddingBottom: 0,
  fontSize: '14px',
  fontWeight: 700,
  color: isItem ? theme.palette.brandPurple.main : theme.palette.brandAlert[50],
  [theme.breakpoints.up('sm')]: {
    fontSize: '16px',
  },
}))

export const StyledCloseButton = clubbiStyled('button')(({ theme }) => ({
  textDecoration: 'underline',
  border: 'none',
  borderRadius: '50px',
  marginTop: '4px',
  backgroundColor: theme.palette.brandWhite.main,
}))

export const StyledCloseIcon = clubbiStyled(CloseIcon)(({ theme }) => ({
  width: '16px',
  height: '16px',
  fontSize: '16px',
}))

export const StyledContent = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  padding: '12px 0',
  width: '94%',
  paddingLeft: '20px',
  fontSize: '14px',
  lineHeight: '21.23px',
  background: theme.palette.brandWhite.main,
  [theme.breakpoints.up('sm')]: {
    fontSize: '16px',
  },
}))

export const StyledButton = clubbiStyled(ButtonClubbi)<{ isItem: boolean }>(
  ({ isItem, theme }) => ({
    textTransform: 'capitalize',
    fontSize: '12px',
    lineHeight: '22.43px',
    marginLeft: '20px',
    alignSelf: 'end',
    color: isItem ? theme.palette.brandPurple.main : theme.palette.brandAlert[50],
    fontWeight: 700,
    borderColor: isItem ? theme.palette.brandPurple.main : theme.palette.brandAlert[50],
    '&:hover': {
      backgroundColor: isItem ? theme.palette.brandPurple[10] : theme.palette.brandAlert[10],
    },
    '&:disabled': {
      borderColor: theme.palette.brandGrey.main,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
    },
  })
)

export const StyledTextButton = clubbiStyled(ButtonClubbi)<{
  label: string
}>(({ label, disabled, theme }) => ({
  label: label,
  disabled: disabled,
  typeButton: 'text',
  size: 'small',
  color: theme.palette.brandGrey.dark,
  textDecoration: 'none',
  textTransform: 'capitalize',
  fontSize: '12px',
  fontWeight: 600,
  [theme.breakpoints.up('sm')]: {
    fontSize: '14px',
  },
}))

export const StyledButtonContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  width: '100%',
  paddingTop: '12px',
}))
