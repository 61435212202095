import { clubbiStyled } from 'clubbi-ui'

export const StyledTooltip = clubbiStyled('div')<{ isInCartPage: boolean }>(
  ({ theme, isInCartPage }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'end',
    gap: '1px',
    width: '280px',
    height: '80px',
    borderRadius: '4px',
    zIndex: 9999,
    position: 'absolute',
    top: '-30px',
    left: isInCartPage ? '65px' : '68px',

    color: theme.palette.brandWhite.main,
    background: theme.palette.brandPurple[90],

    [theme.breakpoints.up('sm')]: {
      top: '-30px',
      left: '398px',
    },
  })
)

export const StyledClose = clubbiStyled('button')(({ theme }) => ({
  width: '30px',
  height: '5px',
  border: 'none',
  borderRadius: '4px',
  transform: 'scale(0.8)',

  color: theme.palette.brandPurple[40],
  background: theme.palette.brandPurple[90],
}))

export const StyledTextContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  height: '50px',
  padding: 0,
  margin: 0,
}))

export const StyledTitle = clubbiStyled('p')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXXS,
  fontWeight: 700,
  lineHeight: '14px',
  width: '100%',
  padding: '0 8px 0 8px',
  marginBottom: '5px',

  color: theme.palette.brandWhite.main,
}))

export const StyledDescription = clubbiStyled('p')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXXS,
  lineHeight: '16px',
  width: '100%',
  padding: '0 8px',

  color: theme.palette.brandWhite.main,
}))
