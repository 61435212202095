import { useState, useEffect } from 'react'

import { cartActions } from '../../../store/slices/cart'
import { useAppDispatch, useAppSelector } from '../../../common/hooks'
import { QuantityChange, PriceChange, PRODUCT_CHANGES_ACTIONS, Warnings } from './interfaces'
import { CartWarningModal } from './CartWarningModal'
import { compareProducts } from './compareProducts'
import { CartChange, CartItem, Product } from '../../../store/data/types'
import { getPriceByMode } from '../../../common/productUtils'

export const CartWarningModalManager = () => {
  const initialWarnings = {
    outOfStock: [],
    priceChanges: [],
    limitChanges: [],
    quantityChanges: [],
  }
  const [warnings, setWarnings] = useState<Warnings>(initialWarnings)
  const dispatch = useAppDispatch()
  const { removeByEan, updateProduct } = cartActions

  const cartItems = useAppSelector((state) =>
    Object.values(state.cart).filter((e) => e.quantity > 0)
  )

  const { regionId, merchantCode, clubberEmail, cartSessionId, alertControl } = useAppSelector(
    (state) => state.session
  )

  const onDisposeHandler = () => {
    setWarnings(initialWarnings)
  }

  useEffect(() => {
    compareProducts(cartItems, merchantCode, regionId, cartSessionId, clubberEmail).then(
      (cartChanges) => {
        if (!(cartChanges && cartItems.length)) return

        const outOfStockProducts: Product[] = cartItems
          .map(({ product }) => product)
          .filter(({ ean }) => (cartChanges.outOfStockEans ?? []).includes(ean))

        const unitButOfertaoProducts: Product[] = cartItems
          .map(({ product }) => product)
          .filter(({ ean }) => (cartChanges.unitButOfertaoEans ?? []).includes(ean))

        const updatedProducts: PriceChange[] = cartChanges.productUpdated.map((item: any) => {
          const [order, product] = item
          const cart: CartItem[] = cartItems.filter(({ product }) => product.ean == order.ean)

          return { cartItem: cart.pop(), product }
        })

        const discountWarnings: Warnings = getDiscountWarnings(cartChanges, cartItems)
        const discountPriceChanges: PriceChange[] = discountWarnings.priceChanges
        const discountOutOfStock: Product[] = discountWarnings.outOfStock
        const discountLimitChanges: PriceChange[] = discountWarnings.limitChanges

        const priceChanges: PriceChange[] = [...updatedProducts, ...discountPriceChanges]
        const limitChanges: PriceChange[] = [...discountLimitChanges]
        const outOfStock: Product[] = [
          ...outOfStockProducts,
          ...unitButOfertaoProducts,
          ...discountOutOfStock,
        ]

        const quantityChanges: QuantityChange[] = cartChanges.limitChanged ?? []

        if (quantityChanges.length) {
          quantityChanges.map(({ orderItemUpdated }) => {
            dispatch(cartActions.updateQuantityAndMode(orderItemUpdated))
          })
        }

        if (outOfStock.length) {
          dispatch(removeByEan(outOfStock.map((product) => product.ean)))
        }

        if (limitChanges.length) {
          dispatch(updateProduct(limitChanges.map(({ cartItem, product }) => product)))

          limitChanges.map(({ cartItem, product }) => {
            const change: CartChange = {
              context: 'custom_promotions/item',
              quantity: product.limitPerOrder ?? 0,
            }
            dispatch(
              cartActions.setQuantity({
                product,
                cartChange: change,
                merchantCode: merchantCode!,
                clubberEmail,
              })
            )
          })
        }

        if (priceChanges.length) {
          dispatch(updateProduct(priceChanges.map(({ cartItem, product }) => product)))
        }

        const priceChangesWarnings: PriceChange[] = priceChanges.filter(({ cartItem, product }) => {
          const { isPackageMode } = cartItem
          const isPriceDifferent = !!(
            cartItem.product.supplierPrices[0].price !== product.supplierPrices[0].price
          )
          const isPackagePriceDifferent = !!(
            cartItem.product.supplierPrices[0].packagePrice !==
            product.supplierPrices[0].packagePrice
          )

          return isPackageMode ? isPackagePriceDifferent : isPriceDifferent
        })

        setWarnings({
          outOfStock,
          priceChanges: priceChangesWarnings,
          limitChanges,
          quantityChanges,
        })
      }
    )
  }, [alertControl])

  return <CartWarningModal warnings={warnings} onDispose={onDisposeHandler} />
}

const getDiscountWarnings = (cartChanges: any, cartItems: CartItem[]) => {
  const discountChanges: [CartItem, Product, PRODUCT_CHANGES_ACTIONS][] = (
    cartChanges.discountUpdated ?? []
  ).map((item: any) => {
    const [order, limit, discount] = item
    const cartItem: CartItem | undefined = cartItems
      .filter(({ product }) => product.ean == order.ean)
      .pop()

    if (cartItem) {
      if (discount) {
        const { product } = cartItem
        const isPackageMode = true

        const newPrice = getPriceByMode(!isPackageMode, product) ?? 0
        const newPackagePrice = getPriceByMode(isPackageMode, product) ?? 0

        const newProduct: Product = {
          ...product,
          price: +newPrice.toFixed(2),
          packagePrice: +newPackagePrice.toFixed(2),
          discount: discount,
        }

        return [cartItem, newProduct, PRODUCT_CHANGES_ACTIONS.PRICE_CHANGE]
      } else if (limit) {
        const { product } = cartItem
        const newProduct: Product = {
          ...product,
          limitPerOrder: limit || product.limitPerOrder,
        }

        return [cartItem, newProduct, PRODUCT_CHANGES_ACTIONS.LIMIT_CHANGE]
      } else if (limit <= 0) {
        return [cartItem, cartItem.product, PRODUCT_CHANGES_ACTIONS.REMOVE]
      }
    }
  })

  return discountChanges.reduce(
    (acc: Warnings, [cartItem, newProduct, change]) => {
      switch (change) {
        case PRODUCT_CHANGES_ACTIONS.REMOVE:
          return {
            ...acc,
            outOfStock: [...acc.outOfStock, newProduct],
          }
        case PRODUCT_CHANGES_ACTIONS.LIMIT_CHANGE:
          return {
            ...acc,
            limitChanges: [...acc.limitChanges, { cartItem, product: newProduct }],
          }
        default:
          return {
            ...acc,
            priceChanges: [...acc.priceChanges, { cartItem, product: newProduct }],
          }
      }
    },
    { outOfStock: [], limitChanges: [], priceChanges: [] }
  )
}
