import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface TaxBenefitEansProps {
  regionId: number
  eans: string[]
}

type State = TaxBenefitEansProps

const taxBenefitEansSlice = createSlice({
  name: 'taxBenefitEans',
  initialState: [] as unknown as State,
  reducers: {
    eans: (state, action: PayloadAction<TaxBenefitEansProps>): State => {
      return action.payload
    },
  },
})

export const taxBenefitEansReducer = taxBenefitEansSlice.reducer
export const taxBenefitEansActions = taxBenefitEansSlice.actions
