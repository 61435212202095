import { searchListActions } from '../../store/slices/searchList'
import { dispatch } from '../../store'
import gtm from '../../common/gtmTracker'
import { useAppSelector } from '../../common/hooks'
import { StyledDiv } from './styles/ShoppingListButton.style'
import { ShoppingListIcon } from '../molecules/gridHeader/headerIcons/ShoppingListIcon'

export const ShoppingListButtonNew = () => {
  const { merchantCode, clubberEmail } = useAppSelector((state) => state.session)

  const handleClick = () => {
    dispatch(searchListActions.setIsOpen(true))
    gtm.triggerShoppingListClick(merchantCode, clubberEmail)
  }

  return (
    <StyledDiv onClick={handleClick}>
      <ShoppingListIcon />
    </StyledDiv>
  )
}
