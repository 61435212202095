import { clubbiStyled, Popover } from 'clubbi-ui'
import { Link } from 'react-router-dom'

export const StyledLink = clubbiStyled(Link)(({ theme }) => ({
  '&:hover': {
    textDecoration: 'none',
  },
  p: {
    fontSize: theme.typography.fontSizeXS,

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.fontSizeXS,
    },
  },
}))

export const StyledContainerHeader = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  whiteSpace: 'nowrap',
  position: 'relative',
  width: '50%',
  alignItems: 'start',

  [theme.breakpoints.up('sm')]: {
    height: '40px',
    gap: '20px',
  },
}))

export const StyledMainDesktop = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'start',
  width: '100%',
  marginTop: '16px',
}))

export const StyledButton = clubbiStyled('button')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'none',
  fontWeight: '500',
  margin: '2px',

  background: 'transparent',
  color: theme.palette.brandGrey[70],

  [theme.breakpoints.up('sm')]: {
    marginLeft: '5px',
  },

  '&:hover': {
    color: theme.palette.brandGrey[100],
  },
}))

export const StyledExploreCategoriesButton = clubbiStyled('button')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'end',
  background: 'transparent',
  fontWeight: '500',
  border: 'none',
  zIndex: '3',
  color: theme.palette.brandGrey[70],

  [theme.breakpoints.up('sm')]: {
    marginLeft: '5px',
  },

  '&:hover': {
    color: theme.palette.brandGrey[100],
  },
}))

export const StyledOffersButton = clubbiStyled('button')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'none',
  borderRadius: '4px',
  background: 'transparent',
  fontWeight: '700',
  gap: '4px',
  margin: '2px 0 2px 0',

  color: theme.palette.brandAlert[50],

  '&:hover': {
    color: theme.palette.brandAlert[60],
  },
}))

export const StyledPopover = clubbiStyled(Popover)({
  display: 'flex',
  gap: '16px',
  marginTop: '30px',
  '.MuiPaper-elevation': {
    minHeight: '10%',
  },
})

export const StyledPopoverContent = clubbiStyled('div')({
  display: 'flex',
  fontWeight: '500',
  padding: '8px',
})

export const StyledDiv = clubbiStyled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  whiteSpace: 'nowrap',
  height: '40px',
  position: 'relative',

  textAlign: 'center',
  width: '100%',
  margin: '22px 0 16px 30px',
})

export const StyledSeeMoreContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'end',
  justifyContent: 'end',
  whiteSpace: 'nowrap',
  padding: '10px 18px 10px 10px',
  right: '32px',
  top: '0px',
  background: theme.palette.brandGrey[10],
}))

export const StyledTransparentDivAfter = clubbiStyled('div')(() => ({
  background: `linear-gradient(270deg, #F7F7F7 32.9%, rgba(247, 247, 247, 0) 91.56%)`,
  width: '140px',
  height: '20px',
  display: 'flex',
  position: 'absolute',
  alignItems: 'end',
  justifyContent: 'end',
  top: '16px',
  zIndex: '3',
  marginRight: '80px',
}))

export const StyledTransparentDivBefore = clubbiStyled('div')(() => ({
  background: `linear-gradient(270deg, #F7F7F7 32.9%, rgba(247, 247, 247, 0) 91.56%)`,
  width: '140px',
  height: '20px',
  display: 'flex',
  position: 'absolute',
  alignItems: 'end',
  justifyContent: 'end',
  top: '16px',
  zIndex: '3',
  marginRight: '-40px',
}))
