import { LoadablePage, LoadablePageProps } from './LoadablePage'
import { useLocation } from 'react-router-dom'
import React, { useCallback } from 'react'
import { customerProductsResults } from '../../services/shopperPlanApi'
import WrapTab from '../organisms/invoice/delivery/WrapTab'

export const deliveryFetch = async (query: string, customerId: string, planId: string) => {
  if (query.trim() == '') {
    return {
      query: '',
      pickingData: [],
      planId: '',
    }
  }
  const pickingData = await customerProductsResults(query, customerId, planId)
  return {
    query,
    pickingData,
    planId,
  }
}

interface Props {
  pickingData: any
  query: string
  planId: string
}

export const Delivery = (props: LoadablePageProps<Props>) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const customerId = searchParams.get('customerId')!
  const planId = searchParams.get('planId')!

  const fetch = useCallback(() => deliveryFetch('123', customerId, planId), [customerId, planId])
  return (
    <LoadablePage key={customerId} {...props} dataFetch={fetch}>
      {WrapTab}
    </LoadablePage>
  )
}
