import { useLocation } from 'react-router'
import {
  StyledContainer,
  StyledHR,
  StyledMerchantCode,
  StyledMerchantName,
  StyledOptionLink,
  StyledBody,
  StyledSectionTitle,
  getColor,
  StyledSection,
  StyledSectionBody,
} from './styles/SideMenu.style'
import { sideMenuData } from './constants'
import { useAppSelector } from '../../../common/hooks'

export const SideMenu = () => {
  const isContactPage = useLocation().pathname == '/profile/contacts'
  const { merchantName, merchantCode } = useAppSelector((state) => state.session)
  return (
    <StyledContainer>
      <StyledMerchantName>{merchantName}</StyledMerchantName>
      <StyledMerchantCode>{`Código: ${merchantCode}`}</StyledMerchantCode>
      <StyledHR />
      <StyledBody>
        {sideMenuData.map(({ data, title }) => (
          <StyledSection key={title}>
            <StyledSectionTitle>{title}</StyledSectionTitle>
            {data.map(({ label, icon: DataIcon, href, rel, target }) => (
              <StyledSectionBody key={label}>
                <DataIcon color={getColor(isContactPage, label)} />
                <StyledOptionLink
                  isContactPage={isContactPage}
                  label={label}
                  href={href}
                  rel={rel}
                  target={target}
                >
                  {label}
                </StyledOptionLink>
              </StyledSectionBody>
            ))}
          </StyledSection>
        ))}
      </StyledBody>
    </StyledContainer>
  )
}
