import { clubbiStyled, themeClubbi } from 'clubbi-ui'
import { Fragment } from 'react'
import { BiCheckCircle, BiSolidTruck, BiX } from 'react-icons/bi'

export const StyledContainer = clubbiStyled('span')(() => ({
  textAlign: 'left',
  flex: 10,
}))

export const StyledTitle = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXXS,
  fontWeight: 700,
  color: theme.palette.brandBlack.main,
  [theme.breakpoints.up('md')]: {
    fontSize: '20px',
  },
}))

export const StyledData = clubbiStyled('div')<{ customColor: string }>(
  ({ customColor, theme }) => ({
    marginTop: '5px',
    fontSize: theme.typography.fontSize2XS,
    fontWeight: 500,
    color: customColor,
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
    },
  })
)

export const StyledXIcon = clubbiStyled(BiX)(({ theme }) => ({
  height: '12px',
  [theme.breakpoints.up('md')]: {
    height: '16px',
  },
}))

export const StyledDeliveryIcon = clubbiStyled(BiSolidTruck)(({ theme }) => ({
  height: '12px',
  [theme.breakpoints.up('md')]: {
    height: '16px',
  },
}))

export const StyledRoundedCheckIcon = clubbiStyled(BiCheckCircle)(({ theme }) => ({
  height: '12px',
  [theme.breakpoints.up('md')]: {
    height: '16px',
  },
}))

export const StyledStatusWrapper = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  paddingBottom: '24px',
  [theme.breakpoints.up('md')]: {
    paddingBottom: '24px',
  },
}))

const StyledFragment = clubbiStyled(Fragment)(() => ({
  display: 'none',
}))

export const getColorAndIcon = (isPaymentDelayed: boolean, deliveryStatus: string) => {
  if (isPaymentDelayed) return { color: themeClubbi.palette.brandAlert.main, icon: StyledFragment }
  if (deliveryStatus === 'Entrega realizada')
    return {
      color: themeClubbi.palette.brandSuccess[70],
      icon: StyledRoundedCheckIcon,
    }
  if (deliveryStatus === 'Entrega não realizada')
    return { color: themeClubbi.palette.brandAlert.main, icon: StyledXIcon }
  return { color: themeClubbi.palette.brandBlack.main, icon: StyledDeliveryIcon }
}
