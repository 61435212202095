import { Rating } from '../store/data/types'
import { apiGet, apiPut } from './api'

export type PutRatingBody = {
  rating?: number | null
  comment?: string
  reason?: string
}

export enum PutResult {
  SUCCESS,
  UNEXPECTED,
}

export const getLatestRating = async (merchantCode: string): Promise<Rating | undefined> => {
  const response = await apiGet(`/ratings/${merchantCode}/latest`)

  if (response) {
    return {
      id: response.id,
      shopperPlanId: response.shopperPlanId,
      ratingValue: response.ratingValue,
      creationDatetime: response.creationDatetime,
    } as Rating
  }
  return undefined
}

export const putRating = async (ratingId: number, data: PutRatingBody): Promise<PutResult> => {
  const response = await apiPut(`/ratings/id/${ratingId}`, data)
  if (!response.ok) {
    return PutResult.UNEXPECTED
  }
  return PutResult.SUCCESS
}
