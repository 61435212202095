import React, { SyntheticEvent, MouseEvent } from 'react'
import { Rating, themeClubbi, useMediaQuery } from 'clubbi-ui'

export const RatingStars = ({
  isReadOnly,
  disabled,
  isInCard,
  handleChangeRating,
  currentRating,
  onClick,
}: {
  isReadOnly: boolean
  disabled: boolean
  isInCard: boolean
  handleChangeRating: (event: SyntheticEvent<Element, Event>, newValue: number | null) => void
  onClick: ((event: MouseEvent<HTMLElement>) => void) | null
  currentRating?: number | null
}) => {
  const isMobile = useMediaQuery(themeClubbi.breakpoints.down('md'))
  return (
    <Rating
      onChange={handleChangeRating}
      size={isMobile ? 'small' : 'medium'}
      value={currentRating}
      readOnly={isReadOnly && !isInCard}
      disabled={disabled && !isInCard}
      onClick={
        onClick
          ? onClick
          : (event: React.MouseEvent<HTMLElement>) => {
              return
            }
      }
    />
  )
}
