import { clubbiStyled } from 'clubbi-ui'

export const SearchDiv = clubbiStyled('div')(({ theme }) => ({
  display: 'flexbox',
  flexDirection: 'row',
  justifyContent: 'between',
  paddingTop: '24px',
}))

export const StyledSection = clubbiStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brandGrey.light,
  width: '100%',
  height: 'calc(100% + 400px)',
}))

export const StyledContent = clubbiStyled('div')(({ theme }) => ({
  width: '100%',
}))

export const StyledText = clubbiStyled('span')(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 'bold',
  marginLeft: '15px',

  [theme.breakpoints.up('sm')]: {
    fontSize: '16px',
    marginLeft: '35px',
  },
}))

export const StyledContainerList = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: '20px',
  paddingTop: '16px',
  marginBottom: '100px',
  width: '100%',
  paddingLeft: '3px',
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'start',
    gap: '16px',
    paddingLeft: '16px',
  },
}))
export const StyledScrollCapture = clubbiStyled('hr')(({ theme }) => ({
  width: '90%',
  height: '1px',
  borderColor: theme.palette.brandGrey.light,
}))
