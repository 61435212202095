import { useEffect, useState } from 'react'
import { AddIcon, RemoveIcon, themeClubbi } from 'clubbi-ui'

import { FetchOrdersFunction, OrderItem } from './OpenOrderDetails'
import { patchOrderItem } from '../../../services/ordersApi'
import { useAppSelector } from '../../../common/hooks'
import { isBlockedEditSupplier, isGetMoreForLess } from '../../../common/utils'

import { StyledTextButton } from './styles/SectionDetailRow.style'
import {
  StyledCollapse,
  StyledIconButton,
  StyledLi,
  StyledQtyChangeRow,
  StyledQuantityCell,
  StyledQuantityChangeBox,
  StyledQuantityText,
  StyledUl,
} from './styles/QuantityCell.style'
import { getMoreForLessByEan } from '../../../services/getMoreForLessApi'

export function QuantityCell({
  product,
  fetchOrders,
  orderSupplier,
}: Readonly<{
  product: OrderItem
  fetchOrders: FetchOrdersFunction
  orderSupplier: string | null
}>) {
  const { quantity, isMultiPackage, isCancelled, id, productId } = product || {}
  const pricingGroupId = useAppSelector((state) => state.session.regionId)
  const [quantityBoxIsOpen, setQuantityBoxIsOpen] = useState(false)
  const [productQuantity, setProductQuantity] = useState(quantity)
  const [loading, setLoading] = useState(false)
  const [getMoreForLess, setGetMoreForLess] = useState<Record<string, unknown> | null>(null)

  useEffect(() => {
    getMoreForLessByEan(productId).then((getMoreForLessResponse) => {
      setGetMoreForLess(getMoreForLessResponse)
    })
  }, [productId])

  const onChangeQuantity = (isIncrease: boolean) => {
    if (isIncrease) {
      productQuantity < quantity && setProductQuantity(productQuantity + 1)
    } else {
      productQuantity > 1 && setProductQuantity(productQuantity - 1)
    }
  }

  const onQuantitySave = async () => {
    setLoading(true)
    if (productQuantity !== quantity) {
      await patchOrderItem(id, { quantity: productQuantity })
      await fetchOrders()
    }
    setLoading(false)
    setQuantityBoxIsOpen(false)
  }

  const disabledChangeQuantity =
    isGetMoreForLess(getMoreForLess, pricingGroupId) || isBlockedEditSupplier(orderSupplier)

  return (
    <StyledQuantityCell>
      {!quantityBoxIsOpen && (
        <>
          <StyledQuantityText>
            <strong>{quantity}</strong> {isMultiPackage ? ' cx.' : ' un.'}
          </StyledQuantityText>
          {!isCancelled && (
            <StyledTextButton
              size="small"
              label="Alterar"
              typeButton="text"
              customColor={themeClubbi.palette.brandPurple.main}
              onClick={() => setQuantityBoxIsOpen(!quantityBoxIsOpen)}
              disabled={disabledChangeQuantity}
            />
          )}
        </>
      )}

      <StyledCollapse in={quantityBoxIsOpen} quantityBoxIsOpen={quantityBoxIsOpen}>
        <StyledQuantityChangeBox id="collapse-text">
          <StyledUl>
            <StyledLi key={id}>
              <StyledQtyChangeRow>
                <StyledIconButton
                  disabled={productQuantity <= 1}
                  onClick={() => onChangeQuantity(false)}
                >
                  <RemoveIcon fontSize="inherit" />
                </StyledIconButton>
                <StyledQuantityText>
                  <strong>{productQuantity}</strong> {isMultiPackage ? 'cx.' : 'un.'}
                </StyledQuantityText>
                <StyledIconButton
                  disabled={productQuantity >= quantity}
                  onClick={() => onChangeQuantity(true)}
                >
                  <AddIcon fontSize="inherit" />
                </StyledIconButton>
              </StyledQtyChangeRow>
            </StyledLi>
            <StyledLi key={id}>
              <StyledQtyChangeRow>
                <StyledTextButton
                  size="small"
                  label="Cancelar"
                  typeButton="text"
                  customColor={themeClubbi.palette.brandGrey[60]}
                  onClick={() => {
                    setProductQuantity(quantity)
                    setQuantityBoxIsOpen(!quantityBoxIsOpen)
                  }}
                />
                <StyledTextButton
                  size="small"
                  label={loading ? 'Salvando...' : 'Salvar'}
                  typeButton="text"
                  customColor={themeClubbi.palette.brandPurple.main}
                  onClick={onQuantitySave}
                />
              </StyledQtyChangeRow>
            </StyledLi>
          </StyledUl>
        </StyledQuantityChangeBox>
      </StyledCollapse>
    </StyledQuantityCell>
  )
}
