import { Product } from '../../store/data/types'
import { LoadablePage, LoadablePageProps } from './LoadablePage'
import { getCustomCorridorProducts } from '../../services/productApi'
import React, { memo, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { ProductsGrid } from '../templates/ProductsGrid'
import { useAppSelector } from '../../common/hooks'
import { StyledLinkToHome } from '../atoms/styles/LinkBackHomeDiv.style'
import { LinkBackHome } from '../atoms'

export const customCorridorProductsFetch = async (id: string): Promise<Props> => {
  const { products, label } = await getCustomCorridorProducts(id)
  return { products, id, label }
}

interface Props {
  label: string
  products: Product[]
  id: string
}

const _LinkHome = memo(() => (
  <StyledLinkToHome>
    <LinkBackHome />
  </StyledLinkToHome>
))

const _Grid = ({ products }: Props) => {
  const specialHeight = 63

  const header = {
    node: <_LinkHome />,
    height: specialHeight,
  }
  return (
    <ProductsGrid
      context={['corridor']}
      data={products}
      header={header}
      data-testid="grid-container"
    />
  )
}

export const CustomCorridor = (props: LoadablePageProps<Props>) => {
  const { id } = useParams<{ id: string }>()
  const { merchantCode, regionId } = useAppSelector((state) => state.session)
  const fetch = useCallback(() => customCorridorProductsFetch(id), [id, merchantCode, regionId])
  return (
    <LoadablePage {...props} dataFetch={fetch}>
      {_Grid}
    </LoadablePage>
  )
}
