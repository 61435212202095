import { TypeInfos, getTotalPrice } from '../../../../common/cardUtils'
import { Product } from '../../../../store/data/types'

import { StyledCardInfos, StyledWhiteBorder, StyledMessage } from './styles/CardInfos.style'

interface CardInfosProps {
  typeCardInfos: TypeInfos
  totalPrice?: number
  limit?: number
  mode?: string
  quantity?: number
  product: Product
}

export const CardInfos = ({
  typeCardInfos,
  totalPrice,
  limit,
  mode,
  quantity,
  product,
}: CardInfosProps) => {
  const getInfos = {
    totalPrice: `Adicionado: R$${getTotalPrice(product, totalPrice, quantity).replace('.', ',')}`,
    limit: mode === 'unit' ? `Máximo de ${limit} unidades` : `Máximo de ${limit} caixas`,
    mode: 'Quer Caixa ou Fracionado?',
    hide: '',
    neutral: '',
  }

  return (
    <StyledCardInfos type={typeCardInfos}>
      <StyledWhiteBorder />
      <StyledMessage>{getInfos[typeCardInfos]}</StyledMessage>
    </StyledCardInfos>
  )
}
