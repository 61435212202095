import { StyledCircle, StyledLine, StyledPointer } from './styles/SelectMissionTooltipPointer.style'

export const SelectMissionTooltipPointer = () => {
  return (
    <StyledPointer>
      <StyledCircle></StyledCircle>
      <StyledLine></StyledLine>
    </StyledPointer>
  )
}
