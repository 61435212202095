import { useHistory } from 'react-router-dom'

import { getLowestUnitPrice, getPackagePrice } from '../../../common/productUtils'
import { Product } from '../../../store/data/types'
import { WITHOUT_PRICE } from '../../../common/constants'
import gtm from '../../../common/gtmTracker'
import { useAppSelector } from '../../../common/hooks'
import { showPrices } from '../../../common/utils'

import {
  StyledContainer,
  StyledContent,
  StyledDescription,
  StyledSubTitle,
  StyledPrice,
  StyledContainerPrice,
  StyledCurrencySign,
  StyledTopDiv,
  StyledBottomDiv,
  StyledImage,
  StyledContentPrice,
  StyledContainerImage,
  StyledContainerDescription,
} from './style/CardOfertao.style'

interface OfertaoCardProps {
  product: Product
}

export const OfertaoCard = ({ product }: OfertaoCardProps) => {
  const { description, packageNumberOfItems, imageUrls } = product
  const history = useHistory()
  const { merchantCode, clubberEmail } = useAppSelector((state) => state.session)

  const packagePrice = getPackagePrice(product).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const currencyPrice = getLowestUnitPrice(product).toString().replace('.', ',')

  const finalPackagePrice = showPrices(merchantCode) ? packagePrice : WITHOUT_PRICE
  const finalUnitPrice = showPrices(merchantCode) ? currencyPrice : WITHOUT_PRICE

  const packageDescription = `Caixa ${packageNumberOfItems} un: R$ ${finalPackagePrice}`

  const handleClickSeeMoreOffersButton = (): void => {
    gtm.triggerBannerOfertaoClick(merchantCode!, clubberEmail)
    history.push('/products/ofertoes')
  }

  const reduceDescription = description.split(' ').slice(0, 7).join(' ').toUpperCase()

  return (
    <StyledContainer
      onClick={() => {
        handleClickSeeMoreOffersButton()
      }}
    >
      <StyledContent>
        <StyledContainerDescription>
          <StyledDescription>{reduceDescription}</StyledDescription>
          <StyledSubTitle>{packageDescription}</StyledSubTitle>
        </StyledContainerDescription>
        <StyledContainerPrice>
          <StyledTopDiv />
          <StyledContentPrice>
            <StyledCurrencySign>R$</StyledCurrencySign>
            <StyledPrice>{finalUnitPrice}</StyledPrice>
            <StyledCurrencySign>/un.</StyledCurrencySign>
          </StyledContentPrice>
          <StyledBottomDiv />
        </StyledContainerPrice>
        <StyledContainerImage>
          <StyledImage src={imageUrls.image120Px} />
        </StyledContainerImage>
      </StyledContent>
    </StyledContainer>
  )
}
