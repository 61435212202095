import { LinkData, Product } from '../../store/data/types'
import { ProductsGrid } from '../templates/ProductsGrid'
import { categoryRoutes } from '../../common/categoryUtils'
import { LoadablePage, LoadablePageProps } from './LoadablePage'
import { getCategoryProducts } from '../../services/productApi'
import React, { memo, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../common/hooks'
import { StyledLinkToHome } from '../atoms/styles/LinkBackHomeDiv.style'
import { LinkBackHome } from '../atoms'

export interface CategoryProps {
  products: Product[]
  routes: LinkData[]
}

export const categoryFetch = async (
  sectionName: string,
  categoryName: string
): Promise<CategoryProps> => {
  const category = await getCategoryProducts(sectionName, categoryName)
  return {
    routes: categoryRoutes(category),
    products: category.products,
  }
}

const _LinkHome = memo(() => (
  <StyledLinkToHome>
    <LinkBackHome />
  </StyledLinkToHome>
))

const _Category = ({ products, routes }: CategoryProps) => {
  const header = useMemo(
    () => ({
      node: <_LinkHome />,
      height: 63,
    }),
    [routes]
  )

  return <ProductsGrid data={products} context={['category']} header={header} />
}

export const Category = (props: LoadablePageProps<CategoryProps>) => {
  const { regionId, merchantCode } = useAppSelector((state) => state.session)
  const { section, category } = useParams<{ section: string; category: string }>()
  const fetch = useCallback(
    () => categoryFetch(section, category),
    [section, category, regionId, merchantCode]
  )

  return (
    <LoadablePage {...props} dataFetch={fetch}>
      {_Category}
    </LoadablePage>
  )
}
