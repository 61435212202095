import { MouseEvent, useState } from 'react'

import { getMaxLimitForMode } from '../../../store/data/cartFunctions'
import { Product } from '../../../store/data/types'

import {
  StyledAvailability,
  StyledMode,
  StyledPopover,
  StyledPopoverContainer,
  StyledTitle,
} from './styles/AvailableQuantity.style'

export const AvailableQuantity = ({ product }: { product: Product }) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)

  const setPopoverStatus = (event: MouseEvent<HTMLElement>) => {
    if (anchor) setAnchor(null)
    else setAnchor(event.currentTarget)
  }

  return (
    <StyledAvailability data-testid="available-quantity" onClick={setPopoverStatus}>
      Disp.
      <StyledPopover
        open={!!anchor}
        anchorEl={anchor}
        onClose={setPopoverStatus}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledPopoverContainer>
          <StyledTitle>Disponibilidade:</StyledTitle>
          <StyledMode>
            Caixa: <strong>{getMaxLimitForMode(true, product)}</strong>
          </StyledMode>
          <StyledMode>
            Fracionado: <strong>{getMaxLimitForMode(false, product)}</strong>
          </StyledMode>
        </StyledPopoverContainer>
      </StyledPopover>
    </StyledAvailability>
  )
}
