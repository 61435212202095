import { clubbiStyled } from 'clubbi-ui'

export const StyledMain = clubbiStyled('div')(({ theme }) => ({
  background: theme.palette.brandGrey.light,
  maxWidth: '100%',
  marginBottom: '50px',

  [theme.breakpoints.up('md')]: {
    maxWidth: 'calc(100% - 250px)',
    gap: '15px',
    marginBottom: '100px',
  },
}))

export const StyleContent = clubbiStyled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  padding: '2px 16px 24px 16px',

  [theme.breakpoints.up('sm')]: {
    padding: '16px 32px',
  },
}))

export const StyledContainerList = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: '20px',
  paddingTop: '16px',
  marginBottom: '100px',
  width: '100%',
  paddingLeft: '3px',

  [theme.breakpoints.up('sm')]: {
    justifyContent: 'start',
    gap: '16px',
    paddingLeft: '16px',
  },
}))
