export const TagIcon = ({ color }: { color: string }) => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_324_359)">
        <path
          d="M2.625 3.75C2.32663 3.75 2.04048 3.63147 1.8295 3.4205C1.61853 3.20952 1.5 2.92337 1.5 2.625C1.5 2.32663 1.61853 2.04048 1.8295 1.8295C2.04048 1.61853 2.32663 1.5 2.625 1.5C2.92337 1.5 3.20952 1.61853 3.4205 1.8295C3.63147 2.04048 3.75 2.32663 3.75 2.625C3.75 2.92337 3.63147 3.20952 3.4205 3.4205C3.20952 3.63147 2.92337 3.75 2.625 3.75ZM14.5575 7.185L7.8075 0.435C7.5375 0.165 7.1625 0 6.75 0H1.5C0.6675 0 0 0.6675 0 1.5V6.75C0 7.1625 0.165 7.5375 0.4425 7.8075L7.185 14.5575C7.4625 14.8275 7.8375 15 8.25 15C8.6625 15 9.0375 14.8275 9.3075 14.5575L14.5575 9.3075C14.835 9.0375 15 8.6625 15 8.25C15 7.83 14.8275 7.455 14.5575 7.185Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_324_359">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
