import { useLocation } from 'react-router'
import { useDispatch } from 'react-redux'

import { TagIcon } from '../categoriesHorizontalMenu/icons/TagIcon'
import { tagCategoriesMenuActions } from '../../../store/slices/tagCategoriesMenu'
import { useAppSelector } from '../../../common/hooks'
import { CategoriesMenuProps } from '../../organisms/layout/Layout'
import gtm from '../../../common/gtmTracker'

import {
  StyledContainer,
  StyledSection,
  StyledTitle,
  StyledLink,
  StyledTagNew,
  StyledOffersButton,
  StyledCategoriesTitleContainer,
  StyledSeeMoreButton,
} from './styles/CategoriesList.style'
import { themeClubbi } from 'clubbi-ui'

export const CategoriesList = ({ handleDrawerToggle }: CategoriesMenuProps) => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const [welcomeOffers, categories, merchantCode, tagPromotions, clubberEmail] = useAppSelector(
    (state) => [
      state.welcomeOffers,
      state.categories,
      state.session.merchantCode,
      state.tagCategoriesMenu.tagPromotions,
      state.session.clubberEmail,
    ]
  )

  const handleClick = (name: string) => {
    gtm.triggerCategoriesClick(merchantCode, pathname, 'categories menu', name, clubberEmail)

    if (name === 'Melhores Ofertas') {
      dispatch(tagCategoriesMenuActions.setStatus({ badgeMenuIcon: false, tagPromotions: false }))
      localStorage.setItem('tag-promotions', JSON.stringify(true))
    }

    if (handleDrawerToggle) {
      handleDrawerToggle()
    }
  }

  const handleClickSeeMoreButton = () => {
    if (handleDrawerToggle) {
      handleDrawerToggle()
    }
  }

  const categoryList = categories
    .filter(({ level }) => level === 0)
    .sort((a, b) => (a.name > b.name ? 1 : -1))

  return (
    <StyledContainer>
      <StyledSection>
        <StyledOffersButton>
          <TagIcon color={themeClubbi.palette.brandAlert[50]} />
          Ofertas
        </StyledOffersButton>
        {welcomeOffers?.map(({ name, link }, index) => {
          const { href } = link
          const showPromotionsTag = tagPromotions && name === 'Melhores Ofertas'
          return (
            <>
              <StyledLink
                key={index}
                to={href}
                onClick={() => handleClick(name)}
                isBold={showPromotionsTag}
              >
                {name}
                {showPromotionsTag && <StyledTagNew>novo</StyledTagNew>}
              </StyledLink>
            </>
          )
        })}
      </StyledSection>
      <StyledSection>
        <StyledCategoriesTitleContainer>
          <StyledTitle>Outras Categorias</StyledTitle>
          <StyledSeeMoreButton to={'/explorar-as-categorias'} onClick={handleClickSeeMoreButton}>
            Ver todas
          </StyledSeeMoreButton>
        </StyledCategoriesTitleContainer>

        {categoryList.map(({ name, slug }, index) => {
          return (
            <StyledLink
              key={index}
              to={`/products/${slug}`}
              onClick={() => handleClick(name)}
              isBold={false}
            >
              {name}
            </StyledLink>
          )
        })}
      </StyledSection>
    </StyledContainer>
  )
}
