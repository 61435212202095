import { clubbiStyled } from 'clubbi-ui'

type StyledTitleCheckoutProps = {
  isOrderUnderReview: boolean
}

export const StyledTitleCheckout = clubbiStyled('div')<StyledTitleCheckoutProps>(
  ({ theme, isOrderUnderReview }) => ({
    color: isOrderUnderReview ? theme.palette.brandWarning[50] : theme.palette.brandSuccess[70],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  })
)

export const StyledTitleText = clubbiStyled('p')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.fontSize3XL,
  lineHeight: theme.spacing(15.25),
  textAlign: 'center',
}))
