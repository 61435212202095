import { Link } from 'react-router-dom'
import { clubbiStyled } from 'clubbi-ui'

export const StyledProductList = clubbiStyled('div')(() => ({
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'column',
  alignItems: 'start',
  width: '100%',
  padding: '8px 8px 8px 18px',
  gap: '5px',
  background: 'white'!,
  '&:hover': {
    background: 'white',
    borderRadius: '4px',
  },
}))

export const StyledProduct = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '8px 8px 8px 0px',
  gap: '30px',
  '&:hover': {
    background: '#EFEEF7',
  },
}))

export const StyledContent = clubbiStyled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  width: '90%',
  height: '60px',
  gap: '8px',
  marginLeft: '5px',
}))

export const StyledDescription = clubbiStyled('p')(({ theme }) => ({
  fontWeight: 500,
  fontSize: theme.typography.fontSizeXXS,
  color: theme.palette.brandGrey.dark,
  alignSelf: 'center',
  lineHeight: '14px',
  margin: '0px',
}))

export const StyledImage = clubbiStyled('img')(() => ({
  width: '60px',
  height: '60px',
  objectFit: 'contain',
}))

export const StyledTitle = clubbiStyled('h5')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  fontWeight: 600,
  alignSelf: 'start',
}))

export const StyledSearchBarSectionsTitle = clubbiStyled('h5')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  fontWeight: 600,
  alignSelf: 'start',
  marginLeft: '10px',
}))

export const StyledContentPrice = clubbiStyled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '6px',
}))

export const StyledCurrencySign = clubbiStyled('span')(({ theme }) => ({
  fontSize: theme.typography.fontSize3XS,
  color: theme.palette.brandGrey.dark,
  fontWeight: 400,
  lineHeight: '15px',
  alignSelf: 'flex-start',
}))

export const StyledPrice = clubbiStyled('span')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXS,
  color: theme.palette.brandGrey.dark,
  fontWeight: 600,
  lineHeight: '16px',
}))

export const StyledAlgoliaItem = clubbiStyled('div')(() => ({
  margin: '-10px',
}))

export const StyledHr = clubbiStyled('hr')(({ theme }) => ({
  color: theme.palette.brandGrey.light,
  width: '100%',
}))

export const StyledLink = clubbiStyled(Link)(({ theme }) => ({
  color: theme.palette.brandGrey.light,
}))
