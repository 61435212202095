import { useCallback, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

interface Props {
  showModal: boolean
  hide: () => void
  submitOrder: () => void
}

export const WarningCutoffModal = ({ showModal, hide, submitOrder }: Props) => {
  const [disabledButton, setDisabledButton] = useState(false)
  const { goBack, listen } = useHistory()

  const justBack = useCallback(() => {
    hide()
    goBack()
  }, [goBack])

  useEffect(
    () =>
      listen(() => {
        if (showModal) {
          hide()
        }
      }),
    [showModal, hide, listen]
  )

  const handleSubmitOrder = () => {
    setDisabledButton(true)
    return submitOrder()
  }

  return (
    <Modal size={'lg'} onHide={justBack} show={showModal}>
      <Modal.Header closeButton>
        <Modal.Title as={'h5'}>Horário limite do pedido</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Seu pedido está sendo realizado após às <b>20:00</b>, a sua entrega será adiada de amanhã
          para o dia útil seguinte
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={justBack} variant="secondary">
          Voltar
        </Button>
        <Button onClick={() => handleSubmitOrder()} disabled={disabledButton} variant="primary">
          Continuar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
