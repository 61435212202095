import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DayOff, Popup } from '../data/types'

type State = Popup

const popupSlice = createSlice({
  name: 'popup',
  initialState: { showModal: false, dayOff: {} as DayOff } as State,
  reducers: {
    setHide: (state, action: PayloadAction<undefined>): State => {
      return { ...state, showModal: false }
    },
    setShow: (state, action: PayloadAction<undefined>): State => {
      return { ...state, showModal: true }
    },
    setDayOff: (state, action: PayloadAction<DayOff>): State => {
      const dayOff = action.payload
      return { ...state, dayOff }
    },
  },
})

export const popupReducer = popupSlice.reducer
export const popupActions = popupSlice.actions
