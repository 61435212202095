import { Button } from '@mui/material'

import gtm from '../../common/gtmTracker'
import { searchListActions } from '../../store/slices/searchList'
import { dispatch } from '../../store'
import { useAppSelector } from '../../common/hooks'
import { ShoppingListIconDesktop } from '../molecules/gridHeader/headerIcons/ShoppingListDesktop'

export const ShoppingListButtonDesktop = () => {
  const { merchantCode, clubberEmail } = useAppSelector((state) => state.session)

  const handleClick = () => {
    dispatch(searchListActions.setIsOpen(true))
    gtm.triggerShoppingListClick(merchantCode, clubberEmail)
  }

  return (
    <Button onClick={handleClick}>
      <ShoppingListIconDesktop />
    </Button>
  )
}
