import { clubbiStyled } from 'clubbi-ui'

export const StyledNotFoundContainer = clubbiStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brandAlert[10],
  border: `1px solid ${theme.palette.brandAlert[50]}`,
  borderRadius: '0.5rem',
  padding: '16px',
  fontSize: theme.typography.fontSize2XS,
  lineHeight: '20px',
  margin: '16px',
}))

export const StyledNotFoundTitle = clubbiStyled('p')(({ theme }) => ({
  color: theme.palette.brandAlert[50],
  fontSize: theme.typography.fontSizeS,
  fontWeight: 600,
  lineHeight: '30px',
  margin: '0',
}))

export const StyledNotFoundText = clubbiStyled('p')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  lineHeight: '20px',
  margin: '0',
  paddingTop: '8px',
}))

export const StyledNotFoundList = clubbiStyled('li')({
  marginLeft: '16px',
})
