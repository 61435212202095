import { RecommendedProducts } from './RecommendedProducts'
import {
  StyledDrawerRecommended,
  StyledTitle,
  StyleMainContainer,
} from './styles/recommendedCorridor.style'

export const RecommendedCorridor = () => {
  return (
    <StyleMainContainer data-testid="recommeded-corridor">
      <StyledDrawerRecommended>
        <StyledTitle>Recomendados Para você</StyledTitle>
        <RecommendedProducts />
      </StyledDrawerRecommended>
    </StyleMainContainer>
  )
}
