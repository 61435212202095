import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AlgoliaEvent } from '../data/types'

type State = {
  events: AlgoliaEvent[]
  queryId: string
}

const algoliaEventSlice = createSlice({
  name: 'algoliaEvent',
  initialState: { events: [], queryId: '' } as State,
  reducers: {
    addAlgoliaEvent: (state, action: PayloadAction<AlgoliaEvent>) => {
      const algoliaEvent = action.payload
      if (state.events) {
        const index = state.events.findIndex((item) => item.queryId == algoliaEvent.queryId)
        if (index >= 0) {
          if (state.events[index].objectIds.includes(algoliaEvent.objectIds[0])) return state
          return {
            ...state,
            events: [
              ...state.events.slice(0, index),
              {
                ...state.events[index],
                objectIds: state.events[index].objectIds.concat(algoliaEvent.objectIds[0]),
              },
              ...state.events.slice(index + 1),
            ],
          }
        }
      }

      return {
        ...state,
        events: state.events.concat(algoliaEvent),
      }
    },
    setQueryId: (state, action: PayloadAction<{ queryId: string }>) => {
      const { queryId } = action.payload

      return {
        ...state,
        queryId,
      }
    },
    cleanUpEvents: (state, _: PayloadAction<undefined>) => {
      return {
        ...state,
        events: [],
      }
    },
  },
})

export const algoliaEventReducer = algoliaEventSlice.reducer
export const algoliaEventActions = algoliaEventSlice.actions
