import { BoletoIcon, CreditCardIcon, PixIcon } from 'clubbi-ui'
import {
  StyledIconlessContainer,
  StyledSubLabel,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from './styles/ToggleButtonGroup.style'

import { ClubbiToggleButtonGroupProps } from './interfaces'

const getIcon = (value: any) => {
  switch (value) {
    case 0:
      return <BoletoIcon />
    case 1:
      return <CreditCardIcon />
    case 2:
      return <PixIcon />

    default:
      break
  }
}

export const ClubbiToggleButtonGroup = ({
  id,
  hasIcon,
  value,
  paymentLabels,
  onChange,
}: ClubbiToggleButtonGroupProps) => {
  return (
    <StyledToggleButtonGroup exclusive value={value} id={id} onChange={onChange} fullWidth>
      {paymentLabels.map(({ id: itemId, label, subLabel }) => (
        <StyledToggleButton
          isFiscal={id === 'invoiceType'}
          id={`${id}-${itemId}`}
          value={itemId}
          key={`${id}-${itemId}`}
        >
          {hasIcon ? (
            <>
              <span className="payment-icon">{getIcon(itemId)}</span>
              <span>{label}</span>
            </>
          ) : (
            <StyledIconlessContainer>
              <span>{label}</span>
              {subLabel && <StyledSubLabel>{subLabel}</StyledSubLabel>}
            </StyledIconlessContainer>
          )}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  )
}
