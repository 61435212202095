import { StyledSaveButton, StyledTooltip } from './styles/SaveButton.style'
import { useLocation } from 'react-router'
import {
  areDeliveryDaysValid,
  areRequiredContactsFilled,
  getPatchMerchantBody,
  getPatchMerchantDeliveryRestrictionBody,
  isBreakValid,
  isTotalDeliveryWindowValid,
} from './helpers'

import { useAppSelector } from '../../../common/hooks'
import { AlertSnackbar } from './AlertSnackbar'
import { ConfirmationModal } from './ConfirmationModal'
import { patchDeliveryRestrictions, patchMerchant } from '../../../services/merchantApi'
import { useState } from 'react'
import { themeClubbi, useMediaQuery } from 'clubbi-ui'

export const SaveButton = () => {
  const [isSaving, setIsSaving] = useState(false)
  const [openOkSnackBar, setOpenOkSnackBar] = useState(false)
  const [openErrorSnackBar, setOpenErrorSnackBar] = useState(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const { merchantCode } = useAppSelector((state) => state.session)
  const { merchant, merchantDeliveryRestriction, merchantContacts, hasBreak } = useAppSelector(
    (state) => state.profile
  )
  const isContactPage = useLocation().pathname == '/profile/contacts'
  const isMobile = useMediaQuery(themeClubbi.breakpoints.down('md'))

  const handleResponse = async (response: Response) => {
    if (response.ok) setOpenOkSnackBar(true)
    else {
      setOpenErrorSnackBar(true)
      const errorMessage = await response.text()
      throw new Error(errorMessage)
    }
  }

  const handleSaveContactPageForm = async () => {
    const patchMerchantBody = getPatchMerchantBody(merchant, merchantContacts)
    const response = await patchMerchant(merchantCode, patchMerchantBody)
    await handleResponse(response)
  }

  const handleSaveProfilePageForm = async () => {
    if (merchantDeliveryRestriction) {
      const patchMerchantDeliveryRestrictionBody = getPatchMerchantDeliveryRestrictionBody(
        merchantDeliveryRestriction,
        hasBreak
      )
      const response = await patchDeliveryRestrictions(
        merchantCode,
        patchMerchantDeliveryRestrictionBody
      )
      await handleResponse(response)
    }
  }

  const handleSaveForm = async () => {
    setIsSaving(true)
    if (isContactPage) await handleSaveContactPageForm()
    else setOpenConfirmationModal(true)
    setIsSaving(false)
  }

  const isSaveButtonDisabled = isContactPage
    ? !areRequiredContactsFilled(merchantContacts)
    : !(
        areDeliveryDaysValid(merchantDeliveryRestriction) &&
        isTotalDeliveryWindowValid(hasBreak, merchantDeliveryRestriction) &&
        isBreakValid(hasBreak, merchantDeliveryRestriction)
      )

  return (
    <>
      <StyledTooltip
        title="Você precisa revisar alguns dos campos acima para salvar as alterações."
        placement={isMobile ? 'top' : 'top-start'}
        disableHoverListener={!isSaveButtonDisabled}
        disableTouchListener={!isSaveButtonDisabled}
        disableFocusListener
        enterTouchDelay={10}
      >
        <span>
          <StyledSaveButton
            label={isSaving ? 'Salvando...' : 'Salvar'}
            typeButton="filled"
            size="medium"
            onClick={handleSaveForm}
            disabled={isSaveButtonDisabled}
          />
        </span>
      </StyledTooltip>
      <AlertSnackbar
        openOkSnackBar={openOkSnackBar}
        setOpenOkSnackBar={setOpenOkSnackBar}
        openErrorSnackBar={openErrorSnackBar}
        setOpenErrorSnackBar={setOpenErrorSnackBar}
      />
      <ConfirmationModal
        handleSave={handleSaveProfilePageForm}
        open={openConfirmationModal}
        setOpen={setOpenConfirmationModal}
      />
    </>
  )
}
