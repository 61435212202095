import {
  StyledContainer,
  StyledFromToContainer,
  StyledFromToLabel,
  StyledLabel,
  StyledMenuItem,
  StyledSelect,
} from './styles/TimeRestriction.style'

import { MerchantDeliveryRestrictionKey, TimeRestrictionProps } from './interfaces'
import { SelectChangeEvent } from 'clubbi-ui'
import { SATURDAY_VALUE } from './constants'
import { useAppDispatch, useAppSelector } from '../../../common/hooks'
import { profileActions } from '../../../store/slices/profile'
import { getTimeSelectionData } from './helpers'

const FromToSelect = ({
  dropdownKey,
  label,
  isReadOnly,
}: {
  dropdownKey: MerchantDeliveryRestrictionKey
  label: string
  isReadOnly?: boolean
}) => {
  const dispatch = useAppDispatch()
  const { merchantDeliveryRestriction } = useAppSelector((state) => state.profile)

  const handleChange = (e: SelectChangeEvent) => {
    dispatch(
      profileActions.updateMerchantDeliveryRestrictions({
        ...merchantDeliveryRestriction,
        [dropdownKey]: e.target.value,
      })
    )
  }
  return (
    <>
      <StyledFromToLabel>{label}</StyledFromToLabel>
      <StyledSelect
        inputProps={{ IconComponent: () => null }}
        value={merchantDeliveryRestriction?.[dropdownKey]}
        onChange={(event: SelectChangeEvent<any>) => handleChange(event)}
        className="select"
        variant="outlined"
        data-testid={`${dropdownKey}-select`}
        readOnly={isReadOnly}
        MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
        isReadOnly={isReadOnly}
      >
        {getTimeSelectionData(dropdownKey, merchantDeliveryRestriction!).map(
          ({ value, label }, ind, arr) => (
            <StyledMenuItem isLast={ind === arr.length - 1} key={value} value={value}>
              {label}
            </StyledMenuItem>
          )
        )}
      </StyledSelect>
    </>
  )
}

export const TimeRestriction = ({
  startKey,
  finishKey,
  fromLabel,
  title,
  toLabel,
}: TimeRestrictionProps) => {
  const { merchantDeliveryRestriction } = useAppSelector((state) => state.profile)
  const isReadOnly =
    (startKey == 'saturdayClosure' &&
      merchantDeliveryRestriction?.deliveryDaysRestriction?.includes(SATURDAY_VALUE)) ??
    false

  return (
    <StyledContainer isReadOnly={isReadOnly}>
      <StyledLabel>{title}</StyledLabel>
      <StyledFromToContainer>
        <FromToSelect dropdownKey={startKey} label={fromLabel} isReadOnly={isReadOnly} />
        {finishKey && toLabel && <FromToSelect dropdownKey={finishKey} label={toLabel} />}
      </StyledFromToContainer>
    </StyledContainer>
  )
}
