// TODO reuse
import styled from 'styled-components'
import { Form } from 'react-bootstrap'

const ProductTitle = styled.span`
  display: -webkit-box;
  min-height: 44px;
  max-height: 44px;
  overflow: hidden;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #2a2a2f;

  :hover {
    cursor: pointer;
    text-decoration-line: underline;
  }
`

export { ProductTitle }

export const QuantityInput = styled(Form.Control)`
  color: #626266;
  appearance: none;
  margin: 0;
  -moz-appearance: textfield;
  width: 100px;
  min-width: 60px;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
