import { clubbiStyled, Modal, CloseIcon } from 'clubbi-ui'

export const StyledModal = clubbiStyled(Modal)(({ theme }) => ({
  border: 'none',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  outlineColor: theme.palette.brandWhite.main,
}))

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  width: '90vw',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '90vh',
  overflowY: 'auto',
  position: 'absolute',
  top: '10px',

  background: theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    width: '650px',
  },
}))

export const StyledHeader = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  padding: '10px',
  fontSize: theme.typography.fontSizeM,
  borderBottom: `1px solid ${theme.palette.brandGrey[20]}`,

  background: theme.palette.brandPurple[10],

  [theme.breakpoints.up('sm')]: {
    alignItems: 'center',
    fontSize: theme.typography.fontSizeL,
    paddingLeft: '30px',
  },
}))

export const StyledCloseButton = clubbiStyled('button')(({ theme }) => ({
  textDecoration: 'underline',
  border: 'none',

  background: theme.palette.brandPurple[10],
}))

export const StyledCloseIcon = clubbiStyled(CloseIcon)(() => ({
  width: '30px',
}))

export const StyledContent = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'start',
  marginTop: '10px',
  padding: '30px',

  background: theme.palette.brandWhite.main,
}))
