import { useLocation } from 'react-router'

import { useAppSelector } from '../../common/hooks'
import { Mission } from '../../store/data/types'
import { SelectMissionButton } from '../atoms/SelectMissionButton'
import gtm from '../../common/gtmTracker'

import {
  StyledMissionCard,
  StyledPromotion,
  StyledDescription,
  StyledPromotionHeader,
  StyledContainerButtons,
  StyledSeeProducts,
} from './styles/MissionCard.style'
import { MissionPromo } from './MissionPromo'

interface MissionCardProps {
  mission: Mission
}

export const MissionCard = ({ mission }: MissionCardProps) => {
  const { merchantCode, clubberEmail } = useAppSelector((state) => state.session)
  const { pathname } = useLocation()

  const { id, description, criteria, customerIds } = mission
  const [{ selected }] = customerIds

  const isCheckoutPage = pathname === '/cart'
  const isMissionTooltipPage = pathname === '/' || isCheckoutPage

  const handleClick = () => {
    if (merchantCode) {
      isCheckoutPage
        ? gtm.triggerMissionClickOnCheckout(mission, merchantCode, clubberEmail)
        : gtm.triggerMissionClick(mission, merchantCode, clubberEmail)
    }
  }

  return (
    <StyledMissionCard isMissionTooltipPage={isMissionTooltipPage}>
      <StyledPromotion className="promotion">
        <StyledPromotionHeader>
          <MissionPromo mission={mission} />
          <StyledContainerButtons>
            <SelectMissionButton missionId={id} selected={selected} />
          </StyledContainerButtons>
        </StyledPromotionHeader>
      </StyledPromotion>
      <StyledDescription className="description" location={isMissionTooltipPage}>
        <div>
          {description}
          {criteria !== 'NONE' && (
            <StyledSeeProducts to={`/mission/${id}`} onClick={handleClick}>
              Ver produtos
            </StyledSeeProducts>
          )}
        </div>
      </StyledDescription>
    </StyledMissionCard>
  )
}
