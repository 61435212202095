import { apiGet } from './api'

type CreditData = {
  remainingCredit: number
  initialCredit: number
}

interface GetMerchantCreditInfoParams {
  merchantCode: string
  cartValue: number
}

export const getMerchantCreditInfo = async ({
  merchantCode,
  cartValue,
}: GetMerchantCreditInfoParams): Promise<CreditData | undefined> => {
  const response = await apiGet(`/merchants/${merchantCode}/credit?cart_value=${cartValue}`)

  if (response) {
    return {
      remainingCredit: response.remainingCredit,
      initialCredit: response.initialCredit,
    } as CreditData
  }
  return undefined
}
