import React, { ReactElement } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import {
  StyledAditionalInfoCard,
  StyledButton,
  StyledCardMedia,
  StyledDiv,
  StyledMain,
  StyledTypography,
} from './CardDetails.style'
import { Popover } from 'clubbi-ui'

interface CardDetailsProps {
  anchorEl: HTMLElement
  openPopover: boolean
  handlePopoverClose: () => void
  title: string
  image: string
  additionalInfo?: ReactElement
}

export const CardDetails = ({
  anchorEl,
  openPopover,
  handlePopoverClose,
  title,
  image,
  additionalInfo,
}: CardDetailsProps) => {
  return (
    <Popover
      open={openPopover}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      data-testid="details-popover"
    >
      <StyledMain>
        <StyledButton onClick={handlePopoverClose}>
          <CloseIcon />
        </StyledButton>
        <StyledCardMedia component="img" image={image} alt={title} style={{ width: 'auto' }} />
        <StyledTypography>{title}</StyledTypography>
        {additionalInfo && (
          <StyledDiv>
            <StyledAditionalInfoCard>{additionalInfo}</StyledAditionalInfoCard>
          </StyledDiv>
        )}
      </StyledMain>
    </Popover>
  )
}
