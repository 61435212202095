import { useCallback } from 'react'
import {
  ProfileIcon,
  Popover,
  StoreIcon,
  PeopleIcon,
  TruckIcon,
  CartCheckoutIcon,
  HelpIcon,
  ChatIcon,
} from 'clubbi-ui'

import { ClubberSection } from './ClubberSection'
import { CLUBBI_HELP_LINK, CX_WHATSAPP_NUMBER } from '../../../common/constants'
import { useAppDispatch, useAppSelector } from '../../../common/hooks'
import { cartActions } from '../../../store/slices/cart'
import { sessionActions } from '../../../store/slices/session'

import {
  StyleCode,
  StyledContainerLogged,
  StyledDiv,
  StyledExit,
  StyledHeader,
  StyledHr,
  StyledOptionLink,
  StyledLink,
  StyledRow,
  StyleName,
} from './style/LoginPopover.style'
import { getSeasonStyle } from '../../../common/seasonality'

interface LoginPopoverProps {
  anchorEl: HTMLElement
  openPopover: boolean
  handlePopoverClose: () => void
}

export const LoginPopover = ({ anchorEl, openPopover, handlePopoverClose }: LoginPopoverProps) => {
  const name = useAppSelector((state) => state.session.merchantName)
  const { merchantCode, clubberEmail } = useAppSelector((state) => state.session)
  const dispatch = useAppDispatch()
  const season = getSeasonStyle()

  const showModal = useCallback(() => {
    dispatch(sessionActions.showModal(false))
    handlePopoverClose()
  }, [dispatch])

  return (
    <Popover
      open={openPopover}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handlePopoverClose}
      style={{ zIndex: '99999' }}
    >
      {merchantCode && (
        <>
          {clubberEmail && <ClubberSection />}
          <StyledContainerLogged data-cy="login-popover">
            <StyledRow>
              <StyledDiv>
                <ProfileIcon color={season.iconColor} />
              </StyledDiv>
              <StyledHeader>
                <StyleName>{name}</StyleName>
                <StyleCode>{`Código: ${merchantCode}`}</StyleCode>
              </StyledHeader>
            </StyledRow>
            <StyledHr />
            <StyledRow>
              <StoreIcon />
              <StyledLink to={'/profile'}>Dados da Empresa</StyledLink>
            </StyledRow>
            <StyledRow>
              <PeopleIcon />
              <StyledLink to={'/profile/contacts'}>Dados de Contatos</StyledLink>
            </StyledRow>
            <StyledRow>
              <CartCheckoutIcon />
              <StyledLink to={'/pedidos-em-aberto'}>Pedidos em Aberto</StyledLink>
            </StyledRow>
            <StyledRow>
              <TruckIcon />
              <StyledLink to={'/invoices'}>Minhas entregas</StyledLink>
            </StyledRow>
            <StyledRow>
              <HelpIcon />
              <StyledOptionLink href={CLUBBI_HELP_LINK} target="_blank" rel="noopener noreferrer">
                Central de Ajuda
              </StyledOptionLink>
            </StyledRow>
            <StyledRow>
              <ChatIcon />
              <StyledOptionLink
                href={`https://wa.me/${CX_WHATSAPP_NUMBER}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Fale com a gente
              </StyledOptionLink>
            </StyledRow>
            <StyledHr />
            <StyledExit
              onClick={() => {
                dispatch(sessionActions.clearMerchantData())
                dispatch(cartActions.cleanUp())
                showModal()
              }}
            >
              sair
            </StyledExit>
          </StyledContainerLogged>
        </>
      )}
    </Popover>
  )
}
