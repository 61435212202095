import { RecommendedProducts } from '../recommendedCorridor/RecommendedProducts'
import {
  StyledDrawerRecommended,
  StyleMainContainer,
  StyledTitle,
} from './styles/StyledCarouselContent.styles'

export const RecommendedCorridorMyDeliveryPage = () => {
  return (
    <StyleMainContainer data-testid={'recommended-carousel'}>
      <StyledDrawerRecommended>
        <StyledTitle>Que tal fazer um pedido?</StyledTitle>
        <RecommendedProducts />
      </StyledDrawerRecommended>
    </StyleMainContainer>
  )
}
