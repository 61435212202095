import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AllowedPurchaseBanner } from '../data/types'

type State = AllowedPurchaseBanner

const allowedPurchaseBannerSlice = createSlice({
  name: 'allowedPurchaseBanner',
  initialState: { showBanner: false } as State,
  reducers: {
    setHide: (state, action: PayloadAction<undefined>): State => {
      return { ...state, showBanner: false }
    },
    setShow: (state, action: PayloadAction<undefined>): State => {
      return { ...state, showBanner: true }
    },
  },
})

export const allowedPurchaseBannerReducer = allowedPurchaseBannerSlice.reducer
export const allowedPurchaseBannerActions = allowedPurchaseBannerSlice.actions
