import AcceptModalForm from './AcceptModalForm'
import AcceptModalThanks from './AcceptModalThanks'
import { Modal } from 'react-bootstrap'
import ModalHeader from 'react-bootstrap/ModalHeader'
import { AcceptModalProps } from './types'

const AcceptModal = ({
  isModalOpened,
  setIsModalOpened,
  handleInputChange,
  handleSubmit,
  neutralizeBack,
  revivalBack,
  validated,
  errors,
  isLoading,
  page,
}: AcceptModalProps) => {
  return (
    <Modal
      centered
      size={'sm'}
      onEnter={() => neutralizeBack()}
      onHide={() => {
        setIsModalOpened(false)
        revivalBack()
      }}
      show={isModalOpened}
    >
      <ModalHeader closeButton>
        <Modal.Title>Confirmar fatura</Modal.Title>
      </ModalHeader>
      {page === 0 && (
        <AcceptModalForm
          validated={validated}
          handleInputChange={handleInputChange}
          errors={errors}
          isLoading={isLoading}
          handleSubmit={handleSubmit}
        />
      )}
      {page === 1 && <AcceptModalThanks />}
    </Modal>
  )
}
export default AcceptModal
