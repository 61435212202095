import { InvoiceSummary } from '../../../store/data/types'
import React, { useState } from 'react'
import { Collapse } from 'clubbi-ui'
import {
  StyledOptionButton,
  StyledNfeLink,
  StyledLi,
  StyledUl,
  StyledWrapperButtons,
  StyledCollapseWrap,
} from './styles/DesktopLinkBoard.style'

import { InvoiceCardLink } from './InvoiceCardLink'
import { Option } from './InvoiceCardLinkBoard'

export const DesktopLinkBoard = ({
  options,
  invoice,
}: {
  options: Option[]
  invoice: InvoiceSummary
}) => {
  const { shopperPlanId, nfeUrls } = invoice

  const [planCardNfIsOpen, setPlanCardNfIsOpen] = useState(false)

  return (
    <StyledWrapperButtons>
      {options.map((option, index) =>
        option.subOptions.length <= 0 ? (
          <InvoiceCardLink key={index} option={option} />
        ) : (
          <StyledOptionButton
            key={option.label}
            data-shopper-plan={shopperPlanId}
            onClick={(event: any) => {
              if (event.target instanceof HTMLSpanElement) {
                return setPlanCardNfIsOpen(!planCardNfIsOpen)
              }
            }}
            aria-controls="collapse-text"
            aria-expanded={planCardNfIsOpen}
          >
            <StyledCollapseWrap id={shopperPlanId} disabled={nfeUrls.length <= 0}>
              Doc. Fiscal
              <Collapse in={planCardNfIsOpen && nfeUrls.length > 0}>
                <div id="collapse-text">
                  <StyledUl>
                    {nfeUrls.map((url) => (
                      <StyledLi key={url}>
                        <StyledNfeLink href={url} target="_blank" rel="noreferrer">
                          {url.includes('amazon') ? 'Nota Fiscal' : 'Cupom Fiscal'}
                        </StyledNfeLink>
                      </StyledLi>
                    ))}
                  </StyledUl>
                </div>
              </Collapse>
            </StyledCollapseWrap>
          </StyledOptionButton>
        )
      )}
    </StyledWrapperButtons>
  )
}
