import { StyledOptionButton } from './styles/InvoiceCardLinkBoard.style'
import { StyledLink } from './styles/InvoiceCardLink.style'
import { Option } from './InvoiceCardLinkBoard'

export const InvoiceCardLink = ({ option }: { option: Option }) => {
  return (
    <StyledOptionButton key={option.label}>
      <StyledLink
        disabled={option.disabled}
        onClick={
          option.url
            ? () => window.open(option.url, '_blank')
            : () => {
                null
              }
        }
      >
        {option.label}
      </StyledLink>
    </StyledOptionButton>
  )
}
