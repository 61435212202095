import { Button, Card, Form } from 'react-bootstrap'
import { H3, Icon } from '../atoms'
import MaskedInput from 'react-maskedinput'
import React, { useCallback, useState } from 'react'
import { sendNewClientLead } from '../../services/newClient'
import { useHistory } from 'react-router-dom'

export interface NewClientData {
  name: string
  email: string
  phone: string
}

export interface Props {
  setData: (d: NewClientData | undefined) => void
}

type _FormType = {
  email: HTMLInputElement
  name: HTMLInputElement
  phone: HTMLInputElement
}

export const NewClientForm = ({ setData }: Props) => {
  const { goBack } = useHistory()

  const [validated, setValidated] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(
    (e: React.SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()
      setLoading(true)

      const target = e.target as typeof e.target & _FormType

      const { email, name, phone } = target

      const data = {
        email: email.value,
        name: name.value,
        phone: phone.value,
      }
      sendNewClientLead(data)
        .then(() => setData(data))
        .finally(() => setLoading(false))
    },
    [setData]
  )

  return (
    <Form onSubmit={onSubmit} validated={validated}>
      <Card.Body>
        <Button variant="outline-primary" className={'mb-4'} onClick={goBack}>
          <Icon.ChevronLeft className={'mr-2'} />
          Voltar
        </Button>
        <H3>Olá! Parece que nós não nos conhecemos ainda...</H3>
        <span>
          <Card.Text>
            Nós podemos entrar em contato com a sua empresa! Só precisamos de algumas informações:
          </Card.Text>
        </span>
        <Form.Group>
          <Form.Label>Nome:</Form.Label>
          <Form.Control
            type={'text'}
            minLength={3}
            name={'name'}
            placeholder="Informe o seu nome"
            required={true}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Telefone:</Form.Label>
          <Form.Control
            as={MaskedInput}
            type={'tel'}
            name={'phone'}
            minLength={10}
            mask={'(11) 1111-11111'}
            pattern="\(\d{2}\) \d{4}-\d{4,5}[_]?"
            placeholder={'(00) 0000-00000'}
            required={true}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email:</Form.Label>
          <Form.Control
            type={'email'}
            name={'email'}
            placeholder="contato@suaempresa.com.br"
            // pattern='\w+[@]\w+'
            required={true}
          />
        </Form.Group>
      </Card.Body>
      <Card.Footer>
        <Button
          variant="secondary"
          size="lg"
          type={'submit'}
          onClick={() => setValidated(true)}
          disabled={loading}
        >
          <Icon.TelephoneForward size="1.5em" className="mr-2" />
          Entrar em contato
        </Button>
      </Card.Footer>
    </Form>
  )
}
