import { StyledBadge } from './DiscountBadge.style'

interface DiscountBadgeProps {
  discount: number
}

export const DiscountBadge = ({ discount }: DiscountBadgeProps) => {
  return (
    <StyledBadge>
      <span>- {Math.trunc(discount * 100)}%</span>
    </StyledBadge>
  )
}
