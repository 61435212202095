import {
  StyledContainer,
  StyledHR,
  StyledOptionalLabel,
  StyledPhoneContainer,
} from './styles/PhonesInfo.style'
import { PhonesInfoProps } from './interfaces'
import { ProfileTextInput } from './ProfileTextInput'

import { OPTIONAL_LABEL, contactsData } from './constants'
import { useAppSelector } from '../../../common/hooks'
import { ContactType } from '../../../store/data/types'
import { themeClubbi, useMediaQuery } from 'clubbi-ui'

export const OptionalLabel = () => <StyledOptionalLabel>{OPTIONAL_LABEL}</StyledOptionalLabel>

export const PhonesInfo = ({ isContactPage }: PhonesInfoProps) => {
  const { merchantContacts } = useAppSelector((state) => state.profile)
  const isDesktop = useMediaQuery(themeClubbi.breakpoints.up('md'))

  const getMerchantContact = (tag: ContactType) =>
    merchantContacts?.find(({ contactType }) => contactType === tag)?.phone

  return (
    <StyledContainer isContactPage={isContactPage}>
      {contactsData.map(({ tag, label, isOptional }) => (
        <>
          <StyledPhoneContainer key={tag}>
            <ProfileTextInput
              label={label}
              readOnly={false}
              defaultContent={getMerchantContact(tag) ?? ''}
              width={'40%'}
              name={tag}
              isContact
              isOptional={isOptional}
            />
            {isOptional && isDesktop && <OptionalLabel />}
          </StyledPhoneContainer>
          <StyledHR />
        </>
      ))}
    </StyledContainer>
  )
}
