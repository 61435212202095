import { clubbiStyled } from 'clubbi-ui'
import { Money } from '../../../atoms'

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '14px',
  borderRadius: '4px',
  gap: '4px',
  backgroundColor: theme.palette.brandWhite.main,
  [theme.breakpoints.up('md')]: {
    padding: '20px',
  },
}))

export const StyledRowContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginTop: '8px',
  fontSize: '12px',
  lineHeight: '13.19px',

  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '15.39px',
    marginTop: '20px',
  },
}))

export const StyledLimit = clubbiStyled('div')<{ align: string }>(({ align }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: align,
  justifyContent: 'center',
  gap: '4px',
  padding: '4px',
}))

export const StyledTotalLimitText = clubbiStyled('div')(({ theme }) => ({
  marginTop: '14px',
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  fontSize: '12px',
  lineHeight: '13.19px',
  gap: '4px',
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '15.39px',
    marginTop: '16px',
  },
}))

export const StyledTitle = clubbiStyled('p')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontStyle: 'normal',
  fontSize: '16px',
  lineHeight: '15.39px',
  color: theme.palette.brandBlack.main,
  textAlign: 'left',
  marginTop: '3px',
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
    lineHeight: '17.58px',
  },
}))

export const StyledLabelText = clubbiStyled('p')(({ theme }) => ({
  color: theme.palette.brandGrey[100],
  textAlign: 'left',
  margin: 0,
}))

export const StyledSubtitleText = clubbiStyled('p')(({ theme }) => ({
  fontWeight: 400,
  fontStyle: 'normal',
  fontSize: '10px',
  lineHeight: '10.99px',
  color: theme.palette.brandGrey[60],
  textAlign: 'left',
  margin: 0,
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '15.39px',
    fontWeight: 500,
  },
}))

export const StyledMoney = clubbiStyled(Money)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '12px',
  lineHeight: '13.19px',
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '15.39px',
  },
}))
