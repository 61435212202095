import { Tag, themeClubbi } from 'clubbi-ui'
import {
  StyledArrowButton,
  StyledContainer,
  StyledDiv,
  StyledHeader,
  StyledLink,
  StyledMain,
} from './SubCategoriesTags.style'
import { useAppSelector } from '../../../common/hooks'
import { useLocation } from 'react-router'
import gtm from '../../../common/gtmTracker'
import { filterSubCategories } from '../../../common/getCategories'
import { useEffect, useRef, useState } from 'react'
import {
  handleScrollTo,
  hasHorizontalScroll,
  showOrHideArrowsByScroll,
} from '../../../common/ScrollUtils'

const SubCategoriesTags = (section: any) => {
  const [categories, merchantCode, clubberEmail] = useAppSelector((state) => [
    state.categories,
    state.session.merchantCode,
    state.session.clubberEmail,
  ])

  const currentPage = useLocation().pathname
  const subcatgCorridor = useRef(null)

  const [leftButton, setLeftButton] = useState(false)
  const [rightButton, setRightButton] = useState(true)

  const categoriesList = filterSubCategories(categories, section)

  const handleClick = (category: string) => {
    gtm.triggerCategoriesClick(
      merchantCode!,
      currentPage,
      'subcategories-tags',
      category,
      clubberEmail
    )
  }

  useEffect(() => {
    const element: any = subcatgCorridor.current

    if (!hasHorizontalScroll(element)) {
      setRightButton(false)
      setLeftButton(false)
    }
  }, [])

  const handleScrollBar = (_e: any) => {
    const element: any = subcatgCorridor.current
    showOrHideArrowsByScroll(element, setRightButton, setLeftButton)
  }

  const handleScrollToRight = () => {
    const element: any = subcatgCorridor.current
    handleScrollTo(element, 'right')
  }

  const handleScrollToLeft = () => {
    const element: any = subcatgCorridor.current
    handleScrollTo(element, 'left')
  }

  return (
    <StyledMain>
      <StyledHeader>
        <span>Sub Categorias</span>
      </StyledHeader>
      <StyledDiv>
        {leftButton && (
          <StyledArrowButton
            direction="left"
            onClick={handleScrollToRight}
            color={themeClubbi.palette.brandWhite.main}
          />
        )}
        <StyledContainer ref={subcatgCorridor} onScroll={handleScrollBar}>
          {categoriesList.map((category, index) => (
            <StyledLink key={index} to={category.path}>
              <Tag label={category.label} onClick={() => handleClick(category.label)} />
            </StyledLink>
          ))}
        </StyledContainer>
        {rightButton && (
          <StyledArrowButton
            direction="right"
            onClick={handleScrollToLeft}
            color={themeClubbi.palette.brandWhite.main}
          />
        )}
      </StyledDiv>
    </StyledMain>
  )
}

export default SubCategoriesTags
