import { clubbiStyled } from 'clubbi-ui'

export const StyledRatingModule = clubbiStyled('div')(() => ({
  display: 'flex',
  gap: '4px',
}))

export const StyledCardHeader = clubbiStyled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '4px',
}))

export const StyledEvaluationLabel = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXXS,
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
}))
