import { clubbiStyled } from 'clubbi-ui'

export const StyledInputBase = clubbiStyled('div')(() => ({
  textAlign: 'start',
  border: 'none',
  padding: '12px 0 ',
  paddingLeft: '20px',
}))

export const StyledInput = clubbiStyled('input')(({ theme }) => ({
  textAlign: 'start',
  border: `1px solid ${theme.palette.brandPurple.main}`,
  width: '90%',
  borderRadius: '4px',
  fontSize: '14px',
  lineHeight: '21.23px',
  padding: '5px',
  background: theme.palette.brandWhite.main,
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
  },
  '&:focus': {
    border: `2px solid ${theme.palette.brandPurple.main}`,
    outline: 'none',
  },
}))

export const StyledComplement = clubbiStyled('p')(({ theme }) => ({
  color: theme.palette.brandGrey[70],
  fontSize: '12px',
  margin: '0',
  padding: '2px',
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
  },
}))
