import { clubbiStyled, Spinner } from 'clubbi-ui'

export const StyledSpinner = clubbiStyled(Spinner)(({ theme }) => ({
  position: 'fixed',
  left: 'calc(45%)',
  top: 'calc(40%)',
  [theme.breakpoints.up('sm')]: {
    left: 'calc(48%)',
  },
}))
