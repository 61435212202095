import { clubbiStyled } from 'clubbi-ui'

export const StyledMissionHeadline = clubbiStyled('div')(() => ({
  display: 'flex',
  flexWrap: 'nowrap',
  gap: '5px',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '-5px',
}))

export const StyledTextPurple = clubbiStyled('div')<{ isCartPage?: boolean }>(
  ({ theme, isCartPage = false }) => ({
    color: theme.palette.brandPurple[60],
    fontSize: isCartPage ? '18px' : theme.typography.fontSizeXS,

    [theme.breakpoints.up('sm')]: {
      fontSize: isCartPage ? '16px' : theme.typography.fontSizeL,
    },
  })
)
