import { themeClubbi } from 'clubbi-ui'

interface Props {
  isSelected: boolean
}

export const HomeIcon = ({ isSelected }: Props) => {
  const color = isSelected ? themeClubbi.palette.brandPurple[60] : themeClubbi.palette.brandGrey[70]
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isSelected ? (
        <path d="M10 20.5V14.5H14V20.5H19V12.5H22L12 3.5L2 12.5H5V20.5H10Z" fill={color} />
      ) : (
        <path
          d="M12 6.19L17 10.69V18.5H15V12.5H9V18.5H7V10.69L12 6.19ZM12 3.5L2 12.5H5V20.5H11V14.5H13V20.5H19V12.5H22L12 3.5Z"
          fill={color}
        />
      )}
    </svg>
  )
}
