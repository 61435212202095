import { Stepper } from 'clubbi-ui'
import React from 'react'
import { orderSuccessfulSteps, orderUnderReviewSteps } from '../../../common/constants'
import { StyledContainer } from './styles/OrderStepper.style'

export interface OrderStepperProps {
  isOrderUnderReview: boolean
}

export const OrderStepper = ({ isOrderUnderReview }: OrderStepperProps) => {
  const activeStep = isOrderUnderReview ? 0 : 1
  const steps = isOrderUnderReview ? orderUnderReviewSteps : orderSuccessfulSteps

  return (
    <StyledContainer>
      <Stepper activeStep={activeStep} steps={steps} />
    </StyledContainer>
  )
}
