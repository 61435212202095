import { TagCategory } from 'clubbi-ui'
import { Link, useLocation } from 'react-router-dom'
import { StyledContainer, StyledHeader, StyledLink, StyledMain } from './ExploreCategories.style'
import { useAppSelector } from '../../../common/hooks'
import { Key } from 'react'
import { LocationDescriptor, Location } from 'history'
import gtm from '../../../common/gtmTracker'
import { filterExploreCategories } from '../../../common/getCategories'

interface categoryList {
  path: LocationDescriptor<unknown> | ((location: Location<unknown>) => LocationDescriptor<unknown>)
  colorBg: string
  label: string
  image: string | undefined
}

export const ExploreCategories = (...props: any) => {
  const [categories] = useAppSelector((state) => [state.categories])
  const { merchantCode, clubberEmail } = useAppSelector((state) => state.session)
  const currentPage = useLocation().pathname

  const isHomePage = currentPage == '/'

  const isExploreRoute = currentPage == '/explorar-as-categorias'

  const categoriesList = filterExploreCategories(categories)

  const handleClick = (category: string) => {
    gtm.triggerCategoriesClick(
      merchantCode!,
      currentPage,
      'explore-categories',
      category,
      clubberEmail
    )
  }

  return (
    <StyledMain isHomePage={isHomePage}>
      <StyledHeader>
        {(isHomePage || isExploreRoute) && (
          <>
            <span>🔍 Explore as categorias</span>
            {!isExploreRoute && (
              <Link to="/explorar-as-categorias">
                <span>{'Ver todos '}</span>
              </Link>
            )}
          </>
        )}
      </StyledHeader>
      <StyledContainer>
        {isHomePage &&
          categoriesList.map(
            (category: categoryList, index: Key | null | undefined) =>
              Number(index) < 10 && (
                <StyledLink key={index} to={category.path}>
                  <TagCategory
                    colorBg={category.colorBg}
                    label={category.label}
                    variantType="category"
                    image={category.image}
                    onClick={() => handleClick(category.label)}
                  />
                </StyledLink>
              )
          )}
        {isExploreRoute &&
          !isHomePage &&
          categoriesList.map((category: categoryList, index: Key | null | undefined) => (
            <StyledLink key={index} to={category.path}>
              <TagCategory
                colorBg={category.colorBg}
                label={category.label}
                variantType="category"
                image={category.image}
                onClick={() => handleClick(category.label)}
              />
            </StyledLink>
          ))}
      </StyledContainer>
    </StyledMain>
  )
}
