import { CategoriesMenuProps } from '../../organisms/layout/Layout'
import { CategoriesList } from './CategoriesList'
import { StyledMenu } from './styles/CategoriesMenu.style'

export const CategoriesMenu = ({ handleDrawerToggle, isOpen }: CategoriesMenuProps) => {
  return (
    <StyledMenu
      variant="temporary"
      data-testid="categories-menu"
      open={isOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile
      }}
    >
      <CategoriesList handleDrawerToggle={handleDrawerToggle} />
    </StyledMenu>
  )
}
