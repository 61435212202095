import { checkOrder, Order, OrderChangeResult } from '../../../services/ordersApi'
import { CartItem } from '../../../store/data/types'

export const compareProducts = async (
  cartItems: CartItem[],
  merchantCode: string | undefined,
  regionId: number | undefined,
  cartSessionId: string,
  clubberEmail: string | undefined
): Promise<any | null> => {
  if (!(merchantCode && regionId)) return null

  const payload: Order = {
    merchantCode,
    regionId,
    merchantStatus: '',
    invoiceType: 0,
    paymentType: 0,
    items: cartItems,
    sessionId: cartSessionId,
    clubberEmail,
  }

  const [resultType, result] = await checkOrder(payload)

  switch (resultType) {
    case OrderChangeResult.PRODUCT_CHANGES:
      return result
    case OrderChangeResult.NO_CHANGES:
      return null
    default:
      throw OrderChangeResult.UNEXPECTED
  }
}
