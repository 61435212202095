import { useEffect, useState } from 'react'
import { ProfileHeader } from './ProfileHeader'
import {
  StyledContainer,
  StyledDesktopSection,
  StyledGap,
  StyledHR,
  StyledSectionSubTitle,
  StyledSectionTitle,
  StyledSectionTitleContainer,
} from './styles/ProfileForm.style'
import { useAppDispatch, useAppSelector } from '../../../common/hooks'
import { merchantProfileDataThunk } from '../../../store/slices/profile'
import gtm from '../../../common/gtmTracker'
import { StyledSpinner } from '../../routes/LoadablePage'
import { useLocation } from 'react-router'
import { InputsForm } from './InputsForm'
import { WorkingHoursInfo } from './WorkingHoursInfo'
import {
  PROFILE_COMPANY_SECTION_SUBTITLE,
  PROFILE_COMPANY_SECTION_TITLE,
  PROFILE_CONTACT_SECTION_TITLE,
  getProfileTitle,
} from './constants'
import { PhonesInfo } from './PhonesInfo'
import { TruckIcon } from 'clubbi-ui'
import { SaveButton } from './SaveButton'
import { SubSectionTitle } from './SubSectionTitle'
import { areRequiredContactsFilled } from './helpers'

export const ProfileForm = () => {
  const [loading, setLoading] = useState(false)

  const { merchantCode, clubberEmail } = useAppSelector((state) => state.session)
  const dispatch = useAppDispatch()
  const { merchant, merchantContacts } = useAppSelector((state) => state.profile)
  const isContactPage = useLocation().pathname == '/profile/contacts'

  const fetchProfile = async () => {
    if (merchantCode && merchant?.merchantCode != merchantCode) {
      setLoading(true)
      await dispatch(merchantProfileDataThunk(merchantCode))
      gtm.triggerProfile(merchantCode, clubberEmail)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchProfile()
  }, [])

  useEffect(() => {
    fetchProfile()
  }, [merchantCode, dispatch, merchant?.merchantCode])

  return !loading && merchant ? (
    <StyledContainer>
      <ProfileHeader title={getProfileTitle(isContactPage)} />
      <StyledHR />
      <InputsForm isContact={isContactPage} />
      <StyledSectionTitleContainer>
        <StyledSectionTitle>
          {isContactPage ? (
            PROFILE_CONTACT_SECTION_TITLE
          ) : (
            <div>
              <StyledDesktopSection>
                <TruckIcon />
              </StyledDesktopSection>
              {PROFILE_COMPANY_SECTION_TITLE}
            </div>
          )}
        </StyledSectionTitle>
        {isContactPage ? (
          <SubSectionTitle
            firstSubtitle="Para garantir o recebimento das mensagens dos nossos canais de comunicação, é necessário preencher, pelo menos, os contatos "
            highlightedText="principal e financeiro"
            isFulfilled={areRequiredContactsFilled(merchantContacts)}
          />
        ) : (
          <StyledSectionSubTitle>{PROFILE_COMPANY_SECTION_SUBTITLE}</StyledSectionSubTitle>
        )}
      </StyledSectionTitleContainer>
      <WorkingHoursInfo isContactPage={isContactPage} />
      <PhonesInfo isContactPage={isContactPage} />
      <SaveButton />
      <StyledGap />
    </StyledContainer>
  ) : (
    <StyledSpinner animation={'border'} />
  )
}
