import { Product } from '../../store/data/types'
import { ProductsGrid } from '../templates/ProductsGrid'
import { LoadablePage, LoadablePageProps } from './LoadablePage'
import { getMoreItemsBetterPrices } from '../../services/productApi'
import React, { memo } from 'react'
import { StyledLinkToHome } from '../atoms/styles/LinkBackHomeDiv.style'
import { LinkBackHome } from '../atoms'

export const moreItemsBetterPricesFetch = async (): Promise<Props> => {
  const data = await getMoreItemsBetterPrices()
  return {
    products: data?.products ?? [],
  }
}

interface Props {
  products: Product[]
}

const _LinkHome = memo(() => (
  <StyledLinkToHome>
    <LinkBackHome />
  </StyledLinkToHome>
))

const _Grid = ({ products }: Props) => {
  const header = {
    node: <_LinkHome />,
    height: 63,
  }

  return <ProductsGrid context={['ofertoes']} data={products} header={header} />
}

export const MoreItemsBetterPrices = (props: LoadablePageProps<Props>) => {
  return (
    <LoadablePage {...props} dataFetch={moreItemsBetterPricesFetch}>
      {_Grid}
    </LoadablePage>
  )
}
