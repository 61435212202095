import { clubbiStyled } from 'clubbi-ui'

export const StyleContainerMissions = clubbiStyled('div')<{ isCartPage: boolean }>(
  ({ theme, isCartPage }) => ({
    paddingLeft: isCartPage ? '4px' : '18px',
    position: 'relative',

    [theme.breakpoints.up('sm')]: {
      padding: isCartPage ? '0px' : '0 32px 0 42px',
      margin: 0,
      marginBottom: isCartPage ? '10px' : '48px',
      marginTop: isCartPage ? '10px' : '0px',
    },
  })
)

export const StyledH3 = clubbiStyled('h3')<{ isCartPage: boolean }>(({ theme, isCartPage }) => ({
  fontSize: isCartPage ? '16px' : theme.typography.fontSizeM,
  fontWeight: '600',
  lineHeight: '40px',

  color: theme.palette.brandGrey[100],
  paddingLeft: isCartPage ? '18px' : '0',

  [theme.breakpoints.up('md')]: {
    fontSize: isCartPage ? '18px' : theme.typography.fontSizeL,
    fontWeight: '600',
    lineHeight: '22px',
    margin: 0,
    marginBottom: '16px',
    marginTop: isCartPage ? '20px' : 0,
  },
}))

export const StyledRow = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '5px',
  overflowX: 'auto',
  overflowY: 'hidden',

  '&::-webkit-scrollbar': {
    width: '5px',
    height: '5px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.brandGrey[40],
    borderRadius: '8px',
  },

  '&::-webkit-scrollbar-track': {
    height: '1px',
    borderRadius: '8px',
    background: 'transparent',
  },

  '&:hover::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.brandPurple[60],
  },

  [theme.breakpoints.up('sm')]: {
    gap: '10px',
    margin: '0',
  },
}))

export const StyledMissionProductsButton = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'start',
  marginLeft: '-6px',
}))
