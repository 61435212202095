import { useEffect, useState } from 'react'
import { useAppSelector } from '../../common/hooks'
import { StyledAlert } from './styles/SnackBar.style'
import { isMerchantBlocked } from '../../common/utils'

export interface Message {
  regionId?: number
  type: string
  title: string
  text: string
  dateToStart: Date | string
  dateToExpire: Date | string
  pricingGroupId?: number
}

export const SnackBar = () => {
  const [{ regionId, status, merchantCode }, messages] = useAppSelector((state) => [
    state.session,
    state.messages,
  ])

  const [message, setMessage] = useState<Message[]>([])

  const isDefaultingCustomer = isMerchantBlocked(status)

  useEffect(() => {
    const snackBarData = messages.filter(
      (message) => message.pricingGroupId === regionId && message.type === 'snackbar'
    )

    if (snackBarData.length) {
      setMessage(snackBarData)
    }
  }, [regionId])

  return (
    <>
      {!!(message.length && merchantCode && !isDefaultingCustomer) && (
        <StyledAlert severity="warning" icon={false}>
          <strong>{message[0].title}</strong> <span>{message[0].text}</span>{' '}
        </StyledAlert>
      )}
    </>
  )
}
