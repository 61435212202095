import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import '@fontsource/baloo-2'

import { StyledTextPurple } from './styles/MissionPromo.style'

const StyledModal = styled(Modal)`
  .modal-header {
    border: none;
    padding-bottom: 0px;
  }
  h5 {
    font-weight: bold;
    font-family: 'Baloo 2', Tahoma, Geneva, Verdana, sans-serif;
  }

  .modal-footer {
    border: none;
    align-self: center;
  }
`
const StyledButton = styled('button')`
  font-family: 'Baloo 2', sans-serif;
  background: #7c00f4;
  border-radius: 4px;
  width: 129px;
  height: 34px;
  font-size: 20px;
  font-weight: 700;
  color: #ffff;
  margin-right: 15%;
  margin-bottom: 3%;
  border: 4px;
`

export const MissionModal = ({
  showModal,
  setShowModal,
}: {
  showModal: boolean
  setShowModal: (value: boolean) => void
}) => {
  const handleClose = () => {
    setShowModal(!showModal)
  }
  return (
    <StyledModal size={'lg'} show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <StyledTextPurple>Entenda como funcionam as missões:</StyledTextPurple>
      </Modal.Header>
      <Modal.Body>
        <p>Para garantir os descontos das missões, é simples, basta seguir o passo a passo:</p>
        <ol>
          <li>
            <strong>Adicione os produtos</strong>
            <br /> Adicione os produtos participantes das promoções em seu carrinho
          </li>
          <li>
            <strong>Finalize a compra</strong>
            <br /> Ao finalizar a compra, você verá o desconto aplicado no carrinho automaticamente.{' '}
          </li>
          <br />
          <br />
          <strong>Atenção</strong>
          <br /> Cada oferta tem limite de 1 pedido por cliente
        </ol>
      </Modal.Body>
      <Modal.Footer>
        <StyledButton onClick={handleClose}>Entendi!</StyledButton>
      </Modal.Footer>
    </StyledModal>
  )
}
