import { clubbiStyled } from 'clubbi-ui'

export const StyledOptionButton = clubbiStyled('div')(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
}))

export const StyledWrapperButtons = clubbiStyled('span')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  display: 'none',
  fontFamily: `'${theme.typography.fontFamily}'`,
  justifyContent: 'flex-start',
  paddingTop: theme.spacing(5),
  marginBottom: theme.spacing(3.5),
  gap: '24px',
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
    gap: '24px',
    display: 'flex',
  },
}))

export const StyledNfeLink = clubbiStyled('a')(({ theme }) => ({
  color: theme.palette.brandPurple.main,
  fontWeight: 700,
}))

export const StyledUl = clubbiStyled('ul')(() => ({
  listStyleType: 'none',
  margin: 0,
  padding: 0,
}))

export const StyledLi = clubbiStyled('li')(({ theme }) => ({
  fontSize: theme.typography.fontSizeS,
}))

export const StyledCollapseWrap = clubbiStyled('span')<{ disabled: boolean }>(
  ({ disabled, theme }) => ({
    color: disabled ? theme.palette.brandGrey.main : theme.palette.brandPurple.main,
    fontWeight: 700,
  })
)
