import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { themeClubbi } from 'clubbi-ui'

import { NPS_GRADES } from '../../../common/constants'
import { useAppSelector } from '../../../common/hooks'
import { NpsThanksMessage } from './NpsThanksMessage'
import { modalController } from '../ModalManager'
import { NpsGoodGrade } from './NpsGoodGrade'
import { NpsBadGrade } from './NpsBadGrade'
import {
  checkNpsCheckout,
  checkNpsMain,
  dismissNps,
  NPS,
  putNpsFeedback,
  putNpsValue,
} from '../../../services/merchantApi'

import {
  StyledButton,
  StyledCloseButton,
  StyledCloseIcon,
  StyledContainer,
  StyledContainerButton,
  StyledContainerOptions,
  StyledContent,
  StyledErrorMessage,
  StyledHeader,
  StyledModal,
  StyledOptionButton,
} from './styles/NpsModal.style'

let shown = false

export const NpsModal = ({
  showNps,
  setShowNps,
}: {
  showNps: modalController
  setShowNps: (value: modalController | ((prevVar: modalController) => modalController)) => void
}) => {
  const [npsValue, setNpsValue] = useState<number>(-1)
  const [errorNpsValue, setErrorNpsValue] = useState<number>(-1)
  const [latestNps, setLatestNps] = useState<NPS>({ id: null, show: 0 })
  const _path = useLocation()
  const merchantCode = useAppSelector((state) => state.session.merchantCode)

  const handleShow = () => {
    setShowNps((state) => ({ ...state, showNps: true, showRatings: false }))
    shown = true
  }

  useEffect(() => {
    const fetchLatestNps = async () => {
      if (!merchantCode || shown) return
      if (latestNps.id === null) {
        const nps = await checkNpsMain(merchantCode)
        setLatestNps(nps)
        nps.show > 0 ? handleShow() : null
      } else {
        if (_path.pathname == '/checkout' && latestNps.id < 1) {
          const newId = await checkNpsCheckout(merchantCode, latestNps.id)
          setLatestNps(newId)
          newId.show ? handleShow() : null
        }
      }
    }
    fetchLatestNps()
  }, [_path.pathname, merchantCode])

  const handleClose = async () => {
    setShowNps((state) => ({ ...state, showNps: false }))
    shown = true
    await dismissNps(latestNps.id, {})
  }

  const preferNotToAnswerClick = async () => {
    setShowNps((state) => ({ ...state, showNps: false }))
    shown = false
    await putNpsFeedback(latestNps.id, {
      nps_comment: 'Prefiro não responder',
      nps_experience_feedback: { ['feedback']: 'Prefiro não responder' },
    })
    await putNpsValue(latestNps.id, {
      value: 9999,
    })
  }

  const submitNpsValue = async () => {
    if (npsValue == -1) {
      setErrorNpsValue(-2)
    } else {
      setErrorNpsValue(-1)
      await putNpsValue(latestNps.id, {
        value: npsValue,
      })
      if (npsValue > 8) {
        setShowNps((state) => ({ ...state, showNps: false, showGoodGrade: true }))
      } else {
        setShowNps((state) => ({ ...state, showNps: false, showBadGrade: true }))
      }
    }
  }

  return (
    <>
      <NpsGoodGrade showNps={showNps} setShowNps={setShowNps} latestNps={latestNps} />
      <NpsBadGrade showNps={showNps} setShowNps={setShowNps} latestNps={latestNps} />
      <NpsThanksMessage showNps={showNps} setShowNps={setShowNps} />
      <StyledModal open={showNps.showNps ?? false} onClose={handleClose}>
        <StyledContainer>
          <StyledHeader>
            Nos ajude a melhorar a sua experiência
            <StyledCloseButton onClick={handleClose}>
              <StyledCloseIcon color={themeClubbi.palette.brandGrey.main} />
            </StyledCloseButton>
          </StyledHeader>
          <StyledContent>
            De 0 a 10, quanto você recomendaria a Clubbi para um amigo ou conhecido?
            {errorNpsValue == -2 && (
              <StyledErrorMessage>Escolha uma nota de 0 a 10</StyledErrorMessage>
            )}
            <StyledContainerOptions>
              {NPS_GRADES.map((grade, index) => (
                <StyledOptionButton
                  key={index}
                  name={grade}
                  value={grade}
                  onClick={() => setNpsValue(+grade)}
                  bgColor={npsValue === +grade}
                >
                  {grade}
                </StyledOptionButton>
              ))}
            </StyledContainerOptions>
          </StyledContent>
          <StyledContainerButton>
            <StyledButton
              size="small"
              typeButton="outline"
              label="Prefiro não responder"
              onClick={preferNotToAnswerClick}
            />
            <StyledButton
              size="small"
              typeButton="filled"
              label="Próximo"
              onClick={submitNpsValue}
            />
          </StyledContainerButton>
        </StyledContainer>
      </StyledModal>
    </>
  )
}
