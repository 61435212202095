import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Mission } from '../data/types'

type State = Mission[] | []

export const missionsSlice = createSlice({
  name: 'missions',
  initialState: [] as State,
  reducers: {
    setMissions: (_state, action: PayloadAction<any>): State => {
      return action.payload
    },
    cleanUp: () => {
      return [] as State
    },
  },
})

export const missionsReducer = missionsSlice.reducer
export const missionsActions = missionsSlice.actions
