import { MouseEvent, useState } from 'react'
import { themeClubbi, useMediaQuery } from 'clubbi-ui'

import { LoginPopover } from './LoginPopover'
import { MyAccountIcon } from '../gridHeader/headerIcons/MyAccountIcon'
import { ProfileIcon } from '../gridHeader/headerIcons/ProfileIcon'
import { StyledProfileButton } from '../gridHeader/style/Header.style'
import { useAppSelector } from '../../../common/hooks'
import { dispatch } from '../../../store'
import { missionProgressModalActions } from '../../../store/slices/missionProgressModal'
import { sessionActions } from '../../../store/slices/session'
import { getSeasonStyle } from '../../../common/seasonality'

export const ProfileButton = () => {
  const merchantCode = useAppSelector((state) => state.session.merchantCode)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const season = getSeasonStyle()

  const isDesktop = useMediaQuery(themeClubbi.breakpoints.up('md'))

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    dispatch(missionProgressModalActions.setIsOpen(false))
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  if (anchorEl && !merchantCode) {
    dispatch(sessionActions.showModal(false))
    handlePopoverClose()
  }

  return (
    <>
      <StyledProfileButton
        data-cy="profile-button"
        onClick={handlePopoverOpen}
        isActive={!!anchorEl}
        data-testid="profile-icon"
        isDesktop={isDesktop}
      >
        {isDesktop ? (
          <MyAccountIcon />
        ) : anchorEl ? (
          <ProfileIcon color={season.iconColor} />
        ) : (
          <ProfileIcon color={themeClubbi.palette.brandWhite.main} />
        )}
      </StyledProfileButton>
      {!!(anchorEl && merchantCode) && (
        <LoginPopover
          anchorEl={anchorEl}
          openPopover={!!anchorEl}
          handlePopoverClose={handlePopoverClose}
        />
      )}
    </>
  )
}
