import { useLocation } from 'react-router-dom'
import { themeClubbi } from 'clubbi-ui'

import gtm from '../../../common/gtmTracker'
import { useAppSelector } from '../../../common/hooks'
import { filterCategoriesTag } from '../../../common/getCategories'
import { useRef, useState, MouseEvent } from 'react'
import { TagIcon } from './icons/TagIcon'
import { categoriesTags } from '../../../common/constants'

import {
  StyledButton,
  StyledContainerHeader,
  StyledDiv,
  StyledExploreCategoriesButton,
  StyledLink,
  StyledMainDesktop,
  StyledOffersButton,
  StyledPopover,
  StyledPopoverContent,
  StyledSeeMoreContainer,
  StyledTransparentDivAfter,
  StyledTransparentDivBefore,
} from './CategoriesTagsDesktop.style'

export const CategoriesTagsDesktop = () => {
  const [categories, merchantCode, welcomeOffers, clubberEmail] = useAppSelector((state) => [
    state.categories,
    state.session.merchantCode,
    state.welcomeOffers,
    state.session.clubberEmail,
  ])

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const currentPage = useLocation().pathname

  const categoriesList = filterCategoriesTag(categories)

  const corridor = useRef(null)

  const handleClick = (category: string) => {
    gtm.triggerCategoriesClick(
      merchantCode!,
      currentPage,
      'categories-tags',
      category,
      clubberEmail
    )
  }

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const filteredCategories = categoriesList.filter((category) =>
    categoriesTags.includes(category.label)
  )

  const orderedCategories = [...filteredCategories].sort(
    (CategoryA, CategoryB) =>
      categoriesTags.indexOf(CategoryA.label) - categoriesTags.indexOf(CategoryB.label)
  )

  return (
    <StyledDiv data-testid="categories-tags">
      <StyledMainDesktop>
        <StyledContainerHeader ref={corridor}>
          <StyledOffersButton onClick={handlePopoverOpen} data-testid="open-popover">
            <TagIcon color={themeClubbi.palette.brandAlert[50]} />
            Ofertas
          </StyledOffersButton>
          <StyledPopover open={!!anchorEl} anchorEl={anchorEl} onClose={handlePopoverClose}>
            {welcomeOffers?.map((offer, index) => {
              const { href } = offer.link
              return (
                <StyledPopoverContent key={index}>
                  <StyledLink to={href}>{offer.name}</StyledLink>
                </StyledPopoverContent>
              )
            })}
          </StyledPopover>
          {orderedCategories.map((category, index) => (
            <StyledLink key={index} to={category.path}>
              <StyledButton onClick={() => handleClick(category.label)}>
                {category.label}
              </StyledButton>
            </StyledLink>
          ))}
        </StyledContainerHeader>
      </StyledMainDesktop>
      <StyledSeeMoreContainer>
        <StyledTransparentDivAfter />
        <StyledLink to={'/explorar-as-categorias'} sx={{ zIndex: '5' }}>
          <StyledExploreCategoriesButton>Todas as categorias</StyledExploreCategoriesButton>
        </StyledLink>
        <StyledTransparentDivBefore />
      </StyledSeeMoreContainer>
    </StyledDiv>
  )
}
