import { useState, useCallback, useEffect } from 'react'
import { Button, Container, Modal } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../common/hooks'
import { sessionActions } from '../../store/slices/session'
import '@fontsource/baloo-2'
import { isMerchantBlocked } from '../../common/utils'

const StyledModal = styled(Modal)`
  font-family: 'Baloo 2';
  font-style: normal;
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: 'Baloo 2';
  font-size: 20px;
  line-height: 28px;
  margin-top: 10%;
  @media (max-width: 400px) {
    flex-direction: column;
    width: 329px;
    height: 355px;
    margin-left: 5%;
    margin-top: 30%;
  }
`

const StyledModalHeader = styled(Modal.Header)`
  margin-bottom: 0;
  color: #ef8080;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  border-bottom: none;
  @media (max-width: 400px) {
    width: 325px;
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 0;
    padding-right: 20px;
  }
`

const StyledHeaderText = styled('p')`
  margin-left: 230px;
  margin-bottom: 0;
  margin-top: 25px;
  @media (max-width: 991px) {
    margin-left: 40px;
    margin-top: 5px;
  }
`

const StyledContainer = styled(Container)`
  padding: 0;
  padding-bottom: 21px;
  margin-bottom: 32px;
  width: 609px;
  border-bottom: 1px solid #e1e1e1;
  @media (max-width: 991px) {
    width: 285px;
    font-size: 15px;
    text-align: justify;
    padding: 0 5px;
    margin-bottom: 0;
  }
`

const StyledButton = styled(Button)`
  width: 612px;
  height: 40px;
  background: #7c00f4;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
  @media (max-width: 991px) {
    width: 282px;
    height: 40px;
    margin: 15px 0;
  }
`

export const DefaultingMerchantModal = (): React.ReactElement => {
  const [showModal, setShowModal] = useState(false)
  const dispatch = useAppDispatch()
  const merchantStatus = useAppSelector((state) => state.session.status)
  const isDefaultingCustomer = isMerchantBlocked(merchantStatus)
  const history = useHistory()
  const isHomePage = useLocation().pathname == '/'
  const isCartPage = useLocation().pathname == '/cart'

  useEffect(() => {
    if (isDefaultingCustomer && (isHomePage || isCartPage)) {
      setShowModal(true)
      return
    }

    return () => {
      dispatch(sessionActions.clearIsActive())
    }
  }, [isDefaultingCustomer, dispatch])

  const hideModal = useCallback(() => {
    setShowModal(false)
  }, [])

  return (
    <StyledModal size={'lg'} onHide={hideModal} show={showModal}>
      <StyledModalHeader closeButton>
        <StyledHeaderText>Pagamentos pendentes</StyledHeaderText>
      </StyledModalHeader>
      <Modal.Body style={{ padding: '5px' }}>
        <StyledContainer>
          <strong>Não identificamos o pagamento de uma de suas faturas já vencidas.</strong>
          <p>
            Para continuar utilizando a Clubbi você pode resolver essa pendência clicando no botão
            abaixo, e caso tenha alguma dúvida ou problema é só entrar em contato com o suporte no
            pedido em atraso.
          </p>
        </StyledContainer>
        <StyledButton
          onClick={() => {
            hideModal()
            history.push('/invoices')
          }}
        >
          Acessar seus pagamentos
        </StyledButton>
      </Modal.Body>
    </StyledModal>
  )
}
