import { clubbiStyled } from 'clubbi-ui'

export const StyleContainerTag = clubbiStyled('div')(({ theme }) => ({
  fontSize: '10px',
  fontWeight: 600,
  position: 'absolute',
  top: '95px',
  left: '8px',
  height: '20px',
  borderRadius: '2px',
  textAlign: 'center',
  padding: '0 5px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  whiteSpace: 'nowrap',

  color: theme.palette.brandWhite.main,
  background: theme.palette.brandAlert[50],

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSize2XS,
    top: '135px',
    left: '20px',
    height: '22px',
  },
}))
