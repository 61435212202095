import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Rating } from '../data/types'
import { getLatestRating } from '../../services/ratingApi'

export const latestRatingThunk = createAsyncThunk('rating/getLatestRating', getLatestRating)

const initialState = {
  id: 0,
  shopperPlanId: '',
  ratingValue: null,
  creationDatetime: '',
  shown: false,
} as Rating

const ratingSlice = createSlice({
  name: 'rating',
  initialState: initialState,
  reducers: {
    setShown: (state, action: PayloadAction<undefined>) => ({
      ...state,
      shown: true,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(latestRatingThunk.fulfilled, (state, action) => {
      const ratingData = action.payload

      if (ratingData) {
        return ratingData.id === state.id
          ? { ...state }
          : {
              ...state,
              ...ratingData,
            }
      }
    }),
      builder.addCase(latestRatingThunk.rejected, (state, action) => {
        return initialState
      })
  },
})

export const ratingReducer = ratingSlice.reducer
export const ratingActions = ratingSlice.actions
