import { IconButton, clubbiStyled } from 'clubbi-ui'

export const StyledIconButton = clubbiStyled(IconButton)(({ theme }) => ({
  fontSize: '12px',
  height: '12px',
  color: theme.palette.brandGrey[100],
  padding: 0,
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    height: '14px',
  },
}))

export const StyledInputWrapper = clubbiStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brandWhite.main,
  width: '95px',
  display: 'flex',
  borderRadius: '4px',
  alignItems: 'center',
  padding: '0 2px',
  [theme.breakpoints.up('md')]: {
    width: '121px',
  },
}))

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brandGrey[20],
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '4px',
  alignItems: 'center',
  justifyContent: 'center',
  width: '97px',
  paddingTop: '1px',
  [theme.breakpoints.up('md')]: {
    width: '123px',
  },
}))

export const StyledInputLabel = clubbiStyled('span')(({ theme }) => ({
  color: theme.palette.brandGrey[100],
  lineHeight: ' 92.4%',
  fontSize: '11px',
  padding: '4px',
  [theme.breakpoints.up('md')]: {
    fontSize: '12px',
  },
}))

export const StyledInput = clubbiStyled('input')(({ theme }) => ({
  textAlign: 'center',
  border: 'none',
  width: '70%',
  fontSize: '12px',
  fontWeight: 700,
  lineHeight: '92.4%',
  padding: '0',
  background: theme.palette.brandWhite.main,
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    width: '90%',
    padding: '2px',
  },
  '&:focus': {
    outline: 'none',
  },
}))
