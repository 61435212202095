import { useState } from 'react'
import gtm from '../../common/gtmTracker'
import { useAppSelector } from '../../common/hooks'
import { putMissionsSelected } from '../../services/missionApi'

import { StyledSelectedButton } from './styles/StyledSelectedButton.style'
import { getMissions } from '../../services/productApi'

interface SelectProps {
  missionId: number
  selected: boolean
}

export const SelectMissionButton = ({ missionId, selected }: SelectProps) => {
  const { merchantCode, clubberEmail } = useAppSelector((state) => state.session)
  const [loading, setLoading] = useState(false)

  const setMissionSelected = async () => {
    setLoading(true)
    const errorResponse = await putMissionsSelected(missionId, !selected)
    gtm.triggerSelectMission(merchantCode, missionId, !selected, clubberEmail)
    if (!errorResponse) {
      await getMissions(merchantCode)
    }
    setLoading(false)
  }

  const loadingText = loading ? '...' : ''
  const missionButtonText = selected ? 'Missão Ativa!' : 'Ativar Missão'

  return (
    <StyledSelectedButton
      selected={selected}
      onClick={() => setMissionSelected()}
      disabled={loading}
    >
      {loadingText || missionButtonText}
    </StyledSelectedButton>
  )
}
