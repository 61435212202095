import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Banner } from '../data/types'

type State = Banner[]

const bannersSlice = createSlice({
  name: 'banners',
  initialState: [] as unknown as State,
  reducers: {
    setBanners: (_state, action: PayloadAction<Banner[]>): State => {
      return action.payload
    },
  },
})

export const bannersReducer = bannersSlice.reducer
export const bannersActions = bannersSlice.actions
