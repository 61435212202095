import { Link } from 'react-router-dom'

import { StyledHeader, StyledLogo, StyledTitle } from './styles/SalesHeader.style'

export const SalesHeader = () => {
  return (
    <StyledHeader>
      <Link to="/">
        <StyledLogo src={'/img/clubbi-logo-white.png'} alt="Logo Clubbi" />
      </Link>
      <StyledTitle>Gerador de Encartes</StyledTitle>
    </StyledHeader>
  )
}
