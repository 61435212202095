import { clubbiStyled } from 'clubbi-ui'
import { Link } from 'react-router-dom'

export const StyledMissionCard = clubbiStyled('div')<{ isMissionTooltipPage?: boolean }>(
  ({ theme, isMissionTooltipPage }) => ({
    width: '340px',
    height: '110px',

    background: theme.palette.brandWhite.main,
    border: `1px solid ${theme.palette.brandPurple[60]}`,
    borderRadius: '4px',
    padding: '10px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: isMissionTooltipPage ? '0px' : '15px',

    fontStyle: 'normal',
    fontWeight: '700',

    lineHeight: '18px',
    flex: 'none',

    [theme.breakpoints.up('sm')]: {
      width: '656px',
      height: '124px',
      padding: '20px',
    },
  })
)

export const StyledPromotion = clubbiStyled('div')(() => ({
  display: 'flex',
  justifyContent: 'start',
  flex: 'none',
  width: '100%',
}))

export const StyledDescription = clubbiStyled('div')<{ location?: boolean }>(
  ({ theme, location }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    fontWeight: '400',
    flexWrap: 'wrap',
    marginTop: location ? '8px' : '2px',
    gap: '2px',
    fontSize: theme.typography.fontSize2XS,

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.fontSizeXXS,
      gap: '4px',
    },
  })
)

export const StyledPromotionHeader = clubbiStyled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}))

export const StyledContainerButtons = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  gap: '8px',
}))

export const StyledSeeProducts = clubbiStyled(Link)(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  color: theme.palette.brandPurple[60],
  fontWeight: '600',
  marginLeft: '4px',

  '&:hover': {
    color: theme.palette.brandPurple[60],
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))

export const StyledClickableText = clubbiStyled('button')(({ theme }) => ({
  border: 'none',
  background: 'none',
  color: theme.palette.brandPurple[40],
  '&:hover': {
    background: 'none',
    textDecoration: 'underline',
  },
}))
