import { GroupListing } from '../../store/data/types'
import React, { memo } from 'react'
import { getBestPrices } from '../../services/productApi'
import { LoadablePage, LoadablePageProps } from './LoadablePage'
import { ProductsGrid } from '../templates/ProductsGrid'
import { StyledLinkToHome } from '../atoms/styles/LinkBackHomeDiv.style'
import { LinkBackHome } from '../atoms'

const _LinkHome = memo(() => (
  <StyledLinkToHome>
    <LinkBackHome />
  </StyledLinkToHome>
))

const _Grid = ({ products }: GroupListing) => {
  const header = {
    node: <_LinkHome />,
    height: 63,
  }

  return <ProductsGrid context={['BestPrices']} data={products} header={header} />
}
export const bestPricesFetch = async (): Promise<GroupListing> => {
  const data = await getBestPrices()
  return {
    creationDatetime: '',
    expirationDate: '',
    title: '',
    products: data.products,
  }
}
export const BestPrices = (props: LoadablePageProps<GroupListing>) => {
  return (
    <LoadablePage {...props} dataFetch={bestPricesFetch}>
      {_Grid}
    </LoadablePage>
  )
}
