import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { AcceptModalFormProps } from './types'

const AcceptModalForm = ({
  handleSubmit,
  handleInputChange,
  validated,
  errors,
  isLoading,
}: AcceptModalFormProps) => {
  return (
    <>
      <Modal.Body>
        <Form method={'post'}>
          <Form.Group>
            <Form.Label>Nome:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome completo"
              name="name"
              onChange={handleInputChange}
              isInvalid={!!errors.name && validated}
              isValid={!errors.name && validated}
            />
            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>5 primeiros dígitos do CPF:</Form.Label>
            <Form.Control
              type="number"
              placeholder="00000"
              name="cpf"
              onChange={handleInputChange}
              isInvalid={!!errors.cpf && validated}
              isValid={!errors.cpf && validated}
            />
            <Form.Control.Feedback type="invalid">{errors.cpf}</Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <Button
          variant="success"
          className="font-weight-bold d-flex align-items-center"
          onClick={async (e) => await handleSubmit(e)}
        >
          {isLoading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                className="pb-1"
              />
              <span className="sr-only">Loading...</span>
            </>
          ) : (
            <></>
          )}
          {isLoading ? 'Confirmando...' : 'Confirmar'}
        </Button>
      </Modal.Footer>
    </>
  )
}

export default AcceptModalForm
