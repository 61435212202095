import React from 'react'
import {
  ZENDESK_ORDERS_FAQ_LINK,
  creditReviewLinkTxt,
  creditReviewOrderMessage,
  pendingOrderMessage,
} from '../../../common/constants'
import {
  StyledOpenOrdersLink,
  StyledP,
  StyledZendeskLink,
} from './styles/OrderInstructionsMessage.style'

export interface OrderInstructionsMessageProps {
  isOrderUnderReview: boolean
}

export const OrderInstructionsMessage = ({ isOrderUnderReview }: OrderInstructionsMessageProps) => {
  return (
    <StyledP>
      {isOrderUnderReview ? (
        <>
          {creditReviewOrderMessage}
          <StyledOpenOrdersLink to={'/pedidos-em-aberto'}>Pedidos em Aberto</StyledOpenOrdersLink>
        </>
      ) : (
        pendingOrderMessage
      )}
      {isOrderUnderReview && (
        <>
          <span>.</span>
          <p>
            <StyledZendeskLink href={ZENDESK_ORDERS_FAQ_LINK} target={'_blank'} rel="noreferrer">
              {creditReviewLinkTxt}
            </StyledZendeskLink>
          </p>
        </>
      )}
    </StyledP>
  )
}
