import { getLongDate, getDayOfWeek } from '../../../common/utils'
import { getSeasonStyle } from '../../../common/seasonality'
import { useAppSelector } from '../../../common/hooks'
import { ProductsCardFlyer } from './ProductCardFlyer'
import { Product } from '../../../store/data/types'

import {
  StyedFooter,
  StyledContent,
  StyledDate,
  StyledDateContainer,
  StyledDay,
  StyledFlyerContainer,
  StyledFooterImage,
  StyledHeaderImage,
  StyledProductList,
  StyledProductsContainer,
} from './styles/Flyer.style'

interface FlyerProps {
  products: Product[]
}

export const Flyer = ({ products }: FlyerProps) => {
  const { includeDate } = useAppSelector((state) => state.salesFlyer)

  const season = getSeasonStyle()

  const currentDate = new Date()
  const [day, date] = [getDayOfWeek(currentDate), getLongDate(currentDate)]

  return (
    <StyledFlyerContainer>
      <StyledContent>
        <StyledHeaderImage src={season.imageFlyer} alt="flyer header" />
        {includeDate && (
          <StyledDateContainer>
            <StyledDay>{day}</StyledDay>
            <StyledDate>{date}</StyledDate>
          </StyledDateContainer>
        )}
        <StyledProductsContainer>
          <StyledProductList data-testid={`products`}>
            {products.map((product) => (
              <ProductsCardFlyer key={product.ean} product={product} />
            ))}
          </StyledProductList>
        </StyledProductsContainer>
      </StyledContent>
      <StyedFooter>
        <StyledFooterImage src={season.imageFlyerFooter} alt="flyer footer" />
      </StyedFooter>
    </StyledFlyerContainer>
  )
}
