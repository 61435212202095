import { SnackBar } from '../atoms/SnackBar'
import { SnackBarDefaulting } from '../atoms/SnackBarDefaulting'
import { SnackBarRating } from '../atoms/SnackBarRating'
import { BannerSlider } from '../molecules/bannerSlider/BannerSlider'
import { ExploreCategories } from '../molecules/exploreCategories/ExploreCategories'
import { MissionsRow } from '../molecules/MissionsRow'
import { OfertaoBox } from '../molecules/ofertaoExhibitor/OfertaoBox'
import { ProductsList } from '../organisms/product/ProductsList'
import { useAppSelector } from '../../common/hooks'
import { Offer, Product } from '../../store/data/types'

import { StyledHomeContainer, StyledHomeSections } from './styles/Home.style'

export interface HomeProps {
  groups: Offer[]
  className?: string
  context?: string[]
  seeMoreCategories?: boolean
  ofertaoProducts?: Product[]
}

export const Home = ({ groups, seeMoreCategories = true, context, ofertaoProducts }: HomeProps) => {
  const banners = useAppSelector((state) => state.banners)
  const { websiteRegionId } = useAppSelector((state) => state.session)
  const showOfertao = websiteRegionId !== 77

  return (
    <StyledHomeContainer>
      <SnackBarDefaulting />
      <SnackBarRating />
      <SnackBar />
      <StyledHomeSections data-testid="home-sections">
        {!!banners.length && <BannerSlider banners={banners} />}
        {ofertaoProducts?.length !== 0 && showOfertao && <OfertaoBox products={ofertaoProducts} />}
        <MissionsRow />
        {groups && (
          <ProductsList groups={groups} seeMoreCategories={seeMoreCategories} context={context} />
        )}
        <ExploreCategories />
      </StyledHomeSections>
    </StyledHomeContainer>
  )
}
