import { useCallback, useEffect, useState } from 'react'
import { themeClubbi } from 'clubbi-ui'

import { isMerchantBlocked } from '../../common/utils'
import { useAppSelector } from '../../common/hooks'

import {
  StyledCloseButton,
  StyledCloseIcon,
  StyledContainer,
  StyledContent,
  StyledHeader,
  StyledModal,
} from './styles/PopupWarning.style'

export const PopupWarningByMerchantCode = () => {
  const [modalVisibility, setModalVisibility] = useState(false)

  const notification = useAppSelector((state) => state.notifications)

  const status = useAppSelector((state) => state.session.status)

  const hide = useCallback(() => setModalVisibility(false), [])

  useEffect(() => {
    if (notification) {
      setModalVisibility(true)
    }
  }, [notification])

  const isDefaultingCustomer = isMerchantBlocked(status)

  return (
    <>
      {!isDefaultingCustomer &&
        notification.map((message, index) => (
          <StyledModal key={index} open={modalVisibility} onClose={hide}>
            <StyledContainer>
              <StyledHeader>
                {message.title}
                <StyledCloseButton onClick={hide}>
                  <StyledCloseIcon color={themeClubbi.palette.brandGrey.main} />
                </StyledCloseButton>
              </StyledHeader>
              <StyledContent>
                <p>Cliente Clubbi,</p>
                <p>{message.text}</p>
                <p>Clubbi</p>
              </StyledContent>
            </StyledContainer>
          </StyledModal>
        ))}
    </>
  )
}
