import { ButtonClubbi, clubbiStyled } from 'clubbi-ui'

export const StyledImg = clubbiStyled('img')({
  objectFit: 'contain',
})

export const StyledTotalPriceFooter = clubbiStyled('div')(({ theme }) => ({
  marginBottom: '50px',
  fontSize: '12px',
  fontWeight: 700,
  lineHeight: '20.42px',
  letterSpacing: '0em',
  textAlign: 'left',
  padding: '10px',
  border: `1px solid ${theme.palette.brandGrey[20]}`,
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  backgroundColor: theme.palette.brandWhite.main,
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
    lineHeight: '27px',
    padding: '20px',
  },
}))

export const StyledOrderHeader = clubbiStyled('div')(({ theme }) => ({
  width: '100%',
  margin: '0 auto',
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.palette.brandGrey[20]}`,
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  fontSize: '11px',
  fontWeight: '400',
  lineHeight: '18.72px',
  letterSpacing: '0em',
  textAlign: 'left',
  background: `linear-gradient(0deg, ${theme.palette.brandGrey[10]}, ${theme.palette.brandGrey[10]}), linear-gradient(0deg, ${theme.palette.brandGrey[20]}, ${theme.palette.brandGrey[20]})`,
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '24px',
    padding: '10px 20px',
  },
}))

export const StyledLeftHeaderCell = clubbiStyled('div')(({ theme }) => ({
  alignItems: 'flex-end',
  lineHeight: '23.83px',
  flex: 9,
}))

export const StyledHeaderCell = clubbiStyled('div')(({ theme }) => ({
  textAlign: 'end',
  lineHeight: '23.83px',
  flex: 2,
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
    padding: '2px',
  },
  [theme.breakpoints.up('xl')]: {
    padding: '0',
  },
}))

export const StyledVoidHeaderCell = clubbiStyled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
    flex: 1,
  },
}))

export const StyledQuantityHeaderCell = clubbiStyled('div')(({ theme }) => ({
  textAlign: 'center',
  lineHeight: '23.83px',
  flex: 4,
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
    flex: 3,
  },
  [theme.breakpoints.up('lg')]: {
    flex: 4,
  },
}))

export const StyledTotalPriceValueCell = clubbiStyled('div')(({ theme }) => ({
  flex: 3,
  textAlign: 'end',
}))

export const StyledTotalPriceLabelCell = clubbiStyled('div')(({ theme }) => ({
  flex: 12,
}))

export const StyledOpenOrderCard = clubbiStyled('div')(({ theme }) => ({
  width: '90vw',
  backgroundColor: theme.palette.brandWhite.main,
  borderRadius: '4px',
  margin: '10px 0',
  padding: '1% 5%',
  [theme.breakpoints.up('md')]: {
    width: '100%',
  },
}))

export const StyledCardHeader = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '20px 0',
}))

export const StyledMessage = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}))

export const StyledOrderTag = clubbiStyled('div')(({ theme }) => ({
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '27.23px',
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    fontSize: '20px',
    lineHeight: '34.04px',
    gap: '8px',
  },
}))

export const StyledCreationTag = clubbiStyled('div')(({ theme }) => ({
  color: theme.palette.brandGrey[60],
  fontSize: '12px',
  lineHeight: '20.42px',
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '23.83px',
  },
}))

export const StyledCancelBtn = clubbiStyled(ButtonClubbi)<{
  label: string
}>(({ label, theme }) => ({
  label: label,
  typeButton: 'outline',
  size: 'small',
  color: theme.palette.brandAlert[50],
  borderRadius: '4px',
  textTransform: 'lowercase',
  height: '22px',
  width: '61px',
  lineHeight: '27.23px',
  border: `1px solid ${theme.palette.brandAlert[50]}`,
  '&:hover': {
    backgroundColor: theme.palette.brandAlert[10],
  },
  [theme.breakpoints.up('md')]: {
    height: '30px',
    width: '91px',
  },
}))
