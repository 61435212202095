import { clubbiStyled } from 'clubbi-ui'

export const StyledCard = clubbiStyled('div')(({ theme }) => ({
  width: '228px',
  height: '350px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  border: `1px solid ${theme.palette.brandGrey[20]}`,
  borderRadius: '8px',
  padding: '5px',

  background: theme.palette.brandWhite.main,
}))

export const StyledProductDescription = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSizeL,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '218px',
  height: '130px',
  textAlign: 'center',
  lineHeight: '23px',
}))

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  width: '218px',
  height: '210px',
  position: 'relative',
}))

export const StyledProductImage = clubbiStyled('img')(({ theme }) => ({
  width: 'auto',
  height: '210px',
  margin: '0px',
  maxHeight: '210px',
  maxWidth: '218px',
}))

export const StyledBadgePrice = clubbiStyled('img')(({ theme }) => ({
  width: '110px',
  position: 'absolute',
  bottom: '2px',
  right: '2px',
}))

export const StyledCurrency = clubbiStyled('p')(({ theme }) => ({
  fontSize: theme.typography.fontSizeS,
  width: '110px',
  position: 'absolute',
  bottom: '50px',
  right: '4px',
  textAlign: 'center',

  color: theme.palette.brandYellow[50],
}))

export const StyledProductPrice = clubbiStyled('p')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XL,
  width: '110px',
  position: 'absolute',
  bottom: '10px',
  right: '2px',
  textAlign: 'center',
  fontWeight: 700,

  color: theme.palette.brandYellow[50],
}))
