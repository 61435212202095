import {
  StyledContainerBack,
  StyledArrowBack,
  StyledLinkRouter,
} from './styles/LinkBackHome.styles'

export const LinkBackHome = () => {
  return (
    <StyledContainerBack>
      <StyledArrowBack />
      <StyledLinkRouter to={'/'}>Volte a página inicial</StyledLinkRouter>
    </StyledContainerBack>
  )
}
