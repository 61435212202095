import { RoundedCheckIcon, WarningIcon } from 'clubbi-ui'
import React from 'react'
import { confirmedOrderTitleText, creditReviewOrderTitleText } from '../../../common/constants'
import { StyledTitleCheckout, StyledTitleText } from './styles/TitleCheckout.style'

export interface TitleCheckoutProps {
  isOrderUnderReview: boolean
}

export const TitleCheckout = ({ isOrderUnderReview }: TitleCheckoutProps) => {
  return (
    <StyledTitleCheckout isOrderUnderReview={isOrderUnderReview}>
      {isOrderUnderReview ? <WarningIcon /> : <RoundedCheckIcon />}
      <StyledTitleText>
        {isOrderUnderReview ? creditReviewOrderTitleText : confirmedOrderTitleText}
      </StyledTitleText>
    </StyledTitleCheckout>
  )
}
