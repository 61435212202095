import { Alert, clubbiStyled } from 'clubbi-ui'
import { Link } from 'react-router-dom'

export const StyledAlert = clubbiStyled(Alert)(({ theme }) => ({
  alignItems: 'center',
  marginBottom: '0',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.brandAlert[10],
  color: theme.palette.brandAlert[50],

  [theme.breakpoints.up('sm')]: {
    width: '100%',
    marginTop: '-4px',
  },
}))

export const StyledLink = clubbiStyled(Link)(({ theme }) => ({
  color: theme.palette.brandAlert[50],
  fontWeight: 600,
}))
