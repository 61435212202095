import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProfitabilitiesPayload } from '../data/types'

type State = ProfitabilitiesPayload

const profitabilitiesSlice = createSlice({
  name: 'profitabilities',
  initialState: [] as unknown as State,
  reducers: {
    setProfitabilities: (_state, action: PayloadAction<ProfitabilitiesPayload>): State => {
      return action.payload
    },
  },
})

export const profitabilitiesReducer = profitabilitiesSlice.reducer
export const profitabilitiesActions = profitabilitiesSlice.actions
