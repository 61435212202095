import React from 'react'
import { CartChange, Product } from '../../../store/data/types'
import { StyledIconButton } from './styles/DeleteCartItem.style'
import { DeleteIcon } from 'clubbi-ui'

export interface Props {
  value: number
  onChange: (change: CartChange) => void
  ctx?: string
  addAlgoliaEvent?: (_algoliaEvent: any) => void
  product: Product
  isOfertao: boolean
  isPackageMode: boolean
}

export const DeleteCartItem = React.forwardRef(
  (
    {
      value = 0,
      onChange,
      ctx: ctx = '',
      addAlgoliaEvent,
      product,
      isOfertao,
      isPackageMode,
      ...props
    }: Props,
    ref
  ) => {
    const context = ctx!.concat('/')

    return (
      <StyledIconButton
        {...props}
        data-testid="cart-item-delete-button"
        onClick={() => {
          onChange({ quantity: 0, context: context })
        }}
      >
        <DeleteIcon size="inherit" />
      </StyledIconButton>
    )
  }
)
