import { LoadablePage } from './LoadablePage'
import { useLocation } from 'react-router-dom'
import React, { useCallback } from 'react'
import { orderResults } from '../../services/shopperPlanApi'
import { getMerchantData } from '../../services/merchantApi'
import WrapTab from '../organisms/invoice/purchase/WrapTab'

export const deliveryFetch = async (query: string, customerId: string, planId: string) => {
  if (query.trim() == '') {
    return {
      query: '',
      pickingData: [],
      planId: '',
    }
  }
  const pickingData = await orderResults(query, customerId, planId)
  const customers = await getMerchantData(customerId)
  const customer = customers[0]
  return {
    query,
    pickingData,
    customer,
    planId,
  }
}

export const Purchase = (props: any) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const customerId = searchParams.get('customerId')!
  const planId = searchParams.get('planId')!

  const fetch = useCallback(() => deliveryFetch('123', customerId, planId), [customerId, planId])
  return (
    <LoadablePage key={customerId} {...props} dataFetch={fetch}>
      {WrapTab}
    </LoadablePage>
  )
}
