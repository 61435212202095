import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  badgeMenuIcon?: boolean
  tagPromotions?: boolean
}

const tagCategoriesMenuSlice = createSlice({
  name: 'tagCategoriesMenu',
  initialState: {
    badgeMenuIcon: true,
    tagPromotions: true,
  } as State,
  reducers: {
    setStatus: (state, action: PayloadAction<State>): State => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const tagCategoriesMenuReducer = tagCategoriesMenuSlice.reducer
export const tagCategoriesMenuActions = tagCategoriesMenuSlice.actions
