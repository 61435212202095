import { GroupListing } from '../../store/data/types'
import { getProductsGroup } from '../../services/productApi'
import { LoadablePage, LoadablePageProps } from './LoadablePage'
import { useParams } from 'react-router-dom'
import { useCallback, useMemo } from 'react'
import { ProductsGrid } from '../templates/ProductsGrid'
import styled from 'styled-components'

export const StyledHeader = styled.h2`
  padding: 0 1em !important;
`

const _Group = ({ products, title, expirationDate }: GroupListing) => {
  const header = useMemo(
    () => ({
      node: <StyledHeader>{title || `Promoções ${expirationDate}`}</StyledHeader>,
      height: 63,
    }),
    [title, expirationDate]
  )

  return <ProductsGrid data={products} context={['groupListing']} header={header} />
}

export const groupFetch = async (token: string): Promise<GroupListing> => {
  return await getProductsGroup(token)
}

export const Group = (props: LoadablePageProps<GroupListing>) => {
  const { token } = useParams<{ token: string }>()
  const fetch = useCallback(() => groupFetch(token), [token])

  return (
    <LoadablePage {...props} dataFetch={fetch}>
      {_Group}
    </LoadablePage>
  )
}
