import { Button, Modal } from 'react-bootstrap'

const AcceptModalThanks = () => {
  return (
    <>
      <Modal.Body>
        <h5>Obrigado pela confirmação</h5>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="font-weight-bold"
          onClick={() => {
            window.location.reload()
          }}
        >
          Continuar
        </Button>
      </Modal.Footer>
    </>
  )
}

export default AcceptModalThanks
