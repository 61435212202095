import {
  BorderBold,
  Thanks,
  Header,
  Product,
  Message,
  InformationBottom,
  QuantityPrime,
  DescriptionPrime,
  Body,
  Communication,
  EndCommunication,
  EanPrime,
} from '../../molecules/invoice/SharedStyle'
import { Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import PurchasePriceDetails from '../../molecules/invoice/purchase/PriceDetails'
import DeliveryPriceDetails from '../../molecules/invoice/delivery/PriceDetails'
import { Customer as customerDelivery } from '../../molecules/invoice/delivery/types'
import { Customer as customerPurchase } from '../../molecules/invoice/purchase/types'
import { OrderItem } from '../../molecules/invoice/purchase/types'
import { ProductBucket as ProductBucketsInterface } from '../../molecules/invoice/delivery/types'
import HeaderInformation from '../../molecules/invoice/HeaderInformation'
import SectionDetails from './SectionDetails'
import { CX_WHATSAPP_NUMBER } from '../../../common/constants'
interface Props {
  productBuckets: OrderItem[] | ProductBucketsInterface[]
  customer: customerDelivery | customerPurchase
  regionId: number | null
  orderIds: number[]
  creationDatetime: Date
  payment: string
  isDelivery: boolean
  planId: string
  deliveryFee: number
}

const InvoiceDetails = ({
  isDelivery,
  regionId,
  orderIds,
  creationDatetime,
  payment,
  planId,
  customer,
  productBuckets,
  deliveryFee,
}: Props) => {
  return (
    <Body>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"
        />
      </Helmet>
      <Header>
        <Message>{!isDelivery ? <h3>Pedido</h3> : <h3>Fatura</h3>}</Message>
        <img src={'/img/new_clubbi.png'} alt={'Clubbi'} />
      </Header>

      <HeaderInformation
        customer={customer}
        regionId={regionId}
        orderIds={orderIds}
        creationDatetime={creationDatetime}
        payment={payment}
        planId={planId}
      />
      <Communication>
        <ul>
          {isDelivery ? (
            <li>
              A <b>Fatura</b> deve refletir exatamente aquilo que foi entregue, pedimos que valide
              com cuidado antes de dar o aceite
            </li>
          ) : (
            <></>
          )}

          <li>
            A Nota Fiscal do seu pedido estará disponível na aba de <b>Minhas Entregas</b>, e também
            será enviada por e-mail, assim que for emitida
          </li>
          <li>
            Quaisquer problemas ou mudanças necessárias, é só falar com o nosso atendimento clicando{' '}
            <a
              href={`https://api.whatsapp.com/send/?phone=${CX_WHATSAPP_NUMBER}&text&type=phone_number&app_absent=0`}
              target="_blank"
              rel="noreferrer"
            >
              aqui
            </a>
          </li>
        </ul>
      </Communication>

      <Product className="border-outside">
        <EanPrime>Ean</EanPrime>
        <DescriptionPrime>Descrição</DescriptionPrime>
        {isDelivery && <QuantityPrime> Qtd Entregue</QuantityPrime>}
        {!isDelivery && <QuantityPrime> Qtd Solicitada</QuantityPrime>}
        <QuantityPrime> Preço Unitário</QuantityPrime>
        <QuantityPrime> Valor</QuantityPrime>
      </Product>

      <SectionDetails productBuckets={productBuckets} isDelivery={isDelivery} />

      <InformationBottom>
        {!isDelivery ? (
          <PurchasePriceDetails
            productBuckets={productBuckets as OrderItem[]}
            deliveryFee={deliveryFee}
          />
        ) : (
          <DeliveryPriceDetails customer={customer} />
        )}
        <BorderBold />
        <Thanks>Obrigado!</Thanks>
        <Button
          variant="primary"
          onClick={() => {
            window.location.reload()
          }}
          className={'m-2'}
        >
          Atualizar
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            window.print()
          }}
          className={'m-2'}
        >
          Imprimir
        </Button>
      </InformationBottom>
      <EndCommunication>
        Você tem até 1 dia após o recebimento para solicitar troca ou devolução dos produtos
      </EndCommunication>
    </Body>
  )
}

export default InvoiceDetails
