import { themeClubbi, useMediaQuery } from 'clubbi-ui'
import { StyledCircle, StyledLine, StyledPointer } from './styles/TooltipPointer.style'

export const TooltipPointer = ({ step }: { step: number }) => {
  const isDesktop = useMediaQuery(themeClubbi.breakpoints.up('md'))
  return (
    <StyledPointer step={step} isDesktop={isDesktop}>
      <StyledCircle></StyledCircle>
      <StyledLine></StyledLine>
    </StyledPointer>
  )
}
