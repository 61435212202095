import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { themeClubbi } from 'clubbi-ui'

import gtm from '../../../common/gtmTracker'
import { useAppSelector } from '../../../common/hooks'
import { recommendedDrawerActions } from '../../../store/slices/recommendedDrawer'
import { DrawerCorridor } from '../drawerCorridor/DrawerCorridor'
import {
  StyledDrawerRecommended,
  StyledContainer,
  StayledHeader,
  StyledTitle,
  StyledCloseIcon,
  StyledButton,
  StyledBackButton,
} from './styles/recommendedDrawer.style'

export const RecommendedDrawer = () => {
  const history = useHistory()
  const [{ isOpen }, { merchantCode, regionId, clubberEmail }] = useAppSelector((state) => [
    state.recommendedDrawer,
    state.session,
  ])
  const dispatch = useDispatch()

  const closeDrawer = () => {
    dispatch(recommendedDrawerActions.setIsOpen(false))
    gtm.triggerDrawerClose(merchantCode!, regionId!, clubberEmail)
    history.push('/cart')
  }

  useEffect(() => {
    const currentDate = new Date().toLocaleDateString()
    const openModalDate = localStorage.getItem('lastModalOpened')

    if (openModalDate !== currentDate && isOpen === true) {
      gtm.triggerDrawer(merchantCode!, regionId!, clubberEmail)
      localStorage.setItem('lastModalOpened', currentDate)
    } else if (openModalDate === currentDate && isOpen === true) {
      dispatch(recommendedDrawerActions.setIsOpen(false))
      history.push('/cart')
    }
  }, [isOpen])

  return (
    <>
      <StyledDrawerRecommended
        variant="temporary"
        anchor="bottom"
        open={isOpen}
        onClose={closeDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile
        }}
      >
        <StyledContainer data-testid="recommended-drawer">
          <StayledHeader>
            <StyledTitle>Tem certeza que vai perder essas ofertas?</StyledTitle>
            <StyledButton data-testid="close-drawer" onClick={closeDrawer}>
              <StyledCloseIcon color={themeClubbi.palette.brandGrey.main} />
            </StyledButton>
          </StayledHeader>
          <DrawerCorridor />
          <StyledBackButton onClick={() => closeDrawer()}>
            continuar para o carrinho
          </StyledBackButton>
        </StyledContainer>
      </StyledDrawerRecommended>
    </>
  )
}
