import { clubbiStyled } from 'clubbi-ui'

export const StyledBadge = clubbiStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brandAlert[50],
  position: 'absolute',
  top: '95px',
  left: '10px',
  borderRadius: '2px',
  padding: '0 2px',
  height: '15px',

  span: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.typography.fontSize3XS,
    fontWeight: 700,
    color: theme.palette.brandWhite.main,
    textAlign: 'center',
  },

  [theme.breakpoints.up('sm')]: {
    top: '135px',
    left: '20px',
    padding: '0 3px',
    height: '20px',
    span: {
      fontSize: theme.typography.fontSize2XS,
    },
  },
}))
