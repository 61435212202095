import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  merchantCodeInput: string | undefined
  eans: string[] | []
  includeDate: boolean
  modeGenerator: boolean
}

const initialState: State = {
  merchantCodeInput: '',
  eans: [],
  includeDate: true,
  modeGenerator: false,
}

export const salesFlyerSlice = createSlice({
  name: 'salesFlyer',
  initialState: initialState,
  reducers: {
    setMerchantCodeInput: (state, action: PayloadAction<string>) => {
      const merchantCodeInput = action.payload
      return {
        ...state,
        merchantCodeInput,
      }
    },
    setEans: (state, action: PayloadAction<string[]>) => {
      const eans = action.payload
      return {
        ...state,
        eans,
      }
    },
    setIncludeDate: (state, action: PayloadAction<boolean>) => {
      const includeDate = action.payload
      return {
        ...state,
        includeDate,
      }
    },
    setEanFromSite: (state, action: PayloadAction<{ ean: string; isChecked: boolean }>) => {
      let eans = []

      if (action.payload.isChecked) {
        eans = state.eans.filter((ean) => ean !== action.payload.ean)
      } else {
        eans = [...state.eans, action.payload.ean]
      }

      return {
        ...state,
        eans,
      }
    },
    setModeGenerator: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        modeGenerator: action.payload,
      }
    },
    cleanUp: () => {
      return initialState
    },
  },
})

export const salesFlyerReducer = salesFlyerSlice.reducer
export const salesFlyerActions = salesFlyerSlice.actions
