import { clubbiStyled } from 'clubbi-ui'

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  width: '100%',
  background: theme.palette.brandGrey[10],
}))

export const StyledContent = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  background: theme.palette.brandGrey[10],
}))

export const StyledInitialSpace = clubbiStyled('div')(({ theme }) => ({
  width: '100%',
  height: '70px',
  [theme.breakpoints.up('md')]: {
    height: '60px',
  },
}))
