import { clubbiStyled } from 'clubbi-ui'

export const StyledTag = clubbiStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brandYellow[60],
  width: '38px',
  height: '20px',
  border: 'none',
  borderRadius: '2px',
  fontSize: '10px',
  color: '#3C3C3C',
  fontWeight: 600,
  whiteSpace: 'nowrap',
  textAlign: 'center',
  position: 'relative',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px',

  [theme.breakpoints.up('sm')]: {
    width: '42px',
    height: '22px',
    fontSize: '12px',
  },
}))

export const StyledTooltip = clubbiStyled('div')<{ direction: 'right' | 'left' }>(
  ({ direction, theme }) => ({
    backgroundColor: 'black',
    height: '21px',
    borderRadius: '4px',
    fontSize: '9px',
    color: 'white',
    position: 'absolute',
    right: direction === 'right' ? '0px' : 'auto',
    marginTop: '5px',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px',
    zIndex: 999,

    [theme.breakpoints.up('sm')]: {
      fontSize: '12px',
    },
  })
)
