import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Purchase } from '../data/types'

type State = Purchase

const purchaseSlice = createSlice({
  name: 'purchase',
  initialState: {} as State,
  reducers: {
    setPurchase: (state, action: PayloadAction<Purchase>): State => {
      return {
        ...state,
        ...action.payload,
      }
    },
    cleanUp: (state, action: PayloadAction<undefined>) => {
      return {} as State
    },
  },
})

export const purchaseReducer = purchaseSlice.reducer
export const purchaseActions = purchaseSlice.actions
