import { Product } from '../store/data/types'

type Mode = 'unit' | 'package' | undefined
export const unitMode: Mode = 'unit'
export const packageMode: Mode = 'package'

export type TypeInfos = 'totalPrice' | 'hide' | 'limit' | 'mode' | 'neutral'

export const getFlagTitlePromotion = (product: Product): string | undefined => {
  const { discount, inOfertao } = product
  const PROMOTION_TYPE = { specialOffer: 'OFERTA ESPECIAL', inOfertao: 'OFERTÃO' }

  if (discount || inOfertao)
    return discount ? PROMOTION_TYPE.specialOffer : PROMOTION_TYPE.inOfertao
}

export const setCurrency = (value: number | undefined) => {
  return value?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

export const getTotalPrice = (
  product: Product,
  totalPrice: number | undefined,
  quantity: number | undefined
) => {
  const [{ getMoreForLess }] = product.supplierPrices
  let total = totalPrice || 0

  if (getMoreForLess && quantity) {
    const { quantityToGet, promotionalPrice, priceWithoutPromotion } = getMoreForLess

    const numberOfCombos = Math.floor(quantity / quantityToGet!)

    const totalPromotionPrice = numberOfCombos * promotionalPrice * quantityToGet

    const standardPackageQty = quantity - numberOfCombos * quantityToGet

    const totalStandardPromotionPrice = standardPackageQty * priceWithoutPromotion

    total = totalPromotionPrice + totalStandardPromotionPrice
  }

  return total.toFixed(2)
}

export const getSaveQuantity = (
  quantity: number,
  quantityToGet: number,
  promotionalPrice: number,
  priceWithoutPromotion: number
) => {
  const numberOfCombos = Math.floor(quantity / quantityToGet!)
  const packagesOutsideCombo = quantity - numberOfCombos * quantityToGet
  const totalInsidePromotion = (quantity - packagesOutsideCombo) * promotionalPrice

  return (quantity - packagesOutsideCombo) * priceWithoutPromotion - totalInsidePromotion
}
