import {
  CategoryProducts,
  CustomCorridor,
  GroupListing,
  Mission,
  Product,
  Offer,
  SectionCategories,
  Tag,
} from '../store/data/types'
import { apiGet } from './api'
import { dispatch, store } from '../store'
import { categoriesActions } from '../store/slices/categories'
import { messagesActions } from '../store/slices/messages'
import { bannersActions } from '../store/slices/banners'
import { OpenOrderProps } from '../components/routes/OpenOrders'
import { taxBenefitEansActions } from '../store/slices/taxBenefitEans'
import { missionsActions } from '../store/slices/missions'

export interface WelcomeData {
  ofertoes: Product[]
  dailyOffers: Product[]
  weekOffers: Product[]
  bestPrices: Product[]
  bestSellers: Product[]
  previouslyOrdered: Product[]
  sections: CategoryProducts[]
  bfCorridor?: Product[]
  customPromotions: Product[]
  customCorridors: CustomCorridor[]
}
interface Link {
  text: string
  href: string
}

export interface Section {
  name: string
  order: number
  context: string
  link: Link
  products: Product[]
}

export interface WelcomeDataV2 {
  section: Section[]
}

export interface PromotionsData {
  dailyOffers: Product[]
  weekOffers: Product[]
  bestPrices: Product[]
  groups?: Offer[] | undefined
}

export const getRegionId = () => {
  return store.getState().session.regionId?.toString() || '1'
}

export const getMerchantCode = () => {
  return store.getState().session.merchantCode?.toString() || ''
}

export const buildProductURL = (
  url: string,
  params: Record<string, string | string[] | number | number[]> = {}
): string => {
  params = { ...params, region_id: getRegionId() }

  const merchantCode = getMerchantCode()
  if (merchantCode) {
    params = {
      ...params,
      merchant_code: getMerchantCode(),
    }
  }

  const p = new URLSearchParams()
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((value) => p.append(key, value.toString()))
    } else {
      p.append(key, value.toString())
    }
  })

  return `${url}?${p.toString()}`
}

export const getWelcomeV2 = async (
  merchantCode?: string,
  controller?: AbortController
): Promise<any> =>
  await apiGet(
    buildProductURL('/products/welcome/v2'),
    false,
    '',
    merchantCode
      ? {
          ['x-merchant']: merchantCode,
        }
      : undefined,
    { controller }
  )

export const getBanners = async (
  user_id: string,
  context: string,
  sessionId: string
): Promise<any> => {
  const url = buildProductURL('/sponsored-banners/', {
    user_id: user_id,
    context: context,
    session_id: sessionId,
  })
  const banners = await apiGet(url)
  dispatch(bannersActions.setBanners(banners))
}

export const getBestSellers = async (): Promise<Product[]> =>
  await apiGet(buildProductURL('/products/best-sellers'))

export const getOfertoes = async (): Promise<Product[]> =>
  await apiGet(buildProductURL('/products/ofertoes'))

export const getMoreItemsBetterPrices = async (): Promise<Offer> => {
  try {
    return await apiGet(buildProductURL('/products/more-items-better-prices'))
  } catch (e) {
    return {} as Offer
  }
}

export const getProductsSectionByCategory = async (
  sectionSlug: string
): Promise<SectionCategories> => await apiGet(buildProductURL(`/products/sections/${sectionSlug}`))

export const getProductsGroup = async (id: string): Promise<GroupListing> =>
  await apiGet(buildProductURL(`/products/listing-id`, { id }))

export const getCategoryProducts = async (
  sectionSlug: string,
  categorySlug: string
): Promise<CategoryProducts> =>
  await apiGet(buildProductURL(`/products/sections/${sectionSlug}/categories/${categorySlug}`))

export type SearchResult = {
  numberOfMatches: number
  products: Product[]
  queryId: string
  index: string
}

export const searchProducts = async (
  clubberEmail: string,
  term: string | string[],
  sessionId: string,
  offset: number,
  length: number
): Promise<SearchResult> => {
  const url = buildProductURL('/products/_search', {
    term: term,
    session_id: sessionId,
    clubber_email: clubberEmail,
    offset,
    length,
  })
  return await apiGet(url)
}

export const getSupplierProducts = async (
  corridorId: string
): Promise<{
  supplierId: string
  products: Product[]
  supplierName: string
}> => await apiGet(buildProductURL(`/products/suppliers/${corridorId}`))

export const getCustomCorridorProducts = async (
  supplierId: string
): Promise<{
  id: string
  label: string
  images: string[]
  products: Product[]
  isPartner: boolean
  endTime: string
}> => await apiGet(buildProductURL(`/products/corridor/${supplierId}`))

export const getPreviouslyOrdered = async (
  merchantCode: string,
  clubberEmail: string
): Promise<Product[]> =>
  await apiGet(
    buildProductURL(`/products/previously-ordered`, {
      merchant_code: merchantCode,
      clubber_email: clubberEmail,
    })
  )

export const getTags = async (term: string): Promise<Tag[]> => {
  const urlParams = new URLSearchParams(window.location.search)
  const searchTerm = urlParams.get('term')
  const params = {
    q: searchTerm,
  }
  return await apiGet(`/tag/search?${params}`)
}

export const getPromotions = async (): Promise<PromotionsData> =>
  await apiGet(buildProductURL('/promotions'))

export const getPromotionsDaily = async (): Promise<GroupListing> =>
  await apiGet(buildProductURL('/promotions/daily-promotions'))

export const getBestPrices = async (): Promise<GroupListing> =>
  await apiGet(buildProductURL('/promotions/best-prices'))

export const getWeekPromotions = async (): Promise<Product[]> =>
  await apiGet(buildProductURL('/promotions/week-promotions'))

export const getCustomPromotions = async (): Promise<Product[]> =>
  await apiGet(buildProductURL('/custom-promotions/products'))

export const getMessages = async (): Promise<void> => {
  const messages = await apiGet(buildProductURL('/message/get-all'))
  dispatch(messagesActions.messages(messages))
}

export const getPromotionsSuggestions = async (
  merchantCode: string,
  regionId: number
): Promise<Product[]> =>
  await apiGet(
    buildProductURL('/promotions/last-offers', { merchant_code: merchantCode, region_id: regionId })
  )

const getValidMissions = (mission: Mission): boolean => {
  const customerHasNoCompletions = !mission.customerIds[0].completed
  const missionHasNoLimitOfCompletions = mission.unlimitedCompletions
  return customerHasNoCompletions || missionHasNoLimitOfCompletions
}

export const getMissions = async (merchantCode?: string): Promise<void> => {
  const missions: Mission[] = await apiGet(buildProductURL(`/missions/customer/${merchantCode}`))
  const missionsValid = missions.filter((mission) => getValidMissions(mission))
  dispatch(missionsActions.setMissions(missionsValid))
}

export const getCategories = async (merchantCode: string, regionId: number): Promise<void> => {
  const categories = await apiGet(
    buildProductURL('/categories', { merchant_code: merchantCode, region_id: regionId })
  )
  dispatch(categoriesActions.categories(categories))
}

export const getShoppingList = async (
  merchantCode: string,
  regionId: number,
  userInput: string
) => {
  return await apiGet(
    buildProductURL(`/products/shopping-list`, {
      merchant_code: merchantCode,
      region_id: regionId,
      user_prompt: userInput,
    })
  )
}

export const getOpenOrders = async (merchantCode: string): Promise<OpenOrderProps[]> => {
  const result = await apiGet(buildProductURL(`/orders/customer/${merchantCode}/pending`))
  return result.orderResults
}

export const getCustomProducts = async (
  limit: number,
  sessionId?: string,
  getMethod: (url: string) => Promise<Product[]> = apiGet
): Promise<Product[]> => {
  try {
    const getCustomProducts = getMethod(
      buildProductURL(`/products/custom_products`, { limit, session_id: sessionId ?? '' })
    )

    const suggestions = await getCustomProducts

    return suggestions.slice(0, limit)
  } catch (e) {
    return [] as Product[]
  }
}

export const getTaxBenefitEans = async (region_id: number): Promise<void> => {
  const result = await apiGet(buildProductURL(`/tax-benefit-eans/${region_id}`))
  dispatch(taxBenefitEansActions.eans(result))
}

export const getSalesFlyerProducts = async (
  flyerMerchantCode: string,
  flyerPricingGroupId: number,
  ean: string
): Promise<Product[]> => {
  return await apiGet(
    buildProductURL(`/products/sales-flyer`, {
      flyer_merchant_code: flyerMerchantCode,
      flyer_pricing_group_id: flyerPricingGroupId,
      ean,
    })
  )
}

export const getNeugebauerProducts = async (
  clubberEmail: string,
  sessionId: string,
  offset: number,
  length: number
): Promise<SearchResult> => {
  const url = buildProductURL('/products/_search', {
    term: 'neugebauer',
    session_id: sessionId,
    clubber_email: clubberEmail,
    offset,
    length,
  })
  return await apiGet(url)
}
