import { configureStore, Middleware, PreloadedState } from '@reduxjs/toolkit'
import { logger } from 'redux-logger'

import { createRootReducer } from './reducers'
import { loadState } from './localStorage'

export const developerMiddlewares = (): Middleware<Record<string, any>>[] => {
  if (process.env.NODE_ENV === 'development') {
    return [logger as Middleware<Record<string, any>>]
  }
  return []
}

export type RootState = ReturnType<typeof createRootReducer>
export type AppStore = ReturnType<typeof setupStore>
export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    preloadedState,
    reducer: createRootReducer(),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(developerMiddlewares()),
  })
}

export const store = setupStore(loadState())

export const { dispatch } = store
export type AppDispatch = typeof store.dispatch
