import { OrderItem } from './types'

import { Border, ContainerBottom } from '../SharedStyle'
import { DELIVERY_TYPES } from '../../../../common/constants'

interface Props {
  productBuckets: OrderItem[]
  deliveryFee: number
}

const PurchasePriceDetails = ({ productBuckets, deliveryFee }: Props) => {
  const finalTotalPrice = productBuckets.reduce(
    (acc: number, item: { totalPrice: number }) => acc + item.totalPrice,
    0
  )
  const fee = deliveryFee ?? DELIVERY_TYPES.fast.value

  return (
    <>
      <ContainerBottom>
        <p>Valor dos itens do pedido (R$)</p>
        <p>
          {finalTotalPrice.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </p>
      </ContainerBottom>
      <Border />
      <ContainerBottom>
        <p>Taxa de entrega (R$)</p>
        <p>{fee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
      </ContainerBottom>

      <ContainerBottom>
        <p>Total do pedido (R$)</p>
        <p>
          {(finalTotalPrice + fee).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </p>
      </ContainerBottom>
    </>
  )
}

export default PurchasePriceDetails
