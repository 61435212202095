import { ProgressBar } from 'clubbi-ui'
import React, { useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../../common/hooks'
import {
  StyledContainer,
  StyledLabelText,
  StyledTitle,
  StyledRowContainer,
  StyledTotalLimitText,
  StyledSubtitleText,
  StyledMoney,
  StyledLimit,
} from './styles/CartCreditInfo.style'
import { totalPriceSelector } from '../../routes/Cart'
import { merchantCreditInfoThunk } from '../../../store/slices/credit'

export const CartCreditInfo = () => {
  const { remainingCredit, initialCredit } = useAppSelector((state) => state.credit)
  const isCartOverlimit = remainingCredit < 0
  const { isInstantPayment } = useAppSelector((state) => state.session)
  const merchantCode = useAppSelector((state) => state.session.merchantCode)
  const cartValue = useAppSelector(totalPriceSelector)
  const inputRef = useRef<HTMLInputElement>(null)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [remainingCredit])

  useEffect(() => {
    if (merchantCode) dispatch(merchantCreditInfoThunk({ merchantCode, cartValue }))
  }, [cartValue])

  return !isInstantPayment ? (
    <StyledContainer ref={inputRef}>
      <StyledTitle>Limite de crédito</StyledTitle>
      <StyledRowContainer>
        <StyledLimit align={'start'}>
          <StyledMoney amount={initialCredit - remainingCredit} isBold />
          <StyledSubtitleText>Limite utilizado</StyledSubtitleText>
        </StyledLimit>
        <StyledLimit align={'end'}>
          <StyledMoney amount={remainingCredit} isBold />
          <StyledSubtitleText>Limite disponível</StyledSubtitleText>
        </StyledLimit>
      </StyledRowContainer>
      <ProgressBar
        value={isCartOverlimit ? 100 : (100 * (initialCredit - remainingCredit)) / initialCredit}
      />
      <StyledTotalLimitText>
        <StyledLabelText>Seu total de crédito: </StyledLabelText>
        <StyledMoney amount={initialCredit} isBold />
      </StyledTotalLimitText>
    </StyledContainer>
  ) : (
    <></>
  )
}
