import { Modal, clubbiStyled } from 'clubbi-ui'

export const StyledImageModal = clubbiStyled(Modal)(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'auto',
}))

export const StyledModalContainer = clubbiStyled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'start',
  overflow: 'auto',
  position: 'relative',
}))

export const StyledCloseModal = clubbiStyled('button')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  border: 'none',
  fontWeight: 700,
  position: 'absolute',
  top: '10px',
  left: '15px',
  zIndex: 9999,
  borderRadius: '5px',

  background: theme.palette.brandYellow.main,
  color: theme.palette.brandBlack.main,

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeM,
    top: '30px',
    left: '50px',
  },
}))
