import { Product } from '../../store/data/types'
import React, { memo } from 'react'
import { getWeekPromotions } from '../../services/productApi'
import { LoadablePage, LoadablePageProps } from './LoadablePage'
import { ProductsGrid } from '../templates/ProductsGrid'
import { StyledLinkToHome } from '../atoms/styles/LinkBackHomeDiv.style'
import { LinkBackHome } from '../atoms'

interface WeekPromotionsProps {
  products: Product[]
}

const _LinkHome = memo(() => (
  <StyledLinkToHome>
    <LinkBackHome />
  </StyledLinkToHome>
))

const _Grid = ({ products }: WeekPromotionsProps) => {
  const header = {
    node: <_LinkHome />,
    height: 63,
  }

  return <ProductsGrid context={['bestOffers']} data={products} header={header} />
}
export const weekPromotionsFetch = async (): Promise<WeekPromotionsProps> => {
  const products = await getWeekPromotions()
  return {
    products: products,
  }
}
export const WeekPromotions = (props: LoadablePageProps<WeekPromotionsProps>) => {
  return (
    <LoadablePage {...props} dataFetch={weekPromotionsFetch}>
      {_Grid}
    </LoadablePage>
  )
}
