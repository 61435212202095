import { clubbiStyled } from 'clubbi-ui'
import { Link } from 'react-router-dom'

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '15px',
  padding: '10px',
  marginBottom: '100px',

  [theme.breakpoints.up('md')]: {
    padding: '0px',
  },
}))

export const StyledSection = clubbiStyled('section')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',

  [theme.breakpoints.up('sm')]: {
    gap: '25px',
  },
}))

export const StyledOffersButton = clubbiStyled('h5')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'start',
  fontWeight: '700',
  gap: '4px',
  marginLeft: '5px',
  marginTop: '11px',
  color: theme.palette.brandAlert[50],
}))

export const StyledTitle = clubbiStyled('h5')(() => ({
  fontWeight: 'bold',
  paddingLeft: '10px',
  marginTop: '10px',
}))

export const StyledLink = clubbiStyled(Link)<{ isBold: boolean | undefined }>(
  ({ theme, isBold }) => ({
    height: '30px',
    padding: '5px 10px',
    textDecoration: 'none',
    fontWeight: isBold ? '700' : '400',
    color: theme.palette.brandGrey[100],
    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        borderRadius: '50px',
        fontWeight: 'bold',
        textDecoration: 'none',

        color: theme.palette.brandWhite.main,
        backgroundImage: `linear-gradient(to right, ${theme.palette.brandPurple[50]}, ${theme.palette.brandPurple[70]})`,
      },
    },

    '&:hover': {
      textDecoration: 'none',
    },
  })
)

export const StyledSeeMoreButton = clubbiStyled(Link)(({ theme }) => ({
  height: '30px',
  padding: '5px 10px',
  textDecoration: 'none',
  fontWeight: '500',
  color: theme.palette.brandPurple[70],
  '&:hover': {
    textDecoration: 'none',
  },
}))

export const StyledTagNew = clubbiStyled('span')(({ theme }) => ({
  width: '53px',
  height: '20px',
  padding: '0 10px',
  borderRadius: '50px',
  fontWeight: '700',
  marginLeft: '5px',

  color: theme.palette.brandWhite.main,
  background: theme.palette.brandAlert[50],
}))

export const StyledContainerDesktop = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  marginBottom: '100px',
}))

export const StyledCategoriesTitleContainer = clubbiStyled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))
