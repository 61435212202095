import { InputsFormProps } from './interfaces'
import { ProfileTextInput } from './ProfileTextInput'
import { useAppSelector } from '../../../common/hooks'
import { StyledContainer } from './styles/InputsForm.style'
import { inputFormLabels } from './constants'

export const InputsForm = ({ isContact }: InputsFormProps) => {
  const { merchant } = useAppSelector((state) => state.profile)
  return (
    <StyledContainer>
      {inputFormLabels.map(
        ({ company, contact }) =>
          merchant && (
            <ProfileTextInput
              label={isContact ? contact.label : company.label}
              readOnly={isContact ? contact.readOnly : company.readOnly}
              defaultContent={merchant?.[(isContact ? contact : company).name] ?? ''}
              width={!isContact && company.name === 'fullAddress' ? '100%' : '60%'}
              name={isContact ? contact.name : company.name}
              isContact={false}
              key={company.name}
            />
          )
      )}
    </StyledContainer>
  )
}
