import { clubbiStyled } from 'clubbi-ui'
import { getSeasonStyle } from '../../../../common/seasonality'

const season = getSeasonStyle()

export const StyledWrapper = clubbiStyled('div')(({ theme }) => {
  const styledConditions = {
    height: '38px',
    borderRadius: '100px',
    border: 'none',
  }

  return {
    '.aa-Autocomplete': styledConditions,
    '.aa-Form': styledConditions,
    '.InputWrapperPrefix': styledConditions,
    '.aa-DetachedSearchButton': styledConditions,
    '.aa-SubmitIcon': {
      color: season.hoverColor,
      [theme.breakpoints.up('sm')]: {
        marginTop: '5px',
      },
    },
  }
})
