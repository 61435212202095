import { clubbiStyled } from 'clubbi-ui'

export const StyledGroupedInvoiceCards = clubbiStyled('div')(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.down(750)]: {
    width: '100vw',
    margin: '0px',
  },
  [theme.breakpoints.up(751)]: {
    width: '90vw',
    margin: 'auto',
  },
}))

export const StyledDate = clubbiStyled('div')(({ theme }) => ({
  paddingTop: '50px',
  textAlign: 'left',
  paddingLeft: '3%',
  [theme.breakpoints.up('md')]: {
    paddingLeft: '21%',
  },
}))
