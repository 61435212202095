import { clubbiStyled } from 'clubbi-ui'
import { Link } from 'react-router-dom'

export const StyledP = clubbiStyled('p')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  fontStyle: 'normal',
  fontSize: theme.typography.fontSizeS,
  lineHeight: theme.spacing(7.75),
  color: theme.palette.brandBlack.main,
  width: '100%',
  textAlign: 'center',
  marginBottom: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    width: '80%',
  },
}))

export const StyledZendeskLink = clubbiStyled('a')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontStyle: 'normal',
  fontSize: theme.typography.fontSizeS,
  lineHeight: theme.spacing(7.75),
  color: theme.palette.brandBlack.main,
  textDecoration: 'underline',
}))

export const StyledOpenOrdersLink = clubbiStyled(Link)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontStyle: 'normal',
  fontSize: theme.typography.fontSizeS,
  lineHeight: theme.spacing(7.75),
  color: theme.palette.brandPurple.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}))
