import React, { useState, useRef, useEffect } from 'react'
import { StyledTag, StyledTooltip } from './styles/TwoDaysTag.style'

interface Props {
  direction?: 'right' | 'left'
  openTooltip?: boolean
  isProduct?: boolean
}

const TwoDaysTag = ({ direction = 'left', openTooltip = true, isProduct = true }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const textRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (textRef.current && !textRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div ref={textRef}>
      <StyledTag onClick={() => setIsOpen(!isOpen)}>+2 dias</StyledTag>
      {isOpen && openTooltip && (
        <StyledTooltip direction={direction}>
          {isProduct ? 'Produto' : 'Pedido'} entregue em 2 dias úteis.
        </StyledTooltip>
      )}
    </div>
  )
}

export default TwoDaysTag
