import { clubbiStyled } from 'clubbi-ui'

export const StyledTag = clubbiStyled('div')(({ theme }) => ({
  position: 'absolute',
  top: '23px',
  left: '8px',
  borderRadius: '2px',
  padding: '1px 2px',
  height: '12px',

  backgroundColor: 'rgba(255,255,255,0.7)',

  span: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: theme.typography.fontSize4XS,
    lineHeight: theme.typography.fontSize4XS,

    color: theme.palette.brandGrey[100],
  },

  [theme.breakpoints.up('sm')]: {
    top: '23px',
    left: '20px',
    padding: '1px 2px',
    height: '14px',

    span: {
      fontSize: theme.typography.fontSize2XS,
      lineHeight: theme.typography.fontSize2XS,
    },
  },
}))
