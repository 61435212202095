import { useAppSelector } from '../../common/hooks'
import { Product } from '../../store/data/types'
import { SectionRow } from '../organisms/product/SectionRow'
import { RecommendedCorridor } from '../molecules/recommendedCorridor/RecommendedCorridor'
import { Banner } from '../molecules/ShoppingList/Banner'
import { NotFoundComponent } from '../molecules/ShoppingList/NotFoundComponent'
import { LinkBackHome } from '../atoms'
import { StyledButtonContainer, StyledMain, StyledRecommended } from './styles/ShoppingList.style'
import { StyleContent } from './styles/Section.style'

export interface searchListProps {
  term: string
  products: Product[]
}

export const ShoppingList = () => {
  const { shoppingList } = useAppSelector((state) => state.searchList)
  const notFoundProducts = shoppingList.filter((search) => search.products.length === 0)

  return (
    <StyledMain>
      <StyledButtonContainer>
        <LinkBackHome />
      </StyledButtonContainer>
      <StyleContent>
        <Banner />
      </StyleContent>
      {shoppingList.map(
        (search, index) =>
          search.products.length > 0 && (
            <StyledRecommended key={index}>
              <SectionRow
                products={search.products}
                seeMoreLink={`search?query=${search.term}`}
                label={search.term}
                seeMoreCategories={search.products.length > 2}
              />
            </StyledRecommended>
          )
      )}
      {notFoundProducts.length > 0 && <NotFoundComponent notFoundSearch={notFoundProducts} />}
      <RecommendedCorridor />
    </StyledMain>
  )
}
