import { useHistory } from 'react-router'

import { useAppSelector } from '../../../common/hooks'

import {
  StyleBar,
  StyledInfosContainer,
  StyledLink,
  StyledQuantity,
} from './styles/FlyerGeneratorBar.style'

export const FlyerGeneratorBar = () => {
  const history = useHistory()
  const { eans } = useAppSelector((state) => state.salesFlyer)

  const redirect = () => history.push('/sales-flyer')

  return (
    <StyleBar active={eans.length}>
      <StyledInfosContainer>
        <StyledQuantity data-testid="products-quantity">{eans.length}</StyledQuantity>
        Produtos selecionados
      </StyledInfosContainer>
      <StyledLink onClick={redirect}>Gerar Encarte</StyledLink>
    </StyleBar>
  )
}
