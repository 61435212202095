import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useAppSelector } from '../../../common/hooks'
import { getPriceByMode } from '../../../common/productUtils'
import { CartChange } from '../../../store/data/types'
import { cartActions } from '../../../store/slices/cart'
import { isInternalClubber, quantityToCompleteComboCalc } from '../../../common/utils'
import { ComboBadge } from '../../molecules/PopupGetMoreForLess/ComboBadge'
import { CartItemInput } from '../../molecules/productItem/CartItemInput'
import { RootState } from '../../../store/reducers'
import { GetMoreForLessInformations } from '../../molecules/getMoreForLessInformations/GetMoreForLessInformations'
import { Money } from '../../atoms'
import { ArrowBackIcon, themeClubbi, useMediaQuery } from 'clubbi-ui'
import { StyledProductSimulationCard } from '../../molecules/simuledPriceDrawer/styles/SimuledPriceDrawer.style'
import { getProfitabilities } from '../../../services/profitabilityCalculatorApi'
import { CartProductsListItemProps, ConnectedPrice, selectProduct } from './CartProductsListItem'

import {
  StyledImage,
  StyledInput,
  StyledInputContainer,
  StyledPackagePrice,
  StyledPackageQuantity,
  StyledPriceContainer,
  StyledProductDescription,
  StyledProductDescriptionContainer,
  StyledProductDetailsContainer,
  StyledProductDetailsContent,
  StyledQtyPriceContainer,
  StyledResetButton,
  StyledTotalPrice,
  StyledTotalPriceContainer,
  StyledUnitPrice,
} from './styles/CartProductsListItem.style'
import { ProductProfitabilities } from '../../molecules/ProductProfitabilities'

export const CartListItemSimulation = ({
  item,
  className = '',
  ...props
}: CartProductsListItemProps) => {
  const { isPackageMode, product } = item
  const price = getPriceByMode(isPackageMode, product)
  const { merchantCode, clubberEmail, clubberArea } = useAppSelector((state) => state.session)
  const { cart, session } = useAppSelector((state) => state)
  const isGetMoreForLessPromotion = product.supplierPrices[0].getMoreForLess
  const value = useSelector((state: RootState) => selectProduct(state, { product }))
  const dispatch = useDispatch()
  const [profitabilities, setProfitabilities] = useState<any>({})
  const isDesktop = useMediaQuery(themeClubbi.breakpoints.up('md'))
  const [inputValue, setInputValue] = useState<number>()
  const [updatedItem, setUpdatedItem] = useState<any>(item)

  const height = 50
  const imageURL = height > 120 ? product.imageUrls.image300Px : product.imageUrls.image120Px

  const onChange = useCallback(
    (change: CartChange) => {
      dispatch(
        cartActions.setQuantity({
          product,
          cartChange: change,
          merchantCode: merchantCode!,
          clubberEmail,
          section: 'cart',
        })
      )
    },
    [dispatch, product]
  )

  const quantityGetMoreForLess = !!quantityToCompleteComboCalc(item)
  const productsProfitabilities = useAppSelector((state) => state.profitabilities)

  useEffect(() => {
    if (productsProfitabilities.products) {
      const filteredProduct = productsProfitabilities.products.find(
        (product) => product[item.product.ean]
      )?.[item.product.ean]

      setProfitabilities(filteredProduct)
    }
  }, [productsProfitabilities.products])

  const handleSimuledPriceChange = (productId: string, newSimuledPrice: number) => {
    setUpdatedItem({ ...item, simuledPrice: newSimuledPrice })
    dispatch(cartActions.setSimuledPrice({ productId, simuledPrice: newSimuledPrice }))
    setInputValue(newSimuledPrice)
  }

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      if (value !== '') {
        const numericValue = Number(value)
        setInputValue(numericValue)
        handleSimuledPriceChange(item.product.id, numericValue)
      } else {
        setInputValue(undefined)
      }
    },
    [setInputValue]
  )

  const simuledPrice = updatedItem.simuledPrice

  const handleClearSimuledPrices = async () => {
    dispatch(cartActions.clearSimuledPrice({ productId: item.product.id }))
    setInputValue(undefined)
    setUpdatedItem({ ...item, simuledPrice: undefined })
    await getProfitabilities(cart, session)
  }

  return (
    <StyledProductSimulationCard>
      <StyledProductDetailsContainer quantityGetMoreForLess={quantityGetMoreForLess}>
        {quantityGetMoreForLess && <ComboBadge item={item} />}
        <StyledProductDetailsContent>
          <StyledImage
            loading="lazy"
            decoding="async"
            src={imageURL}
            alt=""
            width={height}
            height={height}
          />
          <StyledProductDescriptionContainer>
            <StyledProductDescription>{product.description}</StyledProductDescription>
            {isPackageMode && (
              <StyledPriceContainer>
                <StyledPackageQuantity>
                  Caixa c/ {product.packageNumberOfItems}
                </StyledPackageQuantity>
                <StyledUnitPrice>
                  {'('}
                  <Money
                    amount={
                      isPackageMode
                        ? (simuledPrice || price) / (product?.packageNumberOfItems ?? 1)
                        : simuledPrice || price
                    }
                  />
                  {'/un'}
                  {')'}
                </StyledUnitPrice>
              </StyledPriceContainer>
            )}
            <StyledPackagePrice>
              <Money amount={simuledPrice || price} />
            </StyledPackagePrice>
            {isGetMoreForLessPromotion && (
              <GetMoreForLessInformations product={product} quantity={value} />
            )}
            <StyledInputContainer>
              <StyledInput
                type="number"
                name="number"
                step="any"
                placeholder="Digite aqui o novo valor"
                onChange={handleChange}
                value={inputValue !== undefined ? inputValue : ''}
              />
              <StyledResetButton onClick={handleClearSimuledPrices}>
                <ArrowBackIcon size="12px" />
              </StyledResetButton>
            </StyledInputContainer>
          </StyledProductDescriptionContainer>
        </StyledProductDetailsContent>
      </StyledProductDetailsContainer>
      <StyledQtyPriceContainer quantityGetMoreForLess={quantityGetMoreForLess}>
        {!isDesktop && isInternalClubber(clubberEmail, clubberArea) && profitabilities && (
          <ProductProfitabilities props={profitabilities} />
        )}
        <CartItemInput
          value={value}
          onChange={onChange}
          product={product}
          ctx={'cart'}
          isOfertao={!!product.inOfertao}
          isPackageMode={isPackageMode}
          variant="small"
        />
        <StyledTotalPriceContainer>
          <StyledTotalPrice>
            <ConnectedPrice price={simuledPrice || price!} id={product.id} product={product} />
          </StyledTotalPrice>
          {isDesktop && isInternalClubber(clubberEmail, clubberArea) && profitabilities && (
            <ProductProfitabilities props={profitabilities} />
          )}
        </StyledTotalPriceContainer>
      </StyledQtyPriceContainer>
    </StyledProductSimulationCard>
  )
}
