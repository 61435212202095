import { useState, useCallback, useEffect } from 'react'
import { WhatsappIcon } from 'react-share'
import { useAppDispatch, useAppSelector } from '../../common/hooks'
import { MerchantStatus, sessionActions } from '../../store/slices/session'
import { Modal, CloseIcon } from 'clubbi-ui'
import {
  StyledBody,
  StyledButton,
  StyledHeader,
  StyledLink,
  StyledMain,
  StyledTitle,
} from './styles/DeactivatedMerchantModal.style'
import { CX_WHATSAPP_NUMBER } from '../../common/constants'

export const DeactivatedMerchantModal = (): React.ReactElement => {
  const [showModal, setShowModal] = useState(false)
  const dispatch = useAppDispatch()
  const status = useAppSelector((state) => state.session.status)

  useEffect(() => {
    if (status === MerchantStatus.DEACTIVATED) {
      setShowModal(true)
    }

    return () => {
      dispatch(sessionActions.clearIsActive())
    }
  }, [status, dispatch])

  const hideModal = useCallback(() => {
    setShowModal(false)
  }, [])

  return (
    <Modal open={showModal} onClose={hideModal}>
      <StyledMain>
        <StyledHeader>
          <StyledTitle>Olá, cliente!</StyledTitle>
          <StyledButton onClick={hideModal}>
            <CloseIcon />
          </StyledButton>
        </StyledHeader>

        <StyledBody>
          <p>
            Percebemos que você possui uma divergência de cadastro conosco e isso impossibilita a
            realização de novos pedidos. Por favor, entre em contato com nosso time de atendimento
            para que possamos resolvê-la.
          </p>
          <p>
            É só{' '}
            <StyledLink
              href={`https://api.whatsapp.com/send?phone=${CX_WHATSAPP_NUMBER}&text=Ol%C3%A1%2C%20estou%20com%20d%C3%BAvidas%20sobre%20pend%C3%AAncias%20que%20possuo%20com%20a%20Clubbi.`}
              target="_blank"
              rel="noreferrer"
            >
              clicar aqui. <WhatsappIcon size={32} round={true} />
            </StyledLink>
          </p>
        </StyledBody>
      </StyledMain>
    </Modal>
  )
}
