import { clubbiStyled } from 'clubbi-ui'

export const StyledContainerClubberInfos = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  lineHeight: '14px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'start',
  padding: '10px',
  height: '100px',

  background: theme.palette.brandYellow[60],
  color: theme.palette.brandGrey[100],

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))

export const StyledHr = clubbiStyled('hr')(({ theme }) => ({
  width: '100%',
  margin: '10px 0',
}))

export const StyledSwitch = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '30px',
  width: '100%',
  margin: 0,
  padding: 0,

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))
