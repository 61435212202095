import { themeClubbi } from 'clubbi-ui'

import {
  StyledButton,
  StyledButtonContainer,
  StyledCloseButton,
  StyledCloseIcon,
  StyledContainer,
  StyledContent,
  StyledHeader,
  StyledHeaderText,
  StyledModal,
  StyledTextButton,
} from './styles/ConfirmationModal.style'
import { cancelOrder, cancelOrderItem } from '../../../services/ordersApi'
import { FetchOrdersFunction } from './OpenOrderDetails'
import { useState } from 'react'
import { OperatorInput } from './OperatorInput'

export const ConfirmationModal = ({
  showModal,
  setShowModal,
  cancellationId,
  isItem = true,
  fetchOrders,
}: {
  showModal: boolean
  setShowModal: (value: boolean | ((prevVar: boolean) => boolean)) => void
  cancellationId: number
  isItem: boolean
  fetchOrders: FetchOrdersFunction
}): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const [operator, setOperator] = useState('')

  const handleClose = (): void => {
    setOperator('')
    setShowModal(false)
  }

  const handleCancel = async (): Promise<void> => {
    setLoading(true)
    isItem ? await cancelOrderItem(cancellationId) : await cancelOrder(cancellationId, { operator })
    await fetchOrders()
    setLoading(false)
    setOperator('')
    setShowModal(false)
  }

  const fixedLabel = isItem ? 'Remover Item' : 'Cancelar Pedido'
  const loagindLabel = isItem ? 'Removendo Item...' : 'Cancelando Pedido...'

  return (
    <StyledModal isItem={isItem} open={showModal} onClose={handleClose}>
      <StyledContainer isItem={isItem}>
        <StyledHeader>
          <StyledHeaderText isItem={isItem}>
            {isItem ? 'Deseja remover este item do pedido?' : 'Deseja cancelar este pedido?'}
          </StyledHeaderText>
          <StyledCloseButton onClick={handleClose}>
            <StyledCloseIcon color={themeClubbi.palette.brandGrey.main} />
          </StyledCloseButton>
        </StyledHeader>
        <StyledContent>
          {isItem
            ? 'Removendo este item você não receberá este produto e o mesmo não será cobrado na fatura.'
            : 'Cancelando este pedido você não receberá nenhum destes produtos e não haverá cobrança.'}
        </StyledContent>
        {!isItem && <OperatorInput operator={operator} setOperator={setOperator} />}
        <StyledButtonContainer>
          <StyledButton
            size="small"
            typeButton="outline"
            label={loading ? loagindLabel : fixedLabel}
            onClick={handleCancel}
            isItem={isItem}
            disabled={isItem ? false : operator === ''}
          />
          <StyledTextButton
            size="small"
            typeButton="text"
            label={`Manter ${isItem ? 'Item' : 'Pedido'}`}
            onClick={handleClose}
          />
        </StyledButtonContainer>
      </StyledContainer>
    </StyledModal>
  )
}
