import { clubbiStyled } from 'clubbi-ui'

export const StyledList = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '5px',
  height: 'auto',
  width: '100%',
  marginTop: '10px',
  marginBottom: '120px',

  background: theme.palette.brandGrey[10],

  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
    marginBottom: '0px',
  },
}))

export const StyledLinkToHome = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  paddingRight: '25px',
  marginTop: 0,

  background: theme.palette.brandGrey[10],

  [theme.breakpoints.up('sm')]: {
    marginTop: '-15px',
  },
}))
