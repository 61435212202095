import { IconButton, clubbiStyled } from 'clubbi-ui'
import { BiX } from 'react-icons/bi'
import { Link } from 'react-router-dom'

export const StyledAlert = clubbiStyled('div')(({ theme }) => ({
  alignItems: 'top',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.brandWhite.main,
  color: theme.palette.brandBlack.main,
  width: '92%',
  padding: '12px',
  borderRadius: '4px',
  border: `1px solid ${theme.palette.brandGrey[100]}`,
  margin: '0px 20px',
  marginTop: '12px',
  [theme.breakpoints.up('sm')]: {
    margin: '0 32px',
    marginTop: '4px',
    width: '95%',
  },

  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    alignItems: 'center',
    padding: '24px',
  },
}))

export const StyledLink = clubbiStyled(Link)(({ theme }) => ({
  color: theme.palette.brandGrey[100],
  fontWeight: 400,
  fontSize: '11px',
  paddingTop: '4px',
  textDecoration: 'underline',
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    paddingTop: 0,
  },
}))

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  padding: '12px',
  paddingBottom: 0,
  gap: '8px',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 12px',
  },
}))

export const StyledRatingContainer = clubbiStyled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '12px',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'flex-start',
    marginTop: '8px',
  },
}))

export const StyledLabel = clubbiStyled('div')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '12px',
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
  },
}))

export const StyledCloseButton = clubbiStyled(IconButton)(({ theme }) => ({
  border: 'none',
  padding: 0,
  backgroundColor: theme.palette.brandWhite.main,
  alignItems: 'center',
  display: 'flex',
  height: '14px',
  width: '14px',
  color: theme.palette.brandBlack.main,
  [theme.breakpoints.up('md')]: {
    paddingBottom: '2px',
    alignItems: 'top',
    height: '20px',
    width: '20px',
  },
}))

export const StyledCloseIcon = clubbiStyled(BiX)(({ theme }) => ({
  height: '14px',
  width: '14px',
  [theme.breakpoints.up('md')]: {
    height: '20px',
    width: '20px',
  },
}))
