import { clubbiStyled } from 'clubbi-ui'

export const StyledMain = clubbiStyled('main')<{ noOrders: boolean }>(({ theme, noOrders }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: noOrders ? '100vh' : 'auto',
  paddingBottom: '300px',
  width: '95vw',
  padding: '10px',
}))

export const StyledHeading = clubbiStyled('h1')(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.brandPurple.main,
  padding: '24px 0',
  fontWeight: '1000',
  fontSize: '34px',
  lineHeight: '30px',
  [theme.breakpoints.up('md')]: {
    lineHeight: '50%',
    textAlign: 'left',
    fontSize: '40px',
    paddingBottom: '34px',
  },
}))

export const StyledPageMessage = clubbiStyled('p')(({ theme }) => ({
  textAlign: 'justify',
  color: 'black',
  width: '90vw',
  padding: '1%',
  [theme.breakpoints.up('md')]: {
    width: '68vw',
    margin: '0',
    paddingTop: '0',
  },
  [theme.breakpoints.up('xl')]: {
    width: '50vw',
    margin: '0',
    paddingTop: '0',
  },
}))

export const StyleContent = clubbiStyled('div')(({ theme }) => ({
  alignSelf: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    width: '68vw',
  },
  [theme.breakpoints.up('lg')]: {
    width: '50vw',
  },
}))
