import React, { useEffect, useState } from 'react'
import { createSelector } from 'reselect'
import { Grid, themeClubbi, useMediaQuery } from 'clubbi-ui'
import { useHistory } from 'react-router-dom'

import { CartItem } from '../../store/data/types'
import { RootState } from '../../store/reducers'
import Checkout from '../molecules/Checkout'
import { ProductsSuggestions } from '../organisms/Cart/ProductsSuggestion'
import { useAppDispatch, useAppSelector } from '../../common/hooks'
import { algoliaEventActions } from '../../store/slices/algoliaEvents'
import { tracking } from '../../services/algoliaApi'
import { store } from '../../store'
import { CartProductsList } from '../organisms/Cart/CartProductsList'
import { sessionActions } from '../../store/slices/session'
import { MissionModal } from '../molecules/MissionModal'
import { CartCreditInfo } from '../organisms/Cart/CartCreditInfo'
import { MissionProgress } from '../molecules/MissionProgress'
import { getPriceByMode } from '../../common/productUtils'
import { MissionsRow } from '../molecules/MissionsRow'

import {
  StyledContainer,
  StyledContainerMain,
  StyledDiv,
  StyledMissionContainer,
  StyledMobileGrid,
} from './styles/Cart.style'
import { getProfitabilities } from '../../services/profitabilityCalculatorApi'
import { isExternalClubber } from '../../common/utils'

export const totalPriceSelector = createSelector(
  (state: RootState) => state.cart,
  (cart) =>
    Object.values(cart).reduce(
      (x: number, cartItem: CartItem) =>
        x + cartItem.quantity * getPriceByMode(cartItem.isPackageMode, cartItem.product)!,
      0
    )
)

export const _Cart = () => {
  const { clubberArea } = useAppSelector((state) => state.session)
  const toggleResponsive = useMediaQuery(themeClubbi.breakpoints.up('md'))
  const [showModal, setShowModal] = useState(false)
  const isClubber = !isExternalClubber(clubberArea)

  return (
    <StyledContainerMain container>
      <MissionModal showModal={showModal} setShowModal={setShowModal}></MissionModal>
      <StyledMissionContainer item></StyledMissionContainer>
      <StyledContainer
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        marginTop={3}
      >
        {toggleResponsive && (
          <>
            <Grid item sx={{ width: '50%' }} alignSelf="flex-start">
              <CartCreditInfo />
              <CartProductsList />
              <MissionsRow />
              {isClubber && <ProductsSuggestions />}
            </Grid>
            <Grid item sx={{ width: '30%' }} alignSelf="flex-start">
              <MissionProgress />
              <Checkout />
            </Grid>
          </>
        )}
        {!toggleResponsive && (
          <>
            <StyledMobileGrid item>
              <CartCreditInfo />
              <CartProductsList />
              <MissionProgress />
              {isClubber && <ProductsSuggestions />}
              <MissionsRow />
            </StyledMobileGrid>
            <StyledMobileGrid item>
              <Checkout />
              <StyledDiv />
            </StyledMobileGrid>
          </>
        )}
      </StyledContainer>
    </StyledContainerMain>
  )
}

export const Cart = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { merchantCode, clubberEmail } = useAppSelector((state) => state.session)
  const { cart, session } = useAppSelector((state) => state)
  const history = useHistory()

  useEffect(() => {
    dispatch(sessionActions.showAlert())

    // TODO: maybe we should not send the events here?
    const algoliaEvents = store.getState().algoliaEvent.events
    if (algoliaEvents.length > 0) {
      tracking(algoliaEvents).then(() => dispatch(algoliaEventActions.cleanUpEvents()))
    }
  }, [dispatch])

  useEffect(() => {
    if (clubberEmail && cart) {
      getProfitabilities(cart, session)
    }
  }, [])

  useEffect(() => {
    if (!merchantCode) {
      history.goBack()
    }
  }, [merchantCode, history])

  return <_Cart />
}
