import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { useState } from 'react'
import { NewClientData, NewClientForm } from '../molecules/NewClientForm'
import Icon from '../atoms/Icon'
import { Link } from '../atoms'

const StyledContainer = styled(Container)`
  margin-top: 80px;
`

const NewClientReceived = ({ name }: { name: string }) => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center text-center p-4"
      style={{ height: 'calc(100vh - 200px)' }}
    >
      <h1 className="text-success">
        <Icon.EnvelopOpen size={'1.5em'} className="mr-2" />
        Recebemos o seu contato!
      </h1>
      <p style={{ fontSize: '16px' }} className="mt-4">
        {name}, obrigado por entrar em contato! <br />
        Retornaremos sua mensagem em breve
        <br />
        <Link to="/">
          <Button variant={'secondary'} className={'mt-2'}>
            <Icon.ChevronLeft className={'mr-2'} size={'1em'} />
            Voltar
          </Button>
        </Link>
      </p>
    </div>
  )
}

export const NewClient = () => {
  const [data, setData] = useState<NewClientData | undefined>(undefined)

  return (
    <StyledContainer>
      <Row className={'py-2'}>
        <Col lg={{ span: 6, offset: 3 }} sm={{ span: 12, offset: 0 }}>
          {data ? (
            <NewClientReceived name={data.name} />
          ) : (
            <Card className={'mt-2'}>
              <NewClientForm setData={setData} />{' '}
            </Card>
          )}
        </Col>
      </Row>
    </StyledContainer>
  )
}
