import { clubbiStyled } from 'clubbi-ui'

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  textAlign: 'left',
  fontSize: theme.typography.fontSize2XS,
  paddingBottom: '12px',
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
  },
}))

export const StyledLabel = clubbiStyled('div')(({ theme }) => ({
  fontWeight: 400,
}))

export const StyledRowWrapper = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  gap: '4px',
}))

export const StyledValue = clubbiStyled('div')(({ theme }) => ({
  fontWeight: 700,
}))
