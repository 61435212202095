import React, { useState, MouseEvent, FormEvent } from 'react'
import { themeClubbi } from 'clubbi-ui'

import { NPS, putNpsFeedback } from '../../../services/merchantApi'
import { GOOD_GRADES } from '../../../common/constants'
import { modalController } from '../ModalManager'

import {
  StyledCloseButton,
  StyledCloseIcon,
  StyledContainer,
  StyledContainerOptions,
  StyledContent,
  StyledHeader,
  StyledModal,
  StyledOptionButton,
  StyledInputComment,
  StyledButton,
} from './styles/NpsGoodGrade.style'

export const NpsGoodGrade = ({
  showNps,
  setShowNps,
  latestNps,
}: {
  showNps: modalController
  setShowNps: (value: modalController | ((prevVar: modalController) => modalController)) => void
  latestNps: NPS
}): JSX.Element => {
  const [goodGradeValue, setGoodGradeValue] = useState<Set<string>>(new Set())
  const [comment, setComment] = useState('')

  const areOptionsSelected = [...goodGradeValue].length < 1

  const handleClose = (): void => {
    setShowNps((state) => ({ ...state, showGoodGrade: false }))
  }

  const checkGoodGrades = (event: MouseEvent<HTMLButtonElement>): void => {
    const value = event.currentTarget.value
    let goodGrades = new Set(goodGradeValue)
    if (goodGrades.has(value)) {
      goodGrades.delete(value)
    } else {
      if (goodGrades.size < 1) {
        goodGrades = new Set([...goodGrades, ...[value]])
      } else {
        goodGrades.delete(goodGrades.values().next().value)
        goodGrades = new Set([...goodGrades, ...[value]])
      }
    }
    setGoodGradeValue(goodGrades)
  }

  const handleChange = (event: FormEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value
    setComment(value)
  }

  const submitNpsFeedback = async (): Promise<void> => {
    setShowNps((state) => ({ ...state, showGoodGrade: false, showNpsThanks: true }))
    const feedback: { [feedback: string]: string } = {}
    ;[...goodGradeValue].forEach((element, index) => {
      feedback[`feedback_${index + 1}`] = element
    })
    await putNpsFeedback(latestNps.id, {
      nps_experience_feedback: feedback,
      nps_comment: comment,
    })
  }

  return (
    <StyledModal open={showNps.showGoodGrade} onClose={handleClose}>
      <StyledContainer>
        <StyledHeader>
          Nos ajude a melhorar a sua experiência
          <StyledCloseButton onClick={handleClose}>
            <StyledCloseIcon color={themeClubbi.palette.brandGrey.main} />
          </StyledCloseButton>
        </StyledHeader>
        <StyledContent>
          Quais os três principais benefícios de usar os serviços da Clubbi?
          <StyledContainerOptions>
            {GOOD_GRADES.map((grade, index) => (
              <StyledOptionButton
                key={index}
                name={grade}
                value={grade}
                onClick={checkGoodGrades}
                bgColor={goodGradeValue.has(grade)}
              >
                {grade}
              </StyledOptionButton>
            ))}
          </StyledContainerOptions>
        </StyledContent>
        <StyledContent>
          Escreva comentários, elogios, sugestões ou reclamações
          <StyledInputComment onInput={handleChange} />
        </StyledContent>
        <StyledButton
          size="small"
          typeButton="filled"
          label="Enviar avaliação"
          onClick={submitNpsFeedback}
          disabled={areOptionsSelected}
        />
      </StyledContainer>
    </StyledModal>
  )
}
