import { RouteComponentProps } from 'react-router'

import { Product } from '../../../store/data/types'
import { getLowestUnitPrice } from '../../../common/productUtils'
import { showPrices } from '../../../common/utils'

import {
  StyledProductList,
  StyledDescription,
  StyledImage,
  StyledContent,
  StyledContentPrice,
  StyledProduct,
  StyledTitle,
  StyledCurrencySign,
  StyledPrice,
  StyledHr,
} from './styles/RecommendedSearchBarProducts.style'

export const RecommendedProductsAlgolia = ({
  products,
  history,
  merchantCode,
}: {
  products: Product[]
  history: RouteComponentProps['history']
  merchantCode: string | undefined
}) => {
  const getPrice = (index: number) => {
    const price = getLowestUnitPrice(products[index])
    const formatPrice = price!.toFixed(2).replace('.', ',')

    return showPrices(merchantCode) ? formatPrice : '-'
  }

  return (
    <StyledProductList>
      <StyledHr />
      <StyledTitle>RECOMENDADOS</StyledTitle>
      {products.map((product, index) => {
        const price = getPrice(index)

        return (
          <StyledProduct
            key={product.id}
            onClick={() => {
              history.replace({
                pathname: '/recommended/search',
                search: new URLSearchParams({ query: product.description }).toString(),
              })
              const detachedModeSearchPanel = document.querySelector('.aa-Detached')
              if (detachedModeSearchPanel) {
                detachedModeSearchPanel.classList.remove('aa-Detached')
              }
            }}
          >
            <StyledContent data-testid="recommended-search-products">
              <StyledImage src={product.imageUrls.image120Px} />
              <StyledDescription>{product.description}</StyledDescription>
            </StyledContent>
            <StyledContentPrice>
              <StyledCurrencySign>R$</StyledCurrencySign>
              <StyledPrice>{price}</StyledPrice>
            </StyledContentPrice>
          </StyledProduct>
        )
      })}
    </StyledProductList>
  )
}
