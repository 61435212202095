import { clubbiStyled } from 'clubbi-ui'

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  backgroundColor: theme.palette.brandWhite.main,
  '>div': {
    padding: '14px',
    [theme.breakpoints.up('md')]: {
      padding: '28px',
    },
  },
}))

export const StyledHR = clubbiStyled('hr')(({ theme }) => ({
  color: theme.palette.brandGrey.light,
  width: '100%',
  margin: 0,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

export const StyledSectionTitleContainer = clubbiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingBottom: '0 !important',
}))

export const StyledSectionTitle = clubbiStyled('span')(({ theme }) => ({
  color: theme.palette.brandBlack.main,
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '170.2%',
  [theme.breakpoints.up('md')]: {
    fontSize: '20px',
    '>div': {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
    },
  },
}))

export const StyledSectionSubTitle = clubbiStyled('span')(({ theme }) => ({
  color: theme.palette.brandGrey[60],
  fontSize: '10px',
  lineHeight: '146.2%',
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
  },
}))

export const StyledGap = clubbiStyled('div')(({ theme }) => ({
  height: '20px',
  backgroundColor: theme.palette.brandWhite.main,
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))

export const StyledMobileSection = clubbiStyled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none !important',
  },
}))

export const StyledDesktopSection = clubbiStyled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))
