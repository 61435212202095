import { useEffect, useState } from 'react'
import { CloseIcon } from 'clubbi-ui'
import { useLocation } from 'react-router'

import { SelectMissionTooltipPointer } from './SelectMissionTooltipPointer'

import {
  StyledClose,
  StyledDescription,
  StyledTextContainer,
  StyledTitle,
  StyledTooltip,
} from './styles/SelectMissionTooltip.style'

export const SelectMissionTooltip = () => {
  const { pathname } = useLocation()
  const [isOpenHome, setIsOpenHome] = useState<boolean>(false)
  const [isOpenCheckout, setIsOpenCheckout] = useState<boolean>(false)
  const isInHomePage = pathname === '/'
  const isInCartPage = pathname === '/cart'

  useEffect(() => {
    const shownHome = !!localStorage.getItem('shown-select-mission-tooltip-home')
    setIsOpenHome(!shownHome)

    const shownCheckout = !!localStorage.getItem('shown-select-mission-tooltip-checkout')
    setIsOpenCheckout(!shownCheckout)
  }, [])

  const close = () => {
    if (isInHomePage) {
      setIsOpenHome(false)
      localStorage.setItem('shown-select-mission-tooltip-home', JSON.stringify(true))
    }
    if (isInCartPage) {
      setIsOpenCheckout(false)
      localStorage.setItem('shown-select-mission-tooltip-checkout', JSON.stringify(true))
    }
  }

  const getTooltipInfos = () => {
    if (isInHomePage) {
      return {
        title: 'As missões mudaram!',
        description:
          'Agora você escolhe quais missões deseja ativar. Somente as missões ativas irão gerar descontos no seu pedido.',
      }
    }
    if (isInCartPage) {
      return {
        title: 'Verifique aqui as missões ativas',
        description:
          'Antes de finalizar seu pedido ative as missões e confira os descontos nessa compra.',
      }
    }
  }

  const tooltipInfos = getTooltipInfos()

  const getIsOpen = () => {
    if (isInHomePage) return isOpenHome
    if (isInCartPage) return isOpenCheckout
    return false
  }

  const isOpen = getIsOpen()

  return (
    <>
      {isOpen && (
        <StyledTooltip isInCartPage={isInCartPage}>
          <StyledClose onClick={close}>
            <CloseIcon />
          </StyledClose>
          <StyledTextContainer>
            <StyledTitle>{tooltipInfos?.title}</StyledTitle>
            <StyledDescription>{tooltipInfos?.description}</StyledDescription>
          </StyledTextContainer>
          <SelectMissionTooltipPointer />
        </StyledTooltip>
      )}
    </>
  )
}
