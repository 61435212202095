import { Alert, clubbiStyled } from 'clubbi-ui'

export const StyledAlert = clubbiStyled(Alert)(({ theme }) => ({
  alignItems: 'center',
  marginBottom: 0,
  marginTop: 0,
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.brandWarning[10],
  color: theme.palette.brandWarning[50],

  [theme.breakpoints.up('sm')]: {
    width: '100%',
    marginTop: '-4px',
  },
}))
