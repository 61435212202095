import {
  ContactType,
  Merchant,
  MerchantContact,
  MerchantDeliveryRestriction,
} from '../../../store/data/types'
import {
  MAX_RESTRICTION_DAYS,
  MIN_DELIVERY_WINDOW_HOURS,
  MIN_PHONE_LENGTH,
  MIN_SATURDAY_CLOSURE,
  MIN_WEEKDAY_CLOSURE,
  timeSelectionData,
} from './constants'
import { MerchantDeliveryRestrictionKey, MerchantFromApi } from './interfaces'

export const getContactByType = (
  targetContactType: ContactType,
  merchantContacts?: MerchantContact[] | null
) => merchantContacts?.find(({ contactType }) => contactType === targetContactType)

export const getPatchMerchantBody = (
  merchant: Merchant,
  merchantContacts?: MerchantContact[] | null
) => {
  return {
    clientSiteCode: merchant.merchantCode,
    paymentResponsiblePhone: getContactByType(ContactType.FINANCIAL, merchantContacts)?.phone,
    phones: merchantContacts,
    financeEmail: merchant.financeEmail,
  }
}

export const getPatchMerchantDeliveryRestrictionBody = (
  merchantDeliveryRestriction: MerchantDeliveryRestriction,
  hasBreak?: boolean
) => ({
  ...merchantDeliveryRestriction,
  deliveryBreakTimeStart: hasBreak ? merchantDeliveryRestriction.deliveryBreakTimeStart : null,
  deliveryBreakTimeFinish: hasBreak ? merchantDeliveryRestriction.deliveryBreakTimeFinish : null,
})

export const convertMerchant = (
  merchant: Merchant | MerchantFromApi,
  fromApi: boolean
): Merchant | MerchantFromApi => {
  if (fromApi) {
    const { clientSiteCode, ...rest } = merchant as MerchantFromApi
    return {
      ...(rest as MerchantFromApi),
      merchantCode: clientSiteCode,
    }
  } else {
    const { merchantCode, ...rest } = merchant as Merchant
    return {
      ...(rest as Merchant),
      clientSiteCode: merchantCode,
    }
  }
}

export const getTimeDifference = (timeFinish?: string | null, timeStart?: string | null) => {
  return (
    (new Date(`1970-01-01T${timeFinish ?? '20:00:00'}Z`).getTime() -
      new Date(`1970-01-01T${timeStart ?? '08:00:00'}Z`).getTime()) /
    (1000 * 60 * 60)
  )
}

export const isTotalDeliveryWindowValid = (
  hasBreak?: boolean,
  merchantDeliveryRestriction?: MerchantDeliveryRestriction | null
) => {
  if (merchantDeliveryRestriction) {
    const {
      deliveryTimeStart,
      deliveryTimeFinish,
      deliveryBreakTimeStart,
      deliveryBreakTimeFinish,
    } = merchantDeliveryRestriction

    const regularWindow = getTimeDifference(deliveryTimeFinish, deliveryTimeStart)

    if (!hasBreak) return regularWindow >= MIN_DELIVERY_WINDOW_HOURS

    const totalBreak = getTimeDifference(deliveryBreakTimeFinish, deliveryBreakTimeStart)

    return regularWindow - totalBreak >= MIN_DELIVERY_WINDOW_HOURS
  }
  return false
}

export const areDeliveryDaysValid = (
  merchantDeliveryRestriction?: MerchantDeliveryRestriction | null
) => (merchantDeliveryRestriction?.deliveryDaysRestriction?.length ?? 0) <= MAX_RESTRICTION_DAYS

export const formatHours = (time: string) => {
  return time.substring(0, 5)
}

export const getDaysToDelivery = (daysToNotDelivery: number[]) => {
  const weekdayMapping: { [index: number]: string } = {
    2: 'Segunda-feira',
    3: 'Terça-feira',
    4: 'Quarta-feira',
    5: 'Quinta-feira',
    6: 'Sexta-feira',
    7: 'Sábado',
  }
  const daysToDelivery: string[] = []

  Object.entries(weekdayMapping).forEach(([index, day]) => {
    if (!daysToNotDelivery.includes(Number(index))) {
      daysToDelivery.push(day)
    }
  })

  return daysToDelivery
}

export const formatListWithCommas = (stringArray: string[]) => {
  let result = ''
  stringArray.forEach((el, index) => {
    if (index < stringArray.length - 2) {
      result += el + ', '
    } else if (index === stringArray.length - 2) {
      result += el + ' e '
    } else {
      result += el
    }
  })
  return result
}

export const getTimeSelectionData = (
  dropdownKey: MerchantDeliveryRestrictionKey,
  merchantDeliveryRestriction: MerchantDeliveryRestriction
) => {
  if (dropdownKey == 'saturdayClosure') {
    return timeSelectionData.filter(({ value }) => value >= MIN_SATURDAY_CLOSURE)
  }
  const deliveryTimeStart = merchantDeliveryRestriction.deliveryTimeStart
  const deliveryTimeFinish = merchantDeliveryRestriction.deliveryTimeFinish
  const deliveryBreakTimeStart = merchantDeliveryRestriction.deliveryBreakTimeStart

  if (dropdownKey == 'deliveryBreakTimeStart') {
    return timeSelectionData.filter(
      ({ value }) => value > deliveryTimeStart! && value < deliveryTimeFinish!
    )
  }
  if (dropdownKey == 'deliveryBreakTimeFinish') {
    return timeSelectionData.filter(
      ({ value }) =>
        value > (deliveryBreakTimeStart ?? deliveryTimeStart!) && value < deliveryTimeFinish!
    )
  }
  if (dropdownKey == 'deliveryTimeFinish') {
    return timeSelectionData.filter(({ value }) => value >= MIN_WEEKDAY_CLOSURE)
  }
  return timeSelectionData
}

export const isBreakValid = (
  hasBreak?: boolean,
  merchantDeliveryRestriction?: MerchantDeliveryRestriction | null
) => {
  if (!hasBreak) return true
  if (merchantDeliveryRestriction) {
    const {
      deliveryTimeStart,
      deliveryTimeFinish,
      deliveryBreakTimeStart,
      deliveryBreakTimeFinish,
    } = merchantDeliveryRestriction

    if (deliveryBreakTimeStart! > deliveryBreakTimeFinish!) return false

    const isBreakWithinWorkingHours = (breakTime: string) =>
      breakTime > deliveryTimeStart! && breakTime < deliveryTimeFinish!

    const areBreaksWithinWorkingHours =
      isBreakWithinWorkingHours(deliveryBreakTimeStart!) &&
      isBreakWithinWorkingHours(deliveryBreakTimeFinish!)

    return areBreaksWithinWorkingHours
  }
}

export const phoneMask = (value?: string) => {
  if (!value) return ''
  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{2})(\d)/, '($1) $2')
  value = value.replace(/(\d)(\d{4})$/, '$1-$2')
  return value
}

export const removePhoneMask = (value?: string) => {
  if (!value) return ''
  value = value.replace(/\D/g, '')
  return value
}

export const hasContactType = (
  merchantContacts: MerchantContact[],
  targetContactType: ContactType
) =>
  merchantContacts.some(
    ({ contactType, phone }) =>
      contactType === targetContactType && phone.length >= MIN_PHONE_LENGTH
  )

export const areRequiredContactsFilled = (merchantContacts?: MerchantContact[] | null) => {
  if (merchantContacts) {
    return (
      hasContactType(merchantContacts, ContactType.MAIN_WHATSAPP) &&
      hasContactType(merchantContacts, ContactType.FINANCIAL)
    )
  }
  return false
}
