import { clubbiStyled } from 'clubbi-ui'

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  width: '90%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: '5px',

  background: theme.palette.brandWhite.main,

  [theme.breakpoints.up('sm')]: {
    width: '40%',
  },
}))

export const StyledHeaderContainer = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  width: '100%',
  minHeight: '36px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '5px',
  padding: '0 12px',

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXS,
    padding: '0 20px',
  },
}))

export const StyledDescription = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',

  color: theme.palette.brandGrey[100],
}))

export const StyledTitle = clubbiStyled('div')(({ theme }) => ({
  fontWeight: 700,
  marginRight: '8px',

  color: theme.palette.brandGrey[100],
}))

export const StyledOpenButton = clubbiStyled('button')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: 'none',
  width: '100%',

  color: theme.palette.brandGrey[100],
  background: theme.palette.brandWhite.main,
}))

export const StyledInputsContainer = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  width: '100%',
  height: '400px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'start',
  padding: '0 12px',
  borderRadius: '5px',

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXS,
    padding: '0 20px',
    height: '430px',
  },
}))

export const StyledInputLabel = clubbiStyled('label')(({ theme }) => ({
  width: '100%',
  margin: '10px 0',
  display: 'flex',
  flexDirection: 'column',

  color: theme.palette.brandGrey[100],
}))

export const StyledInputMerchatCode = clubbiStyled('input')(({ theme }) => ({
  width: '100%',
  height: '34px',
  margin: '5px 0',
  padding: '0 10px',
  borderRadius: '5px',
  border: `solid 1px ${theme.palette.brandGrey[60]}`,

  [theme.breakpoints.up('sm')]: {
    width: '201px',
  },

  '&:focus': {
    border: `solid 1px ${theme.palette.brandGrey[60]}`,
    outline: 'none',
  },
}))

export const StyledInfoAndButtonContainer = clubbiStyled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const StyledInfo = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,

  color: theme.palette.brandGrey[60],

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXS,
  },
}))

export const StyledCopyButton = clubbiStyled('button')(({ theme }) => ({
  border: 'none',
  width: '26px',

  background: theme.palette.brandWhite.main,
}))

export const StyledInputEans = clubbiStyled('textarea')(({ theme }) => ({
  width: '100%',
  height: '164px',
  margin: '5px 0',
  padding: '10px',
  borderRadius: '5px',
  border: `solid 1px ${theme.palette.brandGrey[60]}`,

  '&:focus': {
    border: `solid 1px ${theme.palette.brandGrey[60]}`,
    outline: 'none',
  },
}))

export const StyledDateContainer = clubbiStyled('div')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginBottom: '15px',

  color: theme.palette.brandGrey[100],

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXS,
  },
}))

export const StyledCheckbox = clubbiStyled('input')(({ theme }) => ({
  width: '20px',

  [theme.breakpoints.up('sm')]: {
    width: '30px',
  },
}))
