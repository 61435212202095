import { FetchOrdersFunction, OrderItem } from './OpenOrderDetails'
import { StyledSection } from './styles/ProductsDetails.style'
import { SectionDetailRow } from './SectionDetailRow'
import { themeClubbi, useMediaQuery } from 'clubbi-ui'

function SectionDetailItem({
  sectionName,
  productInfoList,
  fetchOrders,
  orderSupplier,
}: {
  sectionName: string
  productInfoList: OrderItem[]
  fetchOrders: () => Promise<void>
  orderSupplier: string | null
}) {
  const isMobile = useMediaQuery(themeClubbi.breakpoints.down('md'))
  return (
    <>
      <StyledSection data-testid={`section-detail-${sectionName}`}>{sectionName}</StyledSection>
      {productInfoList.map((productInfo, index) => (
        <SectionDetailRow
          isMobile={isMobile}
          product={productInfo}
          key={index}
          fetchOrders={fetchOrders}
          orderSupplier={orderSupplier}
        />
      ))}
    </>
  )
}

export const ProductsDetails = ({
  productBuckets,
  fetchOrders,
  orderSupplier,
}: {
  productBuckets: OrderItem[]
  fetchOrders: FetchOrdersFunction
  orderSupplier: string | null
}) => {
  const sections = [
    ...new Set(productBuckets.map((product: OrderItem) => product?.category)),
  ].sort()

  return (
    <>
      {sections
        .map(
          (section: string) =>
            [
              section,
              (productBuckets as OrderItem[]).filter(
                (product: OrderItem) => product.category == section
              ),
            ] as [string, OrderItem[]]
        )
        .map(([sectionName, productsInfo]) => (
          <SectionDetailItem
            sectionName={sectionName}
            productInfoList={productsInfo}
            key={sectionName}
            fetchOrders={fetchOrders}
            orderSupplier={orderSupplier}
          />
        ))}
    </>
  )
}
