import { MenuItem, Select, clubbiStyled } from 'clubbi-ui'

export const StyledContainer = clubbiStyled('div')<{ isReadOnly: boolean }>(
  ({ isReadOnly, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.brandWhite.main,
    color: theme.palette.brandBlack.main,
    opacity: isReadOnly ? '0.2' : '1',
  })
)

export const StyledLabel = clubbiStyled('p')(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '170.2%',
  textAlign: 'left',
  margin: 0,
  marginTop: '3px',
  fontWeight: 700,
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
  },
}))

export const StyledFromToContainer = clubbiStyled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '14px',
  marginTop: '4px',
}))

export const StyledFromToLabel = clubbiStyled('p')(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '170.2%',
  color: theme.palette.brandBlack.main,
  textAlign: 'left',
  margin: 0,
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
  },
}))

export const StyledSelect = clubbiStyled(Select)<{ isReadOnly?: boolean }>(
  ({ isReadOnly, theme }) => ({
    border: `1px solid ${theme.palette.brandGrey[60]}`,
    width: '80px',
    height: '32px',
    fontSize: '12px',
    lineHeight: '170.2%',
    ':hover': !isReadOnly && {
      border: `1px solid ${theme.palette.brandPurple[60]}`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': !isReadOnly && {
      border: `1px solid ${theme.palette.brandPurple[60]}`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      width: '96px',
      height: '42px',
    },
  })
)

export const StyledMenuItem = clubbiStyled(MenuItem)<{ isLast?: boolean }>(({ isLast, theme }) => ({
  marginBottom: isLast ? '36px' : 0,
  [theme.breakpoints.up('md')]: {
    marginBottom: 0,
  },
}))
